import React, { Component } from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';
import classes from './Breadcrumb.scss';

interface Props {
	children: React.ReactNode;
	link: string;
	current?: boolean;
}

export class Breadcrumb extends Component<Props> {
	public render() {
		return (
			<li className={classnames(classes.container)}>
				<Link
					to={this.props.link}
					className={classes.breadcrumbLink}
					aria-current={this.props.current ? 'page' : undefined}
				>
					<div className={classes.shortLink}>•••</div>
					<span>{this.props.children}</span>
				</Link>
			</li>
		);
	}
}
