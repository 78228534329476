import { handleActions } from '../..';
import { SmsCallerIdState } from './types';
import { actions } from './actions';

const initialState: SmsCallerIdState = {};

export default handleActions<SmsCallerIdState, PossibleActions<typeof actions>>(
	{
		SMS_CALLERIDS_FETCH_SUCCESS: (state, action) => {
			return {
				...state,
				[action.data.smsId]: { items: action.payload.items },
			};
		},
		SMS_CALLERID_SET_ACTIVE_PENDING: (state, { data }) => {
			const smsCallerIds = state[data.smsId];
			if (smsCallerIds) {
				const newItems = smsCallerIds.items.map(smsCallerId => ({
					...smsCallerId,
					defaultNumber: smsCallerId.id === data.smsCallerId ? data.isDefaultNumber : false,
				}));

				if (!data.isDefaultNumber) {
					newItems[0].defaultNumber = true;
				}
				return { ...state, [data.smsId]: { items: newItems } };
			}
			return state;
		},
		VERIFY_SMS_CALLERID_SUCCESS: (state, { payload, data }) => {
			const smsCallerIds = state[data.smsId];
			if (smsCallerIds) {
				const newItems = smsCallerIds.items.map(smsCallerId => {
					if (smsCallerId.id === data.smsCallerId) {
						return { ...smsCallerId, verified: payload.verified };
					}
					return smsCallerId;
				});
				return { ...state, [data.smsId]: { items: newItems } };
			}
			return state;
		},
		CREATE_SMS_CALLERIDS_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				[data.smsId]: { items: payload.smsCallerIds.items },
			};
		},
	},
	initialState
);
