import { createAction } from '../..';

import api from '../../../api';
import { Contact } from '../../../api/types/contacts';
import { ReduxState } from '../../types';

import { ContactsCreateAction, ContactsFetchAction } from './types';

const PAGE_SIZE = 1000;

async function fetchContactPage(
	page: number,
	signal: AbortSignal
): Promise<{ items: Contact[]; totalCount: number }> {
	const result = await api.cancellable(signal).getContacts({
		limit: PAGE_SIZE,
		offset: page * PAGE_SIZE,
	});

	return result;
}

export const fetchContacts =
	(force?: boolean) =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		const state = getState().contacts;

		if (state.abortController || (state.fetched && !force)) {
			return;
		}

		const abortController = new AbortController();
		dispatch({
			type: 'CONTACTS_FETCH_PENDING',
			abortController,
		});

		try {
			const internalContacts = await api.cancellable(abortController.signal).getInternalContacts();

			dispatch({
				type: 'CONTACTS_FETCH_BATCH_INTERNAL',
				contacts: internalContacts.items,
			});

			const firstPage = await fetchContactPage(0, abortController.signal);
			dispatch({
				type: 'CONTACTS_FETCH_BATCH',
				contacts: firstPage.items,
			});

			const newIds = new Set<string>(firstPage.items.map(contact => contact.id));
			const totalPages = Math.ceil(firstPage.totalCount / PAGE_SIZE);

			for (let currentPage = 1; currentPage < totalPages; currentPage += 1) {
				const page = await fetchContactPage(currentPage, abortController.signal); // eslint-disable-line no-await-in-loop

				for (const contact of page.items) {
					newIds.add(contact.id);
				}

				dispatch({
					type: 'CONTACTS_FETCH_BATCH',
					contacts: page.items,
				});
			}

			const removedIds = [];
			for (const contact of state.items) {
				if (typeof contact.id === 'string' && !newIds.has(contact.id)) {
					removedIds.push(contact.id);
				}
			}

			dispatch({
				type: 'CONTACTS_FETCH_SUCCESS',
				removedIds,
			});
		} catch (error) {
			if (!(error instanceof Error && error.name === 'AbortError')) {
				dispatch({
					type: 'CONTACTS_FETCH_ERROR',
					error,
				});
			}
		}
	};

export const forceFetchContacts =
	() =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		const abortController = getState().contacts.abortController;

		if (abortController) {
			abortController.abort();
		}

		dispatch({
			type: 'CONTACTS_FETCH_ABORT',
		});

		await fetchContacts(true)(dispatch, getState);
	};

export const fetchContactsByPhonenumbers =
	(e164Numbers: string[]) =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		if (getState().contacts.fetched || e164Numbers.length === 0) {
			return;
		}

		// api expects phonenumber without +
		const result = await api.getContactsByPhonenumbers(
			e164Numbers.map(number => number.replace('+', ''))
		);

		dispatch({
			type: 'CONTACTS_FETCH_BATCH',
			contacts: result.items,
		});
	};

export const createContact =
	(contact: Omit<Contact, 'id'>) =>
	(
		dispatch: <Action extends ContactsFetchAction | ContactsCreateAction>(action: Action) => Action
	) => {
		return dispatch({
			type: 'CONTACTS_CREATE',
			payload: {
				promise: async () => {
					await api.createContact(contact);
				},
				data: {
					contact,
				},
			},
		});
	};

export const updateContact = createAction('CONTACTS_UPDATE', (contact: Contact) => ({
	promise: () => api.updateContact(contact),
	data: {
		contact,
	},
}));

export const deleteContact = createAction('CONTACTS_DELETE', (contactId: string) => ({
	promise: () => api.deleteContact(contactId),
	data: {
		contactId,
	},
}));

export const deleteAllContacts = createAction('CONTACTS_DELETE_ALL', () => ({
	promise: () => api.deleteAllContacts(),
}));

export const importContactsFromCSV =
	(base64Content: string) =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		await api.importContactsFromCSV(base64Content);

		forceFetchContacts()(dispatch, getState);
	};

export const importContactsFromGoogle =
	(token: string) =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		await api.importContactsFromGoogle(token);

		forceFetchContacts()(dispatch, getState);
	};
