import { handleActions } from '../..';
import { BalanceState } from './types';
import * as actions from './actions';

const initialState: BalanceState = {
	fetched: false,
	fetching: false,
};

export default handleActions<BalanceState, PossibleActions<typeof actions>>(
	{
		BALANCE_FETCH_PENDING: state => ({ ...state, fetching: true }),
		BALANCE_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			...payload,
		}),
	},
	initialState
);
