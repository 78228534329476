import classnames from 'classnames';
import React from 'react';
import { usePandaContext } from '../../contexts/pandaContext';
import { Props as AvatarProps } from '../avatar/Avatar';
import { VisualAvatar } from '../avatar/VisualAvatar';
import { getColor, getInitials } from '../avatar/colors';

export type Props = {
	/**
	 * Es werden die gleichen Props übergeben wie bei der Avatar Komponente. `email` ist optional, sollte aber im App-Web immer übergeben werden, damit die Farben der Avatare einheitlich generiert werden.
	 */
	users: Pick<AvatarProps, 'firstname' | 'lastname' | 'email' | 'imageUrl' | 'imageSrcSet'>[];
	size?: 'medium' | 'large';
};

const styles = {
	container: (size: 'medium' | 'large') =>
		classnames(
			size === 'medium' && ['-space-x-[0.25rem]', 'h-32'],
			size === 'large' && ['-space-x-[0.375rem]', 'h-40']
		),
};

export const AvatarStack = ({ users, size = 'large' }: Props): JSX.Element => {
	const { languageKeys } = usePandaContext();

	return (
		<div className={styles.container(size)}>
			{users.length <= 5 ? (
				users.map((user, index) => (
					<VisualAvatar
						key={user.email || user.firstname + user.lastname}
						text={getInitials(user.firstname, user.lastname)}
						backgroundColor={getColor(user.firstname, user.lastname, user.email)}
						textColor="white"
						size={size}
						imageUrl={user.imageUrl}
						imageSrcSet={user.imageSrcSet}
						isOverlapped={index !== 0}
						ariaLabel={`${languageKeys.PANDA_AVATAR_FROM} ${user.firstname} ${user.lastname}`}
						title={`${user.firstname} ${user.lastname}`}
					/>
				))
			) : (
				<>
					{users.slice(0, 4).map((user, index) => (
						<VisualAvatar
							key={user.email || user.firstname + user.lastname}
							text={getInitials(user.firstname, user.lastname)}
							backgroundColor={getColor(user.firstname, user.lastname, user.email)}
							textColor="white"
							size={size}
							imageUrl={user.imageUrl}
							imageSrcSet={user.imageSrcSet}
							isOverlapped={index !== 0}
							ariaLabel={`${languageKeys.PANDA_AVATAR_FROM} ${user.firstname} ${user.lastname}`}
							title={`${user.firstname} ${user.lastname}`}
						/>
					))}

					<VisualAvatar
						text={`+${users.length - 4}`}
						title={`+ ${users.length - 4} ${languageKeys.PANDA_AVATAR_REMAINING_COUNT}`}
						backgroundColor="var(--gray-100)"
						textColor="var(--gray-600)"
						size={size}
						isOverlapped
						isRemainingCount
					/>
				</>
			)}
		</div>
	);
};
