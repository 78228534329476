import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchIncomingBlocklistEntries = (state: ReduxState) =>
	!state.blocklist.fetched && !state.blocklist.fetching;

export const createBlocklistEntry = createAction(
	'BLOCKLIST_ENTRY_CREATE',
	(phoneNumber: string, isBlock: boolean) => ({
		promise: () => api.createIncomingBlacklistEntry(phoneNumber, isBlock),
		data: { phoneNumber, isBlock },
	})
);

export const deleteBlocklistEntry = createAction(
	'BLOCKLIST_ENTRY_DELETE',
	(phoneNumber: string) => ({
		promise: () => api.deleteIncomingBlacklistEntry(phoneNumber),
		data: { phoneNumber },
	})
);

export const fetchBlocklistEntries = createAction('BLOCKLIST_ENTRIES_FETCH', () => ({
	promise: () => api.getIncomingBlacklistEntries(),
	shouldFetch: shouldFetchIncomingBlocklistEntries,
}));

export const updateBlocklistEntry = createAction(
	'BLOCKLIST_ENTRY_UPDATE',
	(oldPhoneNumber: string, newPhoneNumber: string, isBlock: boolean) => ({
		promise: async () => {
			await api.deleteIncomingBlacklistEntry(oldPhoneNumber);

			return api.createIncomingBlacklistEntry(newPhoneNumber, isBlock);
		},
		data: { oldPhoneNumber, newPhoneNumber, isBlock },
	})
);
