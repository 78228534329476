import { combineReducers } from 'redux';
import { reducer as account } from './modules/account';
import { reducer as addresses } from './modules/addresses';
import { reducer as appProperties } from './modules/appProperties';
import { reducer as balance } from './modules/balance';
import { reducer as blocklist } from './modules/blocklist';
import { reducer as trunkCallRestrictions } from './modules/callRestrictions/trunk';
import { reducer as callRestrictions } from './modules/callRestrictions/webuser';
import { reducer as customerProducts } from './modules/customerProducts';
import { reducer as contacts } from './modules/contacts';
import { reducer as deviceContingents } from './modules/deviceContingents';
import { reducer as deviceLocalprefix } from './modules/deviceLocalprefix';
import { reducer as deviceProperties } from './modules/deviceProperties';
import { reducer as devices } from './modules/devices';
import { reducer as contracts } from './modules/contracts';
import { reducer as mobileDeviceTariffAnnouncements } from './modules/mobileDeviceTariffAnnouncements';
import { reducer as dialogs } from './modules/snackbar';
import { reducer as lpaUris } from './modules/esim';
import { reducer as events } from './modules/events';
import faxlines from './modules/faxlines';
import { reducer as forwardings } from './modules/timeBasedForwardings';
import { reducer as googleContactsReducer } from './modules/googleContacts';
import { reducer as groups } from './modules/groups';
import { reducer as groupStatistics } from './modules/groupStatistics';
import { reducer as gsuite } from './modules/gsuite';
import { reducer as holidayPresets } from './modules/holidayPresets';
import { reducer as identityVerification } from './modules/identityVerification';
import { reducer as linksReducer } from './modules/links';
import loadingIndicator from './modules/loadingIndicator';
import notifications from './modules/notifications';
import { reducer as numbers } from './modules/numbers';
import { reducer as ssoConfiguration } from './modules/ssoConfiguration';
import { reducer as personalAccessTokens } from './modules/personalAccessToken';
import { reducer as phonelineBlockAnonymous } from './modules/phonelineBlockAnonymous';
import { reducer as phonelines } from './modules/phonelines';
import { reducer as phoneProvisioning } from './modules/phoneProvisioning';
import portings from './modules/portings';
import { reducer as products } from './modules/products';
import { reducer as productsTacs } from './modules/productsTacs';
import { reducer as productOptions } from './modules/productOptions';
import { reducer as pseudo } from './modules/pseudo';
import { reducer as restrictions } from './modules/restrictions';
import { reducer as screenreaderAnnouncements } from './modules/screenreaderAnnouncements';
import { reducer as smsReducer } from './modules/sms';
import smsCallerIds from './modules/smsCallerIds';
import { reducer as tacs } from './modules/tacs';
import { reducer as translations } from './modules/translations';
import { reducer as trunks } from './modules/trunks';
import { reducer as trunkContingentContracts } from './modules/trunkContingentContracts';
import { reducer as trunkContracts } from './modules/trunkContracts';
import { reducer as trunkProducts } from './modules/trunkProducts';
import { reducer as trunkContingentProducts } from './modules/trunkContingentProducts';
import { reducer as userinfo } from './modules/userinfo';
import { reducer as users } from './modules/users';
import { reducer as voicemails } from './modules/voicemails';
import { reducer as twoFactorAuthenticationSettings } from './modules/twoFactorAuthenticationSettings';
import { reducer as scopes } from './modules/scopes';
import { reducer as dataAutomations } from './modules/dataAutomation';
import { reducer as dataAutomationHistory } from './modules/dataAutomationHistory';
import { reducer as dataUsage } from './modules/dataUsage';
import { reducer as microsoftTeams } from './modules/microsoftTeams';
import { reducer as microsoftTeamsRoutingConfiguration } from './modules/microsoftTeamsRoutingConfiguration';
import { reducer as conferenceRoom } from './modules/conferenceRoom';
import { reducer as ivrs } from './modules/ivrs';
import { reducer as mobile } from './modules/mobile';
import { reducer as audioFiles } from './modules/audioFiles';
import { reducer as avatars } from './modules/avatars';
import { reducer as appBookings, appSubscriptionReducer as appSubscriptions } from './modules/app';
import { reducer as dateBasedForwardings } from './modules/dateBasedForwardings';
import { reducer as acds } from './modules/acds';
import { reducer as acdAudioFiles } from './modules/acdAudioFiles';
import { reducer as domainVerification } from './modules/domainVerification';
import { reducer as igel } from './modules/igel';
import { reducer as transferAudioFiles } from './modules/transferAudioFiles';
import { reducer as callRecordingAudioFiles } from './modules/callRecordingAudioFiles';
import { reducer as organisationContacts } from './modules/satelliteUser';
import { reducer as organisationMemberships } from './modules/organisationMembership';
import { reducer as organisationPaymentState } from './modules/paymentState';
import { reducer as tokenDetailsState } from './modules/token';

export const rootReducer = combineReducers({
	organisationContacts,
	organisationMemberships,
	organisationPaymentState,
	tokenDetailsState,
	userinfo,
	account,
	addresses,
	appProperties,
	balance,
	blocklist,
	callRestrictions,
	deviceContingents,
	contracts,
	customerProducts,
	deviceLocalprefix,
	deviceProperties,
	mobileDeviceTariffAnnouncements,
	dataAutomations,
	dataAutomationHistory,
	dataUsage,
	devices,
	dialogs,
	events,
	faxlines,
	forwardings,
	groups,
	groupStatistics,
	googleContacts: googleContactsReducer,
	holidayPresets,
	identityVerification,
	links: linksReducer,
	loadingIndicator,
	lpaUris,
	microsoftTeams,
	domainVerification,
	microsoftTeamsRoutingConfiguration,
	notifications,
	numbers,
	personalAccessTokens,
	phonelineBlockAnonymous,
	audioFiles,
	phonelines,
	phoneProvisioning,
	portings,
	restrictions,
	screenreaderAnnouncements,
	sms: smsReducer,
	smsCallerIds,
	tacs,
	productsTacs,
	ssoConfiguration,
	translations,
	trunkContingentProducts,
	trunkContingentContracts,
	trunkContracts,
	trunkProducts,
	trunkCallRestrictions,
	trunks,
	users,
	pseudo,
	voicemails,
	gsuite,
	contacts,
	products,
	productOptions,
	twoFactorAuthenticationSettings,
	scopes,
	conferenceRoom,
	mobile,
	ivrs,
	avatars,
	appBookings,
	dateBasedForwardings,
	acds,
	acdAudioFiles,
	igel,
	appSubscriptions,
	transferAudioFiles,
	callRecordingAudioFiles,
});
