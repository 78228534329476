import moment from 'moment';
import * as actions from './actions';
import { AllAppBookingsState } from './types';
import { handleActions } from '../..';
import { ApiAppBooking } from '../../../api/types/app';
import { AppBooking } from '../../../views/users/user/app/types/AppBooking';

const initialState: AllAppBookingsState = {
	items: {},
	fetchingForWebuser: [],
	fetchedForWebuser: [],
	fetchingForAccount: false,
	fetchedForAccount: false,
};

const mapApiBookingToBooking = (apiBooking: ApiAppBooking) => {
	return {
		bookingId: apiBooking.bookingId,
		bookedAt: moment(apiBooking.bookedAt),
		terminatedFor: apiBooking.terminatedFor ? moment(apiBooking.terminatedFor) : null,
		subscriptionName: apiBooking.subscriptionName,
		...(apiBooking.trialEndsAt != null &&
			apiBooking.isTrialCancelled != null && {
				trial: {
					endsAt: moment(apiBooking.trialEndsAt),
					isCancelled: apiBooking.isTrialCancelled,
				},
			}),
	};
};

const mapApiBookingsToBookings = (apiBookings: ApiAppBooking[]): AppBooking[] => {
	return apiBookings.map(apiBooking => mapApiBookingToBooking(apiBooking));
};

const mapApiBookingsForAccountToBookings = (
	apiBookings: ApiAppBooking[]
): { [key: string]: AppBooking[] } => {
	const result: { [key: string]: AppBooking[] } = {};

	apiBookings.forEach(apiBooking => {
		const userId = apiBooking.userId;

		if (!result[userId]) {
			result[userId] = [];
		}

		result[userId].push(mapApiBookingToBooking(apiBooking));
	});

	return result;
};

export default handleActions<AllAppBookingsState, PossibleActions<typeof actions>>(
	{
		APP_BOOKINGS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForWebuser: [...state.fetchingForWebuser, data.webuserId],
		}),
		APP_BOOKINGS_FETCH_SUCCESS: (state, { data, payload }) => ({
			...state,
			items: { ...state.items, [data.webuserId]: mapApiBookingsToBookings(payload.items) },
			fetchingForWebuser: state.fetchingForWebuser.filter(
				webuserId => webuserId !== data.webuserId
			),
			fetchedForWebuser: [
				...state.fetchedForWebuser.filter(webuserId => webuserId !== data.webuserId),
				data.webuserId,
			],
		}),
		APP_BOOKINGS_FOR_ACCOUNT_FETCH_PENDING: state => ({
			...state,
			fetchingForAccount: true,
		}),
		APP_BOOKINGS_FOR_ACCOUNT_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			items: mapApiBookingsForAccountToBookings(payload.items),
			fetchingForAccount: false,
			fetchedForAccount: true,
		}),
	},
	initialState
);
