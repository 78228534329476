import React from 'react';
import { ReduxState } from '../../redux/types';
import { connect, ReduxProps } from '../../redux';
import { screenreaderPoliteAnnouncementClose } from '../../redux/modules/screenreaderAnnouncements';

const mapStateToProps = (state: ReduxState) => ({
	screenreaderAnnouncements: state.screenreaderAnnouncements,
});

const mapDispatchToProps = {
	screenreaderPoliteAnnouncementClose,
};

class PoliteLiveRegion extends React.PureComponent<
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>
> {
	private timer = 0;

	public componentDidUpdate(prevProps: ReduxProps<typeof mapStateToProps>) {
		if (
			this.props.screenreaderAnnouncements.politeMessage !==
			prevProps.screenreaderAnnouncements.politeMessage
		) {
			clearTimeout(this.timer);
			this.timer = window.setTimeout(() => {
				this.props.screenreaderPoliteAnnouncementClose();
			}, 250);
		}
	}

	public componentWillUnmount() {
		clearTimeout(this.timer);
	}

	public render() {
		return (
			<div aria-live="polite" aria-atomic="true" className="sr-only">
				{this.props.screenreaderAnnouncements.politeMessage}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliteLiveRegion);
