import { createAction } from '../..';
import { ReduxState } from '../../types';
import api from '../../../api';

function shouldFetchSmsCallerIds(state: ReduxState, smsId: string) {
	return !(smsId in state.smsCallerIds);
}

const fetchSmsCallerIds = createAction(
	'SMS_CALLERIDS_FETCH',
	(userId: string, smsId: string, force?: boolean) => ({
		promise: () => api.fetchSmsCallerIds(userId, smsId),
		shouldFetch: (state: ReduxState) => force || shouldFetchSmsCallerIds(state, smsId),
		data: {
			userId,
			smsId,
		},
	})
);

const createSmsCallerIds = createAction(
	'CREATE_SMS_CALLERIDS',
	(userId: string, smsId: string, phonenumber: string) => ({
		promise: async () => {
			await api.createSmsCallerId(userId, smsId, phonenumber);
			const smsCallerIds = await api.fetchSmsCallerIds(userId, smsId);

			return {
				smsCallerIds,
				newSmsCallerId: smsCallerIds.items.find(item => item.phonenumber === phonenumber),
			};
		},
		data: {
			userId,
			smsId,
		},
	})
);

const setSmsCallerIdActive = createAction(
	'SMS_CALLERID_SET_ACTIVE',
	(userId: string, smsId: string, smsCallerId: number, isDefaultNumber: boolean) => ({
		promise: () => api.setActiveSmsCallerId(userId, smsId, smsCallerId, isDefaultNumber),
		data: {
			userId,
			smsId,
			smsCallerId,
			isDefaultNumber,
		},
	})
);

const verifySmsCallerId = createAction(
	'VERIFY_SMS_CALLERID',
	(userId: string, smsId: string, smsCallerId: number, verificationCode: string) => ({
		promise: () => api.verifySmsCallerId(userId, smsId, smsCallerId, verificationCode),
		data: {
			userId,
			smsId,
			smsCallerId,
		},
	})
);

export const actions = {
	fetchSmsCallerIds,
	createSmsCallerIds,
	verifySmsCallerId,
	setSmsCallerIdActive,
};
