import { createAction } from '../..';

import api from '../../../api';
import { LinksState } from './types';
import { ReduxState } from '../../types';

const shouldFetchLinks = (state: LinksState) => !state.fetching && !state.fetched;

export const fetchLinks = createAction('LINKS_FETCH', () => ({
	promise: () => api.fetchLinks(),
	shouldFetch: (state: ReduxState) => shouldFetchLinks(state.links),
}));

export const forceFetchLinks = createAction('LINKS_FETCH', () => ({
	promise: () => api.fetchLinks(),
}));
