import React from 'react';
import classnames from 'classnames';
import greenIcon from './icons/green-12.svg';
import orangeIcon from './icons/orange-12.svg';
import redIcon from './icons/red-12.svg';
import blueIcon from './icons/blue-12.svg';
import { createPropContext } from '../../contexts/props-override';

type Size = 'xsmaller' | 'small' | 'medium';
export type StateColor = 'green' | 'orange' | 'red' | 'blue';

export type Props = {
	size: Size;
	/* Bestimmt die Farbe des angezeigten Status. */
	color: StateColor;
	/**
	 * Ein String der den aktuellen Status des Objekts beschreibt wie online oder offline.
	 */
	children: string;
};

const { PropsProvider, usePropsOverride } = createPropContext<Props>();

export const StatePropsProvider = PropsProvider;

const renderIcon = (type: StateColor) => {
	switch (type) {
		case 'green':
			return <img src={greenIcon} alt="" />;
		case 'orange':
			return <img src={orangeIcon} alt="" />;
		case 'red':
			return <img src={redIcon} alt="" />;
		case 'blue':
			return <img src={blueIcon} alt="" />;
	}
};

const styles = {
	state: (size: Size, color: StateColor) =>
		classnames(
			'items-center',
			'rounded-full',
			'ring-inset',
			'font-brand',
			'font-bold',
			'cursor-default',
			size === 'xsmaller' && 'inline-flex align-middle',
			size === 'small' && 'inline-block text-sm/16 px-10 py-4 ring-2',
			size === 'medium' && 'inline-block text-base/24 px-12 py-4 ring-2',
			color === 'green' && size !== 'xsmaller' && 'bg-green-25 text-green-800 ring-green-500',
			color === 'orange' && size !== 'xsmaller' && 'bg-orange-25 text-orange-800 ring-orange-500',
			color === 'red' && size !== 'xsmaller' && 'bg-red-25 text-red-800 ring-red-500',
			color === 'blue' && size !== 'xsmaller' && 'bg-blue-25 text-blue-800 ring-blue-500'
		),
};

export const State = (rawProps: Props): JSX.Element => {
	const { size, color: type, children } = usePropsOverride(rawProps);

	return (
		<div
			aria-label={children}
			className={styles.state(size, type)}
			role="img"
			title={size === 'xsmaller' ? children : undefined}
		>
			{size === 'xsmaller' ? renderIcon(type) : children}
		</div>
	);
};
