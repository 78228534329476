import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { closeAutoCloseInfoSnackbar } from '../../redux/modules/snackbar';
import { ReduxState } from '../../redux/types';
import { AutoCloseSnackbar } from './AutoCloseSnackbar';

const mapStateToProps = (state: ReduxState) => ({
	snackbarOpen: state.dialogs.autoCloseInfoSnackbarOpen,
	snackbarMessage: state.dialogs.autoCloseInfoSnackbarMessage,
});

const mapDispatchToProps = {
	closeAutoCloseInfoSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class AutoCloseInfoSnackbar extends React.PureComponent<Props> {
	public render() {
		return (
			<AutoCloseSnackbar
				open={this.props.snackbarOpen}
				message={this.props.snackbarMessage}
				severity="info"
				onClose={this.props.closeAutoCloseInfoSnackbar}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCloseInfoSnackbar);
