import { AcdAudioFile, AcdAudioFileType } from '.';
import api from '../../../api';
import { ReduxState } from '../../types';
import { createAction } from '../..';

function shouldFetchAudioFiles(state: ReduxState, acdId: string) {
	return (
		!state.acdAudioFiles.fetchedForAcd.find(audioFile => audioFile.acdId === acdId) &&
		!state.acdAudioFiles.fetchingForAcd.includes(acdId)
	);
}

export const fetchAcdAudioFiles = createAction(
	'ACD_AUDIO_FILES_FETCH',
	(acdId: string, force?: boolean) => ({
		promise: () => api.getAcdAudioFiles(acdId),
		shouldFetch: (state: ReduxState) => force || shouldFetchAudioFiles(state, acdId),
		data: {
			acdId,
		},
	})
);

export const createAcdAudioFile = createAction(
	'ACD_AUDIO_FILE_CREATE',
	(acdId: string, filename: string, base64Content: string, type: AcdAudioFileType) => ({
		promise: api.createAcdAudioFile(acdId, filename, base64Content, type),
		data: {
			acdId,
		},
	})
);

export const deleteAcdAudioFile = createAction(
	'ACD_AUDIO_FILE_DELETE',
	(
		acdId: string,
		audioFileId: string,
		audioFileType: AcdAudioFileType,
		defaultAudioFileId?: string
	) => ({
		promise: api.deleteAcdAudioFile(acdId, audioFileId),
		data: {
			audioFileId,
			acdId,
			defaultAudioFileId,
			audioFileType,
		},
	})
);

export const setAcdAudioFileAlias = createAction(
	'ACD_AUDIO_FILE_SET_ALIAS',
	(acdId: string, audioFileId: string, alias: string) => ({
		promise: () => api.setAcdAudioFileAlias(acdId, audioFileId, alias),
		data: {
			acdId,
			audioFileId,
			alias,
		},
	})
);

export const fetchAcdAudioFileContent = createAction(
	'ACD_AUDIO_FILE_FETCH_CONTENT',
	(acdId: string, audioFile: AcdAudioFile, onlyForceOnError?: boolean) => ({
		shouldFetch: () =>
			audioFile.url.state === 'UNFETCHED' || (onlyForceOnError && audioFile.url.state === 'ERROR'),
		promise: () => api.getAcdAudioFileContent(acdId, audioFile.id),
		data: {
			acdId,
			audioFileId: audioFile.id,
		},
	})
);
