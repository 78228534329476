import { handleActions } from '../..';
import { TrunksState } from './types';
import * as actions from './actions';
import { ApiTrunkContingentContract, TrunkContingent } from '../../../api/types/trunk';
import { PhoneType } from '../../../components/contingents/PhoneIcon';
import { deleteAddress } from '../addresses';

const initialState: TrunksState = {
	items: [],
	fetched: false,
	fetching: false,
	fetchingDevicesForTrunk: [],
	fetchedDevicesForTrunk: [],
	fetchingContingentsForTrunk: [],
	fetchedContingentsForTrunk: [],
};

const removeItemFromArray = (arr: readonly string[], deviceId: string) =>
	arr.filter(item => item !== deviceId);

const formatTrunkContingents = (trunkContingentContracts: ApiTrunkContingentContract[]) => {
	const trunkContingents: TrunkContingent[] = [];
	trunkContingentContracts.forEach(contingentContract => {
		contingentContract.contingents.forEach(contingent => {
			const trunkContingent: TrunkContingent = {
				name: contingent.type,
				type: contingent.type.toLowerCase().includes('mobile')
					? PhoneType.MOBILE
					: PhoneType.LANDLINE,
				amount: contingent.amount,
				used: contingent.used,
				cancellationDate: contingentContract.cancellationDate,
				activationDate: contingentContract.activationDate,
				disabledCancellationOrRevocation: contingentContract.disabledCancellationOrRevocation,
				productId: contingentContract.productId,
				customerProductId: contingentContract.contractId,
				productName: contingentContract.productName,
			};
			trunkContingents.push(trunkContingent);
		});
	});
	return trunkContingents;
};

export default handleActions<TrunksState, PossibleActions<typeof actions | typeof deleteAddress>>(
	{
		TRUNKS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		TRUNKS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload.items,
			fetched: true,
			fetching: false,
			fetchingDevicesForTrunk: [],
			fetchedDevicesForTrunk: [],
			fetchingContingentsForTrunk: [],
			fetchedContingentsForTrunk: [],
		}),
		TRUNK_DEVICES_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingDevicesForTrunk: [
				...removeItemFromArray(state.fetchingDevicesForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNK_DEVICES_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					devices: payload.items,
				};
			}),
			fetchingDevicesForTrunk: [
				...removeItemFromArray(state.fetchingDevicesForTrunk, data.trunkId),
			],
			fetchedDevicesForTrunk: [
				...removeItemFromArray(state.fetchedDevicesForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNK_CONTINGENTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingContingentsForTrunk: [
				...removeItemFromArray(state.fetchingContingentsForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNK_CONTINGENTS_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					contingents: formatTrunkContingents(payload.items),
				};
			}),
			fetchingContingentsForTrunk: removeItemFromArray(
				state.fetchingContingentsForTrunk,
				data.trunkId
			),
			fetchedContingentsForTrunk: [
				...removeItemFromArray(state.fetchedContingentsForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNKS_RENAME_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.id) {
					return item;
				}

				return {
					...item,
					alias: data.alias,
				};
			}),
		}),
		TRUNKS_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.id),
		}),
		TRUNKS_SET_CALLER_ID_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					fallbackCallerId: data.callerId,
				};
			}),
		}),
		TRUNKS_SET_EMERGENCY_LOCATION_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					emergencyAddressId: data.addressId,
				};
			}),
		}),
		NEW_ADDRESS_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.emergencyAddressId !== data.addressId.toString()) {
					return item;
				}

				return {
					...item,
					emergencyAddressId: data.emergencyAddressId ? data.emergencyAddressId.toString() : '',
				};
			}),
		}),
	},
	initialState
);
