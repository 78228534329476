import { handleActions } from '../..';
import { Product } from '../products';
import * as actions from './actions';
import { TrunkProductsState } from './types';

const initialState: TrunkProductsState = {
	items: [],
	fetching: false,
	fetched: false,
};

export default handleActions<TrunkProductsState, PossibleActions<typeof actions>>(
	{
		TRUNK_PRODUCTS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
			fetched: false,
		}),
		TRUNK_PRODUCTS_FETCH_SUCCESS: (state, { payload }) => {
			const orderedItems = [...payload.items].sort(
				(a: Product, b: Product) => a.recurringFee - b.recurringFee
			);

			return {
				...state,
				fetching: false,
				fetched: true,
				items: orderedItems,
			};
		},
	},
	initialState
);
