import { createAction } from '../..';

import api from '../../../api';
import { Restriction } from './types';

export const fetchRestrictions = createAction(
	'RESTRICTIONS_FETCH',
	(userId?: string, restrictions?: Restriction[]) => ({
		promise: (() => api.fetchRestrictions(userId, restrictions))(),
	})
);
