import { useEffect } from 'react';
import { fetchUserInfo } from './actions';
import { useDispatch, useSelector } from '../../utils/wrapper';

export const useUserInfo = () => {
	const dispatch = useDispatch();
	const userInfo = useSelector(state => state.userinfo);

	useEffect(() => {
		dispatch(fetchUserInfo());
	}, [dispatch]);

	return userInfo.fetched ? userInfo.data : null;
};

export const useFeatureFlag = (featureFlag: string) => {
	const userinfo = useUserInfo();

	if (!userinfo) {
		return false;
	}

	return userinfo.flags.includes(featureFlag);
};

export const useSipgateDomain = () => {
	const userInfo = useUserInfo();
	return userInfo?.domain ?? undefined;
};
