import React from 'react';
import classnames from 'classnames';
import { ContextMenu } from '../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../components/contextMenu/MenuItem';
import classes from './ListView.module.css';
import { assertChildTypes } from '../../util/assert-children';
import { ListViewColumn } from './ListViewColumn';

type ContextMenuEntry = {
	label: string;
	onClick: () => void;
	critical?: boolean;
};

type Props = {
	/**
	 * Die einzelnen ListViewColumn Einträge.
	 */
	children: React.ReactNode;
	/**
	 * Ein Array von `ContextMenu`-Items
	 *
	 * **ContextMenuEntry**:
	 *
	 * ```
	 * {
	 *  label: string;
	 *  onClick: () => void;
	 *  critical?: boolean;
	 * }
	 * ```
	 */
	contextMenu: (ContextMenuEntry | null)[];
};

const styles = {
	row: classnames('bg-white', 'text-gray-800', 'whitespace-nowrap'),
	background: classnames([
		'p-0',
		'sticky',
		'right-0',
		'bg-gradient-to-r',
		'from-transparent',
		'to-gray-25',
	]),
	menu: classnames([
		'ps-24',
		'border-gray-100',
		'border-y-2',
		'border-e-2',
		'rounded-y-lg',
		'rounded-e-lg',
		'overflow-hidden',
	]),
	shadow: classnames([
		classes.shadow,
		'relative',
		'bg-white',
		'p-24',
		'pointer-coarse:p-16',
		"before:content-['']",
		'before:h-full',
		'before:w-full',
		'before:absolute',
		'before:block',
		'before:top-0',
		'before:left-0',
		'before:shadow',
		'before:transition-opacity',
	]),
	firstLineOverflow: classnames([
		classes.horribleCornerHack,
		'sticky',
		'left-0',
		'p-0',
		"after:content-['']",
		'after:h-full',
		'after:w-full',
		'after:block',
		'after:top-0',
		'after:absolute',
		'after:border-gray-100',
		'after:border-s-2',
		'after:border-y-2',
		'after:rounded-y-lg',
		'after:rounded-s-lg',
		'after:bg-gradient-to-r',
		'after:from-white',
	]),
};

export const ListViewItem = ({ children, contextMenu }: Props) => {
	assertChildTypes(children, [ListViewColumn]);

	return (
		<tr className={styles.row}>
			<td aria-hidden className={styles.firstLineOverflow} />

			{children}

			<td className={styles.background}>
				<div className={styles.menu}>
					<div className={styles.shadow}>
						<ContextMenu>
							{contextMenu
								.filter((e): e is ContextMenuEntry => e !== null)
								.map(({ label, onClick, critical }) => (
									<MenuItem onClick={onClick} critical={critical} key={label}>
										{label}
									</MenuItem>
								))}
						</ContextMenu>
					</div>
				</div>
			</td>
		</tr>
	);
};
