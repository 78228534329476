const REPLACE_MULTISLASHES_REGEX = /\/\/+/g;

export const DIALOG_PATH = 'dialog';
export const DIALOG_NAME = ':dialogName';

const safePattern = (str: string) => `${str}`.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');

const insertParameterIntoPath = (route: string, parameter: string, value: string) =>
	route.replace(new RegExp(safePattern(`:${parameter}`), 'g'), value);

const getWindowLocationPathname = () => window.location.pathname.replace(/^\/*/, '/');

export const buildDialogPath = (name: string, id?: string) => {
	let path = getWindowLocationPathname().replace(/\/*$/, '');
	const dialogIndex = path.indexOf('/dialog');

	if (dialogIndex > -1) {
		path = path.substr(0, dialogIndex);
	}

	if (id !== null && typeof id !== 'undefined') {
		return insertParameterIntoPath(
			`${path}/${DIALOG_PATH}/${DIALOG_NAME}/${id}`,
			'dialogName',
			name
		).replace(REPLACE_MULTISLASHES_REGEX, '/');
	}

	return insertParameterIntoPath(
		`${path}/${DIALOG_PATH}/${DIALOG_NAME}`,
		'dialogName',
		name
	).replace(REPLACE_MULTISLASHES_REGEX, '/');
};

/** @deprecated Please use this.props.onClose() from DialogProps */
export const currentPathWithoutDialog = () => {
	const currentPath = getWindowLocationPathname();
	return currentPath.replace(/\/dialog\/.*/i, '');
};

export const IdentityVerificationPath = () => buildDialogPath('videoident');

export const CancelAccountDialogPath = () => buildDialogPath('account/cancel');

export const VerifyAccountPath = () => `${buildDialogPath('verify-account')}`;

export const ContingentOverviewPath = (userId: string, deviceId: string) =>
	`${buildDialogPath('contingent-overview')}/${encodeURIComponent(userId)}/${encodeURIComponent(
		deviceId
	)}`;

export const PoolSettingsPath = (userId: string, deviceId: string) =>
	`${buildDialogPath('pool-settings')}/${encodeURIComponent(userId)}/${encodeURIComponent(
		deviceId
	)}`;

export const CallPackagesPath = (userId: string, deviceId: string) =>
	`${buildDialogPath('pool-settings-single')}/${encodeURIComponent(userId)}/${encodeURIComponent(
		deviceId
	)}/call-packages`;

export const BookContingentPath = (userId: string, deviceId: string) =>
	`${ContingentOverviewPath(userId, deviceId)}/book-contingent`;

export const BookContingentSinglePath = (userId: string, deviceId: string) =>
	`${buildDialogPath('contingent-overview-single')}/${encodeURIComponent(
		userId
	)}/${encodeURIComponent(deviceId)}/book-contingent`;
