export const pickByEnvironment = (
	variable: string,
	options: {
		local: string;
		development: string;
		production: string;
	}
) => {
	try {
		if (process.env.LIVE_SERVICES && process.env.LIVE_SERVICES.split(',').includes(variable)) {
			return options.production;
		}

		if (process.env.DEV_SERVICES && process.env.DEV_SERVICES.split(',').includes(variable)) {
			return options.development;
		}

		if (process.env.LOCAL_SERVICES && process.env.LOCAL_SERVICES.split(',').includes(variable)) {
			return options.local;
		}
		switch (process.env.NODE_ENV) {
			case 'local':
			case 'test':
				return options.local;
			case 'development':
				return options.development;
			default:
				// dev and live both share the same code and are bundled with NODE_ENV=PRODUCTION
				return window.location.hostname.includes('.dev.sipgate') ||
					// github-action deployments also get build with PRODUCTION
					!!window.location.hostname.match(/^(app|signup)-web-dev/g)
					? options.development
					: options.production;
		}
	} catch (e) {
		return options.production;
	}
};

export default {
	restApi: pickByEnvironment('api', {
		local: 'https://api.local.sipgate.com:3396',
		development: 'https://api.dev.sipgate.com',
		production: 'https://api.sipgate.com',
	}),

	appWeb: pickByEnvironment('app-web', {
		local: 'https://app.local.sipgate.com:3443',
		development: 'https://app.dev.sipgate.com',
		production: 'https://app.sipgate.com',
	}),

	teamWebDe: pickByEnvironment('team-web', {
		local: 'https://team-de.local.sipgate.com:10443',
		development: 'https://team-de.dev.sipgate.com',
		production: 'https://team-de.live.sipgate.com',
	}),

	teamWebUk: pickByEnvironment('team-web', {
		local: 'https://team-uk.local.sipgate.com:10443',
		development: 'https://team-uk.dev.sipgate.com',
		production: 'https://team-uk.live.sipgate.com',
	}),

	authentication: {
		baseKeycloak: pickByEnvironment('keycloak', {
			local: 'https://login.dev.sipgate.com',
			development: 'https://login.dev.sipgate.com',
			production: 'https://login.sipgate.com',
		}),
		keycloak: pickByEnvironment('keycloak', {
			local: 'https://login.dev.sipgate.com/auth',
			development: 'https://login.dev.sipgate.com/auth',
			production: 'https://login.sipgate.com/auth',
		}),

		logoutUrl: pickByEnvironment('website', {
			local: 'https://www.sipgate.de/logout',
			development: 'https://www.sipgate.de/logout',
			production: 'https://www.sipgate.de/logout',
		}),

		accessCodeLoginUrl: pickByEnvironment('app-web', {
			local: 'https://app.local.sipgate.com:3443/app-login',
			development: 'https://app.dev.sipgate.com/app-login',
			production: 'https://app.sipgate.com/app-login',
		}),
	},

	botify: {
		socketUrl: pickByEnvironment('botify', {
			local: 'https://chatbot.dev.sipgate.com',
			development: 'https://chatbot.dev.sipgate.com',
			production: 'https://chatbot.sipgate.com',
		}),
	},
};
