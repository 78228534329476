import React, { useContext } from 'react';
import { icons } from '../assets/icons/icon-set/definitions';

type SetIcons = typeof icons;
type IconTranslations = Record<SetIcons[keyof SetIcons]['label'], string>;

const defaultIconTranslations = {} as IconTranslations;
for (const definition of Object.values(icons)) {
	defaultIconTranslations[definition.label] = definition.label;
}

/** @deprecated Only for use in tests */
export const placeholderLanguageKeys = {
	PANDA_CONTEXTMENU_LABEL: 'PANDA_CONTEXTMENU_LABEL',
	PANDA_CONTEXTMENU_ARIA_LABEL: 'PANDA_CONTEXTMENU_ARIA_LABEL',
	PANDA_LISTNAV_OF: 'PANDA_LISTNAV_OF',
	PANDA_LISTNAV_COUNT: 'PANDA_LISTNAV_COUNT',
	PANDA_LISTNAV_TOTAL: 'PANDA_LISTNAV_TOTAL',
	PANDA_LISTNAV_SORT_BY: 'PANDA_LISTNAV_SORT_BY',
	PANDA_LIST_VIEW_ACTIONS: 'PANDA_LIST_VIEW_ACTIONS',
	PANDA_DETAIL_VIEW_SECTION_COUNT: 'PANDA_DETAIL_VIEW_SECTION_COUNT',
	PANDA_DETAIL_VIEW_SECTION_TOTAL: 'PANDA_DETAIL_VIEW_SECTION_TOTAL',
	PANDA_SEARCH_INPUT_LABEL: 'PANDA_SEARCH_INPUT_LABEL',
	PANDA_SEARCH_INPUT_CLEAR: 'PANDA_SEARCH_INPUT_CLEAR',
	PANDA_SEARCH_RESULTS: 'PANDA_SEARCH_RESULTS',
	PANDA_SEARCH_NO_RESULTS_HEADING: 'PANDA_SEARCH_NO_RESULTS_HEADING',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	PANDA_SEARCH_NO_RESULTS_PARAGRAPH: (replacement: string) => 'PANDA_SEARCH_NO_RESULTS_PARAGRAPH',
	PANDA_SEARCH_NO_RESULTS_SUBHEADING: 'PANDA_SEARCH_NO_RESULTS_SUBHEADING',
	PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1: 'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1',
	PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2: 'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2',
	PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3: 'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3',
	PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT: 'PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT',
	PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL: 'PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL',
	PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT: 'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT',
	PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX: 'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX',
	PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX: 'PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX',
	PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL: 'PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL',
	PANDA_FILEUPLOAD_INVALID_FILESIZE: 'PANDA_FILEUPLOAD_INVALID_FILESIZE',
	PANDA_FILEUPLOAD_INVALID_FILETYPE: 'PANDA_FILEUPLOAD_INVALID_FILETYPE',
	PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL: 'PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL',
	PANDA_SIDEBAR_NAVIGATION_LANDMARK: 'PANDA_SIDEBAR_NAVIGATION_LANDMARK',
	PANDA_AVATAR_FROM: 'PANDA_AVATAR_FROM',
	PANDA_AVATAR_REMAINING_COUNT: 'PANDA_AVATAR_REMAINING_COUNT',
	PANDA_PASSWORD_INPUT_SHOWN: 'PANDA_PASSWORD_INPUT_SHOWN',
	PANDA_PASSWORD_INPUT_HIDDEN: 'PANDA_PASSWORD_INPUT_HIDDEN',
	PANDA_SELECT_PLACEHOLDER: 'PANDA_SELECT_PLACEHOLDER',
	PANDA_TITLE_SUFFIX: 'PANDA_TITLE_SUFFIX',
	PANDA_INPUT_OPTIONAL: 'PANDA_INPUT_OPTIONAL',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	PANDA_COMBOBOX_OVERFLOW: (replacement: string) => 'PANDA_COMBOBOX_OVERFLOW',

	...defaultIconTranslations,
};

export type LanguageKeys = typeof placeholderLanguageKeys;

export const PandaContext = React.createContext<{
	languageKeys: LanguageKeys;
	portalRoot: HTMLElement;
}>({
	languageKeys: placeholderLanguageKeys,
	portalRoot: document.createElement('div'),
});

export const usePandaContext = () => useContext(PandaContext);
