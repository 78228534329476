import { handleActions } from '../..';

import { TranslateState } from './types';
import { buildTranslate, buildStringOnlyTranslate } from './translate';
import * as actions from './actions';

const initialState: TranslateState = {
	translate: buildTranslate(buildStringOnlyTranslate({})),
	fetching: false,
	fetched: false,
	locale: '',
};

export default handleActions<TranslateState, PossibleActions<typeof actions>>(
	{
		TRANSLATIONS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		TRANSLATIONS_FETCH_SUCCESS: (state, { data, payload }) => {
			const translate = buildTranslate(buildStringOnlyTranslate(payload));

			return {
				...state,
				fetching: false,
				fetched: true,
				translate,
				locale: data.locale,
			};
		},
	},
	initialState
);
