import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchAccessibleNumbers } from './actions';

export const useNumbers = () => {
	const dispatch = useDispatch();

	const numbers = useSelector(s => s.numbers);

	useEffect(() => {
		dispatch(fetchAccessibleNumbers());
	}, [dispatch]);

	return numbers.fetched ? numbers.items : null;
};
