import { RestrictionState } from './types';
import { ApiRestriction } from '../../../api/types/restrictions';

export function getRestriction(
	restrictions: RestrictionState,
	restriction: ApiRestriction,
	target?: string
) {
	if (restrictions && restrictions[restriction] && restrictions[restriction].length) {
		const restrictionForTarget = restrictions[restriction].filter(
			restrictionItem => restrictionItem.target === null || restrictionItem.target === target
		);

		return restrictionForTarget.length === 1 ? restrictionForTarget[0].value : false;
	}

	return false;
}
