import {
	PhoneNumber,
	PhoneNumberFormat,
	PhoneNumberType,
	PhoneNumberUtil,
} from 'google-libphonenumber';
import { memoize } from 'lodash';

const LOCALE_STORAGE_KEY = 'app-locale';

export const saveLocale = (locale: string) =>
	localStorage.setItem(LOCALE_STORAGE_KEY, JSON.stringify(locale));

const getLocale = () => {
	const token = localStorage.getItem(LOCALE_STORAGE_KEY);
	return token ? JSON.parse(token) : 'de_DE';
};

const phoneUtil = PhoneNumberUtil.getInstance();
const MIN_PHONENUMBER_LENGTH = 5;
const MIN_PHONENUMBER_E164_LENGTH = 10;
const allowedServiceNumbers: { [countryCode: string]: string[] } = {
	'49': ['1801', '1802', '1803', '1804', '1805', '1806', '18088', '700', '800'],
	'44': ['870', '845'],
};
const EWRCountries = [
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IS',
	'IE',
	'IT',
	'LV',
	'LI',
	'LT',
	'LU',
	'MT',
	'NL',
	'NO',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
];

function getLanguageFromLocale(locale: string) {
	return locale.split(/[_-]/)[1];
}

export function isDirectDialNumber(phoneNumber: string | number) {
	return `${phoneNumber}`.length <= MIN_PHONENUMBER_LENGTH;
}

/** @deprecated, please use normalizePhonenumber() */
export function getE164Number(phoneNumber: string, locale = getLocale()) {
	if (isDirectDialNumber(phoneNumber)) {
		return phoneNumber;
	}

	const sanitizedNumber =
		phoneNumber.match(/^[1-9][0-9]{1,2}/) && phoneNumber.length > MIN_PHONENUMBER_E164_LENGTH
			? `+${phoneNumber}`
			: phoneNumber;
	try {
		const parsedPhoneNumber = phoneUtil.parse(sanitizedNumber, getLanguageFromLocale(locale));
		return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
	} catch (err) {
		return phoneNumber;
	}
}

function isAllowedServiceNumbers(parsedPhoneNumber: PhoneNumber) {
	try {
		const internationalPrefix = parsedPhoneNumber.getCountryCode();
		if (!internationalPrefix) {
			return false;
		}

		const e164PhoneNumber = phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);

		return (
			!!allowedServiceNumbers[internationalPrefix] &&
			!!allowedServiceNumbers[internationalPrefix].find(allowedNationalPrefix =>
				e164PhoneNumber.startsWith(`+${internationalPrefix}${allowedNationalPrefix}`)
			)
		);
	} catch (err) {
		return false;
	}
}

export function isValidPhoneNumberType(parsedPhoneNumber: PhoneNumber) {
	try {
		const phoneNumberType = phoneUtil.getNumberType(parsedPhoneNumber);
		return (
			(phoneNumberType === PhoneNumberType.FIXED_LINE_OR_MOBILE ||
				phoneNumberType === PhoneNumberType.FIXED_LINE ||
				phoneNumberType === PhoneNumberType.MOBILE ||
				phoneNumberType === PhoneNumberType.VOIP ||
				phoneNumberType === PhoneNumberType.UAN ||
				phoneNumberType === PhoneNumberType.TOLL_FREE ||
				phoneNumberType === PhoneNumberType.VOICEMAIL ||
				isAllowedServiceNumbers(parsedPhoneNumber)) &&
			phoneNumberType !== PhoneNumberType.UNKNOWN
		);
	} catch (err) {
		return false;
	}
}

/** @deprecated, please use normalizePhonenumber() */
export function isValidPhonenumber(phoneNumber: string, locale = getLocale()) {
	try {
		if (typeof phoneNumber === 'undefined' || !phoneNumber.length) {
			return false;
		}

		if (/[^+0-9/(),-.\s]/.test(phoneNumber)) {
			return false;
		}

		if (phoneNumber.length <= MIN_PHONENUMBER_LENGTH) {
			return false;
		}

		const parsedPhoneNumber = phoneUtil.parse(phoneNumber, getLanguageFromLocale(locale));
		return isValidPhoneNumberType(parsedPhoneNumber);
	} catch (err) {
		return false;
	}
}

/** @deprecated use localizeNumber() instead */
export function getLocalizedNumber(phoneNumber: string, locale = getLocale()) {
	try {
		if (!phoneNumber) return null;

		if (isDirectDialNumber(phoneNumber)) {
			return phoneNumber;
		}

		const sanitizedPhoneNumber = /^[1-9].*/.test(phoneNumber) ? `+${phoneNumber}` : phoneNumber;

		if (!isValidPhonenumber(sanitizedPhoneNumber)) {
			return phoneNumber;
		}

		const parsedPhoneNumber = phoneUtil.parse(sanitizedPhoneNumber, getLanguageFromLocale(locale));
		const regionCode = phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);

		if (!regionCode) {
			return sanitizedPhoneNumber;
		}

		const phoneNumberFormat =
			getLanguageFromLocale(locale).toLowerCase() === regionCode.toLowerCase()
				? PhoneNumberFormat.NATIONAL
				: PhoneNumberFormat.INTERNATIONAL;

		return phoneUtil.format(parsedPhoneNumber, phoneNumberFormat).replace(/\s+/, '-');
	} catch (err) {
		return phoneNumber;
	}
}

// Lodashs memoize only memoizes based on first parameter, so lets
// do memoization based on locale by hand.
const localizedNumbersByLocale = new Map<string, (number: string) => string>();

/**
 * @deprecated Please always provide a locale to localizeNumber.
 */
export function localizeNumber(phoneNumber: string): string;
export function localizeNumber(phoneNumber: string, locale: string): string;
export function localizeNumber(number: string, locale = getLocale()) {
	if (!localizedNumbersByLocale.has(locale)) {
		localizedNumbersByLocale.set(
			locale,
			memoize(num => getLocalizedNumber(num, locale) || '')
		);
	}

	// We check for existence immediately above.
	//
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return localizedNumbersByLocale.get(locale)!(number);
}

export function formatPhonenumberForScreenreader(number: string) {
	return number
		.replace(/[^\d+]+/g, ',')
		.replace(/(\d)/g, d => ` ${d}`)
		.trimStart();
}

export function getCountryCode(phoneNumber: string, locale = getLocale()) {
	try {
		const parsedPhoneNumber = phoneUtil.parse(phoneNumber, getLanguageFromLocale(locale));
		return phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
	} catch (err) {
		return '??';
	}
}

export function isMobileNumber(phoneNumber?: string, locale = getLocale()) {
	try {
		if (!phoneNumber || !isValidPhonenumber(phoneNumber, locale)) {
			return false;
		}

		const sanitizedPhoneNumber = /^[1-9].*/.test(phoneNumber) ? `+${phoneNumber}` : phoneNumber;
		const parsedPhoneNumber = phoneUtil.parse(sanitizedPhoneNumber, getLanguageFromLocale(locale));
		return phoneUtil.getNumberType(parsedPhoneNumber) === PhoneNumberType.MOBILE;
	} catch (err) {
		return phoneNumber;
	}
}

export function isMobileOrFixedLineNumber(phoneNumber?: string, locale = getLocale()) {
	try {
		if (!phoneNumber || !isValidPhonenumber(phoneNumber, locale)) {
			return false;
		}

		const sanitizedPhoneNumber = /^[1-9].*/.test(phoneNumber) ? `+${phoneNumber}` : phoneNumber;
		const parsedPhoneNumber = phoneUtil.parse(sanitizedPhoneNumber, getLanguageFromLocale(locale));
		const phoneNumberType = phoneUtil.getNumberType(parsedPhoneNumber);
		return (
			phoneNumberType === PhoneNumberType.FIXED_LINE ||
			phoneNumberType === PhoneNumberType.MOBILE ||
			phoneNumberType === PhoneNumberType.FIXED_LINE_OR_MOBILE
		);
	} catch (err) {
		return phoneNumber;
	}
}

export function isAnonymous(phoneNumber: string) {
	return phoneNumber === 'anonymous' || phoneNumber === 'unknown';
}

/** @deprecated, please use normalizePhonenumber() */
export const sanitizeNumber = (phoneNumber: string) => {
	if (phoneNumber) {
		return phoneNumber.replace(/[^0-9+*#]/g, '');
	}

	return phoneNumber;
};

export function extendNumberObjectLocalization<T extends { number: string }>(
	parsedPhoneNumber: T
): T & { countryCode: string | undefined; localized: string | null } {
	return {
		...parsedPhoneNumber,
		countryCode: getCountryCode(parsedPhoneNumber.number, getLocale()),
		localized: getLocalizedNumber(parsedPhoneNumber.number, getLocale()),
	};
}

export function getCurrentCountryCode() {
	return phoneUtil.getCountryCodeForRegion(getLanguageFromLocale(getLocale()));
}

export function formatNumberForAPI(phonenumber: string) {
	let normalized = phonenumber.trim();

	normalized = normalized.replace(/^\+/, '00');
	normalized = normalized.replace(/[^0-9]/g, '');

	if (isValidPhonenumber(normalized)) {
		return getE164Number(normalized);
	}

	if (normalized.length <= 4 && normalized[0] !== '0') {
		return normalized;
	}

	if (normalized.startsWith('0') && !normalized.startsWith('00')) {
		const countryCode = getCurrentCountryCode();

		return normalized.replace(/^0/, `+${countryCode}`);
	}

	if (/^0*$/.test(normalized)) {
		return `+${getCurrentCountryCode()}`;
	}

	return normalized.replace(/^0*/, '+');
}

export function isEWRNumber(number: string, locale: string) {
	const countryCode = getCountryCode(number, locale);

	if (countryCode) {
		return EWRCountries.includes(countryCode);
	}

	return false;
}

export const isPotentialPhonenumber = (searchString: string) =>
	searchString.trim().length > 0 && !/[^(){}[\]*+\-_\d\s\\/]/.test(searchString);

export * from './normalize';
export * from './match';
