import { Trunk } from './types';
import { PhoneType } from '../../../components/contingents/PhoneIcon';

export const selectTrunk = (trunks: Trunk[], trunkId: string) =>
	trunks.find(trunk => trunk.id === trunkId);

export const getCountContingentUsage = (trunk: Trunk, type: PhoneType) => {
	const filteredTrunkContingents = trunk.contingents.filter(contingent => contingent.type === type);

	if (filteredTrunkContingents.length === 0) {
		return null;
	}
	return filteredTrunkContingents.reduce(
		(sum, contingent) => ({
			used: sum.used + contingent.used,
			amount: sum.amount + contingent.amount,
		}),
		{ used: 0, amount: 0 }
	);
};
