import { handleActions } from '../..';
import { BlocklistState } from './types';
import * as actions from './actions';

const initialState: BlocklistState = {
	fetched: false,
	fetching: false,
	items: [],
};

const getItemObject = (phoneNumber: string, isBlock: boolean) => ({ phoneNumber, isBlock });

export default handleActions<BlocklistState, PossibleActions<typeof actions>>(
	{
		BLOCKLIST_ENTRIES_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		BLOCKLIST_ENTRIES_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload.items,
		}),
		BLOCKLIST_ENTRY_CREATE_PENDING: (state, { data }) => {
			const updatedItems = [...state.items];
			const index = updatedItems.findIndex(item => item.phoneNumber === data.phoneNumber);
			if (index > -1) {
				return state;
			}

			return {
				...state,
				items: [...state.items, getItemObject(data.phoneNumber, data.isBlock)],
			};
		},
		BLOCKLIST_ENTRY_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.phoneNumber !== data.phoneNumber),
		}),
		BLOCKLIST_ENTRY_UPDATE_PENDING: (state, { data }) => {
			const updatedItems = [...state.items];
			const itemIndexToUpdate = updatedItems.findIndex(
				item => item.phoneNumber === data.oldPhoneNumber
			);

			updatedItems[itemIndexToUpdate] = getItemObject(data.newPhoneNumber, data.isBlock);

			return { ...state, items: updatedItems };
		},
	},
	initialState
);
