import { HeadingBoundary, InternalLink } from '@panda/ui';
import classnames from 'classnames';
import pathToRegex from 'path-to-regexp';
import React, { useContext, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Preview } from '../../accountNavigation/components/Preview/Preview';
import { AuthenticatedLayoutContext } from '../../layouts/AuthenticatedLayout';
import SatelliteLogoSchwarz from '../../media/logo/satellite_wort_bild_marke_schwarz.svg';
import { useAccount } from '../../redux/modules/account';
import { useTranslate } from '../../redux/modules/translations';
import { useUserInfo } from '../../redux/modules/userinfo';
import { AccountOverviewPath, EventlistPath } from '../../routes/paths';
import { trackClick } from '../../third-party/mixpanel';
import AccountVerificationButton from '../AccountVerificationButton';
import ConnectedCommunicationButtons from '../ConnectedCommunicationButtons';
import ToggleNavigation from './img/toggle-navigation-schwarz.svg';
import classes from './SideNavigation.scss';
import { SideNavigationMenu } from './SideNavigation.types';
import {
	ActiveGroupContext,
	AreColorsInvertedContext,
	IsSideNavigationOpenContext,
	IsTransitioningContext,
} from './SideNavigation.utils';
import { SideNavigationGroup } from './SideNavigationGroup/SideNavigationGroup';
import { SideNavigationLink } from './SideNavigationLink/SideNavigationLink';
import FirebaseListener from '../../helpers/FirebaseListener';

type Props = {
	menu: SideNavigationMenu;
};

export const SideNavigation = ({ menu }: Props) => {
	const translate = useTranslate();
	const [inTransition, setInTransition] = React.useState(false);
	const history = useHistory();
	const { hasBeenNavigated } = useContext(AuthenticatedLayoutContext);
	const account = useAccount();
	const userInfo = useUserInfo();
	const dashboardActive = pathToRegex(history.location.pathname, { end: false }).test(
		'/controlpanel'
	);
	const [openGroups, setOpenGroups] = React.useState<string[]>(
		!hasBeenNavigated && dashboardActive ? ['ACCOUNT_NAVIGATION_HEADING_TELEPHONY'] : []
	);

	const { onToggleNavigation, isOpen, isMobile, onNavigation } = React.useContext(
		AuthenticatedLayoutContext
	);
	const isInAdminArea = true;
	const isSideNavigationOpen = isInAdminArea || isMobile ? isOpen : true;

	const onToggleNavigationClick = () => {
		trackClick('Navigation Toggle Button Clicked', {
			isSideNavigationOpen,
		});

		if (!isMobile) {
			setInTransition(true);
		}
		setOpenGroups([]);
		onToggleNavigation();
	};

	useEffect(() => {
		if (isMobile) {
			onNavigation();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location]);

	useEffect(() => {
		if (!isSideNavigationOpen) {
			setOpenGroups([]);
		}
	}, [isSideNavigationOpen]);

	const onScroll = (e: React.UIEvent<HTMLUListElement>) => {
		if (e.currentTarget.scrollTop > 0) {
			e.currentTarget.classList.add(classes.scrolled);
		} else {
			e.currentTarget.classList.remove(classes.scrolled);
		}
	};

	return (
		<IsSideNavigationOpenContext.Provider value={isSideNavigationOpen}>
			<IsTransitioningContext.Provider value={inTransition}>
				<ActiveGroupContext.Provider value={openGroups}>
					<AreColorsInvertedContext.Provider value={!isInAdminArea}>
						<FirebaseListener />
						<div
							className={classnames(classes.overlay, {
								[classes.closed]: !isSideNavigationOpen,
							})}
							onClick={onToggleNavigationClick}
							role="button"
							onKeyUp={onToggleNavigationClick}
							tabIndex={-1000}
						/>
						<div
							className={classnames(classes.navigation, {
								[classes.closed]: !isSideNavigationOpen,
								[classes.inTransition]: inTransition,
								[classes.invertColors]: !isInAdminArea,
							})}
							onTransitionEnd={() => {
								setInTransition(false);
							}}
						>
							<nav id="aria-0" aria-label={translate('ACCOUNT_NAVIGATION_NAV_ARIA_LABEL')}>
								<div className={classes.header}>
									<NavLink to={isInAdminArea ? AccountOverviewPath : EventlistPath}>
										<img src={SatelliteLogoSchwarz} alt="satellite logo" className={classes.logo} />
									</NavLink>
									{(isInAdminArea || isMobile) && (
										<button
											type="button"
											onClick={onToggleNavigationClick}
											className={classes.toggleButton}
											data-testid="close-navigation-button"
											aria-expanded={isSideNavigationOpen}
											aria-label={translate('ACCOUNT_NAVIGATION_TOGGLE_ARIA_LABEL')}
											aria-controls="aria-0"
										>
											<img src={ToggleNavigation} alt="Button zum zuklappen des Menüs" />
										</button>
									)}
								</div>
								{account && !isInAdminArea && (
									<AccountVerificationButton accountIsVerified={account.verified} />
								)}
								{!isInAdminArea && userInfo && (
									<div className={classes.communicationButtons}>
										<ConnectedCommunicationButtons webuserId={userInfo.sub} />
									</div>
								)}
								<HeadingBoundary>
									<ul onScroll={onScroll}>
										{menu.items.map(item => {
											if (item.kind === 'LINK') {
												return <SideNavigationLink key={item.path} item={item} />;
											}
											return (
												<SideNavigationGroup
													key={item.title}
													item={item}
													toggleOpenGroup={() => {
														const hasOpenGroup = openGroups.includes(item.title);
														setOpenGroups([
															...openGroups.filter(a => a !== item.title),
															...(hasOpenGroup ? [] : [item.title]),
														]);
													}}
												/>
											);
										})}
									</ul>
								</HeadingBoundary>
							</nav>
							{isInAdminArea && !inTransition && (
								<Preview variant={isSideNavigationOpen ? 'large' : 'small'} />
							)}
							<div className={classes.mobileSectionSwitcher}>
								{isInAdminArea && (
									<InternalLink
										to={EventlistPath()}
										variant="normal"
										size="large"
										width="max-on-touch-device"
										button
									>
										{translate('USER_AREA')}
									</InternalLink>
								)}
								{!isInAdminArea && (
									<InternalLink
										to={AccountOverviewPath()}
										variant="normal"
										size="large"
										width="max-on-touch-device"
										button
									>
										{translate('DEDICATED_ADMIN_AREA')}
									</InternalLink>
								)}
							</div>
						</div>
					</AreColorsInvertedContext.Provider>
				</ActiveGroupContext.Provider>
			</IsTransitioningContext.Provider>
		</IsSideNavigationOpenContext.Provider>
	);
};
