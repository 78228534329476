import { handleActions } from '../..';
import * as actions from './actions';
import { OrganisationMembershipState } from './types';

const initialState: OrganisationMembershipState = {
	items: {},
	fetched: false,
	fetching: false,
};

export default handleActions<OrganisationMembershipState, PossibleActions<typeof actions>>(
	{
		ORGANISATION_MEMBERSHIPS_FETCH_SUCCESS: (state, action) => {
			const items = action.payload;
			return {
				...state,
				items,
				fetching: false,
				fetched: true,
			};
		},
	},
	initialState
);
