import classnames from 'classnames';
import React from 'react';
import { useTableContext } from './contexts/tableContext';
import { TableHeaderRow } from './TableHeaderRow';

type Props = {
	children: React.ReactComponentElement<typeof TableHeaderRow>;
};

const styles = {
	thead: (isSmallView: boolean) =>
		classnames(
			isSmallView && 'hidden',
			!isSmallView && ['table-header-group', 'text-gray-600', 'select-none']
		),
};

const TableHeader = ({ children }: Props): JSX.Element => {
	const { isSmallView } = useTableContext();

	return <thead className={styles.thead(isSmallView)}>{children}</thead>;
};

export { TableHeader };
