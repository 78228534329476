import {
	sanitizeNumber,
	isValidPhonenumber,
	getE164Number,
	isDirectDialNumber,
} from '@web-apps/phonenumbers-utils';
import { handleActions } from '../../..';
import Constants from './constants';
import { GsuitePhoneNumbersState, GsuiteNumber } from './types';
import actions from './actions';

const initialState: GsuitePhoneNumbersState = {
	phoneNumber: {
		e164: '',
		raw: '',
		valid: true,
	},
	transferPhoneNumber: {
		e164: '',
		raw: '',
		valid: true,
	},
};

const prepareNumberState = (phoneNumber: string, locale?: string): GsuiteNumber => {
	const sanitizedNumber = sanitizeNumber(phoneNumber);
	const isDirectDial = isDirectDialNumber(sanitizedNumber);
	const callableNumber = isDirectDial ? sanitizedNumber : getE164Number(sanitizedNumber, locale);
	const valid =
		(sanitizedNumber.match(/^[0-9*#]*$/) && sanitizedNumber.length > -1 && isDirectDial) ||
		isValidPhonenumber(callableNumber, locale);
	return {
		raw: phoneNumber,
		e164: callableNumber,
		valid,
	};
};

export default handleActions<GsuitePhoneNumbersState, PossibleActions<typeof actions>>(
	{
		[Constants.GSUITE_PHONE_NUMBERS_SET]: (
			state,
			{ payload }: { payload: { phoneNumber: string; locale?: string } }
		) => ({
			...state,
			phoneNumber: prepareNumberState(payload.phoneNumber, payload.locale),
		}),
		[Constants.GSUITE_PHONE_NUMBERS_TRANSFER_SET]: (
			state,
			{ payload }: { payload: { transferPhoneNumber: string } }
		) => ({
			...state,
			transferPhoneNumber: prepareNumberState(payload.transferPhoneNumber),
		}),
	},
	initialState
);
