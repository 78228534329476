import { HttpClient } from './client/HttpClient';
import { SatelliteUserContact } from './types/satelliteUserContact';
import { logger } from '../third-party/logger';
import { FirebaseClient } from './client/FirebaseClient';

export class SatelliteApi {
	constructor(private readonly http: HttpClient, private readonly firebaseClient: FirebaseClient) {}

	public async createSatelliteUser(
		firstName: string,
		lastName: string,
		email: string,
		organisationId: string,
		channelId: string
	): Promise<SatelliteUserContact> {
		const token = await this.firebaseClient.getFirebaseToken();
		this.http.setIdToken(token.token);
		const path = `/1ef4fd3121e8e218c056e22aed07267b/v1/organisation/${organisationId}/${channelId}/member`;
		logger.log('SatelliteApi', 'createSatelliteUser', 'path', path);

		return this.http.post('/console', path, {
			firstName,
			lastName,
			email,
		});
	}

	async createAdditionalGroup(name: string): Promise<Response> {
		const token = await this.firebaseClient.getFirebaseToken();
		this.http.setIdToken(token.token);
		const organisationId = await this.firebaseClient.getOrganisationId();
		const path = `/1ef4fd3121e8e218c056e22aed07267b/v1/organisation/${organisationId}/team`;
		return this.http.post('/console', path, { name });
	}

	async bookFlatratePlusForUser(userId: string) {
		const token = await this.firebaseClient.getFirebaseToken();
		this.http.setIdToken(token.token);
		const organisationId = await this.firebaseClient.getOrganisationId();
		const path = `/1ef4fd3121e8e218c056e22aed07267b/v1/organisation/${organisationId}/bookPlus`;
		return this.http.post('/console', path, { userId });
	}

	async changeChannelMembers(members: string[], channelId: string) {
		const token = await this.firebaseClient.getFirebaseToken();
		this.http.setIdToken(token.token);
		const organisationId = await this.firebaseClient.getOrganisationId();
		const path = `/1ef4fd3121e8e218c056e22aed07267b/v1/organisation/${organisationId}/team/${channelId}/update-members`;
		return this.http.post('/console', path, { members });
	}

	async cancelFlatratePlusForUser(userId: string) {
		const token = await this.firebaseClient.getFirebaseToken();
		this.http.setIdToken(token.token);
		const organisationId = await this.firebaseClient.getOrganisationId();
		const path = `/1ef4fd3121e8e218c056e22aed07267b/v1/organisation/${organisationId}/cancelPlus`;
		return this.http.post('/console', path, { userId });
	}
}
