import api from '../../../api';
import { ReduxState } from '../../types';
import { createAction } from '../..';
import { ForwardingDestination } from '../../../api/types/forwardings';
import {
	CustomDateBasedForwarding,
	DateBasedForwarding,
	HolidayDateBasedForwarding,
} from './types';
import { ApiDateBasedSet } from '../../../api/types/dateBasedSets';

const shouldFetchDateBasedForwardings = (state: ReduxState, extensionId: string) =>
	!state.dateBasedForwardings.fetchingForExtension.includes(extensionId) &&
	!state.dateBasedForwardings.fetchedForExtension.includes(extensionId);

export const fetchDateBasedForwardings = createAction(
	'DATE_BASED_FORWARDING_FETCH',
	(extensionId: string, force?: boolean) => ({
		promise: async () => {
			const dateBasedSets = await api.getDateBasedSets(extensionId);
			if (!dateBasedSets || dateBasedSets.items.length < 1) {
				return null;
			}

			return Promise.all(
				dateBasedSets.items.map(async dateBasedSet => {
					const destination = await api
						.getDateBasedForwarding(extensionId, dateBasedSet.id)
						.catch(() => {
							return null;
						});

					if (dateBasedSet.type === 'HOLIDAY') {
						return {
							id: dateBasedSet.id,
							type: dateBasedSet.type,
							holidayPresetId: dateBasedSet.preset,
							selectedHolidayIds: dateBasedSet.selectedHolidays,
							name: dateBasedSet.name,
							extensionId,
							destination,
						};
					}

					return {
						id: dateBasedSet.id,
						type: dateBasedSet.type,
						name: dateBasedSet.name,
						customDates: dateBasedSet.customDates,
						extensionId,
						destination,
					};
				})
			);
		},
		shouldFetch: (state: ReduxState) =>
			force || shouldFetchDateBasedForwardings(state, extensionId),
		data: {
			extensionId,
		},
	})
);

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

const convertToApiDateBasedForwarding = (
	dateBasedForwarding: Optional<DateBasedForwarding, 'id'>
): Optional<ApiDateBasedSet, 'id'> => {
	switch (dateBasedForwarding.type) {
		case 'HOLIDAY':
			return {
				id: dateBasedForwarding.id,
				name: dateBasedForwarding.name || '',
				type: 'HOLIDAY',
				preset: dateBasedForwarding.holidayPresetId,
				selectedHolidays: dateBasedForwarding.selectedHolidayIds,
			};
		case 'CUSTOM':
			return {
				id: dateBasedForwarding.id,
				name: dateBasedForwarding.name,
				type: 'CUSTOM',
				customDates: dateBasedForwarding.customDates,
			};
	}
	throw new Error(`Invalid type: ${dateBasedForwarding}`);
};

export const saveDateBasedForwardings = createAction(
	'DATE_BASED_FORWARDINGS_SAVE',
	(
		extensionId: string,
		currentDateBasedForwardings: DateBasedForwarding[],
		newDateBasedForwarding?:
			| Omit<HolidayDateBasedForwarding, 'id'>
			| Omit<CustomDateBasedForwarding, 'id'>
	) => ({
		promise: () => {
			const dateBasedForwardings: (
				| Optional<HolidayDateBasedForwarding, 'id'>
				| Optional<CustomDateBasedForwarding, 'id'>
			)[] = currentDateBasedForwardings;
			if (newDateBasedForwarding) {
				dateBasedForwardings.push(newDateBasedForwarding);
			}

			const request: Optional<ApiDateBasedSet, 'id'>[] = dateBasedForwardings.map(
				dateBasedForwarding => {
					return convertToApiDateBasedForwarding(dateBasedForwarding);
				}
			);
			return api.saveDateBasedSets(extensionId, request);
		},
		data: {
			extensionId,
		},
	})
);

export const deleteDateBasedForwarding = createAction(
	'DATE_BASED_FORWARDINGS_SAVE',
	(
		extensionId: string,
		currentDateBasedForwardings: DateBasedForwarding[],
		dateBasedSetId: string
	) => ({
		promise: () => {
			return api.saveDateBasedSets(
				extensionId,
				currentDateBasedForwardings
					.filter(dateBasedForwarding => dateBasedForwarding.id !== dateBasedSetId)
					.map(convertToApiDateBasedForwarding)
			);
		},
		data: {
			extensionId,
		},
	})
);

export const saveDateBasedForwardingDestination = createAction(
	'DATE_BASED_FORWARDING_DESTINATION_SAVE',
	(extensionId: string, dateBasedSetId: string, destination: ForwardingDestination) => ({
		promise: () => {
			return api.saveDateBasedForwarding(extensionId, dateBasedSetId, destination);
		},
		data: {
			extensionId,
			dateBasedSetId,
			destination,
		},
	})
);
