import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchProductOptions = (state: ReduxState) =>
	!state.productOptions.fetching && !state.productOptions.fetched;

export const fetchProductOptions = createAction(
	'PRODUCT_OPTIONS_FETCH',
	(productId: string, force?: boolean) => ({
		promise: () => api.getProductOptions(productId),
		shouldFetch: (state: ReduxState) => force || shouldFetchProductOptions(state),
	})
);
