import React, { useCallback } from 'react';
import classnames from 'classnames';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../../components/ActionElement';
import { ListViewHeader } from '../ListViewHeader';
import { Search } from '../../components/search/Search';
import { ListHeader } from './ListHeader';
import { SearchNoResult } from '../../components/search/SearchNoResult';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import classes from './ListView.module.css';

type ColumnMeta<T> = {
	text: string;
	sort?: (a: T, b: T) => number;
	hidden?: boolean;
};

export type Props<T, Columns extends string> = {
	/** Überschrift der View. Erscheint oben in einer Zeile mit den `actionElements`. */
	heading: string;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'link';
	 *  to: string;
	 *  label: string;
	 *  loud?: boolean;
	 *  direction?: 'internal' | 'external';
	 * }
	 * ```
	 * **ButtonDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'button';
	 *  onClick: () => void;
	 *  label: string;
	 *  disabled?: boolean;
	 *  loud?: boolean;
	 * }
	 * ```
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	/**
	 * Angabe der `columns` mit einem frei wählbaren `key`.
	 *
	 * * `sort` muss eine Sortierfunktion sein, welche an [Array.sort](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
	 *   übergeben werden kann und aufsteigend sortiert.
	 *
	 * ```
	 * {
	 *  [key]: {
	 *   text: string;
	 *   sort?: (a: T, b: T) => number;
	 *  }
	 * }
	 * ```
	 */
	columns: Record<Columns, ColumnMeta<T>>;
	/**
	 * Übergib die Komponente die angezeigt werden soll. Falls du keine besonderen Wünsche hast, dann nutz unseren [EmptyState](../?path=/docs/components-emptystate-overview--docs)
	 * Der Safari kann nicht mit SVGs umgehen an denen nicht height="100%" steht, denkt also daran das an eure SVGs dran zu machen.
	 */
	emptyState: React.ReactNode;
	/**
	 * Array der Datensätze, die später in der ListNav dargestellt werden sollen.
	 */
	items: T[];
	/** Eine `.map`-Funktion zur Darstellung eines `items`. Benutze die
	 * `<ListViewColumn>`- und `<ListViewItem>`-Komponenten, um deine Daten für die Liste aufzuarbeiten.
	 */
	children: (item: T) => React.ReactElement;
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 */
	search: (term: string, item: T) => boolean;
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder: string;
};

const styles = {
	search: classnames('sm:max-w-[35rem]', 'mt-16', 'sm:mt-24'),
	scrollContainer: (isScrolling: boolean) =>
		classnames(
			isScrolling ? classes.isScrolling : undefined,
			'max-w-full',
			'overflow-auto',
			'focus-visible:ring',
			'focus-visible:ring-color-focus',
			'mt-8',
			'rounded'
		),
	table: classnames('border-spacing-y-16'),
	searchEmptyState: classnames('p-24', 'max-w-[35rem]'),
};

export const ListView = <T, Columns extends string>({
	actionElements,
	children,
	columns: propColumns,
	emptyState,
	heading,
	items,
	search,
	searchPlaceholder,
}: Props<T, Columns>): JSX.Element => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [sortedField, setSortedField] = React.useState(Object.keys(propColumns)[0] as Columns);
	const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('ascending');

	const [isScrolling, setIsScrolling] = React.useState(false);
	const scrollContainer = useResizeObserver(
		useCallback(entry => {
			const containerWidth = Math.round(entry.contentRect.width);
			const contentWidth = entry.target.scrollWidth;
			setIsScrolling(contentWidth > containerWidth);
		}, [])
	);

	// I think making columns an object might have been a bad idea.
	const columns = Object.entries(propColumns) as [Columns, ColumnMeta<T>][];

	const visibleItems = items
		.filter(item => search(searchTerm, item))
		.sort((a, b) => {
			const sort = propColumns[sortedField].sort;

			if (!sort) {
				return 0;
			}

			return sort(a, b) * (sortDirection === 'ascending' ? 1 : -1);
		});

	return (
		<>
			<ListViewHeader
				heading={heading}
				itemCount={items.length}
				actionElements={actionElements.map((element, i) => (
					<ActionElement
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						element={{ ...element, loud: i === actionElements.length - 1 }}
					/>
				))}
			/>

			{items.length === 0 ? (
				emptyState
			) : (
				<>
					<div className={styles.search}>
						<Search
							value={searchTerm}
							onChange={setSearchTerm}
							placeholder={searchPlaceholder}
							resultCount={visibleItems.length}
							landmark
						/>
					</div>

					<div className={styles.scrollContainer(isScrolling)} ref={scrollContainer}>
						<table className={styles.table}>
							<ListHeader
								columns={columns
									.filter(([_, col]) => !col.hidden)
									.map(([id, col]) => ({
										id,
										label: col.text,
										sortable: !!col.sort,
									}))}
								sortedBy={{
									column: sortedField,
									direction: sortDirection,
								}}
								sortBy={(column, direction) => {
									setSortedField(column);
									setSortDirection(direction);
								}}
							/>

							<tbody>{visibleItems.map(children)}</tbody>
						</table>
					</div>

					{visibleItems.length === 0 ? (
						<div className={styles.searchEmptyState}>
							<SearchNoResult searchTerm={searchTerm} />
						</div>
					) : null}
				</>
			)}
		</>
	);
};
