import classnames from 'classnames';
import React from 'react';
import { Entity } from './EntitySwitcher';
import { SideBarHeader } from './SideBarHeader';
import { SideBarNavigation } from './SideBarNavigation';

import classes from './SideBarView.module.css';
import { HeadingBoundary } from '../../components/heading/HeadingBoundary';
import { State } from '../../components/state/State';

export type EntitySwitcherProps<T extends { id: string }> = {
	/** Callback welches beim Objektwechsel aufgerufen wird. */
	onChange: (entity: T) => void;
	/** Alle auswählbaren Objekte inklusive des aktuell ausgewählten. */
	entities: Entity<T>[];
	/**
	 * Ein Platzhalter welcher im Objektwechsler in der Suche
	 * angezeigt wird.
	 */
	searchPlaceholder: string;
	/**
	 * Ein Aria Label für die Suche im Objektwechsler.
	 */
	searchAriaLabel: string;
};

export type Props<T extends { id: string }> = {
	/**
	 * Der Name welcher für die gesamte Seite per Screenreader kommuniziert wird.
	 * Dies sollte fast immer dem Heading des ausgewählten Objekts entsprechen.
	 */
	heading: string;

	/**
	 * Die Grafik (z.Bsp. den [Avatar](../?path=/docs/components-avatar-overview--docs)), welche das aktuell ausgewählte Objekt repräsentiert.
	 * Sie sollte mindestens 104x104 Pixel groß sein.
	 */
	graphic: React.ReactNode;

	/** Das aktuell ausgewählte Objekt. */
	entitySwitcher: EntitySwitcherProps<T>;

	/**
	 * Eine Rolle die dieses Objekt einnimmt (bspw. Admin oder Standard).
	 */
	role?: string;

	/**
	 * Ein Zustand dieses Objekts (bspw. DND oder offline).
	 */
	status?: { text: string; color: 'red' | 'orange' | 'green' };

	/**
	 * Ein Array von `ContextMenu`-Items.
	 */
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];

	/**
	 * Zusätzliche Informationen, wie zum Beispiel eine Mail Adresse, die unter der Überschrift angezeigt werden.
	 */
	metadata?: React.ReactNode;

	/**
	 * Navigationseinträge, die unter dem Profil dargestellt werden sollen.
	 * Eine Navigation zu externen Seiten wird nicht unterstützt.
	 */
	navigation?: { label: string; url: string }[];

	/**
	 * Ein Platzhalter um eure Widgets anzeigen zu können.
	 */
	widget?: React.ReactNode;
};

const styles = {
	container: classnames(classes.gradient, 'rounded-lg', 'border', 'border-gray-100'),
};

export const SideBarView = <T extends { id: string }>({
	heading,
	graphic,
	navigation,
	entitySwitcher,
	role,
	status,
	contextMenu,
	metadata,
	widget,
}: Props<T>) => {
	return (
		<>
			<section className={styles.container}>
				<SideBarHeader
					heading={heading}
					graphic={graphic}
					entitySwitcher={entitySwitcher}
					contextMenu={contextMenu}
					metadata={metadata}
					role={role}
					status={
						status ? (
							<State size="medium" color={status.color}>
								{status.text}
							</State>
						) : null
					}
				/>

				{navigation ? <SideBarNavigation items={navigation} /> : null}
			</section>
			<HeadingBoundary>{widget}</HeadingBoundary>
		</>
	);
};
