import { handleActions } from '../..';
import { AcdState } from './types';
import * as actions from './actions';

import * as acdAudioFilesActions from '../acdAudioFiles/actions';
import { ApiError } from '../../../api';

const initialState: AcdState = {
	items: [],
	fetched: false,
	fetching: false,
};

type Actions = typeof actions & typeof acdAudioFilesActions;

export default handleActions<AcdState, PossibleActions<Actions>>(
	{
		ACDS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		ACDS_FETCH_SUCCESS: (state, { payload }) => {
			const newItems = [
				...payload.items.map(acd => ({
					id: acd.id,
					name: acd.name,
					phoneNumberIds: acd.phoneNumberIds.map(number => number.toString()),
					agents: acd.agents,
					settings: acd.settings,
				})),
			];
			newItems.sort((a, b) => a.name.localeCompare(b.name));

			return {
				...state,
				fetched: true,
				fetching: false,
				items: newItems,
			};
		},
		ACDS_FETCH_FAILURE: (state, { payload }) => {
			if (payload instanceof ApiError && payload.status !== 403) {
				return state;
			}

			return {
				...state,
				fetched: true,
				fetching: false,
			};
		},

		ACD_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				items: [
					...state.items,
					{
						id: payload.id,
						name: payload.name,
						phoneNumberIds: payload.phoneNumberIds
							? payload.phoneNumberIds.map(number => number.toString())
							: [],
						agents: payload.agents,
						settings: payload.settings,
					},
				],
			};
		},

		ACD_NUMBERS_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						return {
							...acd,
							phoneNumberIds: acd.id === data.id ? data.phoneNumberIds : acd.phoneNumberIds,
						};
					}),
				],
			};
		},

		ACD_AGENTS_SET_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						return {
							...acd,
							agents:
								acd.id === data.id
									? payload.items.map(agent => ({
											id: agent.id,
											deviceIds: agent.deviceIds,
											state: agent.state,
									  }))
									: acd.agents,
						};
					}),
				],
			};
		},

		ACD_AGENT_DEVICES_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}
						return {
							...acd,
							agents: acd.agents.map(agent => {
								return {
									...agent,
									deviceIds: agent.id === data.agentId ? data.deviceIds : agent.deviceIds,
								};
							}),
						};
					}),
				],
			};
		},

		ACD_NAME_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}

						return {
							...acd,
							name: data.name,
						};
					}),
				],
			};
		},

		ACD_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: [...state.items.filter(acd => acd.id !== data.acdId)],
			};
		},

		ACD_QUEUE_WAITING_AUDIO_FILE_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								queue: {
									...acd.settings.queue,
									waitingAudioFileId: data.audioFileId,
								},
							},
						};
					}),
				],
			};
		},

		ACD_GREETING_AUDIO_FILE_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								greetingAudioFileId: data.audioFileId,
							},
						};
					}),
				],
			};
		},

		ACD_AUDIO_FILE_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId || !data.defaultAudioFileId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								queue: {
									...acd.settings.queue,
									waitingAudioFileId:
										data.audioFileType === 'QUEUE_WAITING'
											? data.defaultAudioFileId
											: acd.settings.queue.waitingAudioFileId,
								},
							},
						};
					}),
				],
			};
		},

		ACD_AGENTS_FOLLOW_UP_TIME_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								agents: {
									...acd.settings.agents,
									followUpTime: data.followUpTime,
								},
							},
						};
					}),
				],
			};
		},

		ACD_AGENTS_RING_TIME_SET_PENDING: (state, { data }) => {
			return {
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== data.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								agents: {
									...acd.settings.agents,
									ringTime: data.ringTime,
								},
							},
						};
					}),
				],
			};
		},
	},
	initialState
);
