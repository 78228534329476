import { handleActions } from '../..';

import { Tacs } from './types';

import * as actions from './actions';

const initialState: Tacs = {
	accepted: false,
	fetching: false,
	fetched: false,
};

export default handleActions<Tacs, PossibleActions<typeof actions>>(
	{
		TACS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		TACS_FETCH_SUCCESS: (state, { payload }) => ({
			fetching: false,
			fetched: true,
			accepted: payload.accepted,
		}),
	},
	initialState
);
