/* eslint-disable max-classes-per-file */
import { FormikErrors, FormikProps } from 'formik';
import React from 'react';
import { FormState, ManagedFormConsumer, ManagedFormState } from './ManagedFormState';

export type ManagedValidatorResult<Output> =
	| { valid: true; value: Output }
	| { valid: false; error: string };

export type ManagedFieldValidator<Input, Output> = (value: Input) => ManagedValidatorResult<Output>;

export type ManagedMultiFieldValidator<Input, Values, Output> = (
	value: Input,
	values: Values
) => ManagedValidatorResult<Output>;

export type ManagedFormValidator<Values> = (values: Values) => string[];

export type SubmitResult<Source extends FieldValues> = void | undefined | SubmitErrors<Source>;

export type SubmitHandler<Values extends FieldValues> = (
	values: Values,
	form: SubmitErrors<Values>
) => SubmitResult<Values> | Promise<SubmitResult<Values>>;

export interface FieldValues {
	// FieldValues is only used in `extends` clauses of generic types
	// and using anything else breaks inferrence down the line.
	//
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export type MappedValues<Values extends FieldValues> = {
	// MappedValues is only used in `extends` clauses of generic types
	// and using anything else breaks inferrence down the line.
	//
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key in keyof Values]: any;
};

type MappedFields<Source extends FieldValues, Target extends MappedValues<Source>> = {
	[key in keyof Source]: Source[key] extends Target[key] ? never : key;
}[keyof Source];

export type Validators<Source extends FieldValues, Target extends MappedValues<Source> = Source> = {
	[key in MappedFields<Source, Target>]: ManagedMultiFieldValidator<
		Source[key],
		Source,
		Target[key]
	>;
} & {
	[key in Exclude<keyof Source, MappedFields<Source, Target>>]?: ManagedMultiFieldValidator<
		Source[key],
		Source,
		Target[key]
	>;
};

export type ManagedFieldProps<T> = {
	value: T;
	name: string;
	touched: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	onBlur: React.FocusEventHandler;
	setValue: (value: T) => void;
} & ({ valid: true; error: null } | { valid: false; error: string });

export interface ChildProps<Source extends FieldValues> {
	form: {
		errors: string[];
		canBeSubmitted: boolean;
	};
	fields: { [field in keyof Source]: ManagedFieldProps<Source[field]> };
}

type ContentProps<Source extends FieldValues, Target extends MappedValues<Source>> = {
	initialValues: Source;
	children: (props: ChildProps<Source>) => React.ReactNode;
	onSubmit: (
		data: Target,
		form: SubmitErrors<Target>
	) => SubmitResult<Source> | Promise<SubmitResult<Source>>;
	validators: Validators<Source, Target>;
	formValidator?: ManagedFormValidator<Target>;
	className?: string;

	state: FormState<Source, Target>;
};

class Content<
	Source extends FieldValues,
	Target extends MappedValues<Source>
> extends React.Component<ContentProps<Source, Target>> {
	private static noopFormValidator = () => [];

	public componentDidMount() {
		if (!this.props.state.initialized) {
			this.props.state.init({
				initialValues: this.props.initialValues,
				onSubmit: this.props.onSubmit,
				fieldValidators: this.props.validators,
				formValidator: this.props.formValidator || Content.noopFormValidator,
			});
		}
	}

	private onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		if (!this.props.state.initialized) {
			return;
		}

		return this.props.state.onSubmit(e, this.props.onSubmit);
	};

	public render() {
		if (!this.props.state.initialized) {
			return null;
		}

		return (
			<form noValidate className={this.props.className} onSubmit={this.onSubmit}>
				{this.props.children(this.props.state.childProps)}
			</form>
		);
	}
}

export class SubmitErrors<Source extends FieldValues> {
	#formErrors: string[] = [];

	#fieldErrors: Partial<Record<keyof Source, string>> = {};

	public get fieldErrors(): Readonly<Partial<Record<keyof Source, string>>> {
		return this.#fieldErrors;
	}

	public get formErrors(): Readonly<string[]> {
		return this.#formErrors;
	}

	public fieldError(field: keyof Source, error: string) {
		this.#fieldErrors[field] = error;
		return this;
	}

	public formError(error: string) {
		this.#formErrors.push(error);
		return this;
	}
}

type Props<Source extends FieldValues, Target extends MappedValues<Source>> = {
	initialValues: Source;
	children: (props: ChildProps<Source>) => React.ReactNode;
	onSubmit: (
		data: Target,
		form: SubmitErrors<Target>
	) => SubmitResult<Source> | Promise<SubmitResult<Source>>;
	validators: Validators<Source, Target>;
	formValidator?: ManagedFormValidator<Target>;
	className?: string;

	state?: FormState<Source, Target>;
};

export class ManagedForm<
	Source extends FieldValues,
	Target extends MappedValues<Source>
> extends React.Component<Props<Source, Target>> {
	public static buildField<Values extends FieldValues, Field extends keyof Values & string>(
		formik: FormikProps<Values>,
		field: Field,
		fieldCache: { [field in keyof Values]?: ManagedFieldProps<Values[field]> } = {}
	): ManagedFieldProps<Values[Field]> {
		const cachedField = fieldCache[field];

		const error = formik.errors[field];
		const setValue =
			cachedField?.setValue ??
			((value: Values[typeof field]) => formik.setFieldValue(field, value));

		const newField: ManagedFieldProps<Values[Field]> =
			formik.touched[field] && typeof error === 'string'
				? {
						name: field,
						value: formik.values[field] ?? formik.initialValues[field],
						onBlur: formik.handleBlur,
						onChange: formik.handleChange,
						touched: true,
						setValue,
						valid: false,
						error,
				  }
				: {
						name: field,
						value: formik.values[field] ?? formik.initialValues[field],
						touched: !!formik.touched[field],
						onBlur: formik.handleBlur,
						onChange: formik.handleChange,
						setValue,
						valid: true,
						error: null,
				  };

		if (
			!cachedField ||
			cachedField.error !== newField.error ||
			cachedField.touched !== newField.touched ||
			cachedField.valid !== newField.valid ||
			cachedField.value !== newField.value ||
			cachedField.name !== newField.name
		) {
			return newField;
		}

		return cachedField;
	}

	public static collectData<Source extends FieldValues, Target extends MappedValues<Source>>(
		fieldValidators: Validators<Source, Target>,
		formValidator: ManagedFormValidator<Target>,
		values: Source
	):
		| {
				state: 'valid';
				values: Target;
				formErrors: string[];
				fieldErrors: FormikErrors<Source>;
		  }
		| { state: 'fields-invalid'; formErrors: null; fieldErrors: FormikErrors<Source> }
		| { state: 'form-invalid'; formErrors: string[]; fieldErrors: FormikErrors<Source> } {
		const data = {} as Target;
		const fieldErrors: { [field in keyof Source]?: string } = {};

		for (const field in values) {
			// The whole field <-> validator mapping is not well-trackable by typescript.
			// So we need to use any a couple of times.
			//
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const validator: ManagedMultiFieldValidator<any, Source, any> | undefined =
				// Typescript is unable to infer that `keyof values` is a subset of `keyof validators`
				// so we need to do this cast-fu.
				//
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				fieldValidators[field as any as keyof typeof fieldValidators];

			if (validator) {
				const result = validator(values[field], values);

				if (result.valid) {
					data[field] = result.value;
				} else {
					fieldErrors[field] = result.error;
				}
			} else {
				// Typescript is unable to track single keys in a generic context,
				// so we need to cast the completely valid field value to any.
				//
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				data[field] = values[field] as any;
			}
		}

		if (Object.keys(fieldErrors).length !== 0) {
			return {
				state: 'fields-invalid',
				formErrors: null,
				fieldErrors: fieldErrors as FormikErrors<Source>,
			};
		}

		const formErrors = formValidator(data);
		if (formErrors.length > 0) {
			return {
				state: 'form-invalid',
				formErrors,
				fieldErrors: {},
			};
		}

		return {
			state: 'valid',
			values: data,
			formErrors: [],
			fieldErrors: {},
		};
	}

	public static separated<
		Source extends FieldValues,
		Target extends MappedValues<Source> = Source
	>() {
		return [
			ManagedFormState as ManagedFormState<Source, Target> extends React.Component<infer P>
				? React.ComponentClass<P>
				: never,
			ManagedForm as ManagedForm<Source, Target> extends React.Component<infer P>
				? React.ComponentClass<P & { state: FormState<Source, Target> }>
				: never,
		] as const;
	}

	public render() {
		if (this.props.state) {
			return (
				<Content
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validators={this.props.validators}
					formValidator={this.props.formValidator}
					state={this.props.state}
					className={this.props.className}
				>
					{this.props.children}
				</Content>
			);
		}

		return (
			<ManagedFormConsumer>
				{contextState => {
					if (contextState) {
						return (
							<Content
								initialValues={this.props.initialValues}
								onSubmit={this.props.onSubmit}
								validators={this.props.validators}
								formValidator={this.props.formValidator}
								state={contextState}
								className={this.props.className}
							>
								{this.props.children}
							</Content>
						);
					}

					return (
						<ManagedFormState<Source, Target>>
							{localState => (
								<Content
									initialValues={this.props.initialValues}
									onSubmit={this.props.onSubmit}
									validators={this.props.validators}
									formValidator={this.props.formValidator}
									state={localState}
									className={this.props.className}
								>
									{this.props.children}
								</Content>
							)}
						</ManagedFormState>
					);
				}}
			</ManagedFormConsumer>
		);
	}
}
