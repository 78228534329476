import React from 'react';
import classnames from 'classnames';

import classes from './Button.scss';

interface Props {
	id?: string;
	submit?: boolean;
	primary?: boolean;
	danger?: boolean;
	disabled?: boolean;
	title?: string;
	children: React.ReactNode;
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	testSelector?: string;
	ariaLabel?: string;
}

interface PropsWithRef extends Props {
	forwardedRef?: React.Ref<HTMLButtonElement>;
}

class UnwrappedButton extends React.PureComponent<PropsWithRef> {
	public render() {
		return (
			<button
				id={this.props.id}
				ref={this.props.forwardedRef}
				onClick={this.props.onClick}
				className={classnames(classes.button, this.props.className, {
					[classes.primary]: this.props.primary,
					[classes.danger]: this.props.danger,
				})}
				title={this.props.title}
				type={this.props.submit ? 'submit' : 'button'}
				disabled={this.props.disabled}
				data-test-selector={this.props.testSelector}
				data-testid={this.props.testSelector}
				aria-label={this.props.ariaLabel}
			>
				{this.props.children}
			</button>
		);
	}
}

/** @deprecated Please use Panda Buttons */
export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => (
	<UnwrappedButton
		id={props.id}
		submit={props.submit}
		primary={props.primary}
		danger={props.danger}
		disabled={props.disabled}
		title={props.title}
		className={props.className}
		onClick={props.onClick}
		testSelector={props.testSelector}
		forwardedRef={ref}
		ariaLabel={props.ariaLabel}
	>
		{props.children}
	</UnwrappedButton>
));
