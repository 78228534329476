import React from 'react';

import { UserInfo } from '../redux/modules/userinfo';

interface Props {
	userinfo?: UserInfo;
}

export class LanguageAttributeUpdater extends React.Component<Props> {
	public componentDidMount = () => this.setLanguageAttribute();

	public componentDidUpdate = () => this.setLanguageAttribute();

	private setLanguageAttribute() {
		const { userinfo } = this.props;
		if (userinfo) {
			document.documentElement.lang = userinfo.locale === 'en_GB' ? 'en' : 'de';
		}

		return null;
	}

	public render() {
		return null;
	}
}
