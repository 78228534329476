import { CustomerProductState } from './types';
import * as actions from './actions';
import { handleActions } from '../..';

const initialState = {
	items: [],
	fetched: false,
	fetching: false,
};

export default handleActions<CustomerProductState, PossibleActions<typeof actions>>(
	{
		CUSTOMER_PRODUCTS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		CUSTOMER_PRODUCTS_FETCH_SUCCESS: (state, action) => ({
			fetching: false,
			fetched: true,
			items: action.payload.items,
		}),
	},
	initialState
);
