import React from 'react';
import { Translate } from '../../redux/modules/translations';

import landlineIcon16 from '../../media/icons/icon-voip_phone-dark-line-default-16.svg';
import mobileIcon16 from '../../media/icons/icon-mobil-dark-line-default-16.svg';

import landlineIcon24 from '../../media/icons/icon-voip_phone-dark-line-default-24.svg';
import mobileIcon24 from '../../media/icons/icon-mobil-dark-line-default-24.svg';

interface Props {
	size: 16 | 24;
	type: PhoneType;
	translate: Translate;
}

export enum PhoneType {
	LANDLINE,
	MOBILE,
}
export class PhoneIcon extends React.Component<Props> {
	public render() {
		switch (this.props.type) {
			case PhoneType.LANDLINE:
				return (
					<img
						src={this.props.size === 16 ? landlineIcon16 : landlineIcon24}
						alt={this.props.translate('LANDLINE')}
					/>
				);

			case PhoneType.MOBILE:
				return (
					<img
						src={this.props.size === 16 ? mobileIcon16 : mobileIcon24}
						alt={this.props.translate('MOBILE_TELEPHONY')}
					/>
				);
		}
	}
}
