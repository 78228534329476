import { createAction } from '../../..';

import { CallTerminationReason, CallParticipantState } from './types';
import {
	CALL_CREATED,
	CALL_TERMINATED,
	CALL_ACCEPTED,
	CALL_MUTED,
	CALL_UNMUTED,
	CALL_TERMINATION_FINISHED,
	BROWSER_ONLINE,
	BROWSER_OFFLINE,
	SIP_CLIENT_DISCONNECTED,
	SIP_CLIENT_CONNECTED,
} from './constants';

export const callCreated = createAction(
	CALL_CREATED,
	(call: {
		direction: 'INCOMING' | 'OUTGOING';
		remoteParty?: CallParticipantState;
		localParty?: CallParticipantState;
	}) => call
);

export const callAccepted = createAction(CALL_ACCEPTED);

export const callMuted = createAction(CALL_MUTED);

export const callUnmuted = createAction(CALL_UNMUTED);

export const callTerminated = createAction(
	CALL_TERMINATED,
	(reason: CallTerminationReason) => reason
);

export const callTerminationFinished = createAction(CALL_TERMINATION_FINISHED);

export const sipClientConnected = createAction(SIP_CLIENT_CONNECTED);
export const sipClientDisconnected = createAction(SIP_CLIENT_DISCONNECTED);

export const browserOnline = createAction(BROWSER_ONLINE);
export const browserOffline = createAction(BROWSER_OFFLINE);
