/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classnames from 'classnames';
import { ListNavAsTable } from './ListNavAsTable';
import { ListNavAsList } from './ListNavAsList';
import { ColumnMeta } from './types';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../../components/ActionElement';
import { Search } from '../../components/search/Search';
import { ListViewHeader } from '../ListViewHeader';

export type Props<T, Columns extends Record<string, ColumnMeta<T>>> = {
	/** Überschrift der View. Erscheint oben in einer Zeile mit den `actionElements`. */
	heading: string;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'link';
	 *  to: string;
	 *  label: string;
	 *  loud?: boolean;
	 *  direction?: 'internal' | 'external';
	 * }
	 * ```
	 * **ButtonDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'button';
	 *  onClick: () => void;
	 *  label: string;
	 *  disabled?: boolean;
	 *  loud?: boolean;
	 * }
	 * ```
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	/**
	 * Angabe der `columns` mit einem frei wählbaren `key`.
	 *
	 * * `sort` muss eine Sortierfunktion sein, welche an [Array.sort](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
	 *   übergeben werden kann und aufsteigend sortiert.
	 * * `isState` entscheidet, ob die Spalte `States` enthält, welche in der mobilen Ansicht vorne angezeigt werden sollen
	 *
	 * ```
	 * {
	 *  [key]: {
	 *   text: string;
	 *   sort?: (a: T, b: T) => number;
	 *   clip?: 'meta' | 'truncate';
	 *   size?: 'xsmall' | 'small' | 'medium' | 'large';
	 *   isState?: boolean;
	 *  }
	 * }
	 * ```
	 */
	columns: Columns;
	/**
	 * Übergib die Komponente die angezeigt werden soll. Falls du keine besonderen Wünsche hast, dann nutz unseren [EmptyState](../?path=/docs/components-emptystate-overview--docs)
	 * Der Safari kann nicht mit SVGs umgehen an denen nicht height="100%" steht, denkt also daran das an eure SVGs dran zu machen.
	 */
	emptyState: React.ReactNode;
	/**
	 * Array der Datensätze, die später in der ListNav dargestellt werden sollen.
	 * Beachte, dass die erste Spalte kein Array sein darf, sondern ein `string` sein muss, da daraus der Link für die Zeile gebaut wird.
	 * In allen anderen Spalten können auch Arrays verwendet werden. Je nach verfügbarem Platz werden mehr oder weniger angezeigt.
	 */
	items: T[];
	/**
	 * Anzahl der maximal anlegbaren Datensätze
	 */
	itemLimit?: number;
	/** Eine `.map`-Funktion zur Darstellung eines `items`. Benutze die
	 * `<ListNavColumn>`- und `<ListNavItem>`-Komponenten, um deine Daten für die Liste aufzuarbeiten.
	 */
	children: (item: T) => JSX.Element;
} & (PropsWithSearch<T> | PropsWithoutSearch);

type PropsWithSearch<T> = {
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 * Wenn diese Funktion übergeben wird, zeigt die ListNavView eine Suche an.
	 */
	search: (term: string, item: T) => boolean;
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder: string;
};

type PropsWithoutSearch = {
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 * Wenn diese Funktion übergeben wird, zeigt die ListNavView eine Suche an.
	 */
	search?: undefined;
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder?: undefined;
};

const styles = {
	search: classnames('sm:max-w-[35rem]', 'mt-16', 'sm:mt-24'),
	emptyStateContent: classnames(
		'w-full',
		'h-full',
		'flex',
		'justify-center',
		'items-center',
		'mt-16',
		'sm:mt-40',
		'xl:mt-64'
	),
	mobileContent: classnames('sm:hidden', 'mt-16'),
	desktopContent: classnames('hidden', 'sm:block'),
};

const ListNavView = <T, Columns extends Record<string, ColumnMeta<T>>>({
	heading,
	actionElements,
	columns,
	emptyState,
	items,
	itemLimit,
	search,
	searchPlaceholder,
	children,
}: Props<T, Columns>): JSX.Element => {
	const [sortedField, setSortedField] = React.useState<keyof Columns>(Object.keys(columns)[0]);
	const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('ascending');
	const [searchTerm, setSearchTerm] = React.useState('');

	const sortedAndFilteredItems = [...items]
		.filter(item => (search ? search(searchTerm, item) : true))
		.sort((a, b) => {
			const sortItems = columns[sortedField as keyof Columns].sort;

			if (!sortItems) {
				return 0;
			}

			return sortItems(a, b) * (sortDirection === 'ascending' ? 1 : -1);
		});

	const showEmptyState = items.length === 0;

	const renderHeader = () => {
		const renderSearch = () => {
			if (!search || !searchPlaceholder || showEmptyState) {
				return null;
			}

			return (
				<div className={styles.search}>
					<Search
						value={searchTerm}
						onChange={setSearchTerm}
						placeholder={searchPlaceholder}
						resultCount={sortedAndFilteredItems.length}
						landmark
					/>
				</div>
			);
		};

		return (
			<>
				<ListViewHeader
					heading={heading}
					actionElements={actionElements.map((element, i) => (
						<ActionElement
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							element={{ ...element, loud: i === actionElements.length - 1 }}
						/>
					))}
					itemCount={items.length}
					itemLimit={itemLimit}
				/>
				{renderSearch()}
			</>
		);
	};

	const renderMobile = () => (
		<div className={styles.mobileContent}>
			<ListNavAsList items={sortedAndFilteredItems} columns={columns} searchTerm={searchTerm}>
				{children}
			</ListNavAsList>
		</div>
	);

	const renderDesktop = () => (
		<div className={styles.desktopContent}>
			<ListNavAsTable
				sortedField={sortedField}
				setSortedField={setSortedField}
				sortDirection={sortDirection}
				setSortDirection={setSortDirection}
				items={sortedAndFilteredItems}
				columns={columns}
				searchTerm={searchTerm}
			>
				{children}
			</ListNavAsTable>
		</div>
	);

	if (showEmptyState) {
		return (
			<>
				{renderHeader()}
				<div className={styles.emptyStateContent}>{emptyState}</div>
			</>
		);
	}

	return (
		<>
			{renderHeader()}
			{renderMobile()}
			{renderDesktop()}
		</>
	);
};

export { ListNavView };
