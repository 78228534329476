import { DateTime } from 'luxon';
import {
	BaseDateBasedForwarding,
	CustomDateBasedForwarding,
	DateBasedForwarding,
	DateBasedForwardingState,
	HolidayDateBasedForwarding,
} from './types';
import { Translate } from '../translations';
import { HolidayPresetState } from '../holidayPresets';
import { getHolidayPresetById, getNextHolidayDateFromPreset } from '../holidayPresets/selectors';

export const getDateBasedForwardingsByExtensionId = (
	state: DateBasedForwardingState,
	extensionId: string
): DateBasedForwarding[] | null => {
	return state.items[extensionId];
};

export const isHolidayDateBasedForwarding = (
	dateBasedForwarding: BaseDateBasedForwarding
): dateBasedForwarding is HolidayDateBasedForwarding => {
	return dateBasedForwarding.type === 'HOLIDAY';
};

export const isCustomDateBasedForwarding = (
	dateBasedForwarding: BaseDateBasedForwarding
): dateBasedForwarding is CustomDateBasedForwarding => {
	return dateBasedForwarding.type === 'CUSTOM';
};

export const isAnyCustomDateNotInPast = (
	dateBasedForwarding: CustomDateBasedForwarding,
	now: DateTime
): boolean => {
	const today = now.startOf('day');
	return dateBasedForwarding.customDates.some(customDate => {
		return today <= DateTime.fromISO(customDate, { zone: 'utc' });
	});
};

export const getHolidayDateBasedForwardings = (
	dateBasedForwardings: BaseDateBasedForwarding[]
): HolidayDateBasedForwarding[] => {
	return dateBasedForwardings.filter(isHolidayDateBasedForwarding);
};

export const getCustomDateBasedForwardings = (
	dateBasedForwardings: BaseDateBasedForwarding[]
): CustomDateBasedForwarding[] => {
	return dateBasedForwardings.filter(isCustomDateBasedForwarding);
};

export const getCustomDateBasedForwardingsNotInPast = (
	dateBasedForwardings: DateBasedForwarding[],
	now: DateTime
): CustomDateBasedForwarding[] => {
	return dateBasedForwardings
		.filter(isCustomDateBasedForwarding)
		.filter(value => isAnyCustomDateNotInPast(value, now));
};

export const getCustomDateBasedForwardingsInPast = (
	dateBasedForwardings: DateBasedForwarding[],
	now: DateTime
): CustomDateBasedForwarding[] => {
	return dateBasedForwardings
		.filter(isCustomDateBasedForwarding)
		.filter(dateBasedForwarding => !isAnyCustomDateNotInPast(dateBasedForwarding, now));
};

export const getDateBasedForwardingName = (
	dateBasedForwarding: BaseDateBasedForwarding,
	translate: Translate
): string => {
	return dateBasedForwarding.name || `${translate('ROUTING_CARD_HOLIDAY_PROFILE')} 1`;
};

export const getNextSelectedHolidayDate = (
	presetState: HolidayPresetState,
	holidayDateBasedForwardings: HolidayDateBasedForwarding[]
) => {
	if (holidayDateBasedForwardings.length === 0) {
		return undefined;
	}

	const nextHolidaysOfEachHolidayForwarding = holidayDateBasedForwardings
		.map(holidayDateBasedForwarding => {
			const preset = getHolidayPresetById(presetState, holidayDateBasedForwarding.holidayPresetId);

			if (!preset) {
				return null;
			}

			const nextHolidayFromPreset = getNextHolidayDateFromPreset(
				preset,
				holidayDateBasedForwarding.selectedHolidayIds
			);

			if (!nextHolidayFromPreset) {
				return null;
			}
			return nextHolidayFromPreset;
		})
		.filter(date => date !== null) as DateTime[];

	return DateTime.min(...nextHolidaysOfEachHolidayForwarding);
};

export const getNextCustomDate = (
	customDateBasedForwardings: CustomDateBasedForwarding[],
	now: DateTime
): DateTime | undefined => {
	const allDates = customDateBasedForwardings
		.flatMap(customDateBasedForwarding => customDateBasedForwarding.customDates)
		.filter(date => now.startOf('day') <= DateTime.fromISO(date, { zone: 'utc' }).startOf('day'));
	return DateTime.min(...allDates.map(date => DateTime.fromISO(date, { zone: 'utc' })));
};
