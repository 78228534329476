import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchAcds = createAction('ACDS_FETCH', (force?: boolean) => ({
	promise: () => api.getAcds(),
	shouldFetch: (state: ReduxState) => (!state.acds.fetched && !state.acds.fetching) || force,
}));

export const createAcd = createAction(
	'ACD_CREATE',
	(name: string, numberIds: string[], agentIds: string[]) => ({
		promise: () =>
			api.createAcd(
				name,
				numberIds.map(number => parseInt(number, 10)),
				agentIds
			),
	})
);

export const setAcdAgents = createAction('ACD_AGENTS_SET', (acdId: string, agentIds: string[]) => ({
	promise: () => api.setAcdAgents(acdId, agentIds),
	data: { id: acdId },
}));

export const setAcdNumbers = createAction(
	'ACD_NUMBERS_SET',
	(id: string, name: string, phoneNumberIds: string[]) => ({
		promise: () =>
			api.setAcdNumbers(
				id,
				phoneNumberIds.map(number => parseInt(number, 10))
			),
		data: { id, name, phoneNumberIds },
	})
);

export const setAcdAgentDevices = createAction(
	'ACD_AGENT_DEVICES_SET',
	(acdId: string, agentId: string, deviceIds: string[]) => ({
		promise: () => api.setAcdAgentDevices(acdId, agentId, deviceIds),
		data: { acdId, agentId, deviceIds },
	})
);

export const setAcdName = createAction('ACD_NAME_SET', (acdId: string, name: string) => ({
	promise: () => api.setAcdName(acdId, name),
	data: { acdId, name },
}));

export const deleteAcd = createAction('ACD_DELETE', (acdId: string) => ({
	promise: () => api.deleteAcd(acdId),
	data: { acdId },
}));

export const setAcdQueueWaitingAudioFile = createAction(
	'ACD_QUEUE_WAITING_AUDIO_FILE_SET',
	(acdId: string, audioFileId: string) => ({
		promise: () => api.setAcdQueueWaitingAudioFile(acdId, audioFileId),
		data: { acdId, audioFileId },
	})
);

export const setAcdGreetingAudioFile = createAction(
	'ACD_GREETING_AUDIO_FILE_SET',
	(acdId: string, audioFileId: string | null) => ({
		promise: () => api.setAcdGreetingAudioFile(acdId, audioFileId),
		data: { acdId, audioFileId },
	})
);

export const setAcdFollowUpTime = createAction(
	'ACD_AGENTS_FOLLOW_UP_TIME_SET',
	(acdId: string, followUpTime: number) => ({
		promise: () => api.setAcdFollowUpTime(acdId, followUpTime),
		data: { acdId, followUpTime },
	})
);

export const setAcdRingTime = createAction(
	'ACD_AGENTS_RING_TIME_SET',
	(acdId: string, ringTime: number) => ({
		promise: () => api.setAcdRingTime(acdId, ringTime),
		data: { acdId, ringTime },
	})
);
