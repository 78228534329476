import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchTrunkContingentProducts = (state: ReduxState, productId: string) =>
	!state.trunkContingentProducts.fetching && state.trunkContingentProducts.productId !== productId;

export const fetchTrunkContingentProducts = createAction(
	'TRUNK_CONTINGENT_PRODUCTS_FETCH',
	(productId: string, force?: boolean) => ({
		promise: () => api.getProductOptions(productId),
		shouldFetch: (state: ReduxState) =>
			force || shouldFetchTrunkContingentProducts(state, productId),
		data: { productId },
	})
);
