import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchRestrictions } from './actions';
import { Restriction } from './types';

export const useRestrictions = () => useSelector(state => state.restrictions);

export const useUserRestrictions = (userId?: string, requestedRestrictions?: Restriction[]) => {
	const dispatch = useDispatch();
	const restrictions = useSelector(state => state.restrictions);

	useEffect(() => {
		if (restrictions !== null) return;
		dispatch(fetchRestrictions(userId || undefined, requestedRestrictions));
	}, [dispatch, requestedRestrictions, restrictions, userId]);

	return restrictions || null;
};
