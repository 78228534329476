import React from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { RadioItem } from './RadioItem';
import { RadioProps } from './Radio';

type Props = {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	/**
	 * Die `value`-Prop beschreibt das ausgewählte `Radio` und
	 * entspricht seiner `value`-Prop.
	 */
	value: string;
	/**
	 * Setzt das HTML-Attribut `name` für die `Radio`s
	 */
	name: string;
} & AdditionalProps;

type ManagedProps = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name: string;
		value: string;
		onChange: React.ChangeEventHandler<HTMLInputElement>;
		onBlur: React.FocusEventHandler<HTMLInputElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps;

type AdditionalProps = {
	children: (React.ReactElement<RadioProps> | null)[] | React.ReactElement<RadioProps>;
	/**
	 * Die `title`-Prop ist die Überschrift der `RadioGroup`.
	 */
	title: string;
	disabled?: boolean;
};

const styles = {
	fieldset: 'font-brand',
	title: classnames(
		'pl-0',
		'text-gray-600',
		'font-brand',
		'font-normal',
		'text-xs/14',
		'cursor-default',
		'mb-4'
	),
};

const RadioGroup = ({
	children,
	onChange,
	value,
	name,
	title,
	disabled: isGroupDisabled,
}: Props): JSX.Element => {
	const isContextDisabled = React.useContext(DisabledContext);

	return (
		<fieldset className={styles.fieldset}>
			<legend className={styles.title}>{title}</legend>
			{children &&
				React.Children.map(children, (item: React.ReactElement<RadioProps> | null, index) => {
					if (item === null || item.type === React.Fragment) {
						return null;
					}

					return (
						<RadioItem
							onChange={onChange}
							name={name}
							id={`${name}-${index}`}
							value={item.props.value}
							disabled={isDisabled(item.props.disabled || isGroupDisabled, isContextDisabled)}
							checked={value === item.props.value}
						>
							{item.props.children}
						</RadioItem>
					);
				})}
		</fieldset>
	);
};

const ManagedRadioGroup = ({
	managedField: { name, value, onChange },
	children,
	...otherProps
}: ManagedProps) => (
	<RadioGroup
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...otherProps}
		name={name}
		value={value}
		onChange={onChange}
	>
		{children}
	</RadioGroup>
);

export { RadioGroup, ManagedRadioGroup };
