import { handleActions } from '../..';

import { LinksState } from './types';
import * as actions from './actions';

export const initialState: LinksState = {
	fetching: false,
	fetched: false,
	items: {
		accountsUrl: '',
		accountSettingsUrl: '',
		accountTopUpUrl: '',
		adminProductSettingsUrl: '',
		affiliateUrl: '',
		bookPhonenumberUrl: '',
		brandUrl: '',
		callPackageTacUrl: '',
		consoleWebUrl: '',
		contactsUrl: '',
		dataContingentTacUrl: '',
		devicePoolUrl: '',
		helpdeskPhoneConfigurationUrl: '',
		helpdeskUrl: '',
		legacyTokenAuthenticateUrl: '',
		loginUrl: '',
		logoTargetUrl: '',
		logoutPageUrl: '',
		logoutUrl: '',
		microsoftTeamsUrl: '',
		crmIntegrationsUrl: '',
		nonEWRHelpcenterUrl: '',
		notificationsUrl: '',
		paymentSettingsUrl: '',
		personalSettingsUrl: '',
		presenceContainerUrl: '',
		sendFaxUrl: '',
		startUpPageUrl: '',
		supportUrl: '',
		testProgrammeUrl: '',
		tokenAuthenticateUrl: '',
		trunkingContingentAdvertisementUrl: '',
		userSettingsUrl: '',
		wbciOptInUrl: '',
		whatsNewUrl: '',
		googleSignInUrl: '',
		trunkContingentTacUrl: '',
		trunkContractTacUrl: '',
		callRecorderTacUrl: '',
		microsoftSignInUrl: '',
		mobileDataVisualizationUrl: '',
		appleSignInUrl: '',
	},
};

export default handleActions<LinksState, PossibleActions<typeof actions>>(
	{
		LINKS_FETCH_PENDING: state => ({ ...state, fetching: true, fetched: false }),
		LINKS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload,
		}),
	},
	initialState
);

export const initialLinkState = { ...initialState };
