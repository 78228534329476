import { CustomerBaseProduct } from './types';
import { useSelector } from '../../utils/wrapper';
import { getCustomerBaseProduct } from './selectors';

interface CustomerBaseProductHook {
	fetched: boolean;
	fetching: boolean;
	customerBaseProduct: CustomerBaseProduct | undefined;
}

export const useCustomerBaseProduct = (): CustomerBaseProductHook => {
	const fetching = useSelector(state => state.customerProducts.fetching);
	const fetched = useSelector(state => state.customerProducts.fetched);
	const customerBaseProduct = useSelector(state => getCustomerBaseProduct(state.customerProducts));

	return {
		fetched,
		fetching,
		customerBaseProduct,
	};
};
