import React, { useContext } from 'react';
import classes from './MenuButton.scss';
import { AuthenticatedLayoutContext } from '../layouts/AuthenticatedLayout';
import { useTranslate } from '../redux/modules/translations';

interface Props {
	onClick: () => void;
}

const MenuButton = ({ onClick }: Props) => {
	const translate = useTranslate();
	const { isOpen, mainNavigationId } = useContext(AuthenticatedLayoutContext);

	return (
		<button
			type="button"
			onClick={onClick}
			className={classes.menuButton}
			aria-label={translate('MENU_BUTTON_LANDMARK')}
			aria-controls={mainNavigationId}
			aria-expanded={isOpen}
		>
			<div />
			<div />
			<div />
		</button>
	);
};

export default MenuButton;
