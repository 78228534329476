import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchPhonelines = (state: ReduxState, userId: string) =>
	!state.phonelines.fetchedForUser.includes(userId) &&
	!state.phonelines.fetchingForUser.includes(userId);

export const fetchPhonelines = createAction('PHONELINES_FETCH', (userId: string) => ({
	promise: () => api.getPhonelines(userId),
	shouldFetch: (state: ReduxState) => shouldFetchPhonelines(state, userId),
	data: { userId },
}));

export const forceFetchPhonelines = createAction('PHONELINES_FETCH', (userId: string) => ({
	promise: () => api.getPhonelines(userId),
	data: { userId },
}));

export const setPhonelineAlias = createAction(
	'PHONELINE_SET_ALIAS',
	(userId: string, phonelineId: string, alias: string) => ({
		promise: () => api.setPhonelineAlias(userId, phonelineId, alias),
		data: {
			userId,
			phonelineId,
			alias,
		},
	})
);

export const createPhoneline = createAction('PHONELINE_CREATE', (userId: string) => ({
	promise: api.createPhoneline(userId),
	data: {
		userId,
	},
	restrictions: {
		userId,
		restrictions: ['CAN_CREATE_PHONELINE_EXTENSION'],
	},
}));

export const deletePhoneline = createAction(
	'PHONELINE_DELETE',
	(userId: string, phonelineId: string) => ({
		promise: api.deletePhoneline(userId, phonelineId),
		data: {
			phonelineId,
		},
		restrictions: {
			userId,
			restrictions: ['CAN_CREATE_PHONELINE_EXTENSION', 'CAN_DELETE_PHONELINE_EXTENSION'],
		},
	})
);
