import classnames from 'classnames';
import React from 'react';
import { useTableContext } from './contexts/tableContext';
import { TableHeaderCell } from './TableHeaderCell';

type Props = {
	children: React.ReactComponentElement<typeof TableHeaderCell>[];
};

const styles = {
	tr: classnames('border-b', 'border-gray-100'),
};

const TableHeaderRow = ({ children }: Props): JSX.Element => {
	const { isSmallView, hasControls } = useTableContext();
	return (
		<tr className={styles.tr}>
			{children}
			{hasControls && !isSmallView ? <td /> : null}
		</tr>
	);
};

export { TableHeaderRow };
