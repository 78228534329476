import { createAction } from '../..';

import api from '../../../api';
import { MobileInfoState } from './types';

export const fetchMobileInfo = createAction('MOBILEINFO_FETCH', (force?: boolean) => ({
	promise: () => api.getMobileInfo(),
	shouldFetch: ({ mobile }: { mobile: MobileInfoState }) =>
		force || (!mobile.fetched && !mobile.fetching),
}));
