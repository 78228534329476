import { createAction } from '../..';

import api from '../../../api';

export const fetchLpaData = createAction(
	'LPA_DATA_FETCH',
	(deviceId: string, onFetched?: (iccid: string) => void) => ({
		promise: () =>
			api.getLpaData(deviceId).then(result => {
				if (onFetched) {
					onFetched(result.lpaData.iccid);
				}
				return result;
			}),
		data: { deviceId },
	})
);
