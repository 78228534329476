import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { CreateTrunkContingentContractResponse } from '../../../api/types/trunk';
import { mixpanel } from '../../../third-party/mixpanel';

const shouldFetchTrunkContingentContracts = (state: ReduxState, trunkId: string) =>
	!state.trunkContingentContracts.fetching && state.trunkContingentContracts.trunkId !== trunkId;

export const fetchTrunkContingentContracts = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_FETCH',
	(trunkId: string) => ({
		promise: () => api.getTrunkContingentContracts(trunkId),
		shouldFetch: (state: ReduxState) => shouldFetchTrunkContingentContracts(state, trunkId),
		data: { trunkId },
	})
);

export const forceFetchTrunkContingentContracts = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_FETCH',
	(trunkId: string) => ({
		promise: () => api.getTrunkContingentContracts(trunkId),
		data: { trunkId },
	})
);
export const cancelTrunkContingentContract = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_CANCEL',
	(
		trunkId: string,
		contractId: string,
		cancellationDate: Date | null,
		successCallback?: () => void,
		onError?: (error: Error) => void
	) => ({
		promise: () =>
			api
				.cancelTrunkContingentContract(trunkId, contractId, cancellationDate)
				.then(result => {
					if (successCallback) {
						successCallback();
					}
					return result;
				})
				.catch(error => {
					if (onError) {
						onError(error);
					}
					throw error;
				}),
		data: { trunkId, contractId },
	})
);

export const revokeTrunkContingentContractCancellation = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_REVOKE_CANCELLATION',
	(trunkId: string, contractId: string, successCallback?: () => void) => ({
		promise: () =>
			api.revokeTrunkContingentContractCancellation(trunkId, contractId).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { trunkId, contractId },
	})
);

export const createTrunkContingentContract = createAction(
	'TRUNK_CONTINGENT_CONTRACTS_CREATE',
	(
		trunkSipId: string,
		productId: string,
		productName: string,
		successCallback?: (result: CreateTrunkContingentContractResponse) => void,
		onError?: (error: Error) => void
	) => ({
		promise: api
			.createTrunkContingentContract(trunkSipId, productId)
			.then(result => {
				if (successCallback) {
					successCallback(result);
				}
				mixpanel.track('Booked contract', {
					'Contract id': productId,
					'Contract name': productName,
					'Contract type': 'Trunking contingent',
					'Contract booking type': 'Initial Booking',
				});
				return result;
			})
			.catch(error => {
				if (onError) {
					onError(error);
				}
				throw error;
			}),
		data: { trunkSipId, productId },
	})
);
