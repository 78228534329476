import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchSms(state: ReduxState, userId: string) {
	return !state.sms.fetchedForUser.includes(userId) && !state.sms.fetchingForUser.includes(userId);
}

export const fetchSms = createAction('SMS_FETCH', (userId: string, force?: boolean) => ({
	promise: () => api.fetchSms(userId),
	shouldFetch: (state: ReduxState) => force || shouldFetchSms(state, userId),
	data: { userId },
}));

export const forceFetchSms = (userId: string) => fetchSms(userId, true);

export const setSmsAlias = createAction(
	'SMS_SET_ALIAS',
	(userId: string, smsId: string, alias: string) => ({
		promise: () => api.setSmsAlias(userId, smsId, alias),
		data: {
			userId,
			smsId,
			alias,
		},
	})
);

export const createSms = createAction('SMS_CREATE', (userId: string) => ({
	promise: api.createSms(userId),
	data: {
		userId,
	},
}));

export const deleteSms = createAction('SMS_DELETE', (userId: string, smsId: string) => ({
	promise: api.deleteSms(smsId),
	data: { userId, smsId },
}));
