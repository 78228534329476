import { createAction } from '../..';
import { firebaseClient, satelliteApi } from '../../../api';
import { SatelliteUserContact } from '../../../api/types/satelliteUserContact';
import { UserToChannelMapping } from '../organisationMembership';

export const fetchOrganisationContacts = createAction('ORGANISATION_CONTACTS_FETCH', () => ({
	promise: () => firebaseClient.getOrganisationMemberForUser(),
}));

export const updateOrganisationContacts = createAction(
	'ORGANISATION_CONTACTS_FETCH_SUCCESS',
	(payload: SatelliteUserContact[]) => payload
);

export const fetchUserMemberships = createAction('ORGANISATION_CONTACTS_MEMBERSHIPS_FETCH', () => ({
	promise: () => firebaseClient.getOrganisationMemberGroupMappings(),
}));

export const updateUserMemberships = createAction(
	'ORGANISATION_CONTACTS_MEMBERSHIPS_FETCH_SUCCESS',
	(payload: UserToChannelMapping[]) => payload
);

export const createSatelliteUser = createAction(
	'SATELLITE_USERS_CREATE',
	(
		firstName: string,
		lastName: string,
		email: string,
		organisationId: string,
		channelId: string
	) => ({
		promise: () =>
			satelliteApi.createSatelliteUser(firstName, lastName, email, organisationId, channelId),
		data: {},
	})
);
