import React from 'react';
import { Button, Headline, Input, ManagedInput } from '@panda/ui';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { chainValidators, validateMinLength, validateNonEmpty } from '@web-apps/forms';
import classes from './ChangePasswordView.scss';
import auth from '../../../utils/authenticate/auth';
import SipgateLogo from './sipgate.png';
import { connect, ReduxProps } from '../../../redux';
import { ReduxState } from '../../../redux/types';
import { SubmitButton } from '../../../components/buttons/SubmitButton';
import api from '../../../api';
import { LogoSpinner } from '../../../components/spinner/LogoSpinner';
import { fetchUserInfo } from '../../../redux/modules/userinfo';
import { ManagedForm } from '../../../components/forms/ManagedForm';

type State = {
	showPasswordChangePage: boolean;
	email: string;
	posting: boolean;
	showSpinner: boolean;
};

type Props = RouteComponentProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

const mapDispatchToProps = {
	fetchUserInfo,
};

const mapStateToProps = (state: ReduxState) => ({
	translate: state.translations.translate,
	userinfo: state.userinfo,
	links: state.links,
});

type FormData = {
	password: string;
	passwordConfirm: string;
};

class ChangePasswordView extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			showPasswordChangePage: false,
			email: auth.getMailFromToken(),
			posting: false,
			showSpinner: true,
		};
	}

	public async componentDidMount(): Promise<void> {
		if (!this.props.userinfo.fetched && !this.props.userinfo.fetching) {
			await this.props.fetchUserInfo();
		}

		if (window.localStorage.getItem('from_idp') === 'true') {
			await this.disablePassword();
			window.localStorage.removeItem('from_idp');
		} else {
			this.setState({ showSpinner: false });
		}
	}

	public render() {
		if (this.state.showSpinner) {
			return <LogoSpinner />;
		}

		return (
			<div className={classes.wrapper}>
				<div className={classes.center}>
					<div className={classes.header}>
						<img className={classes.logo} src={SipgateLogo} alt="sipgate logo" />
					</div>
					<div className={classes.content}>{this.renderPage()}</div>
				</div>
			</div>
		);
	}

	private changePassword = async (password: string) => {
		if (!this.state.posting) {
			this.setState({ posting: true });
			try {
				await api.updatePassword(this.props.userinfo.data!.sub, password);
				window.location.href = this.props.links.items.startUpPageUrl;
			} catch (e) {
				window.location.reload();
			}
		}
	};

	private disablePassword = async () => {
		if (!this.state.posting) {
			this.setState({ posting: true });
			try {
				await api.disablePassword(this.props.userinfo.data!.sub, 'social-login');
				window.location.href = this.props.links.items.startUpPageUrl;
			} catch (e) {
				window.location.reload();
			}
		}
	};

	private renderPage() {
		if (
			!this.props.userinfo.fetched ||
			(this.props.userinfo.fetching && !this.props.userinfo.data)
		) {
			return (
				<div className={classes.spinner}>
					<LogoSpinner />
				</div>
			);
		}

		if (!this.state.showPasswordChangePage) {
			return (
				<>
					<div className={classes.email}>
						<Input
							label={this.props.translate('CHANGEPASSWORD_EMAIL_LABEL')}
							placeholder=""
							type="email"
							disabled
							onChange={() => {}}
							value={this.state.email}
						/>
						<Button
							onClick={() => {
								this.setState({ showPasswordChangePage: true });
							}}
							variant="loud"
							size="large"
							width="max"
						>
							{this.props.translate('CHANGEPASSWORD_CONTINUE_WITH_EMAIL')}
						</Button>
					</div>
					<hr className={classes.hr} data-content={this.props.translate('CHANGEPASSWORD_OR')} />
					<button
						type="button"
						className={classnames(classes.idp, classes.first)}
						onClick={() => {
							auth.logoutAndRedirectToLogoutPage(
								this.props.links.items,
								this.props.links.items.googleSignInUrl,
								true
							);
						}}
					>
						<span className={classes.google}>
							{this.props.translate('CHANGEPASSWORD_CONTINUE_WITH_GOOGLE')}
						</span>
					</button>
					<button
						type="button"
						className={classnames(classes.idp)}
						onClick={() => {
							auth.logoutAndRedirectToLogoutPage(
								this.props.links.items,
								this.props.links.items.appleSignInUrl,
								true
							);
						}}
					>
						<span className={classes.apple}>
							{this.props.translate('CHANGEPASSWORD_CONTINUE_WITH_APPLE')}
						</span>
					</button>
				</>
			);
		}

		return (
			<div className={classes.setPassword}>
				<Headline className={classes.title}>
					{this.props.translate('CHANGEPASSWORD_COMMAND_TITLE')}
				</Headline>

				<p>{this.props.translate('CHANGEPASSWORD_COMMAND_HINT')}</p>
				<ManagedForm
					initialValues={{
						password: '',
						passwordConfirm: '',
					}}
					onSubmit={async (data: FormData) => {
						await this.changePassword(data.password);
					}}
					validators={{
						password: chainValidators(
							validateNonEmpty(this.props.translate),
							validateMinLength(this.props.translate, 12)
						),
					}}
				>
					{({ fields, form }) => (
						<>
							<input
								type="text"
								name="username"
								value={auth.getMailFromToken()}
								className={classes.hidden}
							/>
							<ManagedInput
								label={this.props.translate('CHANGEPASSWORD_COMMAND_PASSWORD')}
								managedField={fields.password}
								placeholder={this.props.translate('CHANGEPASSWORD_COMMAND_PASSWORD_PLACEHOLDER')}
								type="password"
							/>
							<SubmitButton disabled={!form.canBeSubmitted} width="max">
								{this.props.translate('CHANGEPASSWORD_COMMAND_SUBMIT')}
							</SubmitButton>
						</>
					)}
				</ManagedForm>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);
