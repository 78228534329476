import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import UserAvatar from '../components/UserAvatar';
import classes from './UserDropdown.scss';
import auth from '../utils/authenticate/auth';
import AccountNumber from '../navigation/logout/AccountNumber';
import DropOut, { DropOutDivider } from './DropOut';
import useKeyboardEvents from './hooks/useKeyboardEvents';
import useOutsideClick from './hooks/useOutsideClick';
import useLinks from '../redux/modules/links/hooks';
import { useTranslate } from '../redux/modules/translations';
import { useCurrentUser } from '../redux/modules/users';
import { useAccount } from '../redux/modules/account';

const UserDropdown = () => {
	const ref = useRef<HTMLDivElement>(null);
	const currentUser = useCurrentUser();
	const translate = useTranslate();
	const { links } = useLinks();
	const accountData = useAccount();
	const [open, setOpen] = useState(false);
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	const hasCustomLogo = !!accountData?.logoUrl;

	if (!currentUser) {
		return null;
	}

	return (
		<div id="userdropdown" className={classes.userDropDown} ref={ref}>
			<button
				type="button"
				className={classnames(classes.userDropDownButton, { [classes.withLogo]: hasCustomLogo })}
				onClick={() => setOpen(!open)}
				aria-controls="user-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="user-dropdown-button"
			>
				{hasCustomLogo && (
					<img
						src={accountData?.logoUrl}
						alt=""
						data-testid="custom_logo"
						className={classes.logo}
					/>
				)}

				<UserAvatar user={currentUser} size={hasCustomLogo ? 'medium' : 'large'} />
			</button>

			<DropOut id="user-menu" open={open} ariaLabelledBy="user-dropdown-button">
				<ul>
					<li>
						<div className={classes.currentUser}>
							<UserAvatar user={currentUser} size="large" editable />
							<div>
								<div className={classes.customerName}>
									{currentUser.firstname} {currentUser.lastname}
								</div>
								<AccountNumber className={classes.accountNumber} />
							</div>
						</div>
					</li>
					<li>
						<DropOutDivider />
					</li>
					<li>
						<div className={classes.logout}>
							<button
								type="button"
								onClick={() => auth.logoutAndRedirectToLogoutPage(links, window.location.origin)}
							>
								{translate('LOGOUT')}
							</button>
						</div>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};

export default UserDropdown;
