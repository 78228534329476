import { createAction } from '../..';
import api from '../../../api';
import { ApiProductType } from '../../../api/types/products';
import { ReduxState } from '../../types';

const shouldFetchProducts = (state: ReduxState, type: ApiProductType) =>
	!state.products.fetchingForProductType.includes(type) &&
	!state.products.fetchedForProductType.includes(type);

export const fetchProducts = createAction('PRODUCTS_FETCH', (type: ApiProductType) => ({
	promise: () => api.getProducts(type),
	shouldFetch: (state: ReduxState) => shouldFetchProducts(state, type),
	data: {
		type,
	},
}));

export const forceFetchProducts = createAction('PRODUCTS_FETCH', (type: ApiProductType) => ({
	promise: api.getProducts(type),
	data: {
		type,
	},
}));

const shouldFetchIsProductBookingAllowed = (state: ReduxState, productId: string) =>
	Number(productId) > 0 &&
	!state.products.fetchingIsProductBookingAllowed.includes(productId) &&
	!state.products.fetchedIsProductBookingAllowed.includes(productId);

export const fetchIsProductBookingAllowed = createAction(
	'IS_PRODUCT_BOOKING_ALLOWED_FETCH',
	(productId: string) => ({
		promise: () => api.isProductAllowed(productId),
		shouldFetch: (state: ReduxState) => shouldFetchIsProductBookingAllowed(state, productId),
		data: {
			productId,
		},
	})
);

const shouldFetchLegacyProduct = (state: ReduxState, productId: string) =>
	!state.products.legacyItems.find(product => product.id === productId) &&
	!state.products.fetchingLegacyItems.includes(productId);

export const fetchLegacyProduct = createAction(
	'SINGLE_PRODUCT_FETCH',
	(productId: string, force?: boolean) => ({
		promise: () => api.getProduct(productId),
		shouldFetch: (state: ReduxState) => force || shouldFetchLegacyProduct(state, productId),
		data: {
			productId,
		},
	})
);
