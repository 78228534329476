import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';

import { createSatelliteUser, fetchOrganisationContacts } from './actions';

export const useOrganisationContacts = () => {
	const dispatch = useDispatch();
	const contactsState = useSelector(state => state.organisationContacts);

	useEffect(() => {
		dispatch(fetchOrganisationContacts());
	}, [dispatch]);

	return useMemo(
		() => ({
			allContacts: contactsState.fetched ? contactsState.items : [],
			currentOrganisationId:
				contactsState.items.length > 0 ? contactsState.items[0].organisationId : '',
			areUsersFetched: contactsState.fetched,
			areUsersFetching: contactsState.fetching,
			createSatelliteUser: (
				firstName: string,
				lastName: string,
				email: string,
				organisationId: string,
				channelId: string
			) => dispatch(createSatelliteUser(firstName, lastName, email, organisationId, channelId)),
		}),
		[contactsState, dispatch]
	);
};
