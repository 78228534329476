import classnames from 'classnames';
import React from 'react';
import { ContextMenu } from '../contextMenu/ContextMenu';
import { useTableBodyContext } from './contexts/tableBodyContext';
import { useTableContext } from './contexts/tableContext';
import { TableRowContext } from './contexts/tableRowContext';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';

type Props = {
	control?: React.ReactElement;
	children: React.ReactNode[];
};

const styles = {
	tr: (isSmallView: boolean, isFirstRow: boolean, isLastRow: boolean) =>
		classnames(
			'block',
			'border-b',
			'border-gray-100',
			!isFirstRow && isLastRow && 'border-none',
			isSmallView ? ['flex', 'flex-wrap'] : 'table-row'
		),

	td: (isSmallView: boolean, isActiveRow: boolean, isContextMenu: boolean) =>
		classnames(
			'w-auto',
			isSmallView && ['-order-1', 'flex-none', 'self-center'],
			isSmallView && !isContextMenu && ['pr-8', 'py-8'],
			!isSmallView && ['table-cell', 'text-right'],
			!isSmallView && !isContextMenu && ['pr-16', 'pl-0', 'last:pr-0', 'py-0'],
			isSmallView && isActiveRow && 'block',
			isSmallView && !isActiveRow && 'hidden',
			isContextMenu && ['align-middle', 'py-4', 'pr-4']
		),
	collapsableHeader: (isSmallView: boolean, isActiveRow: boolean) =>
		classnames(
			'border-gray-100',
			'cursor-pointer',
			'flex',
			'font-brand',
			'font-normal',
			'text-base/20',
			'group',
			'justify-between',
			'order-first',
			'pl-4',
			'pr-8',
			'py-10',
			'select-none',
			'text-left',
			'w-full',
			!isSmallView && 'hidden',
			isActiveRow && 'border-b'
		),
	iconContainer: (isActiveRow: boolean) =>
		classnames(
			'duration-150',
			'ease-in-out',
			'fill-current',
			'flex',
			'float-right',
			'group-hover:text-blue-500',
			'group-active:text-blue-700',
			'h-16',
			'self-center',
			'text-gray-600',
			'transition',
			'w-16',
			isActiveRow && ['scale-y-flip']
		),
};

const TableRow = ({ children, control }: Props): JSX.Element => {
	const { isFirstRow, isLastRow, isActiveRow, rowIndex } = useTableBodyContext();
	const { isSmallView, setActiveRow, activeRow, headerTitles } = useTableContext();
	const isContextMenu = control?.type === ContextMenu;

	const toggleOnSmallView = (
		e: React.MouseEvent<HTMLTableDataCellElement>,
		index: number,
		setActiveRowState: (activeRow: number) => void,
		activeRowState: number
	): void => {
		e.preventDefault();
		setActiveRowState(activeRowState === index ? -1 : index);
	};

	return (
		<tr
			data-row-index={rowIndex}
			data-is-last-row={isLastRow}
			className={styles.tr(isSmallView, isFirstRow, isLastRow)}
		>
			{React.Children.map(children, (child, index) => {
				const isFirstColumn = index === 0;
				if (React.isValidElement(child)) {
					const dataCell = (
						<TableRowContext.Provider
							value={{
								headerTitle: headerTitles[index],
								isFirstColumn,
							}}
						>
							{child}
						</TableRowContext.Provider>
					);
					if (isFirstColumn) {
						return (
							<>
								<th
									scope="row"
									onClick={(e): void => toggleOnSmallView(e, rowIndex, setActiveRow, activeRow)}
									className={styles.collapsableHeader(isSmallView, isActiveRow)}
								>
									{child.props.children}
									<div className={styles.iconContainer(isActiveRow)}>
										<PandaIcon icon="triangle_down-16" />
									</div>
								</th>
								{dataCell}
							</>
						);
					}
					return dataCell;
				}
				return child;
			})}
			{control ? (
				<td className={styles.td(isSmallView, isActiveRow, isContextMenu)}>{control}</td>
			) : null}
		</tr>
	);
};

export { TableRow };
