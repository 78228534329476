import serviceUrls from '@web-apps/service-urls';
import { SipgateApi } from './SipgateApi';
import { RestApiClient } from './client/RestApiClient';
import { HttpClient } from './client/HttpClient';
import { FirebaseClient } from './client/FirebaseClient';
import { SatelliteApi } from './SatelliteApi';

export { ApiError } from './ApiError';

const sipgateHttpClient = new HttpClient({
	baseUrl: serviceUrls.restApi,
});
const satelliteHttpClient = new HttpClient({
	baseUrl: 'https://api.satellite.me',
});

const api = new RestApiClient(sipgateHttpClient);
const firebaseClient = new FirebaseClient(satelliteHttpClient);
const satelliteApi = new SatelliteApi(satelliteHttpClient, firebaseClient);

export default api as SipgateApi;
export { firebaseClient, satelliteApi };
