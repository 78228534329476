import React from 'react';
import { connect, ReduxProps } from '../redux';
import auth from '../utils/authenticate/auth';
import { fetchLinks } from '../redux/modules/links';
import { ReduxState } from '../redux/types';

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
});

const mapDispatchToProps = {
	fetchLinks,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class LogoutView extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchLinks();
	}

	public render() {
		auth.logoutAndRedirectToLogoutPage(this.props.links.items);
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
