import React from 'react';

let ariaId = 0;

export const useAriaId = (prefix: string) => {
	return React.useState(() => {
		ariaId += 1;
		return `${prefix}-${ariaId}`;
	})[0];
};

// @deprecated Do not use this, this function exists literally only to allow resetting this in tests
export const reset = () => {
	ariaId = 0;
};
