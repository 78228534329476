import { createAction } from '../..';

import api from '../../../api';
import { UserInfoState } from './types';

export const fetchUserInfo = createAction('USERINFO_FETCH', (force?: boolean) => ({
	promise: () => api.getUserInfo(),
	shouldFetch: ({ userinfo }: { userinfo: UserInfoState }) =>
		force || (!userinfo.fetched && !userinfo.fetching),
}));

export const setImpersonated = createAction('USERINFO_SET_IMPERSONATED');
