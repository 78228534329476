/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import React from 'react';
import { usePopper } from 'react-popper';
import { useSelect, UseSelectStateChange } from 'downshift';
import { useDisabled } from '../../contexts/disabledContext';
import { Description } from '../visualComponents/Description';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { Label } from '../visualComponents/Label';
import { Props as OptionProps } from './Option';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { usePandaContext } from '../../contexts/pandaContext';

import classes from './Select.module.css';

export type Props = {
	/**
	 * Der `name` entspricht dem HTML-`name`-Attribut.
	 */
	name?: string;
	value: string;
	onChange: (value: string) => void;
	onBlur?: React.FocusEventHandler<HTMLElement>;
	error?: string;
} & AdditionalProps;

type ManagedProps = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name?: string;
		value: string;
		setValue: (value: string) => void;
		onBlur: React.FocusEventHandler<HTMLElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps;

export type AdditionalProps = {
	/**
	 * Der `title` befindet sich über dem eigentlichen Eingabefeld und
	 * beschreibt es mit 1-2 Wörtern (= semantisch das Label).
	 */
	title: string;
	/**
	 * Der `placeholder` ist sichtbar, wenn noch keine Option ausgewählt ist.
	 */
	placeholder?: string;
	/**
	 * Die `description` kann benutzt werden, um in einer zweiten Zeile
	 * unter dem `title` noch eine kurze Beschreibung hinzuzufügen.
	 */
	description?: string;
	disabled?: boolean;
	dataTestId?: string;
	children: React.ReactElement<OptionProps>[];
};

type Condition = 'open' | 'closed';

const styles = {
	div: classnames('group', 'w-full', 'relative'),
	button: (
		condition:
			| 'placeholder'
			| 'open'
			| 'error'
			| 'error-open'
			| 'error-selected'
			| 'selected'
			| 'disabled-placeholder'
			| 'disabled-selected'
	) =>
		classnames(
			'appearance-none',
			'group',
			'flex',
			'items-center',
			'w-full',
			'h-40',
			'p-8',
			'm-0',
			'font-brand',
			'font-normal',
			'text-base/24',
			'text-left',
			'ring-1',
			'ring-inset',
			'box-border',
			'rounded-sm',
			'duration-150',
			'ease-in-out',
			'transition',
			'focus:outline-none',
			condition === 'placeholder' && [
				'text-gray-600',
				'bg-gray-25',
				'ring-gray-100',
				'focus:ring-blue-500',
				'focus:text-gray-800',
				'hover:ring-gray-300',
				'hover:text-gray-800',
				'active:ring-blue-500',
				'cursor-pointer',
			],
			condition === 'open' && ['bg-gray-25', 'ring-blue-500', 'text-gray-800'],
			condition === 'error' && [
				'text-gray-600',
				'bg-gray-25',
				'ring-gray-100',
				'focus:ring-color-error',
				'focus:text-gray-800',
				'hover:ring-gray-300',
				'hover:text-gray-800',
				'active:ring-color-error',
				'cursor-pointer',
			],
			condition === 'error-open' && [
				'text-gray-800',
				'bg-gray-25',
				'ring-color-error',
				'focus:ring-color-error',
				'hover:ring-color-error',
				'active:ring-color-error',
				'cursor-pointer',
			],
			condition === 'error-selected' && [
				'text-gray-800',
				'bg-gray-25',
				'ring-gray-100',
				'focus:ring-color-error',
				'hover:ring-color-error',
				'active:ring-color-error',
				'cursor-pointer',
			],
			condition === 'selected' && [
				'text-gray-800',
				'bg-gray-25',
				'ring-gray-100',
				'focus:ring-blue-500',
				'hover:ring-gray-300',
				'active:ring-blue-500',
				'cursor-pointer',
			],
			condition === 'disabled-placeholder' && [
				'text-gray-400',
				'bg-gray-50',
				'ring-gray-50',
				'cursor-not-allowed',
			],
			condition === 'disabled-selected' && [
				'text-gray-400',
				'bg-gray-50',
				'ring-gray-50',
				'cursor-not-allowed',
			]
		),

	selectedItem: classnames('grow', 'transition', 'duration-150', 'ease-in-out', 'truncate'),
	placeholder: classnames('italic', 'font-light'),
	iconGroup: classnames(
		'ml-4',
		'flex',
		'flex-none',
		'items-center',
		'gap-4',
		'float-right',
		'self-center'
	),
	iconContainer: classnames('h-24', 'w-24', 'flex', 'items-center', 'justify-center'),
	animatedIconContainer: (iconCondition: 'open' | 'closed' | 'disabled') =>
		classnames(
			'h-24',
			'w-24',
			'transition',
			'duration-150',
			'ease-in-out',
			'flex',
			'items-center',
			'justify-center',
			iconCondition === 'open' && ['scale-y-flip'],
			iconCondition === 'disabled' && ['text-gray-400']
		),
	optionList: (condition: Condition) =>
		classnames(
			classes.transitionProperties,
			classes.maxHeight,
			'absolute',
			'z-800',
			'flex',
			'flex-col',
			'items-center',
			'w-full',
			'overflow-x-hidden',
			'overflow-y-scroll',
			'list-none',
			'm-0', // Popper dynamically sets mb-4 (4px) as offset
			'px-0',
			'py-4',
			'shadow',
			'bg-white',
			'ring-1',
			'ring-inset',
			'ring-gray-50',
			'rounded-sm',
			'focus:outline-none',
			'duration-150',
			'ease-in-out',
			condition === 'open' && ['visible'],
			condition === 'closed' && ['invisible']
		),
	option: (listItemCondition: 'default' | 'disabled' | 'highlighted') =>
		classnames(
			'px-[0.4375rem]',
			'py-8',
			'duration-150',
			'ease-in-out',
			'focus:outline-none',
			'focus-visible:outline-none',
			'font-brand',
			'font-normal',
			'text-base/24',
			'select-none',
			'text-left',
			'transition',
			'w-[calc(100%-0.125rem)]',
			'h-40',
			'flex',
			'shrink-0',
			'whitespace-nowrap',
			listItemCondition === 'default' && ['bg-white', 'text-gray-800', 'cursor-pointer'],
			/* The following condition 'highlighted' is our custom build :hover CSS state therefore some CSS states do not work */
			listItemCondition === 'highlighted' && [
				'bg-blue-25',
				'text-blue-800',
				'cursor-pointer',
				'active:bg-blue-50',
				'active:text-blue-900',
			],
			listItemCondition === 'disabled' && ['bg-gray-75', 'cursor-not-allowed', 'text-gray-400']
		),
	optionLabel: classnames('grow', 'truncate', 'block'),
};

const Select = ({
	children,
	description,
	disabled: disabledProp,
	title,
	placeholder,
	dataTestId,
	error,
	onChange: setValue,
	value,
	name,
	onBlur,
}: Props): JSX.Element => {
	const disabled = useDisabled(disabledProp);
	const { languageKeys } = usePandaContext();
	const [listCondition, setListCondition] = React.useState<Condition>('closed');
	const toggleButtonRef = React.useRef(null);
	const popperElementRef = React.useRef(null);
	const { styles: popperStyles, update: updatePopper } = usePopper(
		toggleButtonRef.current,
		popperElementRef.current,
		{
			placement: 'bottom',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 4],
					},
				},
			],
		}
	);

	React.useLayoutEffect(() => {
		if (updatePopper) {
			updatePopper();
		}
	}, [updatePopper, children]);

	const itemValues = React.Children.map(children, (item: React.ReactElement<OptionProps>) => {
		return item.props.value;
	});

	const onIsOpenChange = (changed: UseSelectStateChange<string>) => {
		if (changed.isOpen) {
			setListCondition('open');
		} else if (!changed.isOpen) {
			setListCondition('closed');
		}
	};

	const onSelectedItemChange = (changes: UseSelectStateChange<string>) => {
		if (changes.selectedItem !== undefined && changes.selectedItem !== null) {
			setValue(changes.selectedItem);
		}
	};

	const {
		selectedItem,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
	} = useSelect({
		items: itemValues,
		selectedItem: value,
		onSelectedItemChange: v => {
			/**
			 * downshift triggers this inside its render loop which breaks, because
			 * formik rerenders immediately resulting in a deadlock:
			 *
			 * https://github.com/downshift-js/downshift/issues/1447
			 */
			setTimeout(() => onSelectedItemChange(v), 0);
		},
		isOpen: listCondition === 'open',
		onIsOpenChange,
	});

	let selectedItemText: React.ReactNode;
	React.Children.forEach(children, (item: React.ReactElement<OptionProps>) => {
		if (selectedItem === item.props.value) {
			selectedItemText = item.props.children;
		}
	});

	const getButtonCondition = () => {
		if (disabled) {
			if (selectedItemText) {
				return 'disabled-selected';
			}

			return 'disabled-placeholder';
		}

		if (error) {
			if (listCondition === 'open') {
				return 'error-open';
			}

			if (selectedItemText) {
				return 'error-selected';
			}

			return 'error';
		}

		if (listCondition === 'open') {
			return 'open';
		}

		if (selectedItemText !== undefined) {
			return 'selected';
		}

		return 'placeholder';
	};

	const getIconCondition = () => {
		if (disabled) {
			return 'disabled';
		}

		return listCondition;
	};

	return (
		<div data-testid={dataTestId} className={styles.div}>
			<Label {...getLabelProps()}>{title}</Label>
			{description ? (
				<Description onClick={() => setListCondition('open')}>{description}</Description>
			) : null}
			<button
				type="button"
				{...getToggleButtonProps({
					className: styles.button(getButtonCondition()),
					disabled,
					ref: toggleButtonRef,
					onBlur,
					name,
					// This fixes unclosable options introduced in 04ac02b1395cdc201216319a688d249cc461977d
					onMouseDown: event => {
						event.preventDefault();
					},
				})}
			>
				<span className={styles.selectedItem}>
					{selectedItemText || (
						<span className={styles.placeholder}>
							{placeholder || languageKeys.PANDA_SELECT_PLACEHOLDER}
						</span>
					)}
				</span>

				<div className={styles.iconGroup}>
					{error ? (
						<span className={styles.iconContainer}>
							<PandaIcon icon="exclamation_mark_circle-16" />
						</span>
					) : null}

					<div className={classnames(styles.animatedIconContainer(getIconCondition()))}>
						<PandaIcon icon="triangle_down-16" />
					</div>
				</div>
			</button>
			<ul
				{...getMenuProps({
					className: styles.optionList(listCondition),
					style: popperStyles.popper,
					ref: popperElementRef,
					onKeyDown: event => {
						if (event.key === 'Escape') {
							event.nativeEvent.stopImmediatePropagation();
						}
					},
				})}
			>
				{children &&
					React.Children.map(children, (item: React.ReactElement<OptionProps>, index) => {
						const isItemDisabled = disabled || !!item.props.disabled;
						const isItemHighlighted = highlightedIndex === index;

						const getListItemCondition = () => {
							if (isItemDisabled) {
								return 'disabled';
							}

							if (isItemHighlighted) {
								return 'highlighted';
							}

							return 'default';
						};

						return (
							<li
								{...getItemProps({
									key: `${item.props.value}${index}`,
									item: item.props.value,
									index,
									disabled: isItemDisabled,
									className: styles.option(getListItemCondition()),
								})}
							>
								<span className={styles.optionLabel}>{item.props.children}</span>
								{selectedItem === item.props.value ? (
									<div className={styles.iconGroup}>
										<span className={styles.iconContainer}>
											<PandaIcon icon="check-16" />
										</span>
									</div>
								) : null}
							</li>
						);
					})}
			</ul>
			{error ? (
				<ErrorDescription onClick={() => setListCondition('open')}>{error}</ErrorDescription>
			) : null}
		</div>
	);
};

const ManagedSelect = ({
	managedField: { error, setValue, value, name, onBlur },
	children,
	...otherProps
}: ManagedProps) => (
	<Select
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...otherProps}
		name={name}
		value={value}
		onChange={setValue}
		onBlur={onBlur}
		error={error === null ? undefined : error}
	>
		{children}
	</Select>
);

export { Select, ManagedSelect };
