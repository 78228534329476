import { useCallback } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	deleteUserAvatar,
	fetchUsersAvatars,
	resetUploadError as resetUploadErrorAction,
	resetUploadSuccess as resetUploadSuccessAction,
	uploadUserAvatar,
} from './actions';
import { Avatar } from './types';

interface UseAvatarsHook {
	fetchedForUser: string[];
	fetchingForUser: string[];
	uploading: boolean;
	uploaded: boolean;
	uploadError: boolean;
	items: Avatar[];

	fetchAvatars: (force?: boolean) => void;
	uploadAvatar: (webuserId: string, base64Content: string) => void;
	deleteUserAvatar: (webuserId: string) => void;
	resetUploadError: () => void;
	resetUploadSuccess: () => void;
}

export const useAvatars = (): UseAvatarsHook => {
	const dispatch = useDispatch();
	return {
		items: useSelector(state => state.avatars.items),
		fetchedForUser: useSelector(state => state.avatars.fetchedForUser),
		fetchingForUser: useSelector(state => state.avatars.fetchingForUser),
		uploading: useSelector(state => state.avatars.uploading),
		uploaded: useSelector(state => state.avatars.uploaded),
		uploadError: useSelector(state => state.avatars.uploadError),
		fetchAvatars: useCallback((force?: boolean) => dispatch(fetchUsersAvatars(force)), [dispatch]),
		deleteUserAvatar: useCallback(
			(webuserId: string) => dispatch(deleteUserAvatar(webuserId)),
			[dispatch]
		),
		uploadAvatar: useCallback(
			(webuserId: string, base64Content: string) => {
				return dispatch(uploadUserAvatar(webuserId, base64Content));
			},
			[dispatch]
		),
		resetUploadError: useCallback(() => dispatch(resetUploadErrorAction()), [dispatch]),
		resetUploadSuccess: useCallback(() => dispatch(resetUploadSuccessAction()), [dispatch]),
	};
};

type UseAvatarUpload = Pick<
	UseAvatarsHook,
	| 'uploading'
	| 'uploaded'
	| 'uploadError'
	| 'uploadAvatar'
	| 'resetUploadError'
	| 'resetUploadSuccess'
>;
export const useAvatarUpload = (): UseAvatarUpload => {
	const { uploading, uploaded, uploadError, uploadAvatar, resetUploadError, resetUploadSuccess } =
		useAvatars();
	return { uploading, uploaded, uploadError, uploadAvatar, resetUploadError, resetUploadSuccess };
};

export const useIsAvatarFetchingForUser = (webuserId: string) => {
	const { fetchingForUser } = useAvatars();
	return fetchingForUser.includes(webuserId) || fetchingForUser.includes('*');
};

export const useIsAvatarFetchedForUser = (webuserId: string) => {
	const { fetchedForUser } = useAvatars();
	return fetchedForUser.includes(webuserId);
};
