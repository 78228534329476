import api from '../../../api';
import { ReduxState } from '../../types';
import { createAction } from '../..';

const shouldFetchGroupStatistics = (state: ReduxState, groupId: string) =>
	!state.groupStatistics.fetchingForGroup.includes(groupId) &&
	!state.groupStatistics.fetchedForGroup.includes(groupId);

const shouldFetchGroupStatisticsHistory = (state: ReduxState, groupId: string) =>
	!state.groupStatistics.fetchingHistoryForGroup.includes(groupId) &&
	!state.groupStatistics.fetchedHistoryForGroup.includes(groupId);

export const fetchGroupStatistics = createAction(
	'GROUP_STATISTICS_FETCH',
	(groupId: string, force?: boolean) => ({
		promise: () => api.getGroupStatistics(groupId),
		shouldFetch: (state: ReduxState) => force || shouldFetchGroupStatistics(state, groupId),
		data: {
			groupId,
		},
	})
);

export const fetchGroupStatisticsHistory = createAction(
	'GROUP_STATISTICS_HISTORY_FETCH',
	(groupId: string, force?: boolean) => ({
		promise: () => api.getGroupStatisticsHistory(groupId),
		shouldFetch: (state: ReduxState) => force || shouldFetchGroupStatisticsHistory(state, groupId),
		data: {
			groupId,
		},
	})
);
