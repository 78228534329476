import { Avatar, AvatarsState } from './types';
import { handleActions } from '../../utils/actions';
import * as actions from './actions';

export const initialState: AvatarsState = {
	uploading: false,
	uploaded: false,
	uploadError: false,
	fetchedForUser: [],
	fetchingForUser: [],
	items: [],
};
export default handleActions<AvatarsState, PossibleActions<typeof actions>>(
	{
		AVATARS_FETCH_PENDING: state => ({ ...state, fetchingForUser: ['*'] }),
		AVATARS_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				items: payload.userAvatars,
				fetchedForUser: payload.userAvatars.map(avatar => avatar.webuserId),
				fetchingForUser: [],
			};
		},
		AVATARS_FETCH_FAILURE: state => ({
			...state,
			fetchingForUser: [],
		}),
		AVATAR_FETCH_PENDING: (state, { data }) => {
			const isAlreadyFetchingForUser =
				state.fetchingForUser.findIndex(webuserId => data.webuserId.endsWith(webuserId)) > -1;
			return {
				...state,
				fetchingForUser: isAlreadyFetchingForUser
					? state.fetchingForUser
					: [...state.fetchingForUser, data.webuserId],
			};
		},
		AVATAR_FETCH_SUCCESS: (state, { payload }) => {
			const index = state.items.findIndex(avatar => avatar.webuserId === payload.webuserId);
			const hasAlreadyFetchedForUser =
				state.fetchedForUser.findIndex(webuserId => webuserId === payload.webuserId) > -1;
			const fetchingForUserIndex = state.fetchingForUser.findIndex(
				webuserId => webuserId === payload.webuserId
			);

			return {
				...state,
				items: [...state.items.slice(0, index), payload, ...state.items.slice(index + 1)],
				fetchingForUser: [
					...state.fetchingForUser.slice(0, fetchingForUserIndex),
					...state.fetchingForUser.slice(fetchingForUserIndex + 1),
				],
				fetchedForUser: hasAlreadyFetchedForUser
					? state.fetchedForUser
					: [...state.fetchedForUser, payload.webuserId],
			};
		},
		AVATAR_FETCH_FAILURE: (state, { data }) => {
			const index = state.items.findIndex(avatar => data.webuserId.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		},
		AVATARS_UPLOAD_PENDING: state => ({
			...state,
			uploading: true,
			uploaded: false,
			uploadError: false,
		}),
		AVATARS_UPLOAD_SUCCESS: (state, { data }) => {
			const index = state.items.findIndex(avatar => data.webuserId.endsWith(avatar.webuserId));
			const updatedItem = {
				webuserId: data.webuserId,
				group: 'UPLOADS',
				urls: {
					SMALL: data.base64Content,
					SMALL_2X: data.base64Content,
					SMALL_3X: data.base64Content,
					MEDIUM: data.base64Content,
					MEDIUM_2X: data.base64Content,
					MEDIUM_3X: data.base64Content,
					LARGE: data.base64Content,
					LARGE_2X: data.base64Content,
					LARGE_3X: data.base64Content,
					XLARGE: data.base64Content,
					XLARGE_2X: data.base64Content,
					XLARGE_3X: data.base64Content,
					BIGGEST: data.base64Content,
					BIGGEST_2X: data.base64Content,
					BIGGEST_3X: data.base64Content,
				},
			} as Avatar;

			return {
				...state,
				items: [...state.items.slice(0, index), updatedItem, ...state.items.slice(index + 1)],
				uploading: false,
				uploaded: true,
				uploadError: false,
			};
		},
		AVATARS_UPLOAD_FAILURE: state => ({
			...state,
			uploading: false,
			uploaded: false,
			uploadError: true,
		}),
		AVATAR_DELETE_PENDING: state => ({ ...state }),
		AVATAR_DELETE_SUCCESS: (state, { data }) => {
			const index = state.items.findIndex(avatar => data.webuserId.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		},
		AVATAR_DELETE_FAILURE: (state, { data }) => {
			const index = state.items.findIndex(avatar => data.webuserId.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		},
		AVATAR_RESET_UPLOAD_ERROR: state => ({ ...state, uploadError: false }),
		AVATAR_RESET_UPLOAD_SUCCESS: state => ({ ...state, uploaded: false }),
	},
	initialState
);
