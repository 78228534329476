import React from 'react';

function stringifyElementType(
	type: React.ComponentType | ((...args: never[]) => React.ReactElement | null) | string
) {
	if (typeof type === 'string') {
		return type;
	}

	if ('displayName' in type) {
		return type.displayName;
	}

	return type.name;
}

export function assertChildTypes(
	children: React.ReactNode,
	expected: (React.ComponentType | ((...args: never[]) => React.ReactElement | null) | string)[]
) {
	if (children === null || children === undefined) {
		return;
	}

	if (Array.isArray(children)) {
		for (const child of children) {
			assertChildTypes(child, expected);
		}

		return;
	}

	if (React.isValidElement(children)) {
		if (children.type === React.Fragment) {
			assertChildTypes(children.props.children, expected);
			return;
		}

		if (expected.includes(children.type)) {
			return;
		}

		throw new Error(
			`Expected children of types [${expected
				.map(stringifyElementType)
				.join(', ')}] but received <${
				typeof children.type === 'string' ? children.type : children.type.name
			}>`
		);
	}

	const childArray = React.Children.toArray(children);
	if (childArray.length > 1) {
		for (const child of childArray) {
			assertChildTypes(child, expected);
		}

		return;
	}

	throw new Error(
		`Expected children of types [${expected.map(stringifyElementType).join(', ')}] but received ${
			typeof children === 'string' ? `"${children}"` : children
		}`
	);
}
