import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { AppBookingStatus } from '../../../api/types/AppBookingStatus';

const shouldFetchAppBookings = (state: ReduxState, userId: string) => {
	return (
		!state.appBookings.fetchingForWebuser.includes(userId) &&
		!state.appBookings.fetchedForWebuser.includes(userId)
	);
};

export const fetchAppBookingsForWebuser = createAction(
	'APP_BOOKINGS_FETCH',
	(webuserId: string, active: AppBookingStatus, force?: boolean) => ({
		promise: () => api.getAppBookings(webuserId, active),
		shouldFetch: (state: ReduxState) => force || shouldFetchAppBookings(state, webuserId),
		data: { webuserId },
	})
);

export const fetchAppBookingsForAccount = createAction(
	'APP_BOOKINGS_FOR_ACCOUNT_FETCH',
	(active: AppBookingStatus) => ({
		promise: () => api.getAppBookingsForAccount(active),
	})
);

export const createAppBookings = createAction(
	'APP_BOOKINGS_CREATE',
	(userIds: Set<string>, subscriptionType: string) => ({
		promise: () => api.createAppBookings(userIds, subscriptionType),
	})
);

const shouldFetchAppSubscriptionTypes = (state: ReduxState) => {
	return !state.appSubscriptions.fetched && !state.appSubscriptions.fetching;
};

export const fetchAppSubcriptionTypes = createAction(
	'APP_SUBSCRIPTION_TYPES_FETCH',
	(force?: boolean) => ({
		promise: () => api.getAppSubscriptionTypes(),
		shouldFetch: (state: ReduxState) => force || shouldFetchAppSubscriptionTypes(state),
	})
);
