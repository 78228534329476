import * as React from 'react';
import { Action } from 'redux';
// eslint-disable-next-line no-restricted-syntax
import * as upstream from 'react-redux';
import { ReduxState } from '../types';

// Not sure how to get rid of this any, as its used during type-inference and all other options
// (never, void, unknown etc.) break something.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateProps = Record<string, any>;
type DispatchProps = Record<string, (...any: never[]) => void>;

type EmptyStateProps = () => {}; // eslint-disable-line @typescript-eslint/ban-types
type EmptyDispatchProps = {}; // eslint-disable-line @typescript-eslint/ban-types

type MapStateToProps<Props extends StateProps> = (state: never, ownProps: never) => Props;
type MapDispatchToProps<Props extends DispatchProps> = Props;

type PropsFromMapper<T extends MapStateToProps<StateProps> | MapDispatchToProps<DispatchProps>> =
	T extends MapStateToProps<infer Props>
		? Props
		: T extends MapDispatchToProps<infer Props>
		? {
				[key in keyof Props]: (
					...args: Parameters<Props[key]>
				) => ReturnedAction<ReturnType<Props[key]>>;
		  }
		: never;

export type ReduxProps<
	A extends MapStateToProps<StateProps> | MapDispatchToProps<DispatchProps>,
	B extends MapStateToProps<StateProps> | MapDispatchToProps<DispatchProps> = EmptyStateProps
> = PropsFromMapper<A> & PropsFromMapper<B>;

interface Connect {
	<S extends MapStateToProps<StateProps> = EmptyStateProps>(mapStateToProps: S): <
		Props extends ReduxProps<S>
	>(
		component: React.ComponentType<Props>
	) => React.ComponentType<Omit<Props, keyof ReduxProps<S>>>;
	<D extends MapDispatchToProps<DispatchProps> = EmptyDispatchProps>(
		mapStateToProps: undefined,
		mapDispatchToProps: D
	): <Props extends ReduxProps<D>>(
		component: React.ComponentType<Props>
	) => React.ComponentType<Omit<Props, keyof ReduxProps<D>>>;
	<
		S extends MapStateToProps<StateProps> = EmptyStateProps,
		D extends MapDispatchToProps<DispatchProps> = EmptyDispatchProps
	>(
		mapStateToProps: S,
		mapDispatchToProps: D
	): <Props extends ReduxProps<S, D>>(
		component: React.ComponentType<Props>
	) => React.ComponentType<Omit<Props, keyof ReduxProps<S, D>>>;
}

// Upstream connect type is flat wrong, as it assumes a dispatch() call always returns whatever was
// passed as its first argument. This is a lie, as a middleware can change the returned action into whatever the
// hell it wants.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const connect: Connect = upstream.connect as any;

// Upstream dispatch type is flat wrong, as it assumes the dispatch() call always returns whatever was
// passed as its first argument. This is a lie, as a middleware can change the returned action into whatever the
// hell it wants.
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDispatch: () => Dispatch<Action> = upstream.useDispatch as any;
export const useSelector: upstream.TypedUseSelectorHook<ReduxState> = upstream.useSelector;

export const Provider = upstream.Provider;
