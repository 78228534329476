import { handleActions } from '../..';

import * as actions from './actions';
import { MobileInfoState } from './types';
import { MobileInfoStatus } from '../../../api/types/mobileInfo';

const initialState: MobileInfoState = {
	data: null,
	fetched: false,
	fetching: false,
};

const getStatusSeverity = (status: MobileInfoStatus | null) => {
	if (!status) {
		return 0;
	}

	return status.severity === 'INFO' ? 1 : 2;
};

export default handleActions<MobileInfoState, PossibleActions<typeof actions>>(
	{
		MOBILEINFO_FETCH_PENDING: state => ({ ...state, fetching: true }),
		MOBILEINFO_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				data: payload.map(mobileInfo => ({
					...mobileInfo,
					statusSeverity: getStatusSeverity(mobileInfo.status),
				})),
				fetched: true,
				fetching: false,
			};
		},
	},
	initialState as MobileInfoState
);
