import React from 'react';
import { Unsubscribe } from 'firebase/database';
import { ReduxState } from '../redux/types';
import { connect, ReduxProps } from '../redux';
import {
	fetchOrganisationContacts,
	fetchUserMemberships,
	updateOrganisationContacts,
	updateUserMemberships,
} from '../redux/modules/satelliteUser';
import {
	fetchOrganisationMemberships,
	updateOrganisationMemberships,
} from '../redux/modules/organisationMembership';
import { logger } from '../third-party/logger';
import { firebaseClient } from '../api';
import { SatelliteUserContact } from '../api/types/satelliteUserContact';
import { OrganisationPayment } from '../api/types/organisationPayment';
import {
	fetchOrganisationPaymentState,
	updateOrganisationPaymentState,
} from '../redux/modules/paymentState';

const mapStateToProps = (state: ReduxState) => ({
	organisationContacts: state.organisationContacts,
	organisationMembership: state.organisationMemberships,
});

const mapDispatchToProps = {
	fetchOrganisationContacts,
	fetchUserMemberships,
	fetchOrganisationMemberships,
	fetchOrganisationPaymentState,
	updateOrganisationContacts,
	updateOrganisationMemberships,
	updateUserMemberships,
	updateOrganisationPaymentState,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

let listeners: Unsubscribe[] = [];

class FirebaseListener extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchOrganisationContacts();
		this.props.fetchOrganisationMemberships();
		this.props.fetchUserMemberships();
		this.props.fetchOrganisationPaymentState();
		this.initFirebaseListeners().then(() => logger.log('Listening on firebase listeners'));
	}

	public componentWillUnmount() {
		listeners.forEach(listener => listener());
		listeners = [];
		logger.log('FirebaseListener stopped');
	}

	private async initFirebaseListeners() {
		const organisationId: string = await firebaseClient.getOrganisationId();
		this.addContactsListener(organisationId);
		this.addChannelToUserListener(organisationId);
		this.addUserToChannelsListener(organisationId);
		this.addPaymentStateListener(organisationId);
	}

	private async addContactsListener(organisationId: string) {
		const unsubscribe = await firebaseClient.addOrganisationListener(
			`/contacts/${organisationId}`,
			snapshot => {
				const usersArray = snapshot.val();
				const payload: SatelliteUserContact[] = Object.keys(usersArray).map((id: string) => ({
					...usersArray[id],
					organisationId,
				}));
				this.props.updateOrganisationContacts(payload);
			},
			error => {
				logger.log(`Error addContactsListener onValue -> ${JSON.stringify(error)}`);
			}
		);
		listeners.push(unsubscribe);
	}

	private async addChannelToUserListener(organisationId: string) {
		const unsubscribe = await firebaseClient.addOrganisationListener(
			`/membership/${organisationId}/channelToUsers`,
			async snapshot => {
				const memberships = snapshot.val();
				this.props.updateOrganisationMemberships(memberships);
			},
			error => {
				logger.log(`Error addChannelToUserListener onValue -> ${JSON.stringify(error)}`);
			}
		);
		listeners.push(unsubscribe);
	}

	private async addUserToChannelsListener(organisationId: string) {
		const unsubscribe = await firebaseClient.addOrganisationListener(
			`/membership/${organisationId}/userToChannels`,
			async snapshot => {
				const memberships = snapshot.val();
				const parsedMemberships = Object.keys(memberships).map(userId => {
					const names = memberships[userId];
					return {
						id: userId,
						channels: Object.keys(memberships[userId]).map(channelId => {
							return {
								id: channelId,
								name: names[channelId].name,
							};
						}),
					};
				});
				this.props.updateUserMemberships(parsedMemberships);
			},
			error => {
				logger.log(`Error addUserToChannelsListener onValue -> ${JSON.stringify(error)}`);
			}
		);
		listeners.push(unsubscribe);
	}

	private async addPaymentStateListener(organisationId: string) {
		const unsubscribe = await firebaseClient.addFireStoreListener(
			`/organisations/${organisationId}`,
			async snapshot => {
				const productState: OrganisationPayment = snapshot.data() as OrganisationPayment;
				this.props.updateOrganisationPaymentState(productState);
			},
			error => {
				logger.log(`Error addPaymentStateListener onValue -> ${JSON.stringify(error)}`);
			}
		);
		listeners.push(unsubscribe);
	}

	public render() {
		return <></>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseListener);
