import { handleActions } from '../..';
import { LpaUriState } from './types';

import * as actions from './actions';

const initialState = {
	items: {},
	fetched: false,
	fetching: false,
};

export default handleActions<LpaUriState, PossibleActions<typeof actions>>(
	{
		LPA_DATA_FETCH_PENDING: state => ({ ...state, items: {}, fetching: true }),
		LPA_DATA_FETCH_SUCCESS: (state, { payload, data: { deviceId } }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: { ...state.items, [deviceId]: payload },
		}),
	},
	initialState
);
