import { handleActions } from '../..';
import * as actions from './actions';
import { TokenDetails, TokenDetailsState } from './types';

const initialState: TokenDetailsState = {
	item: {} as TokenDetails,
	fetched: false,
	fetching: false,
};

export default handleActions<TokenDetailsState, PossibleActions<typeof actions>>(
	{
		TOKEN_DETAILS_FETCH_SUCCESS: (state, action) => {
			const item = action.payload as TokenDetails;
			return {
				...state,
				item,
				fetching: false,
				fetched: true,
			};
		},
	},
	initialState
);
