import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchTacs = (state: ReduxState) => !state.tacs.fetched && !state.tacs.fetching;

export const fetchTacs = createAction('TACS_FETCH', () => ({
	promise: api.getTacs(),
	shouldFetch: (state: ReduxState) => shouldFetchTacs(state),
}));
