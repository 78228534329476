import { handleActions } from '../..';
import * as actions from './actions';
import { TrunkContractsState } from './types';

const initialState: TrunkContractsState = {
	items: [],
	fetching: false,
	fetched: false,
};

export default handleActions<TrunkContractsState, PossibleActions<typeof actions>>(
	{
		TRUNK_CONTRACTS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
			fetched: false,
		}),
		TRUNK_CONTRACTS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload.items,
		}),
	},
	initialState
);
