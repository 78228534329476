import classnames from 'classnames';
import React from 'react';

type Props = {
	/**
	 * Der Inhalt der Spalte.
	 */
	children: React.ReactNode;
};

const styles = {
	column: classnames([
		'my-16',
		'py-24',
		'px-16',
		'border-gray-100',
		'border-y-2',
		'rounded-y-lg',
		'[&:nth-child(2)]:ps-8',
		'[&:nth-child(2)]:font-bold',
		'[&:nth-last-child(2)]:w-full',
	]),
	dash: classnames('text-gray-200'),
};

export const ListViewColumn = ({ children }: Props): JSX.Element => {
	return (
		<td className={styles.column}>
			{children ?? (
				<span className={styles.dash} aria-hidden>
					–
				</span>
			)}
		</td>
	);
};
