import { Contact as ApiContact } from '../../../api/types/contacts';
import { InternalContact as ApiInternalContact } from '../../../api/types/internal-contacts';

import { ContactNumber, ContactNumberType, ContactEmailType, Contact } from './types';

const normalizeContactNumberType = (types: string[]): ContactNumberType => {
	const firstType = types.filter(part => part !== 'voice' && part !== 'fax')[0];
	if (!firstType) {
		return 'other';
	}
	switch (firstType.toLowerCase()) {
		case 'home':
			return 'home';
		case 'work':
			return 'work';
		case 'cell':
			return 'cell';
		case 'pager':
			return 'pager';
		case 'directdial':
			return 'directdial';
		default:
			return 'other';
	}
};

const normalizeContactEmailType = (types: string[]): ContactEmailType => {
	const firstType = types[0];

	switch (firstType) {
		case 'home':
			return 'home';
		case 'work':
			return 'work';
		case 'internet':
			return 'internet';
		default:
			return 'other';
	}
};

export const normalizeContactNumber = (contactNumber: {
	number: string;
	type?: string[];
}): ContactNumber => {
	if (!contactNumber.type) {
		return {
			fax: false,
			number: contactNumber.number,
			type: 'other',
		};
	}

	return {
		fax: contactNumber.type.includes('fax'),
		type: normalizeContactNumberType(contactNumber.type),
		number: contactNumber.number,
	};
};

export const normalizeContact = (contact: ApiContact): Contact => {
	return {
		id: contact.id,
		name: contact.name,
		emails: contact.emails.map(email => ({
			address: email.email,
			type: normalizeContactEmailType(email.type),
		})),
		numbers: contact.numbers.map(number => normalizeContactNumber(number)),
		isInternal: false,

		isPublic: contact.scope === 'SHARED',
		picture: contact.picture,
		addresses: contact.addresses,
		organization: contact.organization[0] && contact.organization[0][0],

		apiContact: contact,
	};
};

export const normalizeInternalContact = (contact: ApiInternalContact): Contact => ({
	id: contact.id,
	name: contact.name,
	emails: contact.emails.map(address => ({ address, type: 'other' })),
	numbers: [
		...contact.directdial.map(number => ({
			type: 'directdial' as ContactNumberType,
			number,
			fax: false,
		})),
		...contact.mobile.map(number => ({
			type: 'cell' as ContactNumberType,
			number,
			fax: false,
		})),
		...contact.landline.map(number => ({
			type: 'work' as ContactNumberType,
			number,
			fax: false,
		})),
		...contact.fax.map(number => ({
			type: 'work' as ContactNumberType,
			number,
			fax: true,
		})),
	],
	isInternal: true,
});
