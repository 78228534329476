import { handleActions } from '../..';
import * as actions from './actions';
import { SatelliteUserContactState } from './types';

const initialState: SatelliteUserContactState = {
	items: [],
	fetched: false,
	fetching: false,
	userGroups: [],
};

export default handleActions<SatelliteUserContactState, PossibleActions<typeof actions>>(
	{
		ORGANISATION_CONTACTS_FETCH_PENDING: state => {
			return {
				...state,
				fetching: true,
				fetched: false,
			};
		},
		ORGANISATION_CONTACTS_FETCH_SUCCESS: (state, { payload }) => {
			payload.sort((a, b) => {
				const left = a.name;
				const right = b.name;

				return left.localeCompare(right);
			});
			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload,
			};
		},
		ORGANISATION_CONTACTS_MEMBERSHIPS_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				userGroups: payload,
			};
		},
		SATELLITE_USERS_CREATE_PENDING: state => {
			return {
				...state,
				fetching: true,
				fetched: false,
			};
		},
		SATELLITE_USERS_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
			fetched: true,
			fetching: false,
		}),
	},
	initialState
);
