import { handleActions } from '../..';
import { Product } from '../products/types';
import { ProductOptionsState } from './types';
import * as actions from './actions';

const initialState: ProductOptionsState = {
	items: [],
	fetching: false,
	fetched: false,
};

export default handleActions<ProductOptionsState, PossibleActions<typeof actions>>(
	{
		PRODUCT_OPTIONS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		PRODUCT_OPTIONS_FETCH_SUCCESS: (state, { payload }) => {
			const orderedItems = [...payload.items].sort(
				(a: Product, b: Product) => a.recurringFee - b.recurringFee
			);

			return {
				...state,
				fetched: true,
				fetching: false,
				items: orderedItems,
			};
		},
	},
	initialState
);
