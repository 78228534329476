import React from 'react';
import classnames from 'classnames';

export type SubTabItemProps = {
	label: string;
	isSelected?: boolean;
	handleOnClick?: VoidFunction;
	disabled: boolean;
};

const styles = {
	listItem: classnames('flex', 'items-center', 'self-stretch'),
	outerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'group',
			'cursor-pointer',
			'focus:outline-none',
			'focus-visible:outline-none',
			'justify-center',
			'p-8',
			'whitespace-nowrap',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled && isSelected && ['bg-transparent'],
			!isDisabled && !isSelected && ['bg-transparent'],
			isDisabled && ['bg-transparent', 'disabled:cursor-not-allowed']
		),
	innerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'group-focus-visible:ring',
			'group-focus-visible:ring-inset',
			'px-16',
			'py-8',
			'rounded',
			'text-sm/16',
			'block',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled &&
				isSelected && [
					'bg-blue-50',
					'text-blue-900',
					'group-hover:text-blue-900',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-100',
					'group-active:text-black',
				],
			!isDisabled &&
				!isSelected && [
					'text-gray-800',
					'group-hover:bg-blue-25',
					'group-hover:text-blue-800',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-50',
					'group-active:text-blue-900',
				],
			isDisabled && isSelected && ['text-gray-400', 'bg-gray-75'],
			isDisabled && !isSelected && ['text-gray-400']
		),
};

const SubTabItem = ({
	label,
	isSelected = false,
	disabled,
	handleOnClick,
}: SubTabItemProps): JSX.Element => (
	<li className={styles.listItem} role="presentation">
		<button
			role="tab"
			aria-selected={isSelected}
			type="button"
			className={styles.outerButton(isSelected, disabled)}
			disabled={disabled}
			onClick={!disabled ? handleOnClick : undefined}
		>
			<span className={styles.innerButton(isSelected, disabled)}>{label}</span>
		</button>
	</li>
);

export { SubTabItem };
