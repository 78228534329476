/**
 * Authentication
 *
 * We use a keycloak, which is hosted at login.sipgate.com, as our authentication provider.
 * We support multiple different OpenID-connect flows to authenticate the user.
 *
 * The following entrypoints exist:
 *
 *   * /implicit-auth-redirect: Normal login process via implicit-flow
 *   * /authenticate: ID Token flow, used during the signup process
 *   * /openid-connect: Access Token flow, used by helpdesk "Login as User" feature
 *
 * If none of the above entry points are hit, we try to load an existing token from localstorage
 * (to make the login as user feature persist across reloads) and if that fails, we
 * redirect to the keycloak login page.
 *
 * Once we need to refresh our token, we use a refresh token if one is available. If we
 * don't have one (e.g. because we used implicit flow), we open a hidden iframe on the
 * keycloak login, redirecting to the blank `iframe_refresh.html` page (loading index.html
 * causes a lot of pointless traffic)
 *
 * If we failed to refresh our token our something else happened resulting in the token
 * being rejected by the API, we mark the token as invalid, delete it from localstorage
 * and redirect to the login once again.
 */

import serviceUrls from '@web-apps/service-urls';
import { Links } from '../../redux/modules/links';
import { decodeToken } from './token';
import { AsyncValue } from './AsyncValue';
import { logger } from '../../third-party/logger';

const LOCAL_STORAGE_KEY = 'new-token';
const EXPIRY_BUFFER_MS = 60000;
const REFRESH_TIMEOUT_MS = 10000;

export interface Token {
	access: string;
	refresh?: string;
}

export class Authentication {
	private config = {
		url: serviceUrls.authentication.keycloak,
		realm: 'sipgate-mobile-apps',
		clientId: 'satellite-business-web-app',

		logoutRedirectUri: serviceUrls.authentication.logoutUrl,
		implicitRedirectUri: `${window.location.origin}/implicit-auth-redirect`,
		refreshRedirectUri: `${window.location.origin}/iframe_refresh.html`,
		accessCodeRedirectUri: `${window.location.origin}/openid-connect`,
	};

	/** @deprecated */
	private tokenValue?: Token = undefined;

	private initialized = false;

	private token = new AsyncValue<Token>();

	private lastAccount: null | string = null;

	public constructor() {
		this.token.onValue(token => {
			this.tokenValue = token;
		});
	}

	private baseUrl() {
		return `${this.config.url}/realms/${encodeURIComponent(
			this.config.realm
		)}/protocol/openid-connect`;
	}

	private setToken(token: Token) {
		this.initialized = true;

		const tokenAccount = decodeToken(token.access).sub;
		if (this.lastAccount !== null && this.lastAccount !== tokenAccount) {
			throw new Error('Failed to set token: Account mismatch');
		}

		this.addTokenToLocalStorage(token);

		this.lastAccount = tokenAccount;
		this.token.set(token);

		this.scheduleTokenRefresh(token);
	}

	public getMailFromToken() {
		const token = this.getTokenFromLocalStorage();
		if (token && token.access) {
			const decodedToken = decodeToken(token.access);
			if (decodedToken.email) {
				return decodedToken.email;
			}
		}

		return '';
	}

	private openLogin() {
		const params = new URLSearchParams();

		params.append('client_id', this.config.clientId);
		params.append('response_type', 'token');

		const redirectUriParams = new URLSearchParams({
			redirect: window.location.pathname + window.location.search + window.location.hash,
		});

		params.append(
			'redirect_uri',
			`${this.config.implicitRedirectUri}?${redirectUriParams.toString()}`
		);

		window.location.replace(`${this.baseUrl()}/auth?${params.toString()}`);
	}

	private addTokenToLocalStorage(token: Token) {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(token));
	}

	private removeTokenFromLocalStorage() {
		localStorage.removeItem(LOCAL_STORAGE_KEY);
	}

	private getTokenFromLocalStorage() {
		const token = localStorage.getItem(LOCAL_STORAGE_KEY);

		if (!token) {
			return null;
		}

		return JSON.parse(token) as Token;
	}

	private async redeemAccessCodeForToken(accessCode: string, loginasuser?: string) {
		const params = new URLSearchParams();

		params.set('code', accessCode);
		params.set('grant_type', 'authorization_code');
		params.set(
			'redirect_uri',
			this.config.accessCodeRedirectUri + (loginasuser ? `?loginasuser=${loginasuser}` : '')
		);
		params.set('client_id', this.config.clientId);

		const response = await fetch(`${this.baseUrl()}/token`, {
			method: 'POST',
			body: params.toString(),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});

		if (!response.ok) {
			throw new Error(`Failed to retrieve access token: ${response.status}`);
		}

		const result = await response.json();

		return {
			access: result.access_token,
			refresh: result.refresh_token,
		};
	}

	private refreshUsingIframe(timeout: number) {
		const params = new URLSearchParams();

		params.append('client_id', this.config.clientId);
		params.append('response_type', 'token');
		params.append('redirect_uri', this.config.refreshRedirectUri);

		return new Promise<Token>((res, rej) => {
			const iframe = document.createElement('iframe');
			iframe.src = `${this.baseUrl()}/auth?${params.toString()}`;
			iframe.style.display = 'none';

			const timer = setTimeout(() => {
				document.body.removeChild(iframe);
				iframe.onload = null;

				rej(new Error('Failed to refresh token: Timeout exceeded'));
			}, timeout);

			iframe.onload = () => {
				/* Happens if we are not yet redirected to our iframe_refresh.html */
				if (!iframe.contentWindow) {
					return;
				}

				const query = iframe.contentWindow.location.hash;

				const token = new URLSearchParams(query).get('access_token');

				if (token) {
					clearTimeout(timer);
					document.body.removeChild(iframe);
					iframe.onload = null;

					res({ access: token });
				}
			};

			document.body.appendChild(iframe);
		});
	}

	private async refreshUsingRefreshToken(refreshToken: string): Promise<Token> {
		const params = new URLSearchParams();

		params.append('refresh_token', refreshToken);
		params.append('grant_type', 'refresh_token');
		params.append('client_id', this.config.clientId);

		const response = await fetch(`${this.baseUrl()}/token`, {
			method: 'POST',
			body: params.toString(),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});

		if (!response.ok) {
			throw new Error(`Failed to refresh token: ${response.status}`);
		}

		const result = await response.json();

		return {
			access: result.access_token,
			refresh: result.refresh_token,
		};
	}

	private scheduleTokenRefresh(token: Token) {
		const tokenData = decodeToken(token.access);
		const tokenLifetime = (tokenData.exp - tokenData.iat) * 1000;
		const remainingTokenDuration = tokenData.exp * 1000 - new Date().getTime();

		let firstRefreshAfter = remainingTokenDuration - EXPIRY_BUFFER_MS;
		if (remainingTokenDuration < tokenLifetime / 2) {
			/*
			 * If we have a very short remaining token duration, we assume the users clock
			 * is incorrect and we use the tokens lifetime instead.
			 * This might break if the refresh request takes very long, but it is the most fault-resistant way
			 * we could come up with.
			 */
			logger.warn('System time seems to run ahead of server time.');
			firstRefreshAfter = tokenLifetime - EXPIRY_BUFFER_MS;
		}

		setTimeout(() => this.refreshToken().catch(e => logger.error(e.message)), firstRefreshAfter);
	}

	private async getRefreshToken(token: Token, timeout: number): Promise<Token> {
		if (token.refresh) {
			return this.refreshUsingRefreshToken(token.refresh);
		}

		return this.refreshUsingIframe(timeout);
	}

	public async refreshToken() {
		const refreshToken = await this.getRefreshToken(await this.token.get(), REFRESH_TIMEOUT_MS);
		this.setToken(refreshToken);
	}

	private async logoutTeamWeb(externalwebLogoutUrl: string) {
		await fetch(externalwebLogoutUrl, { redirect: 'follow', credentials: 'include' });
	}

	public getImpersonated() {
		const impersonationFlag = localStorage.getItem('impersonated');

		if (!impersonationFlag) {
			return false;
		}

		return JSON.parse(impersonationFlag);
	}

	public async getToken() {
		return this.token.get();
	}

	public initializeFromLocalStorage() {
		if (this.initialized) {
			/* If we are already initialized, we do not have to try loading a key from localstorage */
			return;
		}

		const token = this.getTokenFromLocalStorage();

		if (!token || !token.refresh) {
			/*
			 * If we have a token without a refresh token, we shortcircuit to login, because
			 * we cannot be sure the local token matches the users session in our keycloak.
			 * Worst case this results in loading a stale token, with the refresh process immediately
			 * hanging because the user was logged out in e.g. another tab.
			 */
			this.openLogin();
			return;
		}

		this.setToken(token);
	}

	public initializeFromToken(token: Token) {
		if (this.initialized) {
			throw new Error('Tried to initiate authentication twice');
		}

		this.setToken(token);
	}

	public async initializeFromAccessCode(accessCode: string, loginasuser?: string) {
		if (this.initialized) {
			throw new Error('Tried to initiate authentication twice');
		}

		/* We initialize the token early to keep the localstorage initiation from running */
		this.initialized = true;

		const token = await this.redeemAccessCodeForToken(accessCode, loginasuser).catch(err => {
			this.initialized = false;
			this.openLogin();
			throw err;
		});

		this.setToken(token);
		if (loginasuser) {
			localStorage.setItem('impersonated', JSON.stringify({ state: true }));
		}
	}

	public invalidateToken() {
		this.initialized = false;
		this.token.clear();
		this.openLogin();
		localStorage.removeItem('impersonated');
	}

	private async logoutKeycloak(redirectUri: string) {
		window.location.href = `${this.baseUrl()}/logout?redirect_uri=${encodeURI(redirectUri)}`;
	}

	private async logoutKeycloakWithUriComponentEncodedRedirectUri(redirectUri: string) {
		window.location.href = `${this.baseUrl()}/logout?redirect_uri=${encodeURIComponent(
			redirectUri
		)}`;
	}

	public async logoutAndRedirectToLogoutPage(
		links?: Links,
		redirectUri?: string,
		encodeAsUriComponent?: boolean
	) {
		try {
			this.removeTokenFromLocalStorage();
			localStorage.removeItem('impersonated');

			if (links && links.logoutUrl) {
				await this.logoutTeamWeb(links.logoutUrl);
			}
		} finally {
			if (encodeAsUriComponent) {
				await this.logoutKeycloakWithUriComponentEncodedRedirectUri(
					redirectUri || (links && links.logoutPageUrl) || this.config.logoutRedirectUri
				);
			} else {
				await this.logoutKeycloak(
					redirectUri || (links && links.logoutPageUrl) || this.config.logoutRedirectUri
				);
			}
		}
	}

	/**
	 * @deprecated
	 *
	 * This method exists, because in the past everything that used the token expected it
	 * to be accessible synchronously.
	 *
	 * The new scheme does not make guarantees when the token is available. So you will want
	 * to use the getToken() method. This might take some time to resolve, but will always
	 * return a token or throw.
	 */
	// eslint-disable-next-line camelcase
	public UNSAFE_getTokenSynchronously() {
		return this.tokenValue ? this.tokenValue.access : undefined;
	}
}
