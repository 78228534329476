import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import auth from '../utils/authenticate/auth';

class ImplicitAuthenticator extends React.PureComponent<RouteComponentProps> {
	public render() {
		const redirect = new URLSearchParams(this.props.location.search).get('redirect');
		const fromIdp = new URLSearchParams(this.props.location.search).get('from_idp');

		window.localStorage.setItem('from_idp', fromIdp || '');

		if (!this.props.location.hash) {
			return <Redirect to="/" />;
		}

		const hash = new URLSearchParams(this.props.location.hash);
		const accessToken = hash.get('access_token');
		if (!accessToken || !redirect) {
			return <Redirect to="/" />;
		}

		auth.initializeFromToken({ access: accessToken });
		return <Redirect to={redirect} />;
	}
}

export default ImplicitAuthenticator;
