import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { closeAutoCloseSuccessSnackbar } from '../../redux/modules/snackbar';
import { ReduxState } from '../../redux/types';
import { AutoCloseSnackbar } from './AutoCloseSnackbar';

const mapStateToProps = (state: ReduxState) => ({
	snackbarOpen: state.dialogs.autoCloseSuccessSnackbarOpen,
	snackbarMessage: state.dialogs.autoCloseSuccessSnackbarMessage,
});

const mapDispatchToProps = {
	closeAutoCloseSuccessSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class AutoCloseSuccessSnackbar extends React.PureComponent<Props> {
	public render() {
		return (
			<AutoCloseSnackbar
				open={this.props.snackbarOpen}
				message={this.props.snackbarMessage}
				severity="success"
				onClose={this.props.closeAutoCloseSuccessSnackbar}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCloseSuccessSnackbar);
