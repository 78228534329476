import { hasKey } from '../../utils/types';
import { ReduxState } from '../types';

interface ShouldFetchAction<Result> {
	payload: {
		promise: () => Promise<Result>;
	};
}

interface ShouldMaybeFetchAction<Result> {
	payload: {
		promise: () => Promise<Result>;
		shouldFetch: (state: ReduxState) => boolean | undefined | null;
	};
}

type ExecutedShouldFetchAction<Action> = Action extends ShouldFetchAction<infer Result>
	? Omit<Action, 'payload'> & {
			payload: Omit<Action['payload'], 'promise'> & { promise: Promise<Result> };
	  }
	: Action;

export type ShouldFetchMiddlewareNext<Action> = Action extends ShouldMaybeFetchAction<unknown>
	? ExecutedShouldFetchAction<Action> | Action
	: ExecutedShouldFetchAction<Action>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ShouldFetchMiddlewareResult<Action, NextResult> = NextResult;

export type ShouldFetchMiddlewareEmits = never;

function isShouldFetchAction(
	action: unknown
): action is ShouldFetchAction<unknown> | ShouldMaybeFetchAction<unknown> {
	if (!hasKey(action, 'payload') || typeof action.payload !== 'object') {
		return false;
	}

	if (hasKey(action.payload, 'shouldFetch') && typeof action.payload.shouldFetch !== 'function') {
		return false;
	}

	return hasKey(action.payload, 'promise') && typeof action.payload.promise === 'function';
}

export const shouldFetchMiddleware = ({ getState }: { getState: () => ReduxState }) => {
	return <Action, NextResult>(next: (action: ShouldFetchMiddlewareNext<Action>) => NextResult) =>
		(action: Action): ShouldFetchMiddlewareResult<Action, NextResult> => {
			if (!isShouldFetchAction(action)) {
				return next(action as ShouldFetchMiddlewareNext<Action>);
			}

			// Not 100% Typesafe as we cannot guarantee that our shouldFetch() actually takes the corresponding state...
			if (!('shouldFetch' in action.payload) || action.payload.shouldFetch(getState())) {
				return next({
					...action,

					payload: {
						...action.payload,

						promise: action.payload.promise(),
					},
				} as ShouldFetchMiddlewareNext<Action>);
			}

			return next(action as ShouldFetchMiddlewareNext<Action>);
		};
};
