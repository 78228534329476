import { handleActions } from '../..';
import { SnackbarState } from '.';
import * as actions from './actions';

export const initialState: SnackbarState = {
	dialogCount: 0,
	warningSnackbarMessage: '',
	warningSnackbarOpen: false,
	errorSnackbarMessage: '',
	errorSnackbarOpen: false,
	invalidAccountStateSnackbarOpen: false,
	insufficientFundsSnackbarOpen: false,
	autoCloseInfoSnackbarOpen: false,
	autoCloseSuccessSnackbarOpen: false,
	autoCloseInfoSnackbarMessage: '',
	autoCloseSuccessSnackbarMessage: '',
	clickToDialSession: undefined,
};

export default handleActions<SnackbarState, PossibleActions<typeof actions>>(
	{
		DIALOG_OPEN: state => ({
			...state,
			dialogCount: state.dialogCount + 1,
		}),
		DIALOG_CLOSE: state => {
			return { ...state, dialogCount: state.dialogCount - 1 };
		},
		WARNING_SNACKBAR_CLOSE: state => ({ ...state, warningSnackbarOpen: false }),
		WARNING_SNACKBAR_OPEN: (state, { payload }) => ({
			...state,
			warningSnackbarOpen: true,
			...payload,
		}),
		ERROR_SNACKBAR_CLOSE: state => ({ ...state, errorSnackbarOpen: false }),
		ERROR_SNACKBAR_OPEN: (state, { payload }) => ({
			...state,
			errorSnackbarOpen: true,
			...payload,
		}),
		INVALID_ACCOUNT_STATE_SNACKBAR_CLOSE: state => ({
			...state,
			invalidAccountStateSnackbarOpen: false,
		}),
		INVALID_ACCOUNT_STATE_SNACKBAR_OPEN: state => ({
			...state,
			invalidAccountStateSnackbarOpen: true,
		}),
		INSUFFICIENTFUNDS_SNACKBAR_OPEN: state => ({
			...state,
			insufficientFundsSnackbarOpen: true,
		}),
		INSUFFICIENTFUNDS_SNACKBAR_CLOSE: state => ({
			...state,
			insufficientFundsSnackbarOpen: false,
		}),
		AUTOCLOSE_INFO_SNACKBAR_OPEN: (state, { payload }) => ({
			...state,
			autoCloseInfoSnackbarOpen: true,
			autoCloseInfoSnackbarMessage: payload.autoCloseInfoSnackbarMessage,
		}),
		AUTOCLOSE_INFO_SNACKBAR_CLOSE: state => ({
			...state,
			autoCloseInfoSnackbarOpen: false,
			autoCloseInfoSnackbarMessage: '',
		}),
		AUTOCLOSE_SUCCESS_SNACKBAR_OPEN: (state, { payload }) => ({
			...state,
			autoCloseSuccessSnackbarOpen: true,
			autoCloseSuccessSnackbarMessage: payload.autoCloseSuccessSnackbarMessage,
		}),
		AUTOCLOSE_SUCCESS_SNACKBAR_CLOSE: state => ({
			...state,
			autoCloseSuccessSnackbarOpen: false,
			autoCloseSuccessSnackbarMessage: '',
		}),
		CLICKTODIAL_SNACKBAR_OPEN: (state, { payload }) => ({
			...state,
			clickToDialSession: {
				sessionId: payload.sessionId,
				alias: payload.alias,
			},
		}),
		CLICKTODIAL_SNACKBAR_CLOSE: state => ({
			...state,
			clickToDialSession: undefined,
		}),
	},
	initialState
);
