import { handleActions } from '../../..';

import { ViewsState } from './types';

import {
	CALL_MUTED,
	CALL_UNMUTED,
	CALL_CREATED,
	CALL_ACCEPTED,
	CALL_TERMINATED,
	CALL_TERMINATION_FINISHED,
	BROWSER_ONLINE,
	BROWSER_OFFLINE,
	SIP_CLIENT_CONNECTED,
	SIP_CLIENT_DISCONNECTED,
} from './constants';
import * as actions from './actions';

const defaultState: ViewsState = {
	view: {
		name: 'READY',
	},
	connectionState: {
		connected: false,
		online: true,
	},
};

export const viewsReducer = handleActions<ViewsState, PossibleActions<typeof actions>>(
	{
		[CALL_CREATED]: (state, action) => {
			if (state.view.name !== 'READY' && state.view.name !== 'CALL_ENDED') {
				return state;
			}

			return {
				...state,
				view: {
					name: 'RINGING' as const,

					call: {
						muted: false,
						startTime: new Date(),
						direction: action.payload.direction,

						localParty: action.payload.localParty,
						remoteParty: action.payload.remoteParty,
					},
				},
			};
		},

		[CALL_ACCEPTED]: state => {
			if (state.view.name !== 'RINGING') {
				return state;
			}

			return {
				...state,
				view: {
					name: 'CALL' as const,
					call: state.view.call,
				},
			};
		},

		[CALL_TERMINATED]: (state, action) => {
			if (state.view.name !== 'CALL' && state.view.name !== 'RINGING') {
				return state;
			}

			return {
				...state,
				view: {
					name: 'CALL_ENDED' as const,
					reason: action.payload,
					call: state.view.call,
				},
			};
		},

		[CALL_TERMINATION_FINISHED]: state => {
			if (state.view.name !== 'CALL_ENDED') {
				return state;
			}

			return {
				...state,
				view: {
					name: 'READY' as const,
				},
			};
		},

		[CALL_MUTED]: state => {
			if (state.view.name !== 'CALL' && state.view.name !== 'RINGING') {
				return state;
			}

			return {
				...state,
				view: {
					...state.view,
					call: {
						...state.view.call,
						muted: true,
					},
				},
			};
		},

		[CALL_UNMUTED]: state => {
			if (state.view.name !== 'CALL' && state.view.name !== 'RINGING') {
				return state;
			}

			return {
				...state,
				view: {
					...state.view,
					call: {
						...state.view.call,
						muted: false,
					},
				},
			};
		},

		[BROWSER_ONLINE]: state => ({
			...state,
			connectionState: {
				...state.connectionState,
				online: true,
			},
		}),

		[BROWSER_OFFLINE]: state => ({
			...state,
			connectionState: {
				...state.connectionState,
				online: false,
			},
		}),

		[SIP_CLIENT_CONNECTED]: state => ({
			...state,
			connectionState: {
				...state.connectionState,
				connected: true,
			},
		}),

		[SIP_CLIENT_DISCONNECTED]: state => ({
			...state,
			connectionState: {
				...state.connectionState,
				connected: false,
			},
		}),
	},

	defaultState
);
