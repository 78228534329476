export enum ContingentType {
	UNDEFINED,
	LANDLINE,
	MOBILE,
}

export enum BookingChangeType {
	NONE,
	CANCELLATION,
	DOWNGRADE,
	UPGRADE,
}
