import React from 'react';
import { Translate } from '../../../../../redux/modules/translations';
import classes from './VideoIdentDelayHint.scss';

type ExternalProps = {
	showIdnowDelayWarning?: boolean;
	translate: Translate;
};

type Props = ExternalProps;

const VideoIdentDelayHint = (props: Props) => {
	if (!props.showIdnowDelayWarning) {
		return <></>;
	}

	return (
		<span className={classes.contentContainer}>
			{props.translate('IDENTITY_VERIFICATION_START_VIDEO_IDENT_DIALOG_IDNOW_DELAY_INFO')}
		</span>
	);
};

export default VideoIdentDelayHint;
