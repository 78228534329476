import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchDomainVerifications = createAction(
	'DOMAIN_VERIFICATIONS_FETCH',
	(force?: boolean) => ({
		promise: () => api.getDomainVerifications(),
		shouldFetch: (state: ReduxState) =>
			(!state.domainVerification.fetched && !state.domainVerification.fetching) || force,
	})
);

export const createDomainVerification = createAction(
	'DOMAIN_VERIFICATIONS_CREATE',
	(domain: string) => ({
		promise: () => api.createDomainVerification(domain),
	})
);

export const deleteDomainVerification = createAction(
	'DOMAIN_VERIFICATIONS_DELETE',
	(verificationId: string) => ({
		promise: () => api.deleteDomainVerification(verificationId),
		data: { verificationId },
		verificationId,
	})
);

export const getDomainVerification = createAction(
	'DOMAIN_VERIFICATIONS_GET',
	(verificationId: string) => ({
		promise: () => api.getDomainVerification(verificationId),
	})
);

export const resetDomainVerification = createAction(
	'DOMAIN_VERIFICATIONS_RESET',
	(verificationId: string) => ({
		promise: () => api.resetDomainVerification(verificationId),
		data: { verificationId },
	})
);
