let id = 0;

export function ariaId() {
	id += 1;

	return `aria-${id - 1}`;
}

/** @deprecated Only ever call this from our test harness!!! */
export function resetAriaId() {
	id = 0;
}
