import { handleActions } from '../..';

import { ContactsState } from './types';
import { normalizeContact, normalizeInternalContact } from './normalize';

import * as actions from './actions';

const initialState: ContactsState = {
	abortController: null,
	fetched: false,
	items: [],
};

export const reducer = handleActions<ContactsState, PossibleActions<typeof actions>>(
	{
		CONTACTS_FETCH_PENDING: (state, action) => ({
			...state,
			abortController: action.abortController,
		}),
		CONTACTS_FETCH_BATCH_INTERNAL: (state, action) => ({
			...state,
			items: [
				...state.items.filter(item => !item.isInternal),
				...action.contacts.map(normalizeInternalContact),
			],
		}),
		CONTACTS_FETCH_BATCH: (state, action) => {
			const newIds = new Set(action.contacts.map(contact => contact.id));

			return {
				...state,
				items: [
					...state.items.filter(contact => contact.id === null || !newIds.has(contact.id)),
					...action.contacts.filter(contact => contact.scope !== 'INTERNAL').map(normalizeContact),
				],
			};
		},
		CONTACTS_FETCH_ABORT: state => ({
			...state,
			abortController: null,
		}),
		CONTACTS_FETCH_SUCCESS: (state, action) => ({
			abortController: null,
			fetched: true,

			items: state.items.filter(
				contact => typeof contact.id === 'string' && !action.removedIds.includes(contact.id)
			),
		}),
		CONTACTS_CREATE_PENDING: (state, action) => {
			const contact = normalizeContact({ id: '', ...action.data.contact });

			return {
				...state,
				items: [...state.items, { ...contact, id: null, apiContact: null }],
			};
		},
		CONTACTS_UPDATE_PENDING: (state, action) => ({
			...state,
			items: state.items.map(contact =>
				contact.id === action.data.contact.id ? normalizeContact(action.data.contact) : contact
			),
		}),
		CONTACTS_DELETE_PENDING: (state, action) => ({
			...state,
			items: state.items.filter(contact => contact.id !== action.data.contactId),
		}),
		CONTACTS_DELETE_ALL_PENDING: state => ({
			...state,
			items: state.items.filter(contact => contact.isInternal),
		}),
	},
	initialState
);
