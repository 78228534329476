import React, { useState } from 'react';
import {
	Match,
	Route,
	useRouteMatch,
	Switch,
	useLocation,
	matchPath,
	useHistory,
	ExtractRouteParams,
} from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ariaId } from '../utils/a11y/aria-id';

interface TransitionClasses {
	enter?: string;
	enterActive?: string;
	exit?: string;
	exitActive?: string;
}

type Subtract<ToBeRemoved, Data> = {
	[key in keyof Data as key extends keyof ToBeRemoved
		? ToBeRemoved[key] extends Data[key]
			? never
			: key
		: key]: Data[key];
};

interface ViewStackContext {
	/**
	 * An id which may be used as the id of a headline element as well
	 * as in an aria-labelled-by prop of a wrapper component (e.g. Dialog).
	 */
	titleId: string;
	/**
	 * The viewstack can be used to wire up back buttons and contains
	 * a list of all ancestor views.
	 */
	viewStack: string[];
	/**
	 * The mountpoint is the route outside of our top-level view.
	 * It is used as the target of the close button and is set to the parent URL
	 * of the outermost-dialog.
	 */
	mountPoint: string;
	/**
	 * Should this view be affected by transitions?
	 * Is true exactly for the outermost view.
	 */
	transitionTarget: boolean;
}

const context = React.createContext<ViewStackContext>({
	titleId: '',
	mountPoint: '',
	viewStack: [],
	transitionTarget: true,
});
context.displayName = 'ViewStackContext';

export const ViewStackConsumer = context.Consumer;
export const ViewStackProvider = context.Provider;

export function withView<Props>(
	Component: React.ComponentType<Props>,
	{
		transparent,
		transition,
	}: { transparent: boolean; transition?: { classes: TransitionClasses; timeout: number } }
) {
	return function WithView<Path extends `/${string}`>(
		/*
		 * This "beautiful" construct allows us to pass in a path which
		 * provides any props of the wrapped component. Which means you
		 * can later do either of the following:
		 *
		 * ```
		 * <MyView path="/:deviceId" />
		 * <MyView path="/" deviceId="x7" />
		 * ```
		 */
		props: { path: Path } & Subtract<ExtractRouteParams<Path> & { onClose: () => void }, Props>
	) {
		const [ownTitleId] = useState(ariaId());

		const location = useLocation();
		const history = useHistory();
		const outerMatch = useRouteMatch();

		const renderWithContext = (ctx: ViewStackContext, match: Match<ExtractRouteParams<Path>>) => {
			const innerCtx = {
				titleId: ctx.titleId === '' ? ownTitleId : ctx.titleId,
				mountPoint: ctx.mountPoint !== '' ? ctx.mountPoint : outerMatch.url,
				viewStack: transparent ? ctx.viewStack : [...ctx.viewStack, match.url],
				transitionTarget: false,
			};

			/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
			const innerProps: any = {
				...props,
				onClose: () => history.push({ pathname: innerCtx.mountPoint, hash: location.hash }),
			};

			for (const [name, param] of Object.entries(match.params)) {
				innerProps[name] = param;
			}

			delete innerProps.path;

			return (
				<ViewStackProvider value={innerCtx}>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...innerProps} />
				</ViewStackProvider>
			);
		};

		return (
			<ViewStackConsumer>
				{ctx => {
					// We only want to transition our outermost dialog
					if (transition && ctx.transitionTarget) {
						return (
							// This entire construct is pretty much taken directly from the react-router docs.
							// The basic gist is, we need a CSSTransition which gets mounted/unmounted
							// whenever our dialog opens or closes to animate the dialog and overlay.
							<TransitionGroup>
								<CSSTransition
									classNames={transition.classes}
									timeout={transition.timeout}
									key={matchPath(location.pathname, props.path) ? 'in' : 'out'}
								>
									<Switch location={location}>
										<Route
											path={props.path}
											render={({ match }) => renderWithContext(ctx, match)}
											sensitive
										/>
									</Switch>
								</CSSTransition>
							</TransitionGroup>
						);
					}

					return (
						<Route
							path={props.path}
							render={({ match }) => renderWithContext(ctx, match)}
							sensitive
						/>
					);
				}}
			</ViewStackConsumer>
		);
	};
}
