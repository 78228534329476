import { handleActions } from '../..';
import { ScreenreaderAnnouncementsState } from '.';
import * as actions from './actions';

export const initialState: ScreenreaderAnnouncementsState = {
	politeMessage: null,
};

export default handleActions<ScreenreaderAnnouncementsState, PossibleActions<typeof actions>>(
	{
		SCREENREADER_POLITE_ANNOUNCEMENT: (state, { payload }) => ({
			politeMessage: payload.message,
		}),
		SCREENREADER_POLITE_ANNOUNCEMENT_CLOSE: () => ({
			politeMessage: null,
		}),
	},
	initialState
);
