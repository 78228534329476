import React from 'react';
import classnames from 'classnames';
import { connect, ReduxProps } from '../../redux';
import MediaQueryDecorator, { MediaQueryProps } from '../../utils/MediaQueryDecorator';
import { ReduxState } from '../../redux/types';
import classes from './Snackbar.scss';

const mapStateToProps = (state: ReduxState) => ({
	translate: state.translations.translate,
});

export type SnackbarSeverity = 'info' | 'success' | 'error' | 'warning';

interface ExternalProps {
	snackbarOpen: boolean;
	severity: SnackbarSeverity;
	closeSnackbar?: () => void;
	autoHideDuration?: number;
	children: React.ReactNode;
}

type Props = ExternalProps & ReduxProps<typeof mapStateToProps> & MediaQueryProps;

class Snackbar extends React.Component<Props> {
	private timerAutoHideId = 0;

	public componentDidMount() {
		if (this.props.snackbarOpen) {
			this.startClickAwayListener();
			this.setAutoHideTimer();
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.snackbarOpen !== this.props.snackbarOpen) {
			if (this.props.snackbarOpen) {
				this.startClickAwayListener();
				this.setAutoHideTimer();
			} else {
				this.stopClickAwayListener();
				clearTimeout(this.timerAutoHideId);
			}
		}
	}

	public componentWillUnmount() {
		this.stopClickAwayListener();
		clearTimeout(this.timerAutoHideId);
	}

	private getMessage = () => {
		return (
			<div className={classnames(classes.messageContainer)} role="status">
				{this.props.children}
			</div>
		);
	};

	// Timer that controls delay before snackbar auto hides
	private setAutoHideTimer() {
		const autoHideDuration = this.props.autoHideDuration;

		if (!autoHideDuration) {
			return;
		}

		if (autoHideDuration > 0) {
			clearTimeout(this.timerAutoHideId);
			this.timerAutoHideId = window.setTimeout(() => {
				if (this.props.snackbarOpen !== null && this.props.closeSnackbar) {
					this.props.closeSnackbar();
				}
			}, autoHideDuration);
		}
	}

	private clickAwayEvents = ['mouseup', 'touchend'];

	private startClickAwayListener() {
		this.clickAwayEvents.forEach((eventName: string) =>
			document.addEventListener(eventName, this.handleClickAway)
		);
	}

	private stopClickAwayListener() {
		this.clickAwayEvents.forEach((eventName: string) =>
			document.removeEventListener(eventName, this.handleClickAway)
		);
	}

	private handleClickAway = () => {
		if (this.props.snackbarOpen !== null && this.props.closeSnackbar) {
			this.props.closeSnackbar();
		}
	};

	public render() {
		if (!this.props.children) {
			return null;
		}

		return (
			<div className={classnames(classes.snackbar, this.props.snackbarOpen && classes.open)}>
				<div className={classnames(classes.snackbarBody, classes[this.props.severity])}>
					<div
						className={classnames(classes.snackbarContent, this.props.snackbarOpen && classes.open)}
					>
						<span>{this.getMessage()}</span>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(MediaQueryDecorator(Snackbar));
