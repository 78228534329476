import React from 'react';
import { PandaContext } from '@panda/ui';
import { connect, ReduxProps } from '../redux';
import { Translate } from '../redux/modules/translations';
import { ReduxState } from '../redux/types';
import { buildTranslate } from '../redux/modules/translations/translate';

const mapStateToProps = (state: ReduxState) => ({
	translate: state.translations.translate,
	translationsFetched: state.translations.fetched,
});

interface ExternalProps {
	children: React.ReactNode;
}

type Props = ExternalProps & ReduxProps<typeof mapStateToProps>;

class PandaProvider extends React.Component<Props> {
	private portalRoot = document.getElementById('overlay-portal')!;

	private getTranslation: Translate = buildTranslate((lk, ...replacements) => {
		if (this.props.translationsFetched) {
			return this.props.translate(lk, replacements);
		}

		return lk;
	});

	public render() {
		return (
			<PandaContext.Provider
				value={{
					languageKeys: {
						PANDA_FILEUPLOAD_INVALID_FILESIZE: this.getTranslation(
							'PANDA_FILEUPLOAD_INVALID_FILESIZE'
						),
						PANDA_FILEUPLOAD_INVALID_FILETYPE: this.getTranslation(
							'PANDA_FILEUPLOAD_INVALID_FILETYPE'
						),
						PANDA_CONTEXTMENU_LABEL: this.getTranslation('PANDA_CONTEXTMENU_LABEL'),
						PANDA_CONTEXTMENU_ARIA_LABEL: this.getTranslation('PANDA_CONTEXTMENU_ARIA_LABEL'),
						PANDA_LISTNAV_OF: this.getTranslation('PANDA_LISTNAV_OF'),
						PANDA_LISTNAV_COUNT: this.getTranslation('PANDA_LISTNAV_COUNT'),
						PANDA_LISTNAV_SORT_BY: this.getTranslation('PANDA_LISTNAV_SORT_BY'),
						PANDA_LIST_VIEW_ACTIONS: this.getTranslation('PANDA_LIST_VIEW_ACTIONS'),
						PANDA_LISTNAV_TOTAL: this.getTranslation('PANDA_LISTNAV_TOTAL'),
						PANDA_DETAIL_VIEW_SECTION_COUNT: this.getTranslation('PANDA_DETAIL_VIEW_SECTION_COUNT'),
						PANDA_DETAIL_VIEW_SECTION_TOTAL: this.getTranslation('PANDA_DETAIL_VIEW_SECTION_TOTAL'),
						PANDA_SEARCH_INPUT_CLEAR: this.getTranslation('PANDA_SEARCH_INPUT_CLEAR'),
						PANDA_SEARCH_INPUT_LABEL: this.getTranslation('PANDA_SEARCH_INPUT_LABEL'),
						PANDA_SEARCH_RESULTS: this.getTranslation('PANDA_SEARCH_RESULTS'),
						PANDA_SEARCH_NO_RESULTS_HEADING: this.getTranslation('PANDA_SEARCH_NO_RESULTS_HEADING'),
						PANDA_SEARCH_NO_RESULTS_PARAGRAPH: replacement =>
							this.getTranslation('PANDA_SEARCH_NO_RESULTS_PARAGRAPH', replacement),
						PANDA_SEARCH_NO_RESULTS_SUBHEADING: this.getTranslation(
							'PANDA_SEARCH_NO_RESULTS_SUBHEADING'
						),
						PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1: this.getTranslation(
							'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1'
						),
						PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2: this.getTranslation(
							'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2'
						),
						PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3: this.getTranslation(
							'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3'
						),
						PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT: this.getTranslation(
							'PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT'
						),
						PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT: this.getTranslation(
							'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT'
						),
						PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX: this.getTranslation(
							'PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX'
						),
						PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL: this.getTranslation(
							'PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL'
						),
						PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL: this.getTranslation(
							'PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL'
						),
						PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL: this.getTranslation(
							'PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL'
						),
						PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX: this.getTranslation(
							'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX'
						),
						PANDA_ICON_INTERNAL_LINK: this.getTranslation('PANDA_ICON_INTERNAL_LINK'),
						PANDA_ICON_COPY: this.getTranslation('PANDA_ICON_COPY'),
						PANDA_ICON_CLOSE: this.getTranslation('PANDA_ICON_CLOSE'),
						PANDA_ICON_DELETE: this.getTranslation('PANDA_ICON_DELETE'),
						PANDA_ICON_DOWNLOAD: this.getTranslation('PANDA_ICON_DOWNLOAD'),
						PANDA_ICON_EXTERNAL_LINK: this.getTranslation('PANDA_ICON_EXTERNAL_LINK'),
						PANDA_ICON_FILE: this.getTranslation('PANDA_ICON_FILE'),
						PANDA_ICON_INFO: this.getTranslation('PANDA_ICON_INFO'),
						PANDA_ICON_MORE: this.getTranslation('PANDA_ICON_MORE'),
						PANDA_ICON_ADD: this.getTranslation('PANDA_ICON_ADD'),
						PANDA_ICON_SEARCH: this.getTranslation('PANDA_ICON_SEARCH'),
						PANDA_ICON_EDIT: this.getTranslation('PANDA_ICON_EDIT'),
						PANDA_ICON_EXPORT: this.getTranslation('PANDA_ICON_EXPORT'),
						PANDA_ICON_BACK: this.getTranslation('PANDA_ICON_BACK'),
						PANDA_ICON_NEXT: this.getTranslation('PANDA_ICON_NEXT'),
						PANDA_ICON_SHOW_PASSWORD: this.getTranslation('PANDA_ICON_SHOW_PASSWORD'),
						PANDA_ICON_HIDE_PASSWORD: this.getTranslation('PANDA_ICON_HIDE_PASSWORD'),
						PANDA_SIDEBAR_NAVIGATION_LANDMARK: this.getTranslation(
							'PANDA_SIDEBAR_NAVIGATION_LANDMARK'
						),
						PANDA_AVATAR_FROM: this.getTranslation('PANDA_AVATAR_FROM'),
						PANDA_AVATAR_REMAINING_COUNT: this.getTranslation('PANDA_AVATAR_REMAINING_COUNT'),
						PANDA_PASSWORD_INPUT_SHOWN: this.getTranslation('PANDA_PASSWORD_INPUT_SHOWN'),
						PANDA_PASSWORD_INPUT_HIDDEN: this.getTranslation('PANDA_PASSWORD_INPUT_HIDDEN'),
						PANDA_SELECT_PLACEHOLDER: this.getTranslation('PLACEHOLDER_SELECT_AN_OPTION'),
						PANDA_TITLE_SUFFIX: this.getTranslation('PANDA_TITLE_SUFFIX'),
						PANDA_INPUT_OPTIONAL: this.getTranslation('PANDA_INPUT_OPTIONAL'),
						PANDA_COMBOBOX_OVERFLOW: overflow =>
							this.getTranslation('PANDA_COMBOBOX_OVERFLOW', overflow),
					},
					portalRoot: this.portalRoot,
				}}
			>
				{this.props.children}
			</PandaContext.Provider>
		);
	}
}

export default connect(mapStateToProps)(PandaProvider);
