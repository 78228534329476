import { handleActions } from '../..';
import { UsersState } from './types';

import * as actions from './actions';

const initialState: UsersState = {
	fetching: false,
	fetched: false,
	items: [],
	itemsLanguage: {},
	fetchedLanguageForUser: [],
	fetchingLanguageForUser: [],
	itemsEmailChangeRequests: {},
	fetchedEmailChangeRequestForUser: [],
	fetchingEmailChangeRequestForUser: [],
};

export default handleActions<UsersState, PossibleActions<typeof actions>>(
	{
		USERS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		USERS_FETCH_SUCCESS: (state, { payload }) => {
			payload.items.sort((a, b) => {
				const left = `${a.firstname} ${a.lastname}`;
				const right = `${b.firstname} ${b.lastname}`;

				return left.localeCompare(right);
			});

			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload.items,
			};
		},
		USERS_SET_ROLE_PENDING: (state, { data }) => {
			const items = state.items.map(item => {
				if (item.id === data.userId) {
					return {
						...item,
						admin: data.isAdmin,
					};
				}

				return item;
			});

			return {
				...state,
				fetching: false,
				fetched: true,
				items,
			};
		},
		USERS_SET_LOCATION_PENDING: (state, { data }) => {
			const items = state.items.map(item => {
				if (item.id === data.userId) {
					return {
						...item,
						addressId: data.addressId,
					};
				}

				return item;
			});

			return {
				...state,
				items,
			};
		},
		USERS_SET_DEFAULT_DEVICE_PENDING: (state, { data }) => {
			const newItems = state.items.map(user => {
				if (user.id === data.userId) {
					return {
						...user,
						defaultDevice: data.deviceId,
					};
				}
				return user;
			});
			return {
				...state,
				fetching: false,
				fetched: true,
				items: newItems,
			};
		},
		USERS_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.userId),
		}),
		USERS_SET_NAME_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(user => {
				if (user.id !== data.userId) {
					return user;
				}

				return {
					...user,
					firstname: data.firstName,
					lastname: data.lastName,
				};
			}),
		}),
		USERS_SET_BUSYONBUSY_SUCCESS: (state, { data }) => {
			const items = state.items.map(item => {
				if (item.id === data.userId) {
					return {
						...item,
						busyOnBusy: data.enabled,
					};
				}

				return item;
			});

			return {
				...state,
				items,
			};
		},
		USERS_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
			fetched: true,
			fetching: false,
		}),
		USERS_CREATE_PENDING: state => ({
			...state,
			fetched: false,
			fetching: true,
		}),
		USERS_LANGUAGE_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingLanguageForUser: [
				...state.fetchingLanguageForUser.filter(userId => userId !== data.userId),
				data.userId,
			],
		}),
		USERS_LANGUAGE_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				itemsLanguage: {
					...state.itemsLanguage,
					[data.userId]: payload.language,
				},
				fetchingLanguageForUser: state.fetchingLanguageForUser.filter(
					userId => userId !== data.userId
				),
				fetchedLanguageForUser: [
					...state.fetchedLanguageForUser.filter(userId => userId !== data.userId),
					data.userId,
				],
			};
		},
		USERS_EMAIL_CHANGE_REQUEST_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingEmailChangeRequestForUser: [
				...state.fetchingLanguageForUser.filter(userId => userId !== data.userId),
				data.userId,
			],
		}),
		USERS_EMAIL_CHANGE_REQUEST_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				itemsEmailChangeRequests: {
					...state.itemsEmailChangeRequests,
					[data.userId]: payload,
				},
				fetchingEmailChangeRequestForUser: state.fetchingEmailChangeRequestForUser.filter(
					userId => userId !== data.userId
				),
				fetchedEmailChangeRequestForUser: [
					...state.fetchedEmailChangeRequestForUser.filter(userId => userId !== data.userId),
					data.userId,
				],
			};
		},
	},
	initialState
);
