/* eslint-disable class-methods-use-this */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import { DropdownOpener } from './DropdownOpener';
import { Dropdown, DropdownPosition, DropdownSize } from './Dropdown';

export type VerticalOpeningPosition = 'Top' | 'Bottom';
export type HorizontalOpeningPosition = 'Left' | 'Right' | 'Center';

const GAP = 8;
const GAP_WINDOW = 8;

interface Props {
	openerId?: string;
	open: boolean;
	opener: React.ReactElement<{
		ref: React.Ref<HTMLElement>;
		onClick: React.MouseEventHandler;
		id: string;
	}>;
	onOpen: (focus: 'first' | 'last') => void;
	onClose: () => void;
	verticalOpeningPosition?: VerticalOpeningPosition;
	horizontalOpeningPosition?: HorizontalOpeningPosition;
	children: React.ReactNode;
}

interface State {
	position: DropdownPosition;
}

/**
 * A combination of e.g. a button and a dropdown.
 * Opens the dropdown based on the openers position.
 */
export class DropdownWithOpener extends React.PureComponent<Props, State> {
	public state: State = {
		position: {
			top: 0,
			right: 0,
		},
	};

	private openerRef = React.createRef<DropdownOpener>();

	private dropdownRef = React.createRef<Dropdown>();

	public componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.open && !prevProps.open) {
			this.updateDropdownDimensions();
		}

		if (!this.props.open && prevProps.open) {
			this.openerRef.current?.focus();
		}
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	private onResize = () => {
		this.updateDropdownDimensions();
	};

	private updateDropdownDimensions = () => {
		if (!this.openerRef.current || !this.dropdownRef.current) {
			return;
		}

		const size = this.dropdownRef.current.calculateSize();
		const position = this.openerRef.current.calculateDropdownPosition();

		if (position) {
			this.setState({ position: this.adjustPosition(position, size) });
		}
	};

	private adjustPosition(position: DropdownPosition, size: DropdownSize | null) {
		const adjustedPosition = {
			...position,
		};

		if ('top' in adjustedPosition) {
			adjustedPosition.top += GAP;
		} else if ('bottom' in adjustedPosition) {
			adjustedPosition.bottom -= GAP;
		}

		return this.shiftIntoViewPort(adjustedPosition, size);
	}

	private shiftIntoViewPort(position: DropdownPosition, size: DropdownSize | null) {
		if (
			!size ||
			size.height + GAP_WINDOW >= document.documentElement.clientHeight ||
			size.width + GAP_WINDOW >= document.documentElement.clientWidth
		) {
			return position;
		}

		const shiftedPosition: DropdownPosition = {
			...position,
		};

		if ('top' in shiftedPosition) {
			if (shiftedPosition.top < GAP_WINDOW) {
				shiftedPosition.top = GAP_WINDOW;
			}

			if (shiftedPosition.top + size.height - GAP_WINDOW > document.documentElement.clientHeight) {
				shiftedPosition.top = document.documentElement.clientHeight - size.height - GAP_WINDOW;
			}
		} else if ('bottom' in shiftedPosition) {
			if (shiftedPosition.bottom < size.height + GAP_WINDOW) {
				shiftedPosition.bottom = size.height + GAP_WINDOW;
			}

			if (shiftedPosition.bottom - GAP_WINDOW > document.documentElement.clientHeight) {
				shiftedPosition.bottom = document.documentElement.clientHeight - GAP_WINDOW;
			}
		}

		if ('left' in shiftedPosition && !('right' in shiftedPosition)) {
			if (shiftedPosition.left < GAP_WINDOW) {
				shiftedPosition.left = GAP_WINDOW;
			}

			if (shiftedPosition.left + size.width - GAP_WINDOW > document.documentElement.clientWidth) {
				shiftedPosition.left = document.documentElement.clientWidth - size.width - GAP_WINDOW;
			}
		} else if ('right' in shiftedPosition && !('left' in shiftedPosition)) {
			if (shiftedPosition.right < size.width + GAP_WINDOW) {
				shiftedPosition.right = size.width + GAP_WINDOW;
			}

			if (shiftedPosition.right - GAP_WINDOW > document.documentElement.clientWidth) {
				shiftedPosition.right = document.documentElement.clientWidth - GAP_WINDOW;
			}
		}

		return shiftedPosition;
	}

	public render() {
		return (
			<>
				<DropdownOpener
					id={this.props.openerId}
					ref={this.openerRef}
					verticalOpeningPosition={this.props.verticalOpeningPosition ?? 'Bottom'}
					horizontalOpeningPosition={this.props.horizontalOpeningPosition ?? 'Right'}
					opened={this.props.open}
					onOpen={this.props.onOpen}
					onClose={this.props.onClose}
				>
					{this.props.opener}
				</DropdownOpener>
				<Dropdown
					ref={this.dropdownRef}
					open={this.props.open}
					onClose={this.props.onClose}
					position={this.state.position}
				>
					{this.props.children}
				</Dropdown>
			</>
		);
	}
}
