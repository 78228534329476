import React from 'react';
import classnames from 'classnames';
import { useDisabled } from '../../contexts/disabledContext';

export type Props = {
	children: string;
	critical?: boolean;
	onClick: () => void;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs#disabled-context) */
	disabled?: boolean;
};

const styles = {
	listItem: classnames('min-w-96', 'w-full'),
	button: (isCritical: boolean) =>
		classnames(
			'bg-white',
			'cursor-pointer',
			'disabled:bg-gray-75',
			'disabled:cursor-not-allowed',
			'disabled:text-gray-400',
			'duration-150',
			'ease-in-out',
			'focus:outline-none',
			'focus-visible:outline-none',
			'font-brand',
			'font-normal',
			'text-base/24',
			'h-full',
			'px-16',
			'py-12',
			'select-none',
			'text-left',
			'transition',
			'w-full',
			'whitespace-nowrap',
			!isCritical && [
				'text-gray-800',
				'hover:bg-blue-25',
				'hover:text-blue-800',
				'active:bg-blue-50',
				'active:text-blue-900',
				'focus-visible:ring',
				'focus-visible:ring-inset',
				'focus-visible:ring-color-focus',
			],
			isCritical && [
				'text-red-500',
				'hover:bg-red-25',
				'hover:text-red-600',
				'active:bg-red-50',
				'active:text-red-700',
				'focus-visible:ring',
				'focus-visible:ring-inset',
				'focus-visible:ring-color-focus',
			]
		),
};

const MenuItem = ({ onClick, children, critical = false, disabled: disabledProp }: Props) => {
	const disabled = useDisabled(disabledProp);

	return (
		<li role="menuitem" className={styles.listItem} aria-disabled={disabled}>
			<button
				className={styles.button(critical)}
				type="button"
				disabled={disabled}
				onClick={onClick}
				tabIndex={-1}
			>
				{children}
			</button>
		</li>
	);
};

export { MenuItem };
