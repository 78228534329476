import { DateTime } from 'luxon';
import { Product, ProductsState } from '../../../redux/modules/products';
import { TrunkContingentProductsState } from '../../../redux/modules/trunkContingentProducts';
import { TrunkProduct, TrunkProductsState } from '../../../redux/modules/trunkProducts';
import { TrunkContract } from '../../../redux/modules/trunkContracts';
import { BookingChangeType } from './types';

export const calculateNetPrices = (product: Product, domain?: string) => {
	const factor = domain === 'sipgate.co.uk' ? 1.21 : 1.19;
	return {
		...product,
		recurringFee: product.recurringFee / factor,
		nonRecurringFee: product.nonRecurringFee / factor,
	};
};

export const formatDate = (date: DateTime, locale: string) => {
	return date.toLocaleString(
		{ year: 'numeric', month: '2-digit', day: '2-digit' },
		{ locale: locale.replace('_', '-') }
	);
};
export const getFirstOfNextMonth = (now: DateTime) => {
	return now.plus({ months: 1 }).startOf('month');
};
export const getLastOfThisMonth = (now: DateTime) => {
	return now.endOf('month');
};
export const getCurrency = (domain: string) => {
	return domain === 'sipgate.co.uk' ? '£' : '€';
};

export const getRecurringFeeSum = (products: Product[]) => {
	return products.reduce((sum, product) => sum + product.recurringFee, 0);
};

export const getTrunkProductsWithNettoPrice = (
	productWithBrutto: TrunkContingentProductsState | TrunkProductsState,
	domain?: string
) => {
	return productWithBrutto.items.map(product => {
		return calculateNetPrices(product, domain);
	});
};

export const getProductsWithNetPrice = (productWithBrutto: Product[], domain?: string) => {
	return productWithBrutto.map(product => {
		return calculateNetPrices(product, domain);
	});
};
export const getProductsStateWithNetPrices = (
	productWithBrutto: ProductsState,
	domain?: string
) => {
	return {
		...productWithBrutto,
		items: productWithBrutto.items.map(item => {
			return {
				...item,
				products: item.products.map(product => {
					return calculateNetPrices(product, domain);
				}),
			};
		}),
	};
};

export const mapContractToProduct = (contract: TrunkContract) => {
	return {
		...contract,
		id: contract.productId.toString(),
		name: contract.productName,
		legacy: false,
	} as TrunkProduct;
};

export const getBookingChangeTypeString = (bookingType: BookingChangeType) => {
	switch (bookingType) {
		case BookingChangeType.DOWNGRADE:
			return 'Downgrade';
		case BookingChangeType.UPGRADE:
			return 'Upgrade';
		case BookingChangeType.CANCELLATION:
			return 'Cancellation';
		case BookingChangeType.NONE:
			return 'Initial Booking';
		default:
			return 'Unknown';
	}
};
