import moment, { Moment } from 'moment';
import { ListResponse, SipgateApi } from '../SipgateApi';
import { Contact } from '../types/contacts';
import { VoicemailDestination } from '../types/forwardings';
import { ApiPresenceSubscription } from '../types/presence';
import { ApiProductType } from '../types/products';
import { ApiSimIntendedUse } from '../types/sim';
import { HttpClient } from './HttpClient';
import { DeviceProperty } from '../types/DeviceProperty';

import {
	ApiBase64Upload,
	ApiBusinessType,
	ApiBusinessVerification,
} from '../types/identityVerification';
import { ApiDateBasedSet } from '../types/dateBasedSets';
import { AppBookingStatus } from '../types/AppBookingStatus';
import {
	ApiCreateAddressRequest,
	ApiDeleteAddressRequest,
	ApiEditAddressRequest,
	ApiMoveAddressRequest,
} from '../types/addresses';
import { ApiDomainState } from '../types/domainVerification';
import { ApiSsoConfiguration } from '../types/ssoConfiguration';
import {
	SsoConfigurationCreateReduxAction,
	SsoConfigurationUpdateReduxAction,
} from '../../redux/modules/ssoConfiguration';
import { AcdAudioFileType } from '../../redux/modules/acdAudioFiles';
import { ApiUser } from '../types/users';
import { ApiCreatePhonebookRequest } from '../types/phonebook';
import { ApiTimeBasedSet } from '../types/timeBasedSets';
import { ApiTimeBasedForwardings } from '../types/timeBasedForwardings';

export class RestApiClient implements SipgateApi {
	private http: HttpClient;

	public constructor(http: HttpClient) {
		this.http = http;
	}

	public getAvailablePresenceSubscriptions = () =>
		this.http.get('/v3', '/presence/availableSubscriptions');

	public getSubscriptionKeyForPresenceSubscriptions = (subscriptions: ApiPresenceSubscription[]) =>
		this.http.post('/v3', '/presence/subscribe', {
			callthroughs: subscriptions.map(s => s.extensionSipid),
		});

	public getMicrosoftTeamsFqdn = () => this.http.get('/v3', '/microsoft-teams/fqdn');

	public getMicrosoftTeamsRoutingConfiguration = (extensionId: string) =>
		this.http.get('/v3', `/microsoft-teams/routing-configuration/${extensionId}`);

	public setMicrosoftTeamsRoutingConfiguration = (
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) =>
		this.http.post('/v3', `/microsoft-teams/routing-configuration/`, {
			extension: extensionId,
			phoneNumbers,
			sipDomain,
			sipPassword,
		});

	public syncMicrosoftTeamsRoutingConfiguration = (
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) =>
		this.http.put('/v3', `/microsoft-teams/routing-configuration/`, {
			extension: extensionId,
			phoneNumbers,
			sipDomain,
			sipPassword,
		});

	public deleteMicrosoftTeamsRoutingConfiguration = (extensionId: string) =>
		this.http.delete('/v3', `/microsoft-teams/routing-configuration/${extensionId}`);

	public createMicrosoftTeamsFqdn = () => this.http.post('/v3', '/microsoft-teams/fqdn');

	public createUnlinkedDevice = (alias: string) =>
		this.http.post('/v3', '/devices/register', { alias, owner: null });

	public getCustomerProducts = () => this.http.get('/v3', '/contracts');

	public createUnlinkedMobileDevice = (alias: string) =>
		this.http.post('/v3', '/devices/mobile', { alias, owner: null });

	public setMicrosoftTeamsFqdnTxtRecord = (fqdn: string, txtRecord: string) =>
		this.http.post('/v3', '/microsoft-teams/fqdn/txt', { fqdn, txtRecord });

	public updateMicrosoftTeamsFqdnTxtRecord = (fqdn: string, txtRecord: string) =>
		this.http.put('/v3', '/microsoft-teams/fqdn/txt', { fqdn, txtRecord });

	public deleteMicrosoftTeamsFqdnTxtRecord = () =>
		this.http.delete('/v3', '/microsoft-teams/fqdn/txt');

	public getMicrosoftTeamsFqdnTxtRecord = () => this.http.get('/v3', '/microsoft-teams/fqdn/txt');

	public cancellable = (abortSignal: AbortSignal) =>
		new RestApiClient(this.http.cancellable(abortSignal));

	public getTranslations = (locale: string) =>
		this.http.get('/v2', `/translations/${locale}`, { unauthenticated: true });

	public getAddresses = () => this.http.get('/v3', '/new-addresses');

	public lookupGbAddresses = (zip: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);
		params.append('country', 'GB');

		return this.http.get('/v3', `/signup/addresses?${params.toString()}`);
	};

	public createAddress = (request: ApiCreateAddressRequest) =>
		this.http.post('/v3', '/new-addresses', request);

	public moveAddress = (id: number, request: ApiMoveAddressRequest) => {
		if (request.countryCode === 'DE') {
			return this.http.post('/v3', '/new-addresses', {
				countryCode: request.countryCode,
				street: request.street,
				number: request.number,
				additional: request.additional,
				city: request.city,
				zip: request.zip,
				relocation: {
					sourceAddress: id,
					date: request.date.toISODate(),
				},
			});
		}

		if (request.countryCode === 'US') {
			return this.http.post('/v3', '/new-addresses', {
				countryCode: request.countryCode,
				additional: request.additional,
				address: request.address,
				city: request.city,
				zip: request.zip,
				state: request.state,
				relocation: {
					sourceAddress: id,
					date: request.date.toISODate(),
				},
			});
		}

		return this.http.post('/v3', '/new-addresses', {
			countryCode: request.countryCode,
			additional: request.additional,
			address: request.address,
			city: request.city,
			zip: request.zip,
			relocation: {
				sourceAddress: id,
				date: request.date.toISODate(),
			},
		});
	};

	public editAddress = (id: number, request: ApiEditAddressRequest) =>
		this.http.put('/v3', `/new-addresses/${id}`, request);

	public deleteAddress = (id: number, request: ApiDeleteAddressRequest) =>
		this.http.delete('/v3', `/new-addresses/${id}`, request);

	public getPhonebookEntry = (addressId: number) =>
		this.http.get('/v3', `/new-addresses/${encodeURIComponent(addressId)}/phonebook`);

	public createPhonebookEntry = (addressId: number, request: ApiCreatePhonebookRequest) =>
		this.http.post('/v3', `/new-addresses/${encodeURIComponent(addressId)}/phonebook`, request);

	public updatePhonebookEntry = (addressId: number, request: ApiCreatePhonebookRequest) =>
		this.http.put('/v3', `/new-addresses/${encodeURIComponent(addressId)}/phonebook`, request);

	public deletePhonebookEntry = (addressId: number) =>
		this.http.delete('/v3', `/new-addresses/${encodeURIComponent(addressId)}/phonebook`);

	public getBusinessTypes = () => this.http.get('/v3', '/business-types');

	public getBusinessSuffixes = () => this.http.get('/v3', '/business-suffixes');

	public getGermanCitiesByZip = (zip: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);

		return this.http.get('/v3', `/address-lookup/de/cities?${params.toString()}`);
	};

	public getGermanStreetsByZipAndCity = (zip: string, city: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);
		params.append('city', city);

		return this.http.get('/v3', `/address-lookup/de/streets?${params.toString()}`);
	};

	public getGermanAreaCode = (zip: string, city: string, street: string, number: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);
		params.append('city', city);
		params.append('street', street);
		params.append('number', number);

		return this.http
			.get('/v3', `/address-lookup/de/area-code?${params.toString()}`)
			.then(response => response.areaCode);
	};

	public getFaxlines = () => this.http.get('/v3', `/fax`);

	public getCallRestrictions = () => this.http.get('/v2', `/callrestrictions`);

	public setCallRestrictions = (userId: string, restriction: string, enabled: boolean) =>
		this.http.post('/v2', `/${userId}/callrestrictions/${restriction}`, { enabled });

	public getTrunkCallRestrictions = () => {
		return this.http.get('/v3', '/callrestrictions/trunks');
	};

	public setTrunkCallRestrictions = (trunkId: string, restriction: string, enabled: boolean) =>
		this.http.put('/v3', `/callrestrictions/trunk/${trunkId}/${restriction}`, { enabled });

	public setFaxlineAlias = (faxlineId: string, alias: string) =>
		this.http.put('/v3', `/fax/${faxlineId}/alias`, { alias });

	public setFaxlineTagline = (faxlineId: string, tagline: string) =>
		this.http.put('/v3', `/fax/${faxlineId}/tagline`, {
			tagline,
		});

	public createFaxline = (ownerId: string, alias: string) =>
		this.http.post('/v3', `/fax`, { ownerId, alias });

	public deleteFaxline = (faxlineId: string) => this.http.delete('/v3', `/fax/${faxlineId}`);

	public setFaxlineCallerId = (faxlineId: string, callerId: string, anonymous: boolean) =>
		this.http.put('/v3', `/fax/${faxlineId}/callerId`, {
			callerId,
			anonymous,
		});

	public getPhonelines = (userId: string) => this.http.get('/v2', `/${userId}/phonelines`);

	public getPhoneline = (userId: string, phonelineId: string) =>
		this.http.get('/v2', `/${userId}/phonelines/${phonelineId}`);

	public createPhoneline = (userId: string) => this.http.post('/v2', `/${userId}/phonelines`);

	public deletePhoneline = (userId: string, phonelineId: string) =>
		this.http.delete('/v2', `/${userId}/phonelines/${phonelineId}`);

	public setPhonelineAlias = (userId: string, phonelineId: string, alias: string) =>
		this.http.put('/v2', `/${userId}/phonelines/${phonelineId}`, { alias });

	public createPhonelineDevice = (userId: string, phonelineId: string, deviceId: string) =>
		this.http.post('/v2', `/${userId}/phonelines/${phonelineId}/devices`, {
			deviceId,
		});

	public deletePhonelineDevice = (userId: string, phonelineId: string, deviceId: string) =>
		this.http.delete('/v2', `/${userId}/phonelines/${phonelineId}/devices/${deviceId}`);

	public setTranscription = (voicemailId: string, enabled: boolean) =>
		this.http.put('/v3', `/voicemails/${voicemailId}/transcription`, {
			enabled,
		});

	public getUser = (userId: string) => this.http.get('/v2', `/users/${userId}`);

	public deleteUser = (userId: string) => this.http.delete('/v2', `/users/${userId}`);

	public createUser(
		firstName: string,
		lastName: string,
		email: string,
		isAdmin: boolean,
		locationId: number
	): Promise<ApiUser> {
		return this.http.post(
			'/v3',
			'/users',
			{
				firstName,
				lastName,
				email,
				isAdmin,
				locationId,
			},
			'follow'
		);
	}

	public setDefaultDevice = (userId: string, deviceId: string) =>
		this.http.put('/v2', `/users/${userId}/defaultdevice`, { deviceId });

	public getUsers = () => this.http.get('/v2', '/users/');

	public setUserRole = (userId: string, isAdmin: boolean) =>
		this.http.put('/v2', `/users/${userId}/role`, { admin: isAdmin });

	public setUserName = (userId: string, firstName: string, lastName: string) =>
		this.http.put('/v3', `/users/${encodeURIComponent(userId)}/name`, { firstName, lastName });

	public setUserEmail = (userId: string, email: string) =>
		this.http.post('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`, {
			newEmail: email,
		});

	public getUserEmailChangeRequest = (userId: string) =>
		this.http.get('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`);

	public cancelUserEmailChangeRequest = (userId: string) =>
		this.http.delete('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`);

	public setUserLanguage = (userId: string, language: string) =>
		this.http.put('/v3', `/users/${encodeURIComponent(userId)}/settings/language`, { language });

	public getUserLanguage = (userId: string) =>
		this.http.get('/v3', `/users/${encodeURIComponent(userId)}/settings/language`);

	public setUserLocation = (userId: string, addressId: string) =>
		this.http.put('/v2', `/users/${userId}/location`, { addressId });

	public setUserBusyOnBusy(userId: string, enabled: boolean): Promise<void> {
		return this.http.put('/v2', `/users/${userId}/busyonbusy`, { enabled });
	}

	public setSmsSim = (deviceId: string, simExtension: string) =>
		this.http.put('/v3', `/devices/${deviceId}/smsSim`, { simExtension });

	public getAudioFiles = (ownerId: string) => {
		return this.http.get('/v3', `/audioFiles?ownerId=${ownerId}`);
	};

	public activateAudioFile = (
		userId: string,
		phonelineId: string,
		voicemailId: string,
		audioFileId: string
	) =>
		this.http.put(
			'/v2',
			`/${userId}/phonelines/${phonelineId}/voicemails/${voicemailId}/greetings/${audioFileId}`,
			{
				active: true,
			}
		);

	public createAudioFile = (ownerId: string, fileName: string, base64Content: string) =>
		this.http.post('/v3', `/audioFiles`, {
			fileName,
			base64Content,
			ownerId,
		});

	public getAudioFileContent = (audioFileId: string) =>
		this.http.get('/v3', `/audioFiles/${audioFileId}`, { mimeType: 'audio/mpeg' });

	public deleteAudioFile = (audioFileId: string) =>
		this.http.delete('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}`);

	public setAudioFileAlias = (audioFileId: string, alias: string) =>
		this.http.put('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}/alias`, { alias });

	public setActiveAudioFileVoicemail = (voicemailId: string, audioFileId: string | null) =>
		this.http.put('/v3', `/voicemails/${voicemailId}/activeGreeting`, { greetingId: audioFileId });

	public setActiveAudioFileGreetingForGroup = (groupId: string, audioFileId: string | null) =>
		this.http.put('/v3', `/groups/${groupId}/greeting/activeAudioFile`, { audioFileId });

	public setActiveAudioFileGreetingForConferenceRoom = (
		conferenceRoomId: string,
		audioFileId: string | null
	) => this.http.put('/v3', `/conferenceroom/${conferenceRoomId}/greeting`, { audioFileId });

	public setActiveAudioFileCallQueue = (groupId: string, audioFileId: string | null) =>
		this.http.put('/v3', `/groups/${groupId}/callqueue/activeAudioFile`, { audioFileId });

	public setAudioFileRecording = (audioFileId: string, active: boolean) =>
		this.http.put('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}/recording`, { active });

	public getContracts = (deviceId: string) =>
		this.http.get('/v3', `/devices/${deviceId}/contracts`);

	public createContract = (deviceId: string, productId: string, acceptedTacId?: string) =>
		this.http.post('/v3', `/devices/${deviceId}/contracts`, { productId, acceptedTacId });

	public revokeContractCancellation = (deviceId: string, contractId: string) => {
		return this.http.delete('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`);
	};

	public cancelContract = (deviceId: string, contractId: string, cancellationDate: Date | null) =>
		this.http.post('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`, {
			cancellationDate: cancellationDate?.toISOString(),
		});

	public setDeviceSettings = (deviceId: string, dnd: boolean, emergencyAddressId?: string) =>
		this.http.put('/v2', `/devices/${deviceId}`, { dnd, emergencyAddressId });

	public setDeviceAlias = (deviceId: string, alias: string) =>
		this.http.put('/v3', `/devices/${deviceId}/alias`, { value: alias });

	public resetDevicePassword = (deviceId: string) =>
		this.http.post('/v2', `/devices/${deviceId}/credentials/password`);

	public getDevices = (userId: string) => {
		let path = '/devices';

		if (userId) {
			path += `?userId=${userId}`;
		}

		return this.http.get('/v3', path);
	};

	public createDevice = (userId: string, type: string, alias?: string) =>
		this.http.post('/v2', `/${userId}/devices`, { type, alias });

	public createDeviceExternal = (userId: string, alias: string, number: string) =>
		this.http.post('/v2', `/${userId}/devices/external`, { alias, number });

	public deleteDevice = (deviceId: string) => this.http.delete('/v2', `/devices/${deviceId}`);

	public createTrunkContract = (productId: number, alias: string, addressId?: number) => {
		return this.http.post('/v3', `/trunks/contracts/`, { productId, alias, addressId });
	};

	public changeTrunkContract = (productId: number, replacesCustomerProductId: number) => {
		return this.http.put('/v3', `/trunks/contracts/`, { productId, replacesCustomerProductId });
	};

	public getTrunkContracts = () => this.http.get('/v3', `/trunks/contracts`);

	public getTrunks = () => this.http.get('/v3', `/trunks`);

	public getTrunkContingentContracts = (trunkId: string) =>
		this.http.get('/v3', `/trunks/${trunkId}/contracts`);

	public cancelTrunkContingentContract = (
		trunkId: string,
		contractId: string,
		cancellationDate: Date | null
	) =>
		this.http.post('/v3', `/devices/${trunkId}/contracts/${contractId}/cancellation`, {
			cancellationDate: cancellationDate?.toISOString(),
		});

	public revokeTrunkContingentContractCancellation = (trunkId: string, contractId: string) => {
		return this.http.delete('/v3', `/devices/${trunkId}/contracts/${contractId}/cancellation`);
	};

	public cancelTrunkContract = (contractId: number) => {
		return this.http.post('/v3', `/trunks/contracts/cancellation`, {
			customerProductId: contractId,
		});
	};

	public revokeTrunkContractCancellation = (contractId: number) => {
		return this.http.delete('/v3', `/trunks/contracts/cancellation`, {
			customerProductId: contractId,
		});
	};

	public getTrunkDevices = (trunkId: string) =>
		this.http.get('/v3', `/trunks/${encodeURIComponent(trunkId)}/devices`);

	public setTrunkCallerId = (trunkId: string, callerId: number) =>
		this.http.put('/v3', `/trunks/${encodeURIComponent(trunkId)}/fallbackCallerId`, {
			callerId,
		});

	public setTrunkEmergencyLocation = (trunkId: string, addressId: string) =>
		this.http.put('/v3', `/trunks/${encodeURIComponent(trunkId)}/emergencyAddressId`, {
			addressId,
		});

	public renameTrunk = (trunkId: string, alias: string) =>
		this.http.put('/v3', `/trunks/${trunkId}/alias`, {
			value: alias,
		});

	public generateTrunkCredentialsPassword = (trunkId: string) =>
		this.http.post('/v3', `/trunks/${trunkId}/credentialsPassword`);

	public deleteTrunk = (trunkId: string) => this.http.delete('/v3', `/trunks/${trunkId}`);

	public createTrunkContingentContract = (trunkSipId: string, productId: string) =>
		this.http.post('/v3', `/trunks/bookMinutes`, {
			productId,
			trunkSipId,
		});

	public getTacs = () => this.http.get('/v2', '/app/tacs');

	public getTacsForProduct = (productId: string) =>
		this.http.get('/v3', `/tacs?productId=${productId}`);

	public fetchLinks = () => this.http.get('/v2', '/app/links');

	public getHistory = (args: {
		connectionIds?: string[];
		types?: string[];
		directions?: string[];
		status?: string[];
		limit?: number;
		directory?: string[];
		offset?: number;
		starred?: string[];
		from?: Moment | null;
		to?: Moment | null;
		labelIds?: number[];
		read?: string[];
		phonenumber?: string;
	}) => {
		const {
			connectionIds = [],
			types = [],
			directions = [],
			status = [],
			limit,
			directory = [],
			offset,
			starred = [],
			from,
			to,
			labelIds = [],
			read = [],
			phonenumber,
		} = args;

		let url = `/events`;
		if (typeof limit !== 'undefined') {
			url += `?limit=${encodeURIComponent(limit.toString(10))}`;
		}
		if (typeof offset !== 'undefined') {
			url += `&offset=${encodeURIComponent(offset.toString(10))}`;
		}
		url += this.getDataDStartAndEndDateParameterString(from, to);
		if (typeof phonenumber !== 'undefined') {
			url += `&phonenumber=${encodeURIComponent(phonenumber)}`;
		}

		url += types.reduce((joined, type) => `${joined}&types=${encodeURIComponent(type)}`, '');
		url += directions.reduce(
			(joined, direction) => `${joined}&directions=${encodeURIComponent(direction)}`,
			''
		);
		url += status.reduce(
			(joined, statusEntity) => `${joined}&status=${encodeURIComponent(statusEntity)}`,
			''
		);
		url += connectionIds.reduce(
			(joined, connectionId) => `${joined}&connectionIds=${encodeURIComponent(connectionId)}`,
			''
		);
		url += starred.reduce(
			(joined, starredEntity) => `${joined}&starred=${encodeURIComponent(starredEntity)}`,
			''
		);
		url += directory.reduce(
			(joined, directoryEntity) => `${joined}&directories=${encodeURIComponent(directoryEntity)}`,
			''
		);

		url += read.reduce(
			(joined, readState) => `${joined}&read=${encodeURIComponent(readState)}`,
			''
		);

		url += labelIds.reduce(
			(joined, labelId) => `${joined}&labelIds=${encodeURIComponent(labelId.toString(10))}`,
			''
		);

		return this.http.get('/v3', url);
	};

	public exportFilteredHistory = (args: {
		connectionIds?: string[];
		types?: string[];
		directions?: string[];
		status?: string[];
		directory?: string[];
		starred?: string[];
		from?: Moment | null;
		to?: Moment | null;
		labelIds?: number[];
		read?: string[];
		phonenumber?: string;
	}) => {
		const {
			connectionIds = [],
			types = [],
			directions = [],
			status = [],
			directory = [],
			starred = [],
			from,
			to,
			labelIds = [],
			read = [],
			phonenumber,
		} = args;

		let url = `/events?limit=${encodeURIComponent(1000)}`;

		url += this.getDataDStartAndEndDateParameterString(from, to);
		if (typeof phonenumber !== 'undefined') {
			url += `&phonenumber=${encodeURIComponent(phonenumber)}`;
		}

		url += types.reduce((joined, type) => `${joined}&types=${encodeURIComponent(type)}`, '');
		url += directions.reduce(
			(joined, direction) => `${joined}&directions=${encodeURIComponent(direction)}`,
			''
		);
		url += status.reduce(
			(joined, statusEntity) => `${joined}&status=${encodeURIComponent(statusEntity)}`,
			''
		);
		url += connectionIds.reduce(
			(joined, connectionId) => `${joined}&connectionIds=${encodeURIComponent(connectionId)}`,
			''
		);
		url += starred.reduce(
			(joined, starredEntity) => `${joined}&starred=${encodeURIComponent(starredEntity)}`,
			''
		);
		url += directory.reduce(
			(joined, directoryEntity) => `${joined}&directories=${encodeURIComponent(directoryEntity)}`,
			''
		);

		url += read.reduce(
			(joined, readState) => `${joined}&read=${encodeURIComponent(readState)}`,
			''
		);

		url += labelIds.reduce(
			(joined, labelId) => `${joined}&labelIds=${encodeURIComponent(labelId.toString(10))}`,
			''
		);

		return this.http.get('/v3', url, {
			mimeType: 'text/csv',
		});
	};

	public updateHistoryEntries = (
		entries: { id: string; archived?: boolean; read?: boolean; starred?: boolean }[]
	) => this.http.put('/v2', `/history`, entries);

	public updateHistoryEntry = (
		entryId: string,
		options: { archived?: boolean; read?: boolean; note?: string; starred?: boolean }
	) => this.http.put('/v2', `/history/${entryId}`, options);

	public deleteHistoryEntries = (ids: string[]) =>
		this.http.delete(
			'/v2',
			`/history?${ids
				.map(encodeURIComponent)
				.map(encodedId => `id=${encodedId}`)
				.join('&')}`
		);

	public transcribeVoicemail = (eventId: string) =>
		this.http.post('/v3', `/events/${eventId}/transcribe`);

	public getEvents = () => this.http.get('/v2', '/app/events');

	public deleteEvent = (id: string) => this.http.delete('/v2', `/app/events/${id}`);

	public getAppProperties = () => this.http.get('/v2', '/app/properties');

	public getDeviceCallerId = (deviceId: string) =>
		this.http.get('/v2', `/devices/${deviceId}/callerid`);

	public setDeviceCallerId = (deviceId: string, callerId: string) =>
		this.http.put('/v2', `/devices/${deviceId}/callerid`, {
			value: callerId,
		});

	public getTariffAnnouncement = (deviceId: string) =>
		this.http.get('/v2', `/devices/${deviceId}/tariffannouncement`);

	public setTariffAnnouncement = (deviceId: string, enabled: boolean) =>
		this.http.put('/v2', `/devices/${deviceId}/tariffannouncement`, { enabled });

	public getNumbers = (userId: string) => this.http.get('/v2', `/${userId}/numbers`);

	public getAllNumbers = () => this.http.get('/v2', `/numbers`);

	public setNumberSettings = (
		numberId: string,
		endpointId: string | null,
		releaseForMnp: boolean,
		isQuickDial: boolean
	) =>
		this.http.put('/v2', `/numbers/${numberId}`, {
			endpointId,
			releaseForMnp,
			quickDial: isQuickDial,
		});

	public fetchSsoConfiguration(): Promise<ApiSsoConfiguration> {
		return this.http.get('/v3', '/sso-configuration/config');
	}

	public createSsoConfiguration(
		ssoConfiguration: SsoConfigurationCreateReduxAction
	): Promise<ApiSsoConfiguration> {
		return this.http.post('/v3', '/sso-configuration/config', ssoConfiguration);
	}

	public updateSsoConfiguration(
		ssoConfiguration: SsoConfigurationUpdateReduxAction
	): Promise<ApiSsoConfiguration> {
		return this.http.patch('/v3', '/sso-configuration/config', ssoConfiguration);
	}

	public getPortings = () => this.http.get('/v2', '/portings');

	public revokePorting = (portingId: string) => this.http.delete('/v2', `/portings/${portingId}`);

	public hangupCall = (sessionId: string) => this.http.delete('/v2', `/calls/${sessionId}`);

	public initiateClickToDial = (caller: string, callee: string) =>
		this.http.post('/v2', '/sessions/calls', { caller, callee });

	public initiateClickToRecord = (deviceId: string, targetId: string) =>
		this.http.post('/v3', '/sessions/voicemail/recording', { deviceId, targetId });

	public initiateClickToRecordIvr = (deviceId: string, targetId: string, endpoint: string) =>
		this.http.post('/v3', '/sessions/ivr/recording', { deviceId, targetId, endpoint });

	public initiateClickToRecordCallthrough = (deviceId: string, targetId: string) =>
		this.http.post('/v3', '/sessions/callthrough/recording', { deviceId, targetId });

	public initiateClickToPlay = (deviceId: string, datadId: string) =>
		this.http.post('/v2', '/sessions/voicemail/play', { deviceId, datadId });

	public getPhonelineBlockAnonymous = (userId: string, phonelineId: string) =>
		this.http.get('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`);

	public setPhonelineBlockAnonymous = (
		userId: string,
		phonelineId: string,
		enabled: boolean,
		target: string
	) =>
		this.http.put('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`, {
			enabled,
			target,
		});

	public getContacts = (options?: { limit: number; offset: number }) => {
		const params = new URLSearchParams();

		if (options) {
			params.append('limit', options.limit.toString(10));
			params.append('offset', options.offset.toString(10));
		}

		return this.http.get('/v2', `/contacts?${params.toString()}`);
	};

	public getContactsByPhonenumbers = (phonenumbers: string[]) => {
		let path = '/contacts';

		if (Array.isArray(phonenumbers)) {
			path += `?${phonenumbers.map(phonenumber => `phonenumbers=${phonenumber}`).join('&')}`;
		}

		return this.http.get('/v2', path);
	};

	public getInternalContacts = () => this.http.get('/v2', '/contacts/internal');

	public deleteContact = (contactId: string) => this.http.delete('/v2', `/contacts/${contactId}`);

	public deleteAllContacts = () => this.http.delete('/v2', '/contacts');

	public importContactsFromCSV = (base64Content: string) =>
		this.http.post('/v2', '/contacts/import/csv', { base64Content });

	public importContactsFromGoogle = (token: string) =>
		this.http.post('/v2', '/contacts/import/google', { token });

	public fetchSms = (userId: string) => this.http.get('/v2', `/${userId}/sms`);

	public createSms = (userId: string) => this.http.post('/v3', `/sms`, { userId });

	public deleteSms = (smsId: string) => this.http.delete('/v3', `/sms/${smsId}`);

	public setSmsAlias = (userId: string, smsId: string, alias: string) =>
		this.http.put('/v2', `/${userId}/sms/${smsId}`, { alias });

	public fetchSmsCallerIds = (userId: string, smsId: string) =>
		this.http.get('/v2', `/${userId}/sms/${smsId}/callerids`);

	public createSmsCallerId = (userId: string, smsId: string, phonenumber: string) =>
		this.http.post('/v2', `/${userId}/sms/${smsId}/callerids`, { phonenumber });

	public verifySmsCallerId = (
		userId: string,
		smsId: string,
		callerId: number,
		verificationCode: string
	) =>
		this.http.post('/v2', `/${userId}/sms/${smsId}/callerids/${callerId}/verification`, {
			verificationCode,
		});

	public setActiveSmsCallerId = (
		userId: string,
		smsId: string,
		callerId: number,
		defaultNumber: boolean
	) =>
		this.http.put('/v2', `/${userId}/sms/${smsId}/callerids/${callerId}`, {
			defaultNumber,
		});

	public sendFax = (
		faxlineId: string,
		recipient: string,
		filename: string,
		base64Content: string
	) =>
		this.http.post('/v2', '/sessions/fax', {
			faxlineId,
			recipient,
			filename,
			base64Content,
		});

	public resendFax = (faxlineId: string | undefined, faxId: string) =>
		this.http.post('/v2', '/sessions/fax/resend', { faxlineId, faxId });

	public sendSms = (smsId: string, recipient: string, message: string, sendAt?: number) => {
		let options: { smsId: string; recipient: string; message: string; sendAt?: number } = {
			smsId,
			recipient,
			message,
		};

		if (typeof sendAt !== 'undefined') {
			options = { ...options, sendAt };
		}

		return this.http.post('/v2', '/sessions/sms', options);
	};

	public getIdentityVerification = (allowStart: boolean) => {
		let url = '/identityVerification';

		if (allowStart) {
			url += `?allowStart=true`;
		}

		return this.http.get('/v2', url);
	};

	public getIdentityVerificationBusinessTypes = (): Promise<ListResponse<ApiBusinessType>> => {
		return this.http.get('/v3', '/identity-verification/businesstype');
	};

	public startIdentityVerificationUpload = (
		businessTypeId: string,
		addressId: number,
		files: ApiBase64Upload[]
	): Promise<void> => {
		const request = { businessTypeId, addressId, files };
		return this.http.post('/v3', '/identity-verification/identity/verify', request);
	};

	public getIdentityVerificationForAddress = (
		addressId: string,
		limit = 10
	): Promise<ListResponse<ApiBusinessVerification>> =>
		this.http.get(
			'/v3',
			`/identity-verification/verification?filter=address.addressId:${addressId}&sort=created:DESC&limit=${limit}`
		);

	public getAccount = () => this.http.get('/v2', '/account');

	public uploadLogo = (request: ApiBase64Upload): Promise<void> => {
		return this.http.post('/v3', `/account/logo`, { logo: request });
	};

	public deleteLogo = () => this.http.delete('/v3', '/account/logo');

	public verifyAccount = (verificationCode: string) =>
		this.http.put('/v2', '/account/verified', { verificationCode });

	public getBalance = () => this.http.get('/v2', '/balance');

	public getNotifications = (userId: string) => this.http.get('/v2', `/${userId}/notifications`);

	public deleteNotification = (userId: string, notificationId: string) =>
		this.http.delete('/v2', `/${userId}/notifications/${notificationId}`);

	public createVoicemailEmailNotification = (userId: string, voicemailId: string, email: string) =>
		this.http.post('/v2', `/${userId}/notifications/voicemail/email`, {
			voicemailId,
			email,
		});

	public createVoicemailSmsNotification = (userId: string, voicemailId: string, number: string) =>
		this.http.post('/v2', `/${userId}/notifications/voicemail/sms`, {
			voicemailId,
			number,
		});

	public createFaxEmailNotification = (
		userId: string,
		faxlineId: string,
		email: string,
		direction: string
	) =>
		this.http.post('/v2', `/${userId}/notifications/fax/email`, {
			faxlineId,
			email,
			direction,
		});

	public createFaxSmsNotification = (
		userId: string,
		faxlineId: string,
		number: string,
		direction: string
	) =>
		this.http.post('/v2', `/${userId}/notifications/fax/sms`, {
			faxlineId,
			number,
			direction,
		});

	public createCallEmailNotification = (
		userId: string,
		endpointId: string,
		email: string,
		direction: string,
		cause: string
	) =>
		this.http.post('/v2', `/${userId}/notifications/call/email`, {
			endpointId,
			email,
			direction,
			cause,
		});

	public createCallSmsNotification = (
		userId: string,
		endpointId: string,
		number: string,
		direction: string,
		cause: string
	) =>
		this.http.post('/v2', `/${userId}/notifications/call/sms`, {
			endpointId,
			number,
			direction,
			cause,
		});

	public createFaxReportNotification = (userId: string, faxlineId: string, email: string) =>
		this.http.post('/v2', `/${userId}/notifications/fax/report`, { faxlineId, email });

	public createSmsEmailNotification = (userId: string, endpointId: string, email: string) =>
		this.http.post('/v2', `/${userId}/notifications/sms/email`, { endpointId, email });

	public fetchRestrictions = (userId?: string, restrictions?: string[]) => {
		const params = new URLSearchParams();

		if (typeof userId === 'string') {
			params.append('userId', userId);
		}

		if (Array.isArray(restrictions)) {
			for (const restriction of restrictions) {
				params.append('restriction', restriction);
			}
		}

		return this.http.get('/v2', `/restrictions?${params.toString()}`);
	};

	public getUserInfo = () => this.http.get('/v2', '/authorization/userinfo');

	public resetUserPassword = (username: string) =>
		this.http.post('/v2', '/passwordreset', { username });

	public getTwoFactorAuthenticationSetting = async (webuserId: string) =>
		this.http.get(
			'/v3',
			`/authorization/two-factor-authentication/settings/${encodeURIComponent(webuserId)}`
		);

	public getLocalprefix = (deviceId: string) =>
		this.http.get('/v2', `/devices/${deviceId}/localprefix`);

	public setLocalprefix = (deviceId: string, localprefix: string, active: boolean) =>
		this.http.put('/v2', `/devices/${deviceId}/localprefix`, {
			value: localprefix,
			active,
		});

	public isSimAllowed = (deviceId: string) =>
		this.http.get('/v3', `/devices/${deviceId}/sim/allowed`);

	public activateSim = (
		userId: string,
		deviceId: string,
		simId: string,
		intendedUse: ApiSimIntendedUse,
		alias: string
	) => this.http.post('/v3', `/devices/${deviceId}/sim`, { simId, intendedUse, alias });

	public changeSim = (
		userId: string,
		deviceId: string,
		id: string,
		simId: string,
		intendedUse: ApiSimIntendedUse,
		alias: string
	) => this.http.put('/v3', `/devices/${deviceId}/sim/${id}`, { simId, intendedUse, alias });

	public setPendingSim = (
		deviceId: string,
		iccid: string,
		intendedUse: ApiSimIntendedUse,
		mobileExtension?: string,
		alias?: string
	) =>
		this.http.put('/v3', `/devices/${deviceId}/pending-sim`, {
			iccid,
			intendedUse,
			alias,
			mobileExtension,
		});

	public setIntendedUse = (
		deviceId: string,
		simExtensionId: string,
		intendedUse: ApiSimIntendedUse
	) =>
		this.http.put('/v3', `/devices/${deviceId}/sim/${simExtensionId}/intendedUse`, {
			value: intendedUse,
		});

	public deactivateSim = (userId: string, deviceId: string) =>
		this.http.delete('/v2', `/${userId}/devices/${deviceId}/sim`);

	public getContingents = (userId: string, deviceId: string) =>
		this.http.get('/v2', `/${userId}/devices/${deviceId}/contingents`);

	public getDataUsage = (
		containerId: string,
		dateStart: string,
		dateStop: string,
		intervalMinutes: string
	) =>
		this.http.get(
			'/v3',
			`/devices/${containerId}/contingents/usage?dateStart=${dateStart}&dateStop=${dateStop}&intervalMinutes=${intervalMinutes}`
		);

	public getDataUsageAggregation = (containerId: string, months: string[]) =>
		this.http.get(
			'/v3',
			`/devices/${containerId}/contingents/usage/aggregation?${months
				.map(month => `months=${month}`)
				.join('&')}`
		);

	public getDataUsageSettings = (containerId: string) =>
		this.http.get('/v3', `/devices/${containerId}/contingents/usage/settings`);

	public setDataUsageSettings = (containerId: string, persistenceLevel: string) =>
		this.http.put('/v3', `/devices/${containerId}/contingents/usage/settings`, {
			persistenceLevel,
		});

	public orderSim = (userId: string, deviceId: string, addressId: string) =>
		this.http.post('/v2', `/${userId}/devices/${deviceId}/sim/orders`, { addressId });

	public getSingleRowDisplay = (deviceId: string) =>
		this.http.get('/v2', `/devices/${deviceId}/singlerowdisplay`);

	public setSingleRowDisplay = (deviceId: string, enabled: boolean) =>
		this.http.put('/v2', `/devices/${deviceId}/singlerowdisplay`, { enabled });

	public setTargetNumberForExternalDevice = (deviceId: string, number: string) =>
		this.http.put('/v2', `/devices/${deviceId}/external/targetnumber`, { number });

	public setIncomingCallDisplayForExternalDevice = (
		deviceId: string,
		incomingCallDisplay: string
	) =>
		this.http.put('/v2', `/devices/${deviceId}/external/incomingcalldisplay`, {
			incomingCallDisplay,
		});

	public getGroups = () => {
		const url = '/groups';
		return this.http.get('/v3', url);
	};

	public getDomainVerifications = () => {
		const url = '/domain-verification/domains';
		return this.http.get('/v3', url);
	};

	public createDomainVerification(domain: string): Promise<ApiDomainState> {
		const url = '/domain-verification/domains';
		return this.http.post('/v3', url, { domain });
	}

	public getDomainVerification(domainId: string): Promise<ApiDomainState> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.http.get('/v3', url);
	}

	public deleteDomainVerification(domainId: string): Promise<void> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.http.delete('/v3', url);
	}

	public resetDomainVerification(domainId: string): Promise<void> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.http.patch('/v3', url, { status: 'pending' });
	}

	public createGroup = (alias: string) => this.http.post('/v3', '/groups', { alias });

	public deleteGroup = (groupId: string) => this.http.delete('/v3', `/groups/${groupId}`);

	public setGroupAlias = (groupId: string, alias: string) =>
		this.http.put('/v3', `/groups/${groupId}/alias`, { value: alias });

	public setGroupMembers = (groupId: string, memberIds: string[]) =>
		this.http.put('/v3', `/groups/${groupId}/members`, { memberIds });

	public getGroupNumbers = (groupId: string) => this.http.get('/v2', `/groups/${groupId}/numbers`);

	public createGroupDevice = (groupId: string, deviceId: string) =>
		this.http.post('/v2', `/groups/${groupId}/devices`, { deviceId });

	public deleteGroupDevice = (groupId: string, deviceId: string) =>
		this.http.delete('/v2', `/groups/${groupId}/devices/${deviceId}`);

	public getGroupStatistics = (groupId: string) =>
		this.http.get('/v3', `/groups/${groupId}/statistics`);

	public getGroupStatisticsHistory = (groupId: string) =>
		this.http.get('/v3', `/groups/${groupId}/statistics/history`);

	public getGroupStatisticsCsv = (groupId: string, month?: string) =>
		this.http.get('/v3', `/groups/${groupId}/statistics/export${month ? `?month=${month}` : ``}`, {
			mimeType: 'text/csv',
		});

	public bookGroupStatisticsContract = (groupId: string) =>
		this.http.post('/v3', `/groups/${groupId}/contracts/statistics`, { tacAccepted: true });

	public createGroupGreeting = (groupId: string) =>
		this.http.post('/v3', `/groups/${groupId}/greeting`);

	public deleteGroupGreeting = (groupId: string) =>
		this.http.delete('/v3', `/groups/${groupId}/greeting`);

	public createCallQueue = (groupId: string) =>
		this.http.post('/v3', `/groups/${groupId}/callqueue`);

	public deleteCallQueue = (groupId: string) =>
		this.http.delete('/v3', `/groups/${groupId}/callqueue`);

	public validateQuickDialNumbers = (quickDialNumber: string) =>
		this.http.get('/v2', `/numbers/quickdial/validation/${quickDialNumber}`);

	public createQuickDialNumber = (userId: string, number: string) =>
		this.http.post('/v2', '/numbers/quickdial', { userId, number });

	public setQuickDialNumber = (userId: string, numberId: string, number: string) =>
		this.http.put('/v2', `/numbers/quickdial/${numberId}`, { userId, number });

	public deleteQuickDialNumber = (numberId: string) =>
		this.http.delete('/v2', `/numbers/quickdial/${numberId}`);

	public getIncomingBlacklistEntries = () => this.http.get('/v2', '/blacklist/incoming');

	public createIncomingBlacklistEntry = (phoneNumber: string, isBlock?: boolean) =>
		this.http.post('/v2', '/blacklist/incoming', {
			phoneNumber,
			isBlock: typeof isBlock === 'undefined' ? false : isBlock,
		});

	public deleteIncomingBlacklistEntry = (phoneNumber: string) =>
		this.http.delete('/v2', `/blacklist/incoming/${phoneNumber}`);

	public addProvisionedPhone = (macAddress: string, webuserId?: string, syncContacts?: boolean) =>
		this.http.post('/v2', '/phoneprovisioning', { macAddress, webuserId, syncContacts });

	public deleteProvisionedPhone = (macAddress: string) =>
		this.http.delete('/v2', `/phoneprovisioning/${macAddress}`);

	public updateSnomConfig = (configuration: string, macAddress: string) =>
		this.http.put('/v2', '/phoneprovisioning/configuration', {
			configuration,
			macAddress,
		});

	public setSnomWebuser = (macAddress: string, webuserExtension: string | null) =>
		this.http.put('/v2', '/phoneprovisioning/webuser', { macAddress, webuserExtension });

	public getProvisionedPhones = () => this.http.get('/v2', '/phoneprovisioning');

	public setSyncContacts = (macAddress: string, syncContacts: boolean) =>
		this.http.put('/v2', '/phoneprovisioning/sync-contacts', { macAddress, syncContacts });

	public getAutoRecordingSetting = (deviceId: string) =>
		this.http.get('/v2', `/autorecordings/${deviceId}/settings`);

	public setAutoRecordingSetting = (deviceId: string, active: boolean) =>
		this.http.put('/v2', `/autorecordings/${deviceId}/settings`, { active });

	public createContact = (contact: Omit<Contact, 'id'>) =>
		this.http.post('/v2', `/contacts`, contact);

	public updateContact = (contact: Contact) =>
		this.http.put('/v2', `/contacts/${contact.id}`, contact);

	public createVoicemail = (ownerId: string) =>
		this.http.post('/v3', `/voicemails?ownerId=${encodeURIComponent(ownerId)}`);

	public getVoicemails = (userId?: string) => {
		const path = userId ? `/voicemails?userId=${encodeURIComponent(userId)}` : '/voicemails';

		return this.http.get('/v3', path);
	};

	public setVoicemailAlias = (voicemailId: string, alias: string) =>
		this.http.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/alias`, { alias });

	public setVoicemailPin = (userId: string, voicemailId: string, pin: string) =>
		this.http.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/pin`, { pin, userId });

	public deleteVoicemailPin = (userId: string, voicemailId: string) =>
		this.http.delete('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/pin`, { userId });

	public setVoicemailAuthorizedNumbers = (
		userId: string,
		voicemailId: string,
		authorizedNumbers: string[]
	) =>
		this.http.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/authorizedNumbers`, {
			authorizedNumbers,
			userId,
		});

	public fetchLabels = () => this.http.get('/v3', '/labels');

	public attachLabel = (labelId: string, eventIds: string[]) =>
		this.http.post('/v3', '/events/batch-attach-label', { labelId, eventIds });

	public detachLabel = (labelId: string, eventIds: string[]) =>
		this.http.post('/v3', '/events/batch-detach-label', { labelId, eventIds });

	public createLabel = (name: string) => this.http.post('/v3', '/labels', { name });

	public editLabel = (id: number, newName: string) =>
		this.http.put('/v3', `/labels/${id}`, { name: newName });

	public deleteLabel = (id: number) => this.http.delete('/v3', `/labels/${id}`);

	public getLpaData = (deviceId: string) => {
		return this.http.get('/v3', `/devices/${deviceId}/esim`);
	};

	public getHolidayPresets = () => this.http.get('/v3', '/routing/date-based/presets');

	public getDateBasedSets = (extensionId: string) =>
		this.http.get('/v3', `/routing/${extensionId}/date-sets`);

	public getDateBasedForwarding = (extensionId: string, dateSetId: string) =>
		this.http.get('/v3', `/routing/${extensionId}/date-sets/${dateSetId}/forwarding`);

	public saveDateBasedSets = (extensionId: string, dateBasedSets: ApiDateBasedSet[]) =>
		this.http.put('/v3', `/routing/${extensionId}/date-sets`, {
			items: dateBasedSets,
		});

	public saveDateBasedForwarding = (
		extensionId: string,
		dateSetId: string,
		destination: VoicemailDestination
	) =>
		this.http.put('/v3', `/routing/${extensionId}/date-sets/${dateSetId}/forwarding`, {
			...destination,
		});

	public getTimeBasedSets = (extensionId: string) =>
		this.http.get('/v3', `/routing/${extensionId}/time-sets`);

	public getTimeBasedForwardings = (extensionId: string, timeSetId: string) =>
		this.http.get('/v3', `/routing/${extensionId}/time-sets/${timeSetId}/forwardings`);

	public saveTimeBasedSets = (extensionId: string, timeBasedSets: ApiTimeBasedSet[]) =>
		this.http.put('/v3', `/routing/${extensionId}/time-sets`, { items: timeBasedSets });

	public saveTimeBasedForwardings = (
		extensionId: string,
		timeSetId: string,
		forwardings: ApiTimeBasedForwardings
	) =>
		this.http.put('/v3', `/routing/${extensionId}/time-sets/${timeSetId}/forwardings`, forwardings);

	public getProducts = (type?: ApiProductType) => this.http.get('/v3', `/products?type=${type}`);

	public getProduct = (productId: string) => this.http.get('/v3', `/products/${productId}`);

	public isProductAllowed = (productId: string) =>
		this.http.get('/v3', `/products/${productId}/allowed`);

	public getProductOptions = (productId: string) =>
		this.http.get('/v3', `/products/${productId}/options`);

	/** DeviceProperties * */

	public getDeviceProperties = (deviceId: string) =>
		this.http.get('/v3', `/devices/${deviceId}/properties`);

	public getDeviceProperty = (deviceId: string, key: string) =>
		this.http.get('/v3', `/devices/${deviceId}/properties/${key}`);

	public setDeviceProperty = (property: DeviceProperty) =>
		this.http.put('/v3', `/devices/${property.deviceId}/properties`, {
			key: property.key,
			value: property.value,
		});

	public getPersonalAccessTokens = (webuserId: string) =>
		this.http.get('/v3', `/authorization/personal-access-token/${webuserId}`);

	public deletePersonalAccessToken = (tokenId: string) =>
		this.http.delete('/v3', `/authorization/personal-access-token/${tokenId}`);

	public createPersonalAccessToken = (webuserId: string, tokenName: string, scopes: string[]) =>
		this.http.post('/v3', `/authorization/personal-access-token/${webuserId}`, {
			tokenName,
			scopes,
		});

	public getScopes = () => this.http.get('/v3', `/authorization/scopes/`);

	public getDataAutomation = (containerId: string) =>
		this.http.get('/v3', `/devices/${containerId}/contingents/automatic`);

	public getDataAutomationHistory = (containerId: string) =>
		this.http.get('/v3', `/devices/${containerId}/contingents/automatic/history`);

	public setDataAutomation = (containerId: string, productId: number, limit?: number) =>
		this.http.put('/v3', `/devices/${containerId}/contingents/automatic`, { productId, limit });

	public deleteDataAutomation = (containerId: string) =>
		this.http.delete('/v3', `/devices/${containerId}/contingents/automatic`);

	public getAccountCancellationStatus = () => this.http.get('/v3', '/account/cancellation');

	public cancelAccount = (holder: string, iban: string) =>
		this.http.post('/v3', '/account/cancellation', {
			holder,
			iban,
		});

	public revokeAccountCancellation = () => this.http.delete('/v3', '/account/cancellation');

	private getDataDStartAndEndDateParameterString = (
		startDate?: Moment | null,
		endDate?: Moment | null
	) => {
		const formattedDates = this.formatStartAndEndDateForDataD(startDate, endDate);
		let url = '';

		if (typeof formattedDates.from !== 'undefined') {
			url += `&from=${encodeURIComponent(formattedDates.from)}`;
		}
		if (typeof formattedDates.to !== 'undefined') {
			url += `&to=${encodeURIComponent(formattedDates.to)}`;
		}

		return url;
	};

	private formatStartAndEndDateForDataD = (from?: Moment | null, to?: Moment | null) => {
		let beginningOfStartDay;
		let endOfEndDay;

		if (from && typeof from !== 'undefined') {
			beginningOfStartDay = from
				.clone()
				/* datepicker defaults to noon (12:00) */
				.hours(0)
				.format('YYYY-MM-DDTHH:mm:ssZ');

			if (to && typeof to !== 'undefined') {
				endOfEndDay = to
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(23)
					.minutes(59)
					.seconds(59)
					.format('YYYY-MM-DDTHH:mm:ssZ');
			} else {
				/* User picked a single day so we use the end of that day */
				endOfEndDay = from
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(0)
					.add(1, 'day')
					.format('YYYY-MM-DDTHH:mm:ssZ');
			}
		}

		return {
			from: beginningOfStartDay,
			to: endOfEndDay,
		};
	};

	public createConferenceRoom = () => this.http.post('/v3', '/conferenceroom');

	public getConferenceRooms = () => this.http.get('/v3', '/conferenceroom');

	public renameConferenceRoom = (conferenceRoomId: string, alias: string) =>
		this.http.put('/v3', `/conferenceroom/${conferenceRoomId}/alias`, {
			value: alias,
		});

	public deleteConferenceRoom = (conferenceRoomId: string) =>
		this.http.delete('/v3', `/conferenceroom/${conferenceRoomId}`);

	public createConferenceRoomGreeting = (conferenceRoomId: string) =>
		this.http.post('/v3', `/conferenceroom/${conferenceRoomId}/greeting`);

	public deleteConferenceRoomGreeting = (conferenceRoomId: string) =>
		this.http.delete('/v3', `/conferenceroom/${conferenceRoomId}/greeting`);

	public getMobileInfo = () => this.http.get('/v3', '/mobile');

	public getMobileInfoMessages = (containerId: string) =>
		this.http.get('/v3', `/mobile/messages/${encodeURIComponent(containerId)}`);

	public getIvrs = () => this.http.get('/v3', '/ivr');

	public addTrunkRouting = (trunkId: string, phoneNumberIds: string[]) =>
		this.http.put('/v3', `/trunks/${encodeURIComponent(trunkId)}/numbers`, { phoneNumberIds });

	public removeTrunkRouting = (trunkId: string, phoneNumberIds: string[]) =>
		this.http.delete('/v3', `/trunks/${encodeURIComponent(trunkId)}/numbers`, { phoneNumberIds });

	public updatePassword = (webuserId: string, password: string) =>
		this.http.post('/v3', `/users/${webuserId}/password`, { password });

	public disablePassword = (webuserId: string, reason: string) =>
		this.http.delete('/v3', `/users/${webuserId}/password?reason=${reason}`);

	public getUsersAvatars = () => this.http.get('/v3', '/users/avatars');

	public getUserAvatarForWebuser = (webuserId: string) =>
		this.http.get('/v3', `/users/${webuserId}/avatars`);

	public getAppBookings = (webuserId: string, bookingStatus: AppBookingStatus) => {
		const queryString = bookingStatus ? `?bookingStatus=${bookingStatus.toUpperCase()}` : '';
		return this.http.get('/v3', `/app/${webuserId}/bookings${queryString}`);
	};

	public getAppBookingsForAccount = (bookingStatus: AppBookingStatus) => {
		const queryString = bookingStatus ? `?bookingStatus=${bookingStatus.toUpperCase()}` : '';
		return this.http.get('/v3', `/app/bookings${queryString}`);
	};

	public createAppBookings = (webuserIds: Set<string>, subscriptionName: string) => {
		const bookingOrders = [...webuserIds].map(webuserId => ({
			userId: webuserId,
			subscriptionName,
		}));

		return this.http.post('/v3', `/app/bookings`, {
			tacAccepted: true,
			bookingOrders,
		});
	};

	public cancelAppBooking = (
		webuserId: string,
		bookingId: string,
		terminationDate: moment.Moment
	) => {
		return this.http.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			terminationDate: terminationDate.format('YYYY-MM-DD'),
		});
	};

	public cancelAppTrial = (webuserId: string, trialId: string, isTrialCancelled: boolean) => {
		return this.http.put('/v3', `/app/${webuserId}/bookings/${trialId}`, {
			isTrialCancelled,
		});
	};

	public revokeCancellation = (webuserId: string, bookingId: string) => {
		return this.http.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			terminationDate: null,
		});
	};

	public revokeTrialCancellation = (webuserId: string, bookingId: string) => {
		return this.http.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			isTrialCancelled: false,
		});
	};

	public getAppSubscriptionTypes = () => {
		return this.http.get('/v3', '/app/subscriptionTypes');
	};

	public setFancyCodec = (deviceId: string, enabled: boolean) =>
		this.http.put('/v3', `/devices/${encodeURIComponent(deviceId)}/fancyCodec`, { enabled });

	public getAcds = () => this.http.get('/v3', '/acds');

	public createAcd = (name: string, phoneNumberIds: number[], agentIds: string[]) =>
		this.http.post('/v3', '/acds', { name, phoneNumberIds, agentIds });

	public setAcdNumbers = (acdId: string, phoneNumberIds: number[]) =>
		this.http.put('/v3', `/acds/${acdId}/phoneNumbers`, { phoneNumberIds });

	public setAcdAgents = (acdId: string, agentIds: string[]) =>
		this.http.put('/v3', `/acds/${acdId}/agents`, { agentIds });

	public setAcdAgentDevices = (acdId: string, agentId: string, deviceIds: string[]) =>
		this.http.put('/v3', `/acds/${acdId}/agents/${agentId}/devices`, { deviceIds });

	public setAcdName = (acdId: string, name: string) =>
		this.http.put('/v3', `/acds/${acdId}/name`, { name });

	public deleteAcd = (acdId: string) => this.http.delete('/v3', `/acds/${acdId}`);

	public setAcdQueueWaitingAudioFile = (acdId: string, audioFileId: string) =>
		this.http.put('/v3', `/acds/${acdId}/settings/queue/waitingAudioFile`, { audioFileId });

	public setAcdGreetingAudioFile = (acdId: string, audioFileId: string | null) =>
		this.http.put('/v3', `/acds/${acdId}/settings/greetingAudioFile`, { audioFileId });

	public setAcdFollowUpTime = (acdId: string, followUpTime: number) =>
		this.http.put('/v3', `/acds/${acdId}/settings/agents/followUpTime`, { followUpTime });

	public setAcdRingTime = (acdId: string, ringTime: number) =>
		this.http.put('/v3', `/acds/${acdId}/settings/agents/ringTime`, { ringTime });

	public getAcdAudioFiles = (acdId: string) => this.http.get('/v3', `/acds/${acdId}/audioFiles`);

	public createAcdAudioFile = (
		acdId: string,
		fileName: string,
		base64Content: string,
		type: AcdAudioFileType
	) =>
		this.http.post('/v3', `/acds/${acdId}/audioFiles`, {
			fileName,
			base64Content,
			type,
		});

	public setAcdAudioFileAlias = (acdId: string, audioFileId: string, alias: string) =>
		this.http.put('/v3', `/acds/${acdId}/audioFiles/${encodeURIComponent(audioFileId)}/alias`, {
			alias,
		});

	public deleteAcdAudioFile = (acdId: string, audioFileId: string) =>
		this.http.delete('/v3', `/acds/${acdId}/audioFiles/${encodeURIComponent(audioFileId)}`);

	public getAcdAudioFileContent = (acdId: string, audioFileId: string) =>
		this.http.get('/v3', `/acds/${acdId}/audioFiles/${audioFileId}`, { mimeType: 'audio/mpeg' });

	public activateCallRecorder = () => this.http.post('/v3', '/call-recorder');

	public deactivateCallRecorder = () => this.http.delete('/v3', '/call-recorder');

	public uploadUserAvatar = (webuserId: string, request: ApiBase64Upload): Promise<void> => {
		return this.http.post('/v3', `/users/${webuserId}/avatars`, { avatar: request });
	};

	public deleteUserAvatar = (webuserId: string): Promise<void> => {
		return this.http.delete('/v3', `/users/${webuserId}/avatars`);
	};

	public getTransferAudioFiles = () => {
		return this.http.get('/v3', `/audioFiles/account/transfer`);
	};

	public createTransferAudioFile = (fileName: string, base64Content: string) =>
		this.http.post('/v3', `/audioFiles/account/transfer`, {
			fileName,
			base64Content,
		});

	public setActiveTransferAudioFile = (audioFileId: string | null) =>
		this.http.put('/v3', `/audioFiles/account/transfer`, { audioFileId });

	public getCallRecordingAudioFiles = () => {
		return this.http.get('/v3', `/audioFiles/account/callRecording`);
	};

	public setActiveCallRecordingAudioFile = (audioFileId: string | null) =>
		this.http.put('/v3', `/audioFiles/account/callRecording`, { audioFileId });

	public createCallRecordingAudioFile = (fileName: string, base64Content: string) =>
		this.http.post('/v3', `/audioFiles/account/callRecording`, {
			fileName,
			base64Content,
		});

	public orderMultipleSim = (
		amount: number,
		shippingAddress: {
			salutation: string;
			firstname: string;
			lastname: string;
			company: string;
			areaCode: string;
			street: string;
			number: string;
			zip: string;
			city: string;
			countryCode: string;
		}
	) =>
		this.http.post('/v3', '/simcards', {
			amount,
			shippingAddress,
		});

	public getFirebaseToken = () => this.http.get('/v1', '/firebase/token');
}
