export { createAction, handleActions } from './utils/actions';
export type { Immutable } from './utils/immutable';

export { Provider, connect } from './utils/wrapper';
export type { ReduxProps } from './utils/wrapper';

// Make sure an action returned by a dispatch call (e.g. calling something connected via mapDispatchToProps)
// actually triggered an API call.
export function didFetch(action: {
	type: string;
	payload?: unknown;
}): action is { type: string; payload: { promise: Promise<unknown> } } {
	const payload: object = action.payload || {};
	if (!('promise' in payload)) {
		return false;
	}

	const payloadWithPromise = payload as { promise: unknown };
	if (typeof payloadWithPromise.promise !== 'object' || payloadWithPromise.promise === null) {
		return false;
	}

	return (
		'then' in payloadWithPromise.promise && typeof payloadWithPromise.promise.then === 'function'
	);
}
