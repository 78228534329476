import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchConferenceRooms = (state: ReduxState) =>
	!state.conferenceRoom.fetched && !state.conferenceRoom.fetching;

export const createConferenceRoom = createAction('CONFERENCE_ROOM_CREATE', () => ({
	promise: () => api.createConferenceRoom(),
	restrictions: {
		restrictions: ['CAN_CREATE_CONFERENCE_ROOM'],
	},
}));

export const fetchConferenceRooms = createAction('CONFERENCE_ROOMS_FETCH', () => ({
	promise: () => api.getConferenceRooms(),
	shouldFetch: shouldFetchConferenceRooms,
	restrictions: {
		restrictions: ['CAN_CREATE_CONFERENCE_ROOM'],
	},
}));

export const forceFetchConferenceRooms = createAction('CONFERENCE_ROOMS_FETCH', () => ({
	promise: () => api.getConferenceRooms(),
	restrictions: {
		restrictions: ['CAN_CREATE_CONFERENCE_ROOM'],
	},
}));

export const renameConferenceRoom = createAction(
	'CONFERENCE_ROOM_RENAME',
	(id: string, alias: string) => ({
		promise: () => api.renameConferenceRoom(id, alias),
		data: { id, alias },
		restrictions: {
			restrictions: ['CAN_CREATE_CONFERENCE_ROOM'],
		},
	})
);

export const deleteConferenceRoom = createAction(
	'CONFERENCE_ROOM_DELETE',
	(conferenceRoomId: string) => ({
		promise: () => api.deleteConferenceRoom(conferenceRoomId),
		data: { id: conferenceRoomId },
		restrictions: {
			restrictions: ['CAN_CREATE_CONFERENCE_ROOM'],
		},
	})
);

export const createGreeting = createAction(
	'CONFERENCE_ROOM_GREETING_CREATE',
	(conferenceRoomId: string) => ({
		promise: () => api.createConferenceRoomGreeting(conferenceRoomId),
		data: {
			conferenceRoomId,
		},
	})
);

export const deleteGreeting = createAction(
	'CONFERENCE_ROOM_GREETING_DELETE',
	(conferenceRoomId: string) => ({
		promise: () => api.deleteConferenceRoomGreeting(conferenceRoomId),
		data: {
			conferenceRoomId,
		},
	})
);

export const setActiveAudioFileGreeting = createAction(
	'CONFERENCE_ROOM_ACTIVE_AUDIOFILE_GREETING_SET',
	(conferenceRoomId: string, activeAudioFileId: string | null) => ({
		promise: api.setActiveAudioFileGreetingForConferenceRoom(conferenceRoomId, activeAudioFileId),
		data: {
			conferenceRoomId,
			activeAudioFileId,
		},
	})
);
