import { handleActions } from '../..';
import { DomainState, DomainVerificationState } from './types';
import * as actions from './actions';
import { ApiDomainState } from '../../../api/types/domainVerification';

const initialState: DomainVerificationState = {
	items: [],
	fetched: false,
	fetching: false,
};

type Actions = typeof actions;

const mapToDomainVerification = (apiDomainVerification: ApiDomainState): DomainState => {
	return {
		...apiDomainVerification,
		txtRecord: `sipgate_domain_verification=${apiDomainVerification.verificationCode}`,
	};
};

export default handleActions<DomainVerificationState, PossibleActions<Actions>>(
	{
		DOMAIN_VERIFICATIONS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		DOMAIN_VERIFICATIONS_FETCH_FAILURE: state => ({ ...state, fetching: false }),
		DOMAIN_VERIFICATIONS_FETCH_SUCCESS: (state, { payload }) => {
			const newItems = [...payload.items.map(mapToDomainVerification)];
			newItems.sort((a, b) => a.domain.localeCompare(b.domain));

			return {
				...state,
				fetched: true,
				fetching: false,
				items: newItems,
			};
		},
		DOMAIN_VERIFICATIONS_CREATE_PENDING: state => ({ ...state, fetching: true }),
		DOMAIN_VERIFICATIONS_CREATE_FAILURE: state => ({ ...state, fetching: false }),
		DOMAIN_VERIFICATIONS_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetching: false,
				items: [...state.items, mapToDomainVerification(payload)],
			};
		},
		DOMAIN_VERIFICATIONS_DELETE_PENDING: state => ({ ...state, fetching: true }),
		DOMAIN_VERIFICATIONS_DELETE_FAILURE: state => ({ ...state, fetching: false }),
		DOMAIN_VERIFICATIONS_DELETE_SUCCESS: (state, payload) => {
			return {
				...state,
				fetching: false,
				items: state.items.filter(i => i.id !== payload.data.verificationId),
			};
		},
		DOMAIN_VERIFICATIONS_GET_PENDING: state => ({ ...state, fetching: true }),
		DOMAIN_VERIFICATIONS_GET_FAILURE: state => ({ ...state, fetching: false }),
		DOMAIN_VERIFICATIONS_GET_SUCCESS: (state, { payload }) => {
			if (state.items.find(s => s.id === payload.id) !== undefined) {
				return state;
			}
			return {
				...state,
				fetching: false,
				items: [...state.items, mapToDomainVerification(payload)],
			};
		},
		DOMAIN_VERIFICATIONS_RESET_PENDING: state => ({ ...state, fetching: true }),
		DOMAIN_VERIFICATIONS_RESET_FAILURE: state => ({ ...state, fetching: false }),
		DOMAIN_VERIFICATIONS_RESET_SUCCESS: (state, payload) => {
			const items = state.items.map(i => {
				if (i.id === payload.data.verificationId) {
					return {
						...i,
						status: 'PENDING',
					};
				}
				return i;
			});
			return { ...state, fetching: true, items };
		},
	},
	initialState
);
