import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchTranslations(state: ReduxState, locale: string) {
	if (state.translations.fetching) {
		return false;
	}

	if (state.translations.locale !== locale) {
		return true;
	}

	return !state.translations.fetched;
}

export const fetchTranslations = createAction(
	'TRANSLATIONS_FETCH',
	(language: string, force?: boolean) => ({
		promise: async () => api.getTranslations(language),
		shouldFetch: (state: ReduxState) => force || shouldFetchTranslations(state, language),
		data: { locale: language },
	})
);
