import { createAction } from '../..';
import api from '../../../api';
import { ChangeTrunkContractResponse, CreateTrunkContractResponse } from '../../../api/types/trunk';
import { ReduxState } from '../../types';
import { mixpanel } from '../../../third-party/mixpanel';
import { BookingChangeType } from '../../../views/trunks/dialogs/types';
import { getBookingChangeTypeString } from '../../../views/trunks/dialogs/utils';

const shouldFetchTrunkContracts = (state: ReduxState) =>
	!state.trunkContracts.fetching && !state.trunkContracts.fetched;

export const fetchTrunkContracts = createAction('TRUNK_CONTRACTS_FETCH', () => ({
	promise: () => api.getTrunkContracts(),
	shouldFetch: (state: ReduxState) => shouldFetchTrunkContracts(state),
}));

export const forceFetchTrunkContracts = createAction('TRUNK_CONTRACTS_FETCH', () => ({
	promise: api.getTrunkContracts(),
}));

export const changeTrunkContract = createAction(
	'TRUNK_CONTRACTS_CHANGE',
	(
		productId: number,
		productName: string,
		replacesCustomerProductId: number,
		bookingType: BookingChangeType,
		successCallback?: (result: ChangeTrunkContractResponse) => void,
		onError?: (error: Error) => void
	) => ({
		promise: () =>
			api
				.changeTrunkContract(productId, replacesCustomerProductId)
				.then(result => {
					if (successCallback) {
						successCallback(result);
					}
					mixpanel.track('Booked contract', {
						'Contract id': productId,
						'Contract name': productName,
						'Contract type': 'Trunking contract',
						'Contract booking type': getBookingChangeTypeString(bookingType),
					});
					return result;
				})
				.catch(error => {
					if (onError) {
						onError(error);
					}
					throw error;
				}),
		data: { productId, replacesCustomerProductId },
	})
);

export const createTrunkContract = createAction(
	'TRUNK_CONTRACTS_CREATE',
	(
		productId: number,
		productName: string,
		alias: string,
		successCallback?: (result: CreateTrunkContractResponse) => void,
		onError?: (error: Error) => void
	) => ({
		promise: api
			.createTrunkContract(productId, alias)
			.then(result => {
				if (successCallback) {
					successCallback(result);
				}
				mixpanel.track('Booked contract', {
					'Contract id': productId,
					'Contract name': productName,
					'Contract type': 'Trunking contract',
					'Contract booking type': 'Initial Booking',
				});
				return result;
			})
			.catch(error => {
				if (onError) {
					onError(error);
				}
				throw error;
			}),
		data: { productId, alias },
	})
);

export const revokeTrunkContractCancellation = createAction(
	'TRUNK_CONTRACTS_REVOKE_CANCELLATION',
	(contractId: number, successCallback?: () => void) => ({
		promise: () =>
			api.revokeTrunkContractCancellation(contractId).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { contractId },
	})
);

export const cancelTrunkContract = createAction(
	'TRUNK_CONTRACTS_CANCELLATION',
	(contractId: number, successCallback?: () => void) => ({
		promise: () =>
			api.cancelTrunkContract(contractId).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { contractId },
	})
);
