import { handleActions } from '../..';
import { AppProperties } from './types';
import * as actions from './actions';

const initialState: AppProperties = {
	identityVerificationRequired: false,
	identityVerificationRequiredForMobile: false,
	showSimRelevantOptions: false,
	showNettoPrices: false,
	fetched: false,
	fetching: false,
};

export default handleActions<AppProperties, PossibleActions<typeof actions>>(
	{
		APP_PROPERTIES_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		APP_PROPERTIES_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				...payload,
				fetched: true,
				fetching: false,
			};
		},
	},
	initialState
);
