import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchTrunkProducts = (state: ReduxState) => !state.trunkProducts.fetching;

export const fetchTrunkProducts = createAction('TRUNK_PRODUCTS_FETCH', (force?: boolean) => ({
	promise: () => api.getProducts('TRUNKING'),
	shouldFetch: (state: ReduxState) => force || shouldFetchTrunkProducts(state),
}));
