import React from 'react';
import { sprintf } from 'sprintf-js';
import { Translate } from './types';
import { Translations } from '../../../api/types/translations';
import { logger } from '../../../third-party/logger';
import { Markdown, InlineMarkdown, escapeMarkdown } from '../../../helpers/Markdown';

export const buildStringOnlyTranslate =
	(translations: Translations) =>
	(lk: string, ...replacements: unknown[]) => {
		const translated = translations[lk] || '';

		if (translated) {
			try {
				return replacements === null ? translated : sprintf(translated, ...replacements);
			} catch (e) {
				// ignore language keys for status page tour because they are not available when we have no currently active issue with our API
				if (lk !== 'STATUS_PAGE_TOUR_TITLE' && lk !== 'STATUS_PAGE_TOUR_DESCRIPTION') {
					logger.error(`Failed to render LK ${lk}:`, e);
				}
				return '';
			}
		}

		// ignore language keys for status page tour because they are not available when we have no currently active issue with our API
		if (lk !== 'STATUS_PAGE_TOUR_TITLE' && lk !== 'STATUS_PAGE_TOUR_DESCRIPTION') {
			logger.warn(`No translation found for '${lk}'`);
			return `>>${lk}<<`;
		}
		return '';
	};

export const buildTranslate = (
	simpleTranslate: (lk: string, ...args: unknown[]) => string
): Translate => {
	const translate: Translate = (...args: [string, ...unknown[]]) => simpleTranslate(...args);

	translate.markdown = {
		block: (lk, ...args) => <Markdown>{translate(lk, ...args.map(escapeMarkdown))}</Markdown>,
		inline: (lk, ...args) => (
			<InlineMarkdown>{translate(lk, ...args.map(escapeMarkdown))}</InlineMarkdown>
		),
	};

	return translate;
};
