import React from 'react';
import classnames from 'classnames';
import classes from './Tabs.module.css';

type Props = {
	label: string;
	isActive?: boolean;
	handleOnClick?: VoidFunction;
	disabled: boolean;
};

const styles = {
	listItem: classnames('flex', 'items-center', 'self-stretch'),
	outerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'group',
			'cursor-pointer',
			'focus:outline-none',
			'focus-visible:outline-none',
			'justify-center',
			'p-8',
			'whitespace-nowrap',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled && isSelected && ['bg-transparent', classes.borderSelected],
			!isDisabled && !isSelected && [classes.border, classes.borderHover, 'bg-transparent'],
			isDisabled && ['bg-transparent', 'disabled:cursor-not-allowed'],
			isDisabled && isSelected && [classes.borderDisabledSelected],
			isDisabled && !isSelected && [classes.border]
		),
	innerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'group-focus-visible:ring',
			'group-focus-visible:ring-inset',
			'px-16',
			'py-8',
			'rounded',
			'text-sm/16',
			'block',
			'duration-150',
			'ease-in-out',
			'transition',
			!isDisabled &&
				isSelected && [
					'bg-blue-75',
					'text-blue-900',
					'group-hover:text-blue-900',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-100',
					'group-active:text-black',
				],
			!isDisabled &&
				!isSelected && [
					'text-gray-800',
					'group-hover:bg-blue-50',
					'group-hover:text-blue-800',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-75',
					'group-active:text-blue-900',
				],
			isDisabled && isSelected && ['text-gray-400', 'bg-gray-75'],
			isDisabled && !isSelected && ['text-gray-400']
		),
};

const TabItem = ({ label, isActive = false, disabled, handleOnClick }: Props): JSX.Element => (
	<li className={styles.listItem} role="presentation">
		<button
			role="tab"
			aria-selected={isActive}
			type="button"
			className={styles.outerButton(isActive, disabled)}
			disabled={disabled}
			onClick={!disabled ? handleOnClick : undefined}
		>
			<span className={styles.innerButton(isActive, disabled)}>{label}</span>
		</button>
	</li>
);

export { TabItem };
