import React, { useContext } from 'react';

export const ViewContext = React.createContext<{
	/** Is this the top-level view and should it render an h1-heading? */
	mainView: boolean;
	/** Into which position is this view rendered? */
	position: 'sidebar' | 'page' | 'content';
}>({ mainView: false, position: 'content' });

export const useView = () => useContext(ViewContext);
