import { hasKey } from '../../utils/types';
import { toggleLoadingAnimation } from '../modules/loadingIndicator';

const debounceTime = 100;
const animationDuration = 1.3 * 1000;

let runningActions = 0;
let animationStart = Date.now();
let intervalId: number | undefined;

function isAction(action: unknown): action is { type: string } {
	return hasKey(action, 'type') && typeof action.type === 'string';
}

function startAction(dispatch: (action: LoadingMiddlewareEmits) => void) {
	runningActions += 1;

	if (runningActions !== 1) {
		return;
	}

	intervalId = window.setTimeout(() => {
		animationStart = Date.now();

		dispatch(toggleLoadingAnimation(true));
	}, debounceTime);
}

function completeAction(dispatch: (action: LoadingMiddlewareEmits) => void) {
	runningActions -= 1;

	if (runningActions > 0) {
		return;
	}

	window.clearTimeout(intervalId);

	const animationRuntime = Date.now() - animationStart;
	const nextAnimationEndTick = animationDuration - (animationRuntime % animationDuration);

	window.setTimeout(() => {
		dispatch(toggleLoadingAnimation(false));
	}, nextAnimationEndTick + 40);
}

export type LoadingMiddlewareNext<Action> = Action;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type LoadingMiddlewareResult<Action, NextResult> = NextResult;

export type LoadingMiddlewareEmits = ReturnType<typeof toggleLoadingAnimation>;

export const loadingAnimationMiddleware = ({
	dispatch,
}: {
	dispatch: (action: LoadingMiddlewareEmits) => void;
}) => {
	return <Action, NextResult>(next: (action: LoadingMiddlewareNext<Action>) => NextResult) =>
		(action: Action): LoadingMiddlewareResult<Action, NextResult> => {
			if (!isAction(action)) {
				return next(action);
			}

			if (action.type.endsWith('_PENDING')) {
				startAction(dispatch);
			}

			if (action.type.endsWith('_FAILURE') || action.type.endsWith('_SUCCESS')) {
				completeAction(dispatch);
			}

			return next(action);
		};
};
