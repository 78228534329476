import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchContracts = (state: ReduxState, deviceId: string) =>
	!state.contracts.fetchedForDevice.includes(deviceId) &&
	!state.contracts.fetchingForDevice.includes(deviceId);

export const fetchContracts = createAction('CONTRACTS_FETCH', (deviceId: string) => ({
	promise: () => api.getContracts(deviceId),
	shouldFetch: (state: ReduxState) => shouldFetchContracts(state, deviceId),
	data: { deviceId },
}));

export const forceFetchContracts = createAction('CONTRACTS_FETCH', (deviceId: string) => ({
	promise: api.getContracts(deviceId),
	data: { deviceId },
}));

export const createContract = createAction(
	'CONTRACTS_CREATE',
	(
		deviceId: string,
		productId: string,
		acceptedTacId?: string,
		successCallback?: () => void,
		onError?: (error: Error) => void
	) => ({
		promise: api
			.createContract(deviceId, productId, acceptedTacId)
			.then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			})
			.catch(error => {
				if (onError) {
					onError(error);
				}
				throw error;
			}),
		data: { deviceId },
	})
);

export const cancelContract = createAction(
	'CONTRACTS_CANCEL',
	(
		deviceId: string,
		contractId: string,
		cancellationDate: Date | null,
		successCallback?: () => void
	) => ({
		promise: () =>
			api.cancelContract(deviceId, contractId, cancellationDate).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { contractId },
	})
);

export const revokeContractCancellation = createAction(
	'CONTRACTS_REVOKE_CANCELLATION',
	(deviceId: string, contractId: string, successCallback?: () => void) => ({
		promise: () =>
			api.revokeContractCancellation(deviceId, contractId).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { deviceId, contractId },
	})
);

export const revokeContractDowngrade = createAction(
	'CONTRACTS_REVOKE_DOWNGRADE',
	(
		deviceId: string,
		activeContractId: string,
		futureContractId: string,
		successCallback?: () => void
	) => ({
		promise: () =>
			api.cancelContract(deviceId, futureContractId, null).then(result => {
				if (successCallback) {
					successCallback();
				}
				return result;
			}),
		data: { activeContractId, futureContractId },
	})
);
