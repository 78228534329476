import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import { DownloadLink, ExternalLink, InternalLink } from '@panda/ui';
import classes from './Markdown.scss';
import { isExternalSipgateLink, isInternalLink } from '../utils/links';

const isDownloadLink = (href: string): boolean => {
	const downloadLinkRegex = /^https?:\/\/.+\.pdf/;
	return downloadLinkRegex.test(href);
};

const isTelLink = (href: string) => {
	try {
		const url = new URL(href);

		return url.protocol === 'tel:';
	} catch {
		return false;
	}
};

const renderPandaLink = (props: { href?: string; children: ReactNode }) => {
	if (!props.href) {
		return null;
	}

	if (isDownloadLink(props.href)) {
		return (
			<DownloadLink variant="quiet" target="blank" url={props.href}>
				{props.children}
			</DownloadLink>
		);
	}

	if (isTelLink(props.href) || isInternalLink(props.href)) {
		// Markdown gives us react nodes (e.g. an array), so we need to cast it because internallink does not
		// like any non-strings but still works with them. This is going to break in the future so im not fixing
		// this warning. As its actually broken. Good luck.
		return <InternalLink to={props.href}>{props.children as any}</InternalLink>;
	}

	if (isExternalSipgateLink(props.href)) {
		return (
			<ExternalLink to={props.href} target="blank">
				{
					// Markdown gives us react nodes (e.g. an array), so we need to cast it because internallink does not
					// like any non-strings but still works with them. This is going to break in the future so im not fixing
					// this warning. As its actually broken. Good luck.
					props.children as any
				}
			</ExternalLink>
		);
	}

	return (
		<ExternalLink variant="quiet" secure target="blank" to={props.href}>
			{
				// Markdown gives us react nodes (e.g. an array), so we need to cast it because internallink does not
				// like any non-strings but still works with them. This is going to break in the future so im not fixing
				// this warning. As its actually broken. Good luck.
				props.children as any
			}
		</ExternalLink>
	);
};

export const escapeMarkdown = (input: string) =>
	input.replace(/[\\`*{}[\]()#+\-.!_>]/g, '\\$&').trim();

interface Props {
	children: string;
}

/**
 * A markdown component which does not output paragraphs and
 * can therefore be used to render things into spans, links,
 * etc.
 */
export const InlineMarkdown = (props: Props) => (
	<ReactMarkdown
		unwrapDisallowed
		components={{
			a: renderPandaLink,
			p: ({ children }) => children,
		}}
	>
		{props.children}
	</ReactMarkdown>
);

/**
 * A markdown component which outputs paragraphs as a proper
 * markdown renderer should :)
 */
export const Markdown = (props: Props) => (
	<ReactMarkdown
		unwrapDisallowed
		components={{
			a: renderPandaLink,
		}}
	>
		{props.children}
	</ReactMarkdown>
);

/** @deprecated Please either use `Markdown` or `InlineMarkdown` components */
export const DeprecatedMarkdown = (props: { source?: string }) => (
	<ReactMarkdown
		unwrapDisallowed
		includeElementIndex
		components={{
			a: renderPandaLink,
			p: (nodeProps: { children: ReactNode; index?: number }) =>
				nodeProps.index === 0 ? (
					nodeProps.children
				) : (
					<div className={classes.pTag}>{nodeProps.children}</div>
				),
		}}
	>
		{props.source || ''}
	</ReactMarkdown>
);

/** @deprecated Please use `translate.markdown` instead */
export const MarkdownTranslate = (props: {
	translate: (lk: string, ...args: unknown[]) => string;
	translationKey: string;
	placeholder?: string[];
}) => {
	const escapedPlaceholders = (props.placeholder || []).map(p => escapeMarkdown(p));
	return (
		<DeprecatedMarkdown source={props.translate(props.translationKey, ...escapedPlaceholders)} />
	);
};
