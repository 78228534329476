import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

const ResizeObserver =
	typeof window !== 'undefined' && window.ResizeObserver ? window.ResizeObserver : Polyfill;

export function useResizeObserver(callback: (entry: ResizeObserverEntry) => void) {
	const observer = useRef<ResizeObserver | null>(null);
	const element = useRef<HTMLElement | null>(null);

	useEffect(() => {
		if (observer.current) {
			observer.current.disconnect();
		}

		observer.current = new ResizeObserver(
			debounce(entries => {
				callback(entries[0]);
			}, 100)
		);

		if (element.current) {
			observer.current.observe(element.current);
		}
	}, [callback]);

	return useCallback((e: HTMLElement | null) => {
		element.current = e;

		if (!observer.current) {
			return;
		}

		observer.current.disconnect();

		if (element.current) {
			observer.current.observe(element.current);
		}
	}, []);
}
