import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classnames from 'classnames';
import { useFocus } from '@web-apps/focus-trap';
import { useDisabled } from '../../contexts/disabledContext';
import { MenuItem, Props as MenuItemProps } from './MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useAriaId } from '../../hooks/useAriaId';
import { usePandaContext } from '../../contexts/pandaContext';
import { assertChildTypes } from '../../util/assert-children';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

export type Props = {
	showLabel?: boolean;
	children: React.ReactElement[] | React.ReactElement;
	/**
	 * Der disabled State kann auch über den Context gesteuert werden.
	 * [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs#disabled-context)
	 */
	disabled?: boolean;
	size?: Size;
	variant?: Variant;
};

type Size = 'small' | 'medium' | 'large' | 'xlarge';
type Variant = 'quiet' | 'normal';

const styles = {
	wrapper: classnames(
		'w-auto',
		'inline-block',
		'group',
		'focus:outline-none',
		'focus-visible:outline-none',
		'relative',
		'align-middle'
	),
	button: (hasLabel: boolean, size: Size, variant: Variant) =>
		classnames(
			'cursor-pointer',
			'disabled:cursor-not-allowed',
			'disabled:text-gray-400',
			'disabled:bg-gray-75',
			'disabled:group-hover:bg-gray-75',
			'disabled:group-hover:text-gray-400',
			'disabled:group-active:bg-gray-75',
			'disabled:group-active:text-gray-400',
			'duration-150',
			'ease-in-out',
			'flex',
			'focus-visible:ring',
			'focus-visible:ring-inset',
			'focus:outline-none',
			'focus-visible:outline-none',
			'font-brand',
			'font-bold',
			'items-center',
			'justify-center',
			'space-x--4',
			'transition',
			'rounded',
			'select-none',
			hasLabel && [
				'pointer-coarse:md:pl-8',
				'pointer-coarse:md:pr-16',
				'pointer-coarse:text-base/24',
			],
			size === 'small' && ['p-4', 'pointer-coarse:p-8'],
			size === 'medium' && ['p-4', 'pointer-coarse:p-8'],
			size === 'large' && ['p-8'],
			size === 'xlarge' && ['p-8'],
			hasLabel && size === 'small' && ['gap-6', 'md:pl-8', 'md:pr-12', 'text-xs/16'],
			hasLabel && size === 'medium' && ['gap-8', 'md:pl-8', 'md:pr-16', 'text-sm/16'],
			hasLabel && size === 'large' && ['gap-10', 'md:pl-12', 'md:pr-20', 'text-base/24'],
			hasLabel && size === 'xlarge' && ['gap-8', 'md:pl-12', 'md:pr-24', 'text-base/24'],
			variant === 'quiet' && [
				'bg-transparent',
				'text-gray-800',
				'group-hover:bg-gray-100',
				'group-hover:text-gray-900',
				'group-active:bg-gray-200',
				'group-active:text-black',
				'focus-visible:ring-color-focus',
			],
			variant === 'normal' && [
				'bg-gray-100',
				'text-gray-800',
				'group-hover:bg-gray-200',
				'group-hover:text-gray-900',
				'group-active:bg-gray-300',
				'group-active:text-black',
				'focus-visible:ring-color-focus',
			]
		),
	label: (showLabel: boolean) =>
		classnames(showLabel && ['hidden', 'md:inline'], !showLabel && 'hidden'),
	menuList: (isOpen: boolean) =>
		classnames(
			'absolute',
			'list-none',
			'z-1450',
			'px-0',
			'py-4',
			'm-0',
			'shadow',
			'bg-white',
			'border',
			'border-gray-50',
			'rounded-sm',
			!isOpen && 'invisible'
		),
};

const ContextMenu = ({
	children,
	disabled: disabledProp,
	showLabel,
	size = 'medium',
	variant = 'normal',
}: Props): JSX.Element => {
	const focus = useRef(0);
	const [isOpen, setIsOpen] = useState(false);

	const { languageKeys, portalRoot } = usePandaContext();
	const menuId = useAriaId('context-menu');
	const buttonId = useAriaId('context-menu');
	const disabled = useDisabled(disabledProp);

	const wrapper = useRef<HTMLDivElement>(null);
	const toggleButton = useRef<HTMLButtonElement>(null);
	const popperElement = useRef<HTMLUListElement>(null);

	useOutsideClick(wrapper, () => setIsOpen(false));

	const isHandlingFocus = useFocus(isOpen);

	const { styles: popperStyles, update: updatePopper } = usePopper(
		toggleButton.current,
		popperElement.current,

		{
			placement: 'right-start',
			strategy: 'fixed',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 4],
					},
				},
			],
		}
	);

	const openDropdown = useCallback(() => {
		if (updatePopper) {
			updatePopper();
		}

		setIsOpen(true);
	}, [updatePopper]);

	const portalElement = useRef<HTMLDivElement | null>(null);
	if (portalElement.current === null) {
		portalElement.current = document.createElement('div');
	}

	useEffect(() => {
		if (portalElement.current) {
			portalRoot.appendChild(portalElement.current);
		}

		return () => {
			if (portalElement.current) {
				portalRoot.removeChild(portalElement.current);
			}
		};
	}, [portalRoot]);

	const getFocussedButton = () => {
		const currentListChild = popperElement.current?.children[focus.current];
		if (!currentListChild) {
			return undefined;
		}

		const firstButtonChild = currentListChild.children[0] as HTMLButtonElement;
		if (!firstButtonChild) {
			return undefined;
		}

		return firstButtonChild;
	};

	useEffect(() => {
		if (!isHandlingFocus) {
			return;
		}

		getFocussedButton()?.focus();
	}, [isHandlingFocus]);

	const moveFocusUp = useCallback(() => {
		if (!isHandlingFocus) {
			return;
		}

		if (focus.current - 1 >= 0) {
			focus.current -= 1;
		}

		getFocussedButton()?.focus();
	}, [isHandlingFocus]);

	const moveFocusDown = useCallback(() => {
		if (!isHandlingFocus) {
			return;
		}

		if (popperElement.current && focus.current + 1 < popperElement.current.children.length) {
			focus.current += 1;
		}

		getFocussedButton()?.focus();
	}, [isHandlingFocus]);

	const moveFocusOut = useCallback(() => {
		focus.current = 0;
	}, [focus]);

	const getIconSize = () => {
		switch (size) {
			case 'small':
				return '16';
			case 'medium':
			case 'large':
				return '24';
			case 'xlarge':
				return '32';
		}
	};

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>): void => {
			const key = event.code;

			if (isOpen) {
				if (key === 'Enter') {
					event.stopPropagation();
					event.preventDefault();

					const button = getFocussedButton();
					if (button) {
						button.click();
						button.blur();
					}
				}

				if (key === 'Escape' || key === 'Tab') {
					event.stopPropagation();
					event.preventDefault();

					moveFocusOut();
					setIsOpen(false);
				}

				if (key === 'ArrowDown') {
					event.stopPropagation();
					event.preventDefault();

					moveFocusDown();
				}

				if (key === 'ArrowUp') {
					event.stopPropagation();
					event.preventDefault();

					moveFocusUp();
				}

				return;
			}

			if (key === 'Enter' || key === 'ArrowDown') {
				event.preventDefault();
				event.stopPropagation();

				openDropdown();
				getFocussedButton()?.focus();
			}
		},
		[isOpen, moveFocusDown, moveFocusOut, moveFocusUp, openDropdown]
	);

	// Buttons are weird, and space should fire on keyup to match browser behaviour
	const onKeyUp = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			event.preventDefault();

			if (event.code === 'Space') {
				if (isOpen) {
					const button = getFocussedButton();
					if (button) {
						button.click();
						button.blur();
					}
				} else {
					openDropdown();
					getFocussedButton()?.focus();
				}
			}
		},
		[isOpen, openDropdown]
	);

	assertChildTypes(children, [MenuItem]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={classnames(styles.wrapper)}
			ref={wrapper}
			onKeyUp={onKeyUp}
			onKeyDown={onKeyDown}
		>
			<button
				id={buttonId}
				ref={toggleButton}
				type="button"
				disabled={disabled}
				className={styles.button(!!showLabel, size, variant)}
				aria-haspopup="menu"
				aria-label={languageKeys.PANDA_CONTEXTMENU_ARIA_LABEL}
				aria-expanded={isOpen ? 'true' : undefined}
				aria-controls={menuId}
				onClick={() => (isOpen ? setIsOpen(false) : openDropdown())}
			>
				<SetIcon icon="more" size={getIconSize()} touchSize="32" />

				<span className={styles.label(!!showLabel)}>{languageKeys.PANDA_CONTEXTMENU_LABEL}</span>
			</button>

			{createPortal(
				<ul
					id={menuId}
					role="menu"
					aria-hidden={!isOpen}
					className={styles.menuList(isOpen)}
					data-testid="contextmenu"
					ref={popperElement}
					style={popperStyles.popper}
					aria-labelledby={buttonId}
				>
					{React.Children.map(children, (child: React.ReactElement<MenuItemProps>) => {
						return React.cloneElement(child, {
							onClick: () => {
								moveFocusOut();
								setIsOpen(false);
								child.props.onClick();
							},
						});
					})}
				</ul>,
				portalElement.current
			)}
		</div>
	);
};

export { ContextMenu };
