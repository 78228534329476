import React from 'react';
import { Redirect } from 'react-router';
import { connect, ReduxProps } from '../redux';

import { fetchLinks } from '../redux/modules/links';
import { ReduxState } from '../redux/types';
import { ViewStackConsumer } from '../dialog/withView';

const mapDispatchToProps = {
	fetchLinks,
};

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
});

interface ExternalProps {
	postFix?: string;
}

type Props = ExternalProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class PageNotFoundRedirect extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchLinks();
	}

	private renderRedirects(parent: string | null) {
		if (parent) {
			return <Redirect to={`${parent}${this.props.postFix ?? ''}`} />;
		}

		if (!this.props.links.fetched) {
			return null;
		}

		return (
			<Redirect
				to={`${new URL(this.props.links.items.startUpPageUrl).pathname}${this.props.postFix ?? ''}`}
			/>
		);
	}

	public render() {
		return (
			<ViewStackConsumer>
				{({ mountPoint }) => this.renderRedirects(mountPoint === '' ? null : mountPoint)}
			</ViewStackConsumer>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFoundRedirect);
