import { handleActions } from '../..';
import { SmsState } from './types';

import * as actions from './actions';

const initialState: SmsState = {
	items: [],
	fetchingForUser: [],
	fetchedForUser: [],
};

export const reducer = handleActions<SmsState, PossibleActions<typeof actions>>(
	{
		SMS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser.filter(item => item !== data.userId), data.userId],
		}),
		SMS_FETCH_SUCCESS: (state, { payload, data }) => {
			const newItems = [
				...state.items.filter(sms => sms.owner !== data.userId),
				...payload.items.map(sms => {
					return {
						...sms,
						owner: data.userId,
					};
				}),
			];

			return {
				...state,
				fetchingForUser: state.fetchingForUser.filter(item => item !== data.userId),
				fetchedForUser: [...state.fetchedForUser.filter(item => item !== data.userId), data.userId],
				items: newItems,
			};
		},
		SMS_SET_ALIAS_PENDING: (state, { data }) => {
			const items = state.items.map(sms => {
				if (sms.id === data.smsId) {
					return { ...sms, alias: data.alias };
				}

				return sms;
			});
			return {
				...state,
				items,
			};
		},
		SMS_CREATE_SUCCESS: (state, { payload, data }) => ({
			...state,
			items: [...state.items, { ...payload, owner: data.userId }],
		}),
		SMS_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.smsId),
		}),
	},
	initialState
);
