import { useEffect } from 'react';
import {
	createAcd as createAcdAction,
	deleteAcd as deleteAcdAction,
	fetchAcds,
	setAcdAgentDevices as setAcdAgentDevicesAction,
	setAcdAgents as setAcdAgentsAction,
	setAcdFollowUpTime as setAcdFollowUpTimeAction,
	setAcdRingTime as setAcdRingTimeAction,
	setAcdName as setAcdNameAction,
	setAcdNumbers as setAcdNumbersAction,
	setAcdQueueWaitingAudioFile as setAcdQueueWaitingAudioFileAction,
	setAcdGreetingAudioFile as setAcdGreetingAudioFileAction,
} from './index';
import { useDispatch, useSelector } from '../../utils/wrapper';

export const useAcds = () => {
	const allAcds = useSelector(state => state.acds);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAcds());
	}, [dispatch]);

	const createAcd = async (name: string, numberIds: string[], agentIds: string[]) => {
		const result = await dispatch(createAcdAction(name, numberIds, agentIds)).payload.promise;
		if (result.type === 'ACD_CREATE_SUCCESS') {
			return result.payload.id;
		}
		throw new Error('Failed to create ACD');
	};

	return {
		allAcds: allAcds.items,
		acdsFetched: allAcds.fetched,
		createAcd,
	};
};

export const useAcd = (id: string | undefined) => {
	const { allAcds, acdsFetched } = useAcds();
	const dispatch = useDispatch();

	const acd = allAcds.find(acdItem => acdItem.id === id);

	if (acd === undefined) {
		return {
			acd: undefined,
			acdsFetched,
			saveAcdNumbers: undefined,
			setAcdAgents: undefined,
		};
	}

	const setAcdAgents = async (agentIds: string[]) => {
		await dispatch(setAcdAgentsAction(acd.id, agentIds)).payload.promise;
	};

	const setAcdNumbers = async (numberIds: string[]) => {
		await dispatch(setAcdNumbersAction(acd.id, acd.name, numberIds)).payload.promise;
	};

	const setAcdAgentDevices = async (agentId: string, deviceIds: string[]) => {
		await dispatch(setAcdAgentDevicesAction(acd.id, agentId, deviceIds)).payload.promise;
	};

	const setAcdName = async (name: string) => {
		await dispatch(setAcdNameAction(acd.id, name)).payload.promise;
	};

	const deleteAcd = async () => {
		await dispatch(deleteAcdAction(acd.id)).payload.promise;
	};

	const setAcdQueueWaitingAudioFile = async (audioFileId: string) => {
		await dispatch(setAcdQueueWaitingAudioFileAction(acd.id, audioFileId)).payload.promise;
	};

	const setAcdGreetingAudioFile = async (audioFileId: string | null) => {
		await dispatch(setAcdGreetingAudioFileAction(acd.id, audioFileId)).payload.promise;
	};

	const setAcdFollowUpTime = async (followUpTime: number) => {
		await dispatch(setAcdFollowUpTimeAction(acd.id, followUpTime)).payload.promise;
	};

	const setAcdRingTime = async (ringTime: number) => {
		await dispatch(setAcdRingTimeAction(acd.id, ringTime)).payload.promise;
	};

	return {
		acd,
		acdsFetched,
		setAcdNumbers,
		setAcdAgents,
		setAcdAgentDevices,
		setAcdName,
		deleteAcd,
		setAcdQueueWaitingAudioFile,
		setAcdGreetingAudioFile,
		setAcdFollowUpTime,
		setAcdRingTime,
	};
};
