import { Avatar } from '@panda/ui';
import React from 'react';
import { StateColor } from '@panda/ui/src/components/state/State';
import classnames from 'classnames';
import { User } from '../redux/modules/users';
import { ReduxState } from '../redux/types';
import { connect, ReduxProps } from '../redux';
import { fetchUsersAvatars } from '../redux/modules/avatars';
import {
	findAvatarForWebuserIdAndAllSizes,
	findAvatarForWebuserIdAndSize,
} from '../redux/modules/avatars/selectors';
import classes from './UserAvatar.scss';

import { avatarScrSet } from '../utils/avatars';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';

type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'biggest';

type StateProps =
	| {
			stateColor: StateColor;
			stateLabel: string;
	  }
	| { stateColor?: undefined; stateLabel?: undefined };

type ExternalProps = {
	user: User;
	size: Size;
	editable?: boolean;
} & StateProps;

const mapStateToProps = (state: ReduxState) => ({
	avatars: state.avatars,
	translate: state.translations.translate,
});

const mapDispatchToProps = {
	fetchUsersAvatars,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	ExternalProps &
	WithDialogProps;

class UserAvatar extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchUsersAvatars();
	}

	public render() {
		const avatarUrl = findAvatarForWebuserIdAndSize(
			this.props.avatars,
			this.props.user.id,
			this.props.size
		);

		const allAvatarSizes = findAvatarForWebuserIdAndAllSizes(
			this.props.avatars,
			this.props.user.id,
			this.props.size
		);

		const srcSet = avatarScrSet(allAvatarSizes);

		const stateProps = this.props.stateLabel
			? {
					stateColor: this.props.stateColor,
					stateLabel: this.props.stateLabel,
			  }
			: {};

		if (this.props.editable) {
			if (avatarUrl === undefined) {
				return (
					<button
						type="button"
						className={classnames(classes.editableWrapper, classes.edit, {
							[classes.sixtyFour]: this.props.size === 'biggest',
						})}
						onClick={e => {
							e.stopPropagation();
							this.props.dialogs.userAvatarUpload.open({ webuserId: this.props.user.id });
						}}
						tabIndex={0}
						aria-label={this.props.translate('CHANGE_AVATAR_LABEL')}
					>
						<Avatar
							firstname={this.props.user.firstname}
							lastname={this.props.user.lastname}
							email={this.props.user.email}
							size={this.props.size}
							/* eslint-disable-next-line react/jsx-props-no-spreading -- passing manually breaks types */
							{...stateProps}
						/>
					</button>
				);
			}

			return (
				<button
					type="button"
					aria-label={this.props.translate('CHANGE_AVATAR_LABEL')}
					className={classnames(classes.editableWrapper, classes.edit, {
						[classes.sixtyFour]: this.props.size === 'biggest',
					})}
					onClick={e => {
						e.stopPropagation();
						this.props.dialogs.userAvatarUpload.open({ webuserId: this.props.user.id });
					}}
					tabIndex={0}
				>
					<Avatar
						firstname={this.props.user.firstname}
						lastname={this.props.user.lastname}
						email={this.props.user.email}
						size={this.props.size}
						imageSrcSet={srcSet}
						imageUrl={avatarUrl}
						/* eslint-disable-next-line react/jsx-props-no-spreading -- passing manually breaks types */
						{...stateProps}
					/>
				</button>
			);
		}

		if (avatarUrl === undefined) {
			return (
				<Avatar
					firstname={this.props.user.firstname}
					lastname={this.props.user.lastname}
					email={this.props.user.email}
					size={this.props.size}
					/* eslint-disable-next-line react/jsx-props-no-spreading -- passing manually breaks types */
					{...stateProps}
				/>
			);
		}

		return (
			<Avatar
				firstname={this.props.user.firstname}
				lastname={this.props.user.lastname}
				email={this.props.user.email}
				size={this.props.size}
				imageSrcSet={srcSet}
				imageUrl={avatarUrl}
				/* eslint-disable-next-line react/jsx-props-no-spreading -- passing manually breaks types */
				{...stateProps}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withDialogs(UserAvatar));
