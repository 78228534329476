import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDevices } from './actions';

export const useDevices = (userId: string | undefined) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userId) {
			dispatch(fetchDevices(userId));
		}
	}, [dispatch, userId]);

	const devices = devicesState.items.filter(device => device.owner === userId);
	const registerDevices = devices.filter(device => device.type === 'REGISTER');

	return {
		devices,
		devicesFetchedForUser: userId && devicesState.fetchedForUser.includes(userId),
		registerDevices,
	};
};
