import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { fetchUserInfo } from '../../redux/modules/userinfo';
import { ReduxState } from '../../redux/types';

import classes from './AccountNumber.scss';

const mapStateToProps = (state: ReduxState) => ({
	userinfo: state.userinfo.fetched ? state.userinfo.data : undefined,
	translate: state.translations.translate,
});

const mapDispatchToProps = {
	fetchUserInfo,
};

interface ExternalProps {
	className?: string;
}

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & ExternalProps;

class AccountNumber extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchUserInfo();
	}

	public render() {
		return (
			<span className={this.props.className} data-test-selector="customer-id">
				{this.props.translate('CUSTOMER_ID')}{' '}
				<span
					aria-label={this.props.userinfo?.masterSipId
						.split('')
						.map(i => `${i} `)
						.join('')}
					className={classes.copiable}
				>
					{this.props.userinfo ? this.props.userinfo.masterSipId : null}
				</span>
			</span>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountNumber);
