import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

// Copied from email-validator@2.0.4 package
// (was licensed under UNLICENSE which contains no stipulations on redistribution so this should be fine)
const regex =
	/^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function validate(email: string) {
	const emailParts = email.split('@');

	if (emailParts.length !== 2) {
		return false;
	}

	const account = emailParts[0];
	const address = emailParts[1];

	if (account.length > 64) {
		return false;
	}

	if (address.length > 255) {
		return false;
	}

	if (address.split('.').some(part => part.length > 63)) {
		return false;
	}

	if (!regex.test(email)) {
		return false;
	}

	return true;
}

export const validateEmail =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	(email: string) => {
		if (validate(email)) {
			return {
				valid: true,
				value: email,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'ERRORHINT_INVALID_EMAIL'),
		};
	};
