import { AnyAction, Reducer } from 'redux';
import { Immutable } from './immutable';

type ActionByType<Actions, Type extends string> = Actions & { type: Type };

export function createAction<Type extends string>(actionType: Type): () => { type: Type };
export function createAction<
	Type extends string,
	PayloadCreator extends (...args: never[]) => void
>(
	actionType: Type,
	payloadCreator: PayloadCreator
): (...args: Parameters<PayloadCreator>) => { type: Type; payload: ReturnType<PayloadCreator> };
export function createAction<
	Type extends string,
	PayloadCreator extends (...args: never[]) => void
>(type: Type, payloadCreator?: PayloadCreator) {
	return (...args: Parameters<PayloadCreator>) => {
		const payload = payloadCreator ? payloadCreator(...args) : args[0];
		const action: { type: Type; payload?: ReturnType<PayloadCreator> } = { type };

		if (payload !== undefined) {
			action.payload = payload;
		}

		return action;
	};
}

export function handleActions<State, Actions extends { type: string }>(
	reducerMap: {
		[key in Actions['type']]?: (
			state: Immutable<State>,
			action: ActionByType<Actions, key>
		) => Immutable<State>;
	},
	initialState: State
): Reducer<State, AnyAction> {
	return (prevState, action) => {
		if (typeof prevState === 'undefined') {
			return initialState;
		}

		if (typeof action.type !== 'string' || !(action.type in reducerMap)) {
			return prevState;
		}

		const reducer = reducerMap[action.type as Actions['type']];
		if (typeof reducer !== 'undefined') {
			return reducer(prevState as Immutable<State>, action as Actions) as State;
		}

		return prevState;
	};
}
