import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import serviceUrls from '@web-apps/service-urls';
import { ReduxState } from '../../redux/types';
import { fetchTranslations } from '../../redux/modules/translations';
import { EventState, fetchEvents, selectForceEvents } from '../../redux/modules/events';
import { connect, ReduxProps } from '../../redux';
import { fetchLinks } from '../../redux/modules/links';
import { buildAuthenticateUrl } from '../../utils/authenticate';
import ChangePasswordView from './changePassword/ChangePasswordView';
import * as paths from '../../routes/paths';
import { fetchUserInfo } from '../../redux/modules/userinfo';

const mapStateToProps = (state: ReduxState) => ({
	translate: state.translations.translate,
	translations: state.translations,
	events: state.events,
	userinfo: state.userinfo,
	links: state.links,
});

const mapDispatchToProps = {
	fetchEvents,
	fetchLinks,
	fetchUserInfo,
	fetchTranslations,
};

type Props = RouteComponentProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class ForceWebView extends React.Component<Props> {
	private allowedCommand = ['CHANGEPASSWORD'];

	public componentDidMount() {
		this.props.fetchEvents();
		this.props.fetchLinks();

		if (this.props.userinfo.fetched) {
			this.props.fetchTranslations(this.props.userinfo.data.locale);
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.userinfo.fetched &&
			this.props.userinfo.data.locale !== prevProps.userinfo.data?.locale
		) {
			this.props.fetchTranslations(this.props.userinfo.data.locale);
		}

		if (
			this.props.links.fetched &&
			this.props.events.fetched &&
			this.props.userinfo.fetched &&
			!this.areThereEventsToHandle(this.props.events, this.allowedCommand)
		) {
			window.location.href = buildAuthenticateUrl(
				this.props.links.items.legacyTokenAuthenticateUrl,
				this.props.userinfo.data!.domain === 'sipgate.de'
					? serviceUrls.teamWebDe
					: serviceUrls.teamWebUk
			);
		} else if (
			this.props.events.fetched &&
			this.props.links.fetched &&
			this.props.translations.fetched &&
			this.areThereEventsToHandle(this.props.events, this.allowedCommand) &&
			this.props.history.location.pathname.endsWith('/force')
		) {
			switch (selectForceEvents(this.props.events)[0].eventName) {
				case 'CHANGEPASSWORD':
					this.props.history.push(`/${paths.FORCE}/changepassword`);
			}
		}
	}

	public render() {
		if (this.props.events.fetching) {
			// return <LogoSpinner />;
		}

		return (
			<>
				<Switch>
					<Route path={`/${paths.FORCE}/changepassword`} component={ChangePasswordView} />
				</Switch>
			</>
		);
	}

	private areThereEventsToHandle(eventsState: EventState, allowedCommands: string[]) {
		return (
			selectForceEvents(eventsState)
				.map(e => e.eventName)
				.filter(name => allowedCommands.includes(name.toUpperCase())).length > 0
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceWebView);
