import { createAction } from '../../utils/actions';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchHolidayPresets = createAction('HOLIDAY_PRESETS_FETCH', () => ({
	promise: () => {
		return api.getHolidayPresets();
	},
	shouldFetch: (state: ReduxState) =>
		!state.holidayPresets.fetched && !state.holidayPresets.fetching,
}));
