import classnames from 'classnames';
import React from 'react';
import { useTableContext } from './contexts/tableContext';

type Props = {
	children: string;
};

const styles = {
	th: (isSmallView: boolean) =>
		classnames(
			'px-8',
			'py-10',
			'font-brand',
			'font-normal',
			'text-xs/14',
			'text-left',
			!isSmallView && ['last:pr-0']
		),
};

const TableHeaderCell = ({ children }: Props): JSX.Element => {
	const { isSmallView } = useTableContext();
	return <th className={styles.th(isSmallView)}>{children}</th>;
};

export { TableHeaderCell };
