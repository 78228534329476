import { createAction } from '../..';
import { firebaseClient } from '../../../api';
import { OrganisationMembershipData } from './types';

export const fetchOrganisationMemberships = createAction('ORGANISATION_MEMBERSHIPS_FETCH', () => ({
	promise: () => firebaseClient.getOrganisationMembershipInfo(),
}));

export const updateOrganisationMemberships = createAction(
	'ORGANISATION_MEMBERSHIPS_FETCH_SUCCESS',
	(payload: OrganisationMembershipData) => payload
);
