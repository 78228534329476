import { ManagedMultiFieldValidator, ManagedValidatorResult } from '../ManagedForm';

/**
 * Combines multiple validators into one, so you can
 * easily validate multiple things in order.
 * Only the last validator can change the parameters
 * type.
 *
 * ```
 * chainValidators(
 *     validateNotEmpty()
 *     validateInteger()
 * )
 * ```
 */
export const chainValidators =
	<Output, Values>(
		...validators: [
			...ManagedMultiFieldValidator<string, Values, string>[],
			ManagedMultiFieldValidator<string, Values, Output>
		]
	): ManagedMultiFieldValidator<string, Values, Output> =>
	(input, values) => {
		const lastValidator = validators[validators.length - 1] as ManagedMultiFieldValidator<
			string,
			Values,
			Output
		>;
		const initialValidators = validators.slice(
			0,
			validators.length - 1
		) as ManagedMultiFieldValidator<string, Values, string>[];

		let result: ManagedValidatorResult<string> = {
			value: input,
			valid: true,
		};

		for (const validator of initialValidators) {
			result = validator(result.value, values);

			if (!result.valid) {
				return result;
			}
		}

		return lastValidator(result.value, values);
	};
