import { handleActions } from '../..';

import { LoadingIndicatorState } from './types';
import * as actions from './actions';

const initialState: LoadingIndicatorState = {
	loading: false,
};

export default handleActions<LoadingIndicatorState, PossibleActions<typeof actions>>(
	{
		TOGGLE_LOADING_ANIMATION: (state, { payload }) => ({ ...state, loading: payload }),
	},
	initialState
);
