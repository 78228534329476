import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { UsersState } from './types';

const shouldFetchUsers = (state: ReduxState) => !state.users.fetched && !state.users.fetching;

export const fetchUsers = createAction('USERS_FETCH', () => ({
	promise: () => api.getUsers(),
	shouldFetch: (state: ReduxState) => shouldFetchUsers(state),
}));

export const forceFetchUsers = createAction('USERS_FETCH', () => ({
	promise: () => api.getUsers(),
}));

export const setUserRole = createAction(
	'USERS_SET_ROLE',
	(userId: string, isAdmin: boolean, errorCallback?: (status: number) => void) => ({
		promise: () =>
			api.setUserRole(userId, isAdmin).catch(error => {
				if (typeof errorCallback === 'function') {
					errorCallback(error.status);
				}

				throw error;
			}),
		data: { userId, isAdmin },
	})
);

export const setUserLocation = createAction(
	'USERS_SET_LOCATION',
	(userId: string, addressId: string) => ({
		promise: () => api.setUserLocation(userId, addressId),
		data: { userId, addressId },
	})
);

export const setUserBusyOnBusy = createAction(
	'USERS_SET_BUSYONBUSY',
	(userId: string, enabled: boolean) => ({
		promise: () => api.setUserBusyOnBusy(userId, enabled),
		data: { userId, enabled },
	})
);

export const setUserName = createAction(
	'USERS_SET_NAME',
	(userId: string, firstName: string, lastName: string) => ({
		promise: () => api.setUserName(userId, firstName, lastName),
		data: { userId, firstName, lastName },
	})
);

export const setUserEmail = createAction('USERS_SET_EMAIL', (userId: string, email: string) => ({
	promise: () => api.setUserEmail(userId, email),
	data: { userId, email },
}));

export const cancelUserEmailChangeRequest = createAction(
	'USERS_CANCEL_EMAIL_CHANGE_REQUEST',
	(userId: string) => ({
		promise: () => api.cancelUserEmailChangeRequest(userId),
		data: { userId },
	})
);

export const fetchUserEmailChangeRequest = createAction(
	'USERS_EMAIL_CHANGE_REQUEST_FETCH',
	(userId: string) => ({
		promise: () => api.getUserEmailChangeRequest(userId),
		data: { userId },
	})
);

export const setUserLanguage = createAction(
	'USERS_SET_LANGUAGE',
	(userId: string, language: string) => ({
		promise: () => api.setUserLanguage(userId, language),
		data: { userId, language },
	})
);

export const fetchUserLanguage = createAction(
	'USERS_LANGUAGE_FETCH',
	(userId: string, force?: boolean) => ({
		data: { userId },
		promise: () => api.getUserLanguage(userId),
		shouldFetch: (state: { users: UsersState }) =>
			force ||
			(!state.users.fetchedLanguageForUser.includes(userId) &&
				!state.users.fetchingLanguageForUser.includes(userId)),
	})
);

export const setDefaultDevice = createAction(
	'USERS_SET_DEFAULT_DEVICE',
	(userId: string, deviceId: string) => ({
		promise: () => api.setDefaultDevice(userId, deviceId),
		data: {
			userId,
			deviceId,
		},
	})
);

export const resetUserPassword = createAction('USERS_PASSWORD_RESET', (username: string) => ({
	promise: () => api.resetUserPassword(username),
	data: { username },
}));

export const deleteUser = createAction('USERS_DELETE', (userId: string) => ({
	promise: () => api.deleteUser(userId),
	data: {
		userId,
	},
}));

export const createUser = createAction(
	'USERS_CREATE',
	(firstName: string, lastName: string, email: string, isAdmin: boolean, locationId: number) => ({
		promise: () => api.createUser(firstName, lastName, email, isAdmin, locationId),
		data: {},
	})
);
