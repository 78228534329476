import classnames from 'classnames';
import React from 'react';
import { useResponsiveBreakpoints } from '../../hooks/useContainerBreakpoint';
import { TableContext } from './contexts/tableContext';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { TableHeaderCell } from './TableHeaderCell';

type Props = {
	children: [
		React.ReactComponentElement<typeof TableHeader>,
		React.ReactComponentElement<typeof TableBody>
	];
};

const styles = {
	table: (isSmallView: boolean) =>
		classnames(
			'w-full',
			'm-0',
			'p-0',
			'overflow-x-auto',
			'text-gray-800',
			!isSmallView && ['border-collapse']
		),
};

const Table = ({ children }: Props): JSX.Element => {
	const { ref: tableRef, isSmall: isSmallView } = useResponsiveBreakpoints();
	const [activeRow, setActiveRow] = React.useState(-1);
	const headerTitles: string[] = [];
	let hasControls = false;

	React.Children.forEach(children, (bodyOrHead: React.ReactNode) => {
		if (React.isValidElement(bodyOrHead)) {
			React.Children.forEach(bodyOrHead.props.children, (row: React.ReactNode) => {
				if (React.isValidElement(row)) {
					if (row.props.control) {
						hasControls = true;
					}
					React.Children.forEach(row.props.children, (cell: React.ReactNode) => {
						// Components rendered in MDX have an extra Wrapper so we need to check for the special prop
						if (
							React.isValidElement(cell) &&
							(cell.type === TableHeaderCell || cell.props.originalType === TableHeaderCell)
						) {
							headerTitles.push(cell.props.children);
						}
					});
				}
			});
		}
	});

	return (
		<TableContext.Provider
			value={{ isSmallView, activeRow, setActiveRow, hasControls, headerTitles }}
		>
			<table ref={tableRef} className={styles.table(isSmallView)}>
				{children}
			</table>
		</TableContext.Provider>
	);
};

export { Table };
