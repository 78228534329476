import classnames from 'classnames';
import React from 'react';
import { ContextMenu } from '../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../components/contextMenu/MenuItem';
import { Headline } from '../../components/heading/Headline';
import { EntitySwitcher } from './EntitySwitcher';
import { EntitySwitcherProps } from './SideBarView';

const styles = {
	header: classnames('mb-16'),
	topRow: (showLabel: boolean) =>
		classnames(
			'px-16',
			'pt-16',
			'pb-[3.625rem]',
			'flex',
			'items-start',
			showLabel ? 'justify-between' : 'justify-end'
		),
	label: classnames(
		'bg-white',
		'text-gray-800',
		'rounded',
		'px-12',
		'py-8',
		'text-sm/16',
		'cursor-default'
	),
	avatarWrapper: classnames(
		'mt-[-4.25rem]',
		'flex',
		'items-center',
		'justify-center',
		'bg-white',
		'w-[6.5rem]',
		'h-[6.5rem]',
		'rounded-full',
		'mx-auto'
	),
	heading: classnames('sr-only'),
	metadata: classnames('text-gray-600', 'text-center', 'text-sm/20'),
	container: classnames('px-16', 'pt-16', 'flex', 'flex-col', 'gap-16'),
};

export type Props<T extends { id: string }> = {
	heading: string;
	graphic: React.ReactNode;
	entitySwitcher: EntitySwitcherProps<T>;
	role?: string;
	status?: React.ReactNode;
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
	metadata?: React.ReactNode;
};

export const SideBarHeader = <T extends { id: string }>({
	heading,
	graphic,
	entitySwitcher,
	role,
	status,
	contextMenu,
	metadata,
}: Props<T>): JSX.Element => {
	return (
		<header className={styles.header}>
			<Headline className={styles.heading}>{heading}</Headline>
			<div className={styles.topRow(!!(role || status))}>
				{role ? <div className={styles.label}>{role}</div> : status}

				{contextMenu && (
					<ContextMenu>
						{contextMenu.map(contextMenuItem => (
							<MenuItem
								key={contextMenuItem.label}
								onClick={contextMenuItem.onClick}
								critical={contextMenuItem.critical}
							>
								{contextMenuItem.label}
							</MenuItem>
						))}
					</ContextMenu>
				)}
			</div>
			<div className={styles.avatarWrapper}>{graphic}</div>
			<div className={styles.container}>
				<EntitySwitcher
					entities={entitySwitcher.entities}
					onChange={entitySwitcher.onChange}
					placeholder={entitySwitcher.searchPlaceholder}
					searchAriaLabel={entitySwitcher.searchAriaLabel}
				/>

				{metadata ? <div className={styles.metadata}>{metadata}</div> : null}
			</div>
		</header>
	);
};

// Dummy Component for documentation only
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntitySwitcherDocs = <T extends { id: string }>(props: EntitySwitcherProps<T>) => (
	<></>
);
