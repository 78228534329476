import { GroupState, Group } from './types';

export function selectGroup(groups: GroupState, groupId: string) {
	return groups.items.find(group => group.id === groupId);
}

export function selectGroupsByUserId(groups: Group[], userId: string) {
	return groups.filter(group => {
		if (!group.users) {
			return false;
		}

		return group.users.includes(userId);
	});
}
