/* eslint-disable react/prop-types */

import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { closeWarningSnackbar } from '../../redux/modules/snackbar';
import Snackbar from './Snackbar';
import { ReduxState } from '../../redux/types';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.warningSnackbarOpen,
	message: state.dialogs.warningSnackbarMessage,
	translate: state.translations.translate,
	translationsFetched: state.translations.fetched,
});

const mapDispatchToProps = {
	closeWarningSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

const WarningSnackbar = (props: Props) => {
	if (!props.translationsFetched) {
		return null;
	}

	return (
		<Snackbar
			key="warning-snackbar"
			snackbarOpen={props.open}
			closeSnackbar={props.closeWarningSnackbar}
			severity="warning"
		>
			{props.message ? props.message : props.translate('SNACKBAR_WARNING_TEXT')}
		</Snackbar>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningSnackbar);
