/* eslint-disable no-irregular-whitespace */
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { isValidPhoneNumberType } from './index';

const MAX_QUICKDIAL_LENGTH = 4;

export interface ValidQuickdial {
	valid: true;

	type: 'QUICKDIAL';
	value: string;

	/** can "the sipgate platform" route this number? */
	routable: true;
}

export interface InvalidQuickdial {
	valid: false;

	error: 'TOO_LONG' | 'TOO_SHORT' | 'LEADING_ZERO' | 'INVALID_CHARACTERS';
}

export type NormalizedQuickdial = ValidQuickdial | InvalidQuickdial;

/**
 * Attempts to normalize a user-provided quickdial number so we can use it
 * as a callee in a click2dial call. We try to be lenient in what we accept
 * but not so lenient that an accidental copy-paste (e.g. copying parts of a
 * full phonenumber with special characters) passes validation.
 */
export function normalizeQuickdial(input: string): NormalizedQuickdial {
	const sanitizedNumber = input.trim();

	if (!/^\d*$/.test(sanitizedNumber)) {
		return {
			valid: false,

			error: 'INVALID_CHARACTERS',
		};
	}

	if (sanitizedNumber.length === 0) {
		return {
			valid: false,

			error: 'TOO_SHORT',
		};
	}

	if (/^0\d+/.test(sanitizedNumber)) {
		return {
			valid: false,

			error: 'LEADING_ZERO',
		};
	}

	if (sanitizedNumber.length > MAX_QUICKDIAL_LENGTH) {
		return {
			valid: false,

			error: 'TOO_LONG',
		};
	}

	return {
		valid: true,

		type: 'QUICKDIAL',
		value: sanitizedNumber,
		routable: true,
	};
}

export interface ValidE164 {
	valid: true;

	type: 'E164';
	value: string;

	/** can "the sipgate platform" route this number? */
	routable: boolean;
}

export interface InvalidE164 {
	valid: false;
	error: 'TOO_SHORT' | 'MISSING_LOCALPREFIX' | 'INVALID_CHARACTERS' | 'GENERIC';
}

export type NormalizedE164 = ValidE164 | InvalidE164;

/**
 * Attempts to normalize a user-provided non-quickdial number so we can use it
 * as a callee in a click2dial call. We try to be lenient in what we accept.
 */
export function normalizeE164(input: string, locale: string): NormalizedE164 {
	if (!/^[\u202d]?[\s(]*\+?[0-9\-()\s/\\\u200b]*[\u202c]?$/.test(input)) {
		return {
			valid: false,
			error: 'INVALID_CHARACTERS',
		};
	}

	const sanitizedNumber = input
		.replace(/[\u202d]/g, '')
		.replace(/[\u200B]/g, '')
		.replace(/[\u202c]/g, '')
		.replace(/[^0-9+]/g, '')
		.replace(/^00/g, '+');

	if (sanitizedNumber.length <= MAX_QUICKDIAL_LENGTH) {
		return {
			valid: false,
			error: 'TOO_SHORT',
		};
	}

	if (!/^[0+]/.test(sanitizedNumber)) {
		return {
			valid: false,
			error: 'MISSING_LOCALPREFIX',
		};
	}

	const phoneNumberUtil = PhoneNumberUtil.getInstance();
	// Upstream types seem to be flat wrong, so we need to cast
	//
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isViableNumber: boolean = (PhoneNumberUtil as any).isViablePhoneNumber(sanitizedNumber);

	if (!isViableNumber) {
		return {
			valid: false,
			error: 'GENERIC',
		};
	}

	try {
		const parsedPhoneNumber = phoneNumberUtil.parse(sanitizedNumber, locale.split('_')[1] || 'DE');
		const formattedPhoneNumber = phoneNumberUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);

		return {
			valid: true,
			type: 'E164',
			value: formattedPhoneNumber,
			routable: isValidPhoneNumberType(parsedPhoneNumber),
		};
	} catch (e) {
		return {
			valid: false,
			error: 'GENERIC',
		};
	}
}

export type NormalizedPhonenumber = NormalizedQuickdial | NormalizedE164;

export function normalizePhonenumber(number: string, locale: string): NormalizedPhonenumber {
	if (number.trim().length <= MAX_QUICKDIAL_LENGTH) {
		return normalizeQuickdial(number);
	}

	return normalizeE164(number, locale);
}

export interface ValidDialableCharacterSequence {
	valid: true;
	charSequence: string;
}

export interface InvalidDialableCharacterSequence {
	valid: false;
	error: 'INVALID_CHARACTERS';
}

export type DialableCharacterSequence =
	| ValidDialableCharacterSequence
	| InvalidDialableCharacterSequence;

/**
 * Attempts to normalize a user-provided sequence of characters so we can dial it
 * via sip. In contrast to normalizePhonenumber, it accepts `*` and `#` characters
 * and does no normalization / checking in regards to localprefixes or length.
 */
export function normalizeDialableCharacterSequence(
	charSequence: string
): DialableCharacterSequence {
	if (!/^[\u202d\u200b]?[\s(]*\+?[0-9*#\-()\s/\\\u200b]+[\u202c]?$/.test(charSequence)) {
		return {
			valid: false,
			error: 'INVALID_CHARACTERS',
		};
	}

	return {
		valid: true,
		charSequence: charSequence
			.replace(/[\u202d]/g, '')
			.replace(/[\u200B]/g, '')
			.replace(/[\u202c]/g, '')
			.replace(/[^0-9*#+]/g, '')
			.replace(/^\+/g, '00'),
	};
}
