import { createAction } from '../..';
import { OrganisationPayment } from '../../../api/types/organisationPayment';
import { firebaseClient } from '../../../api';
import { ReduxState } from '../../types';

export const fetchOrganisationPaymentState = createAction(
	'ORGANISATION_PAYMENT_STATE_FETCH',
	() => ({
		promise: () => firebaseClient.getOrganisationPaymentState(),
		shouldFetch: (state: ReduxState) => !state.organisationPaymentState.fetched,
	})
);

export const updateOrganisationPaymentState = createAction(
	'ORGANISATION_PAYMENT_STATE_FETCH_SUCCESS',
	(payload: OrganisationPayment) => payload
);
