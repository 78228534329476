import React from 'react';
import { usePandaContext } from '../../contexts/pandaContext';
import { StateColor } from '../state/State';
import { getColor, getInitials } from './colors';
import { Size, VisualAvatar } from './VisualAvatar';

type BaseProps = {
	/**
	 * Vorname der Benutzer:in
	 */
	firstname: string;
	/**
	 * Nachname der Benutzer:in
	 */
	lastname: string;
	/**
	 * E-Mail-Adresse der Benutzer:in. Ist optional, sollte aber im App-Web immer übergeben werden, damit die Farben der Avatare einheitlich generiert werden.
	 */
	email?: string;
	/**
	 * Entscheidet ob der Avatar in Groß dargestellt wird
	 */
	size?: Size;
};

type StateProps =
	| {
			/**
			 * Farbe die wie die `State` Komponente am Avatar angezeigt wird.
			 */
			stateColor: StateColor;
			/**
			 * Ein String der den aktuellen Status des Objekts beschreibt wie online oder offline.
			 */
			stateLabel: string;
	  }
	| { stateColor?: undefined; stateLabel?: undefined };

type ImageProps =
	| {
			/**
			 * Ein Bild welches statt den Initialen angezeigt wird
			 */
			imageUrl: string | undefined;
			/**
			 * Ein Set von Bildern welches statt den Initialen angezeigt wird und die Größe responsiv anpasst
			 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#srcset
			 */
			imageSrcSet?: string | undefined;
	  }
	| {
			imageUrl?: undefined;
			imageSrcSet?: undefined;
	  };

export type Props = BaseProps & StateProps & ImageProps;

export const Avatar = ({
	firstname,
	lastname,
	email,
	imageUrl,
	imageSrcSet,
	size = 'large',
	stateColor,
	stateLabel,
}: Props): JSX.Element => {
	const { languageKeys } = usePandaContext();

	// eslint-disable-next-line react/jsx-props-no-spreading
	return (
		<VisualAvatar
			text={getInitials(firstname, lastname)}
			backgroundColor={getColor(firstname, lastname, email)}
			size={size}
			imageUrl={imageUrl}
			imageSrcSet={imageSrcSet}
			ariaLabel={
				stateLabel
					? `${languageKeys.PANDA_AVATAR_FROM} ${firstname} ${lastname} – ${stateLabel}`
					: `${languageKeys.PANDA_AVATAR_FROM} ${firstname} ${lastname}`
			}
			title={stateLabel ? `${firstname} ${lastname} (${stateLabel})` : `${firstname} ${lastname}`}
			textColor="white"
			state={stateColor}
		/>
	);
};
