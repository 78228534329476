import React from 'react';
import classnames from 'classnames';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { usePandaContext } from '../../contexts/pandaContext';
import { useDisabled } from '../../contexts/disabledContext';

type Props<Columns extends string> = {
	columns: { id: Columns; label: string; sortable: boolean }[];
	sortBy: (column: Columns, direction: 'ascending' | 'descending') => void;
	sortedBy: { column: Columns; direction: 'ascending' | 'descending' };
};

const styles = {
	columnHeader: classnames(
		'h-24',
		'px-16',
		'py-0',
		'[&:nth-child(2)]:pl-8',
		'align-bottom',
		'text-left'
	),
	columnSorter: classnames(
		'appearance-none',
		'flex',
		'items-end',
		'group',
		'gap-x-4',
		'p-0',
		'm-0',
		'border-0',
		'bg-transparent',
		'font-meta',
		'font-light',
		'text-base/16',
		'text-gray-800',
		'outline-none',
		'hover:text-blue-500',
		'focus-visible:ring',
		'focus-visible:ring-color-focus',
		'active:text-blue-600',
		'cursor-pointer',
		'duration-150',
		'ease-in-out',
		'transition-colors',
		'whitespace-nowrap',
		'rounded-sm',
		'pointer-coarse:py-16',
		'pointer-coarse:-mb-16',
		'disabled:text-gray-400',
		'disabled:cursor-not-allowed'
	),
	columnLabel: classnames(
		'group',
		'p-0',
		'm-0',
		'font-meta',
		'font-light',
		'text-base/16',
		'text-gray-800',
		'cursor-default',
		'align-bottom',
		'whitespace-nowrap'
	),
	currentSortOrderIcon: (showIcon: boolean, sortDirection: 'ascending' | 'descending') =>
		classnames(
			'h-16',
			'mr-8',
			'transform',
			'group-hover:bg-blue-500',
			'group-active:bg-blue-600',
			'duration-150',
			'ease-in-out',
			'transition',
			'group-disabled:bg-gray-400',
			showIcon ? 'bg-gray-800' : 'rotate-0 hidden',
			sortDirection === 'descending' ? 'rotate-180' : 'group-hover:rotate-0'
		),
	possibleSortOrderIcon: (showSortableIcon: boolean) =>
		classnames(
			'transform',
			'group-hover:bg-blue-500',
			'group-active:bg-blue-600',
			'duration-150',
			'ease-in-out',
			'transition',
			'group-disabled:bg-gray-400',
			showSortableIcon ? 'bg-gray-400' : 'hidden'
		),
	endShadow: classnames(
		'p-0',
		'sticky',
		'right-0',
		'bg-gradient-to-r',
		'from-80%',
		'from-transparent',
		// Stacking contexts be weird (otherwise sort arrows overlap fadeout)
		'z-10',
		'to-gray-25',
		'min-w-[calc(6.5rem+0.125rem)]'
	),
	startShadow: classnames(
		'p-0',
		'sticky',
		'left-0',
		'bg-gradient-to-r',
		'from-gray-25',
		// Stacking contexts be weird (otherwise sort arrows overlap fadeout)
		'z-10',
		'to-transparent',
		'min-w-[calc(1rem+0.125rem)]'
	),
};

export const ListHeader = <Columns extends string>({
	columns,
	sortBy,
	sortedBy,
}: Props<Columns>) => {
	const disabled = useDisabled();
	const { languageKeys } = usePandaContext();

	const onSort = (key: Columns) => () => {
		if (key === sortedBy.column) {
			sortBy(key, sortedBy.direction === 'ascending' ? 'descending' : 'ascending');
		} else {
			sortBy(key, 'ascending');
		}
	};

	return (
		<thead>
			<tr>
				<th aria-hidden className={styles.startShadow} />

				{columns.map(({ id, label, sortable }) => (
					<th
						scope="col"
						key={id}
						className={styles.columnHeader}
						role="columnheader"
						aria-sort={id === sortedBy.column ? sortedBy.direction : 'none'}
					>
						{sortable ? (
							<button
								title={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${label}`}
								className={styles.columnSorter}
								onClick={onSort(id)}
								aria-label={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${label}`}
								disabled={disabled}
							>
								<div>{label}</div>

								<PandaIcon
									icon="sort_arrow-16"
									className={styles.currentSortOrderIcon(
										sortedBy.column === id,
										sortedBy.direction || 'ascending'
									)}
								/>

								<PandaIcon
									icon="sortable_arrows-16"
									className={styles.possibleSortOrderIcon(sortedBy?.column !== id)}
								/>
							</button>
						) : (
							<div className={styles.columnLabel}>{label}</div>
						)}
					</th>
				))}

				<th aria-label={`${languageKeys.PANDA_LIST_VIEW_ACTIONS}`} className={styles.endShadow} />
			</tr>
		</thead>
	);
};
