import { createAction } from '../..';

export const openDialog = createAction('DIALOG_OPEN');

export const closeDialog = createAction('DIALOG_CLOSE');

export const closeWarningSnackbar = createAction('WARNING_SNACKBAR_CLOSE');

export const openWarningSnackbar = createAction(
	'WARNING_SNACKBAR_OPEN',
	(warningSnackbarMessage: string) => ({
		warningSnackbarMessage,
	})
);

export const closeErrorSnackbar = createAction('ERROR_SNACKBAR_CLOSE');

export const openErrorSnackbar = createAction(
	'ERROR_SNACKBAR_OPEN',
	(errorSnackbarMessage: string) => ({
		errorSnackbarMessage,
	})
);

export const closeInvalidAccountStateSnackbar = createAction(
	'INVALID_ACCOUNT_STATE_SNACKBAR_CLOSE'
);

export const openInvalidAccountStateSnackbar = createAction('INVALID_ACCOUNT_STATE_SNACKBAR_OPEN');

export const closeInsufficientFundsSnackbar = createAction('INSUFFICIENTFUNDS_SNACKBAR_CLOSE');

export const openInsufficientFundsSnackbar = createAction('INSUFFICIENTFUNDS_SNACKBAR_OPEN');

export const openAutoCloseInfoSnackbar = createAction(
	'AUTOCLOSE_INFO_SNACKBAR_OPEN',
	(autoCloseInfoSnackbarMessage: string) => ({ autoCloseInfoSnackbarMessage })
);

export const closeAutoCloseInfoSnackbar = createAction('AUTOCLOSE_INFO_SNACKBAR_CLOSE');

export const openAutoCloseSuccessSnackbar = createAction(
	'AUTOCLOSE_SUCCESS_SNACKBAR_OPEN',
	(autoCloseSuccessSnackbarMessage: string) => ({ autoCloseSuccessSnackbarMessage })
);

export const closeAutoCloseSuccessSnackbar = createAction('AUTOCLOSE_SUCCESS_SNACKBAR_CLOSE');

export const openClickToDialSnackbar = createAction(
	'CLICKTODIAL_SNACKBAR_OPEN',
	(sessionId: string, alias: string) => ({ sessionId, alias })
);

export const closeClickToDialSnackbar = createAction('CLICKTODIAL_SNACKBAR_CLOSE');
