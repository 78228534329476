import { defineDialogs } from '../../dialog/Dialogs';

export const { withDialogs, useDialogs, Dialogs, DialogRedirect } = defineDialogs('/dialog', {
	acdForwarding: '/acds/:acdId/forwardingSets/:forwardingId?/forwarding/',
	activateCallRecorder: '/call-recorder/activate',
	activateNumber: '/number-activate/:contextId/:endpointId/:numberId',
	activeTimes: '/activetimes/:webuserId/:type(extension|acd)/:endpointId/',
	addAgentsToAcd: '/acds/:acdId/agents',
	addBlocklist: '/blocklist-add-entry',
	addConferenceRoomGreeting: '/conference-greeting/audiofiles/:conferenceRoomId',
	addContact: '/add-contact/:pseudoKey?',
	addDevice:
		'/add-device/:webuserId/:routingTarget([gp]\\d+)?/:initialDeviceType(REGISTER|MOBILE)?',
	addDevicePoolNumbersToEndpoint: '/device-pool-numbers-add/:endpointId',
	addForwarding:
		'/add-forwarding/:type(online|offline|busy|unconditional)/:webuserId/:endpointId/:setId([0-9]*)?',
	addNumberToContact: '/add-phonenumber-to-contact/:pseudoKey?',
	addNumbersToAcd: '/acds/:acdId/phoneNumbers',
	addNumbersToEndpoint: '/numbers-add/:endpointId',
	addPersonalAccessToken: '/create-token/:webuserId',
	addPhoneProvisioning: '/add-phone-provisioning',
	addQuickdial: '/quickdial/:webuserId/:endpointId',
	additionalExternalDeviceSettings: '/additional-device-settings/:webuserId/:deviceId(x\\d+)',
	additionalNumbers: '/additional-numbers/:contextId/:endpointId/:mode(view|edit)/',
	additionalRegisterDeviceSettings: '/additional-device-settings/:webuserId/:deviceId(e\\d+)',
	addressVerificationLetterSent: '/address-verification-letter-sent',
	appAdminAdvertisement: '/app-admin-advertisement',
	assignHardwareUser: '/hardware-user-select/:macAddress',
	audioFiles: '/audioFiles/:ownerId/:voicemailId',
	blockAnonymous: '/block-anonymous/:webuserId/:phonelineId',
	bookApp: '/book-app/:webuserId',
	bookAppAdminRequired: '/book-app-admin-required',
	bookAppBulk: '/book-app-bulk',
	bookFlatratePlus: '/book/flatrate/:userId',
	bookGroupStatistics: '/groups/book-statistics',
	bookGroupStatisticsSingleGroup: '/groups/book-statistics-single/:groupId',
	bookMobile: '/book-mobile/:webuserId',
	bookTrunk: '/book-trunk',
	bookTrunkContingent: '/book-trunk-contingent/:trunkId/:productId',
	businessVerification: '/business-verification/:addressId',
	businessVerificationFailed: '/business-verification-failed/:addressId/:reason',
	businessVerificationSucceeded: '/business-verification-succeeded',
	callQueueAudioFiles: '/call-queue/audiofiles/:groupId/:mode(create|edit)',
	callRecordingAudioFiles: '/call-recording',
	cancelAccount: '/account/cancel',
	cancelApp: '/cancel-app/:webuserId',
	cancelFlatratePlus: '/cancel/flatrate/:userId',
	cancelTrunkContract: '/cancel-trunk/:trunkId',
	changeSim: '/change-sim/:webuserId/:containerId/:mobileId',
	changeTrunk: '/change-trunk/:trunkId',
	changeTrunkContingent:
		'/change-trunk-contingent/:trunkId/:productId/:contingentProductId/:contractId',
	chooseCreateMobileDeviceOrOrderSim: '/create-mobile-or-order-sim/:webuserId/:containerId',
	clickToCallThrough: '/click-to-record-callthrough/:webuserId/:callthroughId',
	clickToDial: '/new-click-to-dial/:webuserId/:pseudoKey?',
	clickToPlay: '/click-to-play/:webuserId/:datadId',
	clickToRecord: '/click-to-record/:webuserId/:voicemailId',
	clickToRecordIvr: '/click-to-record-ivr/:webuserId/:ivrId/:ivrEndpoint',
	createAcd: '/create-acd',
	createDeLocation: '/create-de-location',
	createGroup: '/create-group',
	createLocation: '/create-location',
	createMobileDevice: '/create-mobile/:webuserId/:containerId/:intendedUse(VOICE|DATA)',
	createUnlinked: '/create-unlinked/:webuserId',
	createUser: '/create-user',
	createUserRouting: '/create-user/:webuserId/routing',
	dataAutomation: '/data-automation/:webuserId/:deviceId',
	dataUpgrade: '/data-upgrade/:webuserId/:deviceId/',
	dataUsageEmptyState: '/data-usage-info',
	dataUsageSettings: '/data-usage/:containerId',
	deactivateCallRecorder: '/call-recorder/deactivate',
	deactivateNumber: '/number-deactivate/:contextId/:numberId',
	deleteAcd: '/acds/:acdId/delete',
	deleteAdditionalSim: '/delete-additional-sim/:webuserId/:containerId/:mobileId',
	deleteConferenceRoom: '/delete-conferenceroom/:conferenceRoomId',
	deleteConferenceRoomGreeting: '/delete-conference-greeting/:conferenceRoomId',
	deleteDevice: '/delete-device/:webuserId/:deviceId',
	deleteDevicePool: '/delete-device-pool/:webuserId/:poolId',
	deleteFaxline: '/delete-faxline/:faxlineId',
	deleteGroup: '/delete-group/:groupId',
	deleteGroupGreeting: '/delete-group-greeting/:groupId',
	deleteLastDomainVerification: '/domain-verification/:domainId/delete',
	deleteLocation: '/delete-location/:addressId',
	deleteLogo: '/delete-logo',
	deletePersonalAccessToken: '/delete-token-confirm/:tokenId',
	deletePhoneProvisioning: '/delete-phone-provisioning/:macAddress',
	deleteRouting: '/delete-routing/:webuserId/:phonelineId',
	deleteTrunk: '/delete-trunk/:trunkId',
	deleteUser: '/delete-user/:userId',
	deviceAlias: '/device-alias/:webuserId/:deviceId',
	deviceCallerId: '/caller-id/:webuserId/:deviceId',
	devicePoolSmsSim: '/sms-sim/:webuserId/:poolId',
	dnsChallenge: '/dns-challenge',
	domainVerification: '/domain-verification',
	downloadApp: '/download-app/:webuserId',
	editCustomDatesForwarding: '/edit-custom-dates-forwarding/:endpointId',
	editDateBasedForwardingDestination:
		'/edit-date-based-forwarding-destination/:endpointId/:dateBasedSetId',
	editDevicePoolNumbersToEndpoint: '/device-pool-numbers-edit/:endpointId/:numberId',
	editForwarding:
		'/edit-forwarding/:type(online|offline|busy|unconditional)/:webuserId/:endpointId/:setId/:stepIndex',
	editGroupMembers: '/:groupId/members/edit',
	editHolidayProfiles: '/edit-holiday-profiles/:endpointId/:dateBasedType(HOLIDAY|CUSTOM)',
	editLocation: '/edit-location/:addressId',
	editQuickdial: '/edit-quickdial/:webuserId/:numberId',
	editSimAlias: '/sim-alias/:webuserId/:containerId/:mobileId',
	emergencyLocation: '/emergency-location/:webuserId/:deviceId',
	emergencyLocationWarning: '/emergency-location-warning',
	externalDeviceSettings: '/external-device-settings/:webuserId/:deviceId',
	externalDeviceTargetNumber: '/external-device-target-number/:webuserId/:deviceId',
	faxReport: '/fax-report/:webuserId/:faxlineId',
	faxSettings: '/fax-settings/:faxlineId',
	featureNotBookable: '/feature-not-bookable',
	generateTrunkCredentialsPassword: '/generate-trunk-password/:trunkId',
	greetingAudioFiles: '/greeting/audiofiles/:groupId/:mode(create|edit)',
	groupMembers: '/group-members/:groupId',
	groupStatisticsSurvey: '/group-statistics-survey',
	groupStatisticsTeaser: '/group-statistics-teaser',
	holidayForwarding: '/holiday-forwarding/:endpointId',
	incomingCallDisplay: '/incoming-call-display/:webuserId/:deviceId',
	localprefix: '/localprefix/:deviceId',
	manageAcdAudioFiles: '/acds/:acdId/settings/queue/audiofiles/:audioFileType(callqueue|greeting)',
	manageAcdFollowUpTime: '/acds/:acdId/settings/agents/followUp',
	manageAcdQueueMaxTime: '/acds/:acdId/settings/queue/maxTime',
	manageAcdRingTime: '/acds/:acdId/settings/agents/ringTime',
	manageAcdRingingSettings: '/acds/:acdId/settings/ringing',
	moveLocation: '/move-location/:addressId',
	notEnoughRightsToCreateAddress: '/not-enough-rights-to-create-address',
	orderSim: '/order-sim',
	phonebook: '/phonebook/:addressId',
	poolCallerId: '/pool-change-callerid-info/:webuserId/:deviceId',
	registeredDevices: '/registered-devices/:webuserId/:deviceId',
	renameConferenceRoom: '/rename-conferenceroom/:conferenceRoomId',
	renameTrunk: '/rename-trunk/:trunkId',
	renameUser: '/rename-user/:userId',
	resetSipPassword: '/reset-sip-password/:deviceId',
	revokeAccountCancellation: '/account/revoke-cancellation',
	revokeCancelApp: '/revoke-app-cancellation/:webuserId',
	revokeChangeTrunk: '/revoke-change-trunk/:trunkId',
	revokeTrunkContingentCancellation: '/revoke-trunk-contingent/:trunkId/:contractId',
	revokeTrunkContractCancellation: '/revoke-trunk/:trunkId',
	scopeList: '/scope-list/:webuserId/:tokenId',
	sendFax: '/new-fax/:webuserId',
	setAcdAgents: '/acds/:acdId/agents',
	setAcdName: '/acds/:acdId/name',
	setAcdNumbers: '/acds/:acdId/phoneNumbers',
	setGroupAlias: '/group-alias/:groupId',
	showGroupPaymentInfo: '/create-group/paymentInfo',
	simDeactivateVoice: '/sim-deactivate-voice/:deviceId/:simId',
	smsSend: '/sms/:webuserId/:pseudoKey([A-Z0-9]+)?',
	ssoConfiguration: '/sso-configuration',
	transcribeSingleVoicemail: '/transcribe-single-voicemail/:eventId/:voicemailId?/',
	transferAudioFiles: '/transferAudio',
	trunkCallerId: '/trunk-caller-id/:trunkId',
	trunkEmergencyLocation: '/trunk-location/:trunkId',
	trunkRouting: '/trunk-routing/:trunkId',
	twoFactorAuthentication: '/two-factor-authentication',
	unlinkedDeviceCallerId: '/unlinked-caller-id/:deviceId',
	unrouteDevicePoolNumbersFromEndpoint: '/device-pool-numbers-unroute/:endpointId/:numberId',
	unverified: '/unverified',
	updateBlocklist: '/blocklist-update-entry/:phoneNumber',
	uploadLogo: '/upload-logo',
	userAvatarUpload: '/user-avatar-upload/:webuserId',
	userEmail: '/user-email/:userId',
	userEmailChangeRequest: '/revoke-user-email-change/:userId',
	userLanguage: '/user-language/:userId',
	userLocation: '/user-location/:userId',
	userPasswordReset: '/user-password-reset/:userId',
	verificationRequired: '/verification-required/:addressId',
	verifyAccount: '/verify-account',
	verifyAddressByLetter: '/verify-address-by-letter',
	voicemailActiveAudioFile: '/active-greeting/:voicemailId',
	voicemailAlias: '/voicemail-alias/:voicemailId',
	voicemailAuthorizedNumbers: '/voicemail-authorized-numbers/:webuserId/:voicemailId',
	voicemailPin: '/voicemail-pin/:userId/:voicemailId',
	voicemailTranscription: '/voicemail-transcription/:voicemailId',
	webphoneNotifications: '/webphone-notification',
});

export type WithDialogProps = {
	dialogs: ReturnType<typeof useDialogs>;
};
