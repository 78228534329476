import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, User, UserCredential } from 'firebase/auth';
import { DataSnapshot, get, getDatabase, onValue, ref } from 'firebase/database';
import { getFirestore, doc, onSnapshot, DocumentSnapshot, getDoc } from 'firebase/firestore';
import jwtDecode from 'jwt-decode';
import { logger } from '../../third-party/logger';
import { SatelliteUserContact } from '../types/satelliteUserContact';
import {
	OrganisationMembershipData,
	UserToChannelMapping,
} from '../../redux/modules/organisationMembership';
import { HttpClient } from './HttpClient';
import { OrganisationPayment } from '../types/organisationPayment';
import { TokenDetails } from '../../redux/modules/token';

export class FirebaseClient {
	config = {
		organisation: {
			bucketName: 'satellite-organisations-29f5c.appspot.com',
			dbUrl: 'https://satellite-organisations-29f5c.europe-west1.firebasedatabase.app',
			projectId: 'satellite-29f5c',
		},
	};

	private readonly liveOrgDBApp;

	private readonly organisationUserCredentials: Promise<UserCredential>;

	constructor(private readonly httpClient: HttpClient) {
		const liveOrganisationDatabaseConfig = {
			apiKey: 'AIzaSyDJ9QJw7f4Awcgyg3C8VeS3axIBT41kH34',
			authDomain: 'satellite-29f5c.firebaseapp.com',
			databaseURL: this.config.organisation.dbUrl,
			projectId: this.config.organisation.projectId,
			storageBucket: this.config.organisation.bucketName,
			messagingSenderId: '1051704079600',
			appId: '1:1051704079600:web:c34a09de9e185abe04fe35',
			measurementId: 'G-DEQ0S0C20J',
		};
		this.liveOrgDBApp = initializeApp(liveOrganisationDatabaseConfig, 'LIVE-ORGANISATION-DB');

		this.organisationUserCredentials = this.signIntoOrganisationDatabase();
	}

	async getOrganisationMemberForUser(): Promise<SatelliteUserContact[]> {
		const contacts = await this.organisationUserCredentials
			.then(async userCredential => {
				const user = userCredential.user;
				return this.getOrganisationContacts(user);
			})
			.catch(error => {
				const errorCode = error.code;
				const errorMessage = error.message;
				logger.log(`Error accessing DB ${errorCode}: ${errorMessage}: ${JSON.stringify(error)}}`);
				return [];
			});
		return contacts as SatelliteUserContact[];
	}

	private async getOrganisationContacts(userCredentials: User) {
		const organisationDatabase = this.getOrganisationDatabase();
		const userId = userCredentials?.uid;
		const organisationIds = await get(ref(organisationDatabase, `/userToOrganisations/${userId}`));
		if (
			!organisationIds.exists() ||
			organisationIds.val() === null ||
			organisationIds.val() === undefined
		) {
			logger.log(`${userId}: User has no organisations in firebase`);
			return [];
		}
		const val = organisationIds.val();
		const organisationId = Object.keys(val)[0];
		const users = await get(ref(organisationDatabase, `/contacts/${organisationId}`));
		if (!users.exists() || users.val() === null || users.val() === undefined) {
			logger.log(`${organisationId}: Organisation has no users in firebase for ${userId}`);
			return [];
		}
		const usersArray = users.val();
		const userObjectArray: SatelliteUserContact[] = Object.keys(usersArray).map((id: string) => ({
			...usersArray[id],
			organisationId,
			id: usersArray[id].userId,
		}));
		return userObjectArray as SatelliteUserContact[];
	}

	public async getOrganisationId() {
		return this.organisationUserCredentials.then(async userCredential => {
			const user = userCredential.user;
			const organisationDatabase = this.getOrganisationDatabase();
			const userId = user?.uid;
			const organisationIds = await get(
				ref(organisationDatabase, `/userToOrganisations/${userId}`)
			);
			if (
				!organisationIds.exists() ||
				organisationIds.val() === null ||
				organisationIds.val() === undefined
			) {
				logger.log(`${userId}: User has no organisations in firebase`);
				return '';
			}
			const val = organisationIds.val();
			return Object.keys(val)[0];
		});
	}

	getOrganisationDatabase() {
		return getDatabase(this.liveOrgDBApp);
	}

	async getToken(): Promise<Response> {
		return this.httpClient.get('/console', '/auth/token');
	}

	private async signIntoOrganisationDatabase() {
		const auth = getAuth(this.liveOrgDBApp);
		const token = await this.getCurrentToken();
		return signInWithCustomToken(auth, token);
	}

	public async getCurrentToken() {
		return (await this.getToken()).text();
	}

	async getFirebaseToken() {
		return this.organisationUserCredentials.then(async userCredential => {
			return userCredential.user.getIdTokenResult();
		});
	}

	async addOrganisationListener(
		databasePath: string,
		successCallback: (value: DataSnapshot) => unknown,
		errorCallback: (value: Error) => unknown
	) {
		return this.organisationUserCredentials.then(async () => {
			const organisationDatabase = this.getOrganisationDatabase();
			const listenerRef = ref(organisationDatabase, databasePath);
			return onValue(
				listenerRef,
				snapshot => {
					successCallback(snapshot);
				},
				error => {
					errorCallback(error);
				},
				{
					onlyOnce: false,
				}
			);
		});
	}

	async getOrganisationMembershipInfo() {
		return this.organisationUserCredentials.then(async () => {
			const db = this.getOrganisationDatabase();
			const organisationId = await this.getOrganisationId();
			const organisationMemberships = await get(
				ref(db, `/membership/${organisationId}/channelToUsers`)
			);
			if (!organisationMemberships.exists() || organisationMemberships.val() === null) {
				logger.warn('No membership membership found in db');
				return {};
			}
			const organisationMembership: OrganisationMembershipData = organisationMemberships.val();
			return organisationMembership;
		});
	}

	async getOrganisationMemberGroupMappings() {
		await this.organisationUserCredentials;
		const db = this.getOrganisationDatabase();
		const organisationId = await this.getOrganisationId();
		const organisationMemberships = await get(
			ref(db, `/membership/${organisationId}/userToChannels`)
		);
		if (!organisationMemberships.exists() || organisationMemberships.val() === null) {
			logger.warn('No membership membership found in db');
			return [] as UserToChannelMapping[];
		}
		const organisationContactGroups = organisationMemberships.val();
		const userToChannelMap: UserToChannelMapping[] = Object.keys(organisationContactGroups).map(
			userId => {
				const names = organisationContactGroups[userId];
				return {
					id: userId,
					channels: Object.keys(organisationContactGroups[userId]).map(channelId => {
						return {
							id: channelId,
							name: names[channelId].name,
						};
					}),
				};
			}
		);
		return userToChannelMap;
	}

	async addFireStoreListener(
		path: string,
		successCallback: (value: DocumentSnapshot) => unknown,
		errorCallback: (value: Error) => unknown
	) {
		const database = getFirestore(this.liveOrgDBApp);
		const listenerRef = doc(database, path);
		return onSnapshot(listenerRef, {
			next: snapshot => {
				successCallback(snapshot);
			},
			error: error => {
				errorCallback(error);
			},
		});
	}

	async getOrganisationPaymentState() {
		const organisationId = await this.getOrganisationId();
		const path = `/organisations/${organisationId}`;
		const database = getFirestore(this.liveOrgDBApp);
		const document = await getDoc(doc(database, path));
		const data = document?.data();
		return data as OrganisationPayment;
	}

	async getTokenDetails() {
		const currentToken = await this.getCurrentToken();
		const decodedToken = jwtDecode(currentToken) as TokenDetails;
		logger.log(decodedToken);
		return decodedToken;
	}
}
