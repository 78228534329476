import { DateTime } from 'luxon';
import * as actions from './actions';
import { HolidayPresetState } from './types';
import { handleActions } from '../../utils/actions';

const initialState: HolidayPresetState = {
	items: [],
	fetched: false,
	fetching: false,
};

export default handleActions<HolidayPresetState, PossibleActions<typeof actions>>(
	{
		HOLIDAY_PRESETS_FETCH_PENDING: state => {
			return {
				...state,
				fetching: true,
			};
		},
		HOLIDAY_PRESETS_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				items: payload.items.map(item => ({
					...item,
					upcomingHolidays: item.upcomingHolidays
						.map(holiday => ({
							...holiday,
							id: holiday.holidayId,
							date: DateTime.fromISO(holiday.date, { zone: 'utc' }),
							observedDate: DateTime.fromISO(holiday.observedDate, { zone: 'utc' }),
						}))
						.sort((a, b) => a.date.toMillis() - b.date.toMillis()),
				})),
			};
		},
	},
	initialState
);
