import React from 'react';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import classes from './VideoIdentFailedDialog.scss';
import { Translate } from '../../../../redux/modules/translations';
import IdentityVerificationAbortIcon from '../../../../media/icons/icon-verification-abort-dark-line-default-64.svg';

type Props = {
	translate: Translate;
};

const VideoIdentFailedDialog = (props: Props) => {
	const getHeadline = () =>
		props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_FAILED_DIALOG_HEADLINE');

	const getMainText = () => props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_FAILED_DIALOG_TEXT');

	return (
		<DeprecatedDialogContent
			containerClassName={classes.container}
			icon={<img src={IdentityVerificationAbortIcon} alt="" />}
			headline={getHeadline()}
		>
			<div className={classes.contentContainer}>{getMainText()}</div>
		</DeprecatedDialogContent>
	);
};

export default VideoIdentFailedDialog;
