import * as actions from './actions';
import { GroupStatisticsState } from './types';
import { handleActions } from '../..';

const initialState: GroupStatisticsState = {
	items: {},
	fetchingForGroup: [],
	fetchedForGroup: [],
	fetchingHistoryForGroup: [],
	fetchedHistoryForGroup: [],
};

export default handleActions<GroupStatisticsState, PossibleActions<typeof actions>>(
	{
		GROUP_STATISTICS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForGroup: [...state.fetchingForGroup, data.groupId],
		}),
		GROUP_STATISTICS_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				fetchingForGroup: [...state.fetchingForGroup.filter(groupId => groupId !== data.groupId)],
				fetchedForGroup: [
					...state.fetchedForGroup.filter(groupId => groupId !== data.groupId),
					data.groupId,
				],
				items: {
					...state.items,
					[data.groupId]: {
						data: {
							...state.items[data.groupId]?.data,
							last30Days: { ...payload, queueCurrentlyWaiting: undefined },
						},
						queueCurrentlyWaiting: payload.queueCurrentlyWaiting,
					},
				},
			};
		},
		GROUP_STATISTICS_HISTORY_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingHistoryForGroup: [...state.fetchingHistoryForGroup, data.groupId],
		}),
		GROUP_STATISTICS_HISTORY_FETCH_SUCCESS: (state, { data, payload }) => ({
			...state,
			fetchingHistoryForGroup: [
				...state.fetchingHistoryForGroup.filter(groupId => groupId !== data.groupId),
			],
			fetchedHistoryForGroup: [...state.fetchedHistoryForGroup, data.groupId],
			items: {
				...state.items,
				[data.groupId]: {
					queueCurrentlyWaiting: state.items[data.groupId]?.queueCurrentlyWaiting,
					data: {
						...(state.items[data.groupId]
							? state.items[data.groupId].data
							: {
									last30Days: {
										days: [],
									},
							  }),
						...payload,
					},
				},
			},
		}),
	},
	initialState
);
