import { createAction } from '../../utils/actions';
import api from '../../../api';
import { ReduxState } from '../../types';
import { ApiBase64Upload } from '../../../api/types/identityVerification';

export const fetchUsersAvatars = createAction('AVATARS_FETCH', (force?: boolean) => ({
	promise: () => api.getUsersAvatars(),
	shouldFetch: (state: ReduxState) => force ?? state.avatars.fetchingForUser.length === 0,
}));

export const fetchUserAvatar = createAction(
	'AVATAR_FETCH',
	(webuserId: string, force?: boolean) => ({
		promise: () => api.getUserAvatarForWebuser(webuserId),
		shouldFetch: () => force,
		data: { webuserId: webuserId.slice(7) },
	})
);

export const uploadUserAvatar = createAction(
	'AVATARS_UPLOAD',
	(webuserId: string, base64Content: string) => ({
		promise: () => {
			const request = {
				name: 'avatar.png',
				content: base64Content.split(',')[1],
			} as ApiBase64Upload;
			return api.uploadUserAvatar(webuserId, request);
		},
		data: { webuserId, base64Content },
		shouldFetch: (state: ReduxState) => !state.avatars.uploading,
	})
);

export const deleteUserAvatar = createAction('AVATAR_DELETE', (webuserId: string) => ({
	promise: async () => {
		await api.deleteUserAvatar(webuserId);
		return api.getUserAvatarForWebuser(webuserId);
	},
	data: { webuserId },
}));

export const resetUploadError = createAction('AVATAR_RESET_UPLOAD_ERROR');

export const resetUploadSuccess = createAction('AVATAR_RESET_UPLOAD_SUCCESS');
