export const CALL_CREATED = 'CALL_CREATED';
export const CALL_ACCEPTED = 'CALL_ACCEPTED';
export const CALL_TERMINATED = 'CALL_TERMINATED';
export const CALL_TERMINATION_FINISHED = 'CALL_TERMINATION_FINISHED';
export const CALL_MUTED = 'CALL_MUTED';
export const CALL_UNMUTED = 'CALL_UNMUTED';
export const SIP_CLIENT_CONNECTED = 'SIP_CLIENT_CONNECTED';
export const SIP_CLIENT_DISCONNECTED = 'SIP_CLIENT_DISCONNECTED';
export const BROWSER_ONLINE = 'BROWSER_ONLINE';
export const BROWSER_OFFLINE = 'BROWSER_OFFLINE';
