// This function literally only exists to keep people from mistyping stuff
const check = <Icons extends Record<string, SetIconDefinition>>(i: Icons): Icons => i;

export interface SetIconDefinition {
	label: string;
	inlineSvg?: string;

	box: Partial<Record<string, string>>;
}

export const icons = check({
	add: {
		label: 'PANDA_ICON_ADD',
		box: {
			'12': 'plus-12',
			'16': 'plus-16',
			'24': 'plus-24',
			'32': 'plus-32',
		},
	},

	back: {
		label: 'PANDA_ICON_BACK',
		box: {
			'16': 'slim_arrow_left-16',
			'24': 'slim_arrow_left-24',
			'32': 'slim_arrow_left-32',
		},
	},

	copy: {
		label: 'PANDA_ICON_COPY',
		box: {
			'16': 'copy-16',
			'24': 'copy-24',
			'32': 'copy-32',
		},
	},

	close: {
		label: 'PANDA_ICON_CLOSE',
		box: {
			'16': 'cross-16',
			'24': 'cross-24',
			'32': 'cross-32',
		},
	},

	delete: {
		label: 'PANDA_ICON_DELETE',
		box: {
			'16': 'delete-16',
			'24': 'delete-24',
			'32': 'delete-32',
		},
	},

	download: {
		label: 'PANDA_ICON_DOWNLOAD',
		inlineSvg: 'download-16-noframe',

		box: {
			'12': 'download-12',
			'16': 'download-16',
			'24': 'download-24',
		},
	},

	edit: {
		label: 'PANDA_ICON_EDIT',
		box: {
			'16': 'settings-16',
			'24': 'settings-24',
			'32': 'settings-32',
		},
	},

	export: {
		label: 'PANDA_ICON_EXPORT',
		box: {
			'16': 'file_with_arrow-16',
			'24': 'file_with_arrow-24',
		},
	},

	'external-link': {
		label: 'PANDA_ICON_EXTERNAL_LINK',
		inlineSvg: 'external_link-16-noframe',

		box: {
			'12': 'external_link-12',
			'16': 'external_link-16',
			'24': 'external_link-24',
		},
	},

	file: {
		label: 'PANDA_ICON_FILE',
		box: {
			'16': 'file-16',
			'24': 'file-24',
			'32': 'file-32',
		},
	},

	'hide-password': {
		label: 'PANDA_ICON_HIDE_PASSWORD',
		box: {
			'16': 'hide-16',
			'24': 'hide-24',
		},
	},

	info: {
		label: 'PANDA_ICON_INFO',
		box: {
			'16': 'info-16',
			'24': 'info-24',
			'32': 'info-32',
		},
	},

	'internal-link': {
		label: 'PANDA_ICON_INTERNAL_LINK',
		box: {
			'12': 'arrow_right-12',
			'16': 'arrow_right-16',
			'24': 'arrow_right-24',
		},
	},

	more: {
		label: 'PANDA_ICON_MORE',
		box: {
			'16': 'meatballs-16',
			'24': 'meatballs-24',
			'32': 'meatballs-32',
		},
	},

	next: {
		label: 'PANDA_ICON_NEXT',
		box: {
			'16': 'slim_arrow_right-16',
			'24': 'slim_arrow_right-24',
			'32': 'slim_arrow_right-32',
		},
	},

	search: {
		label: 'PANDA_ICON_SEARCH',
		box: {
			'16': 'search-16',
		},
	},

	'show-password': {
		label: 'PANDA_ICON_SHOW_PASSWORD',
		box: {
			'16': 'show-16',
			'24': 'show-24',
		},
	},
} as const);
