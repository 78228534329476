import React from 'react';
import classnames from 'classnames';
import { TableBodyContext } from './contexts/tableBodyContext';
import { useTableContext } from './contexts/tableContext';
import { TableRow } from './TableRow';

type Props = {
	children: React.ReactComponentElement<typeof TableRow>[];
};
const styles = {
	tbody: classnames('hyphens'),
};

const TableBody = ({ children }: Props): JSX.Element => {
	const { activeRow } = useTableContext();

	return (
		<tbody className={styles.tbody}>
			{React.Children.map(children, (child, index) => {
				const isActiveRow = activeRow === index;
				const isFirstRow = index === 0;
				const isLastRow = React.Children.count(children) - 1 === index;
				return (
					<TableBodyContext.Provider
						value={{
							rowIndex: index,
							isActiveRow,
							isFirstRow,
							isLastRow,
						}}
					>
						{child}
					</TableBodyContext.Provider>
				);
			})}
		</tbody>
	);
};

export { TableBody };
