import { CustomerBaseProduct, CustomerProductState } from './types';

export const getCustomerBaseProduct = (
	state: CustomerProductState
): CustomerBaseProduct | undefined => {
	if (state.items.length === 0) {
		return undefined;
	}

	return state.items.find(a => a.productType === 'base') as CustomerBaseProduct;
};
