import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import optimist from 'redux-optimist';
import { errorMiddleware } from './middleware/error-middleware';
import { loadingAnimationMiddleware } from './middleware/loading-animation-middleware';
import promiseMiddleware from './middleware/redux-promise-middleware';
import { shouldFetchMiddleware } from './middleware/should-fetch-middleware';
import { updateRestrictionMiddleware } from './middleware/update-restriction-middleware';
import { rootReducer } from './reducer';

export function configureStore() {
	const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(
		optimist(rootReducer),
		undefined,

		composer(
			applyMiddleware(
				thunk,
				shouldFetchMiddleware,
				updateRestrictionMiddleware,
				errorMiddleware,
				promiseMiddleware,
				loadingAnimationMiddleware
			)
		)
	);
}
