import { IgelState } from './types';
import * as actions from './actions';
import { handleActions } from '../..';

export const initialState: IgelState = {
	isScrolled: false,
};

export default handleActions<IgelState, PossibleActions<typeof actions>>(
	{
		IGEL_IS_SCROLLED: (state, { payload: isScrolled }) => ({
			isScrolled,
		}),
	},
	initialState
);
