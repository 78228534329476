import React from 'react';
import { Button } from '@panda/ui';
import { connect, ReduxProps } from '../../redux';
import MediaQueryDecorator, { MediaQueryProps } from '../../utils/MediaQueryDecorator';
import { Overlay } from '../Overlay';

import classes from './DeprecatedDialog.scss';
import { ReduxState } from '../../redux/types';
import { closeDialog, openDialog } from '../../redux/modules/snackbar';

const mapStateToProps = (state: ReduxState) => ({
	translate: state.translations.translate,
});

const mapDispatchToProps = {
	openDialog,
	closeDialog,
};

interface ExternalProps {
	focusTrap?: boolean;
	onDialogBack?: () => void;
	onDialogClose?: () => void;
	additionalOverlayContent?: React.ReactNode;
	children: React.ReactNode;
}

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	ExternalProps &
	MediaQueryProps;

/** @deprecated Use withDialogPage/withDialogFlow instead */
class DeprecatedDialog extends React.Component<Props> {
	public componentDidMount(): void {
		this.props.openDialog();
	}

	public componentWillUnmount(): void {
		this.props.closeDialog();
	}

	private renderBackButton() {
		if (!this.props.onDialogBack) {
			return null;
		}

		return (
			<div className={classes.backIconContainer}>
				<Button
					icon="back"
					iconOnly
					variant="quiet"
					size="xlarge"
					onClick={this.props.onDialogBack}
				/>
			</div>
		);
	}

	private renderCloseButton() {
		if (!this.props.onDialogClose) {
			return null;
		}

		return (
			<div className={classes.closeIconContainer}>
				<Button
					icon="close"
					iconOnly
					variant="quiet"
					size="xlarge"
					onClick={this.props.onDialogClose}
				/>
			</div>
		);
	}

	public render() {
		return (
			<Overlay onClose={this.props.onDialogClose} focusTrap={this.props.focusTrap}>
				<div className={classes.content}>
					{this.renderBackButton()}
					{this.renderCloseButton()}

					{this.props.children}
				</div>

				{this.props.additionalOverlayContent}
			</Overlay>
		);
	}
}

/** @deprecated Use withDialogPage/withDialogFlow instead */
export default connect(mapStateToProps, mapDispatchToProps)(MediaQueryDecorator(DeprecatedDialog));
