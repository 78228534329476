import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { connect, ReduxProps } from '../redux';

import * as mixpanel from '../third-party/mixpanel';

import auth from '../utils/authenticate/auth';
import LogoutView from '../views/LogoutView';
import UserRedirect from '../redirects/UserRedirect';
import WbciOptInRedirect from '../redirects/WbciOptInRedirect';
import { fetchUserInfo } from '../redux/modules/userinfo';
import { fetchTranslations } from '../redux/modules/translations';
import { fetchTacs } from '../redux/modules/tacs';
import { ReduxState } from '../redux/types';
import { fetchAppProperties } from '../redux/modules/appProperties';
import { LogoSpinner } from '../components/spinner/LogoSpinner';
import { fetchEvents, selectForceEvents } from '../redux/modules/events';
import { fetchCustomerProducts } from '../redux/modules/customerProducts';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import ForceWebView from '../views/forceweb/ForceWebView';
import { fetchTokenDetails, TokenDetails } from '../redux/modules/token';

const mapStateToProps = (state: ReduxState) => ({
	tacs: state.tacs,
	userinfo: state.userinfo,
	translations: state.translations,
	appProperties: state.appProperties,
	events: state.events,
	customerProducts: state.customerProducts,
	tokenDetails: state.tokenDetailsState,
});

const mapDispatchToProps = {
	fetchTacs,
	fetchUserInfo,
	fetchTranslations,
	fetchAppProperties,
	fetchEvents,
	fetchCustomerProducts,
	fetchTokenDetails,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & RouteComponentProps;

class AuthenticatedRoot extends React.Component<Props> {
	public componentDidMount() {
		auth.initializeFromLocalStorage();
		this.props.fetchEvents();
		this.props.fetchTacs();
		this.props.fetchUserInfo();
		this.props.fetchAppProperties();
		this.props.fetchCustomerProducts();

		if (this.props.userinfo.fetched) {
			this.props.fetchTranslations(this.props.userinfo.data.locale);
		}

		this.props.fetchTokenDetails();
		this.initiateMixpanel();
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.userinfo.fetched &&
			this.props.userinfo.data.locale !== prevProps.userinfo.data?.locale
		) {
			this.props.fetchTranslations(this.props.userinfo.data.locale);
		}

		if (
			!prevProps.tacs.fetched ||
			!prevProps.userinfo.fetched ||
			!prevProps.customerProducts.fetched
		) {
			this.initiateMixpanel();
		} else if (this.props.location !== prevProps.location) {
			mixpanel.trackPageView(this.props.history.action === 'REPLACE');
		}

		if (
			!window.location.href.includes('/force') &&
			this.props.events.fetched &&
			selectForceEvents(this.props.events).length > 0
		) {
			this.props.history.replace('/force');
		}
	}

	private initiateMixpanel() {
		if (!this.props.userinfo.fetched) {
			return;
		}

		mixpanel.init(this.props.userinfo.data, false);
		mixpanel.trackPageView(this.props.history.action === 'REPLACE');
	}

	public render() {
		if (
			this.props.events.fetched &&
			this.props.translations.fetched &&
			this.props.userinfo.fetched &&
			selectForceEvents(this.props.events).length > 0
		) {
			return (
				<>
					<Switch>
						<Route path="/force" component={ForceWebView} />
					</Switch>
				</>
			);
		}

		if (
			!this.props.translations.fetched ||
			!this.props.userinfo.fetched ||
			!this.props.events.fetched ||
			/* We check appProperties here to smooth out app web loading process and prevent some popin */
			!this.props.appProperties.fetched ||
			!this.props.tokenDetails.fetched
		) {
			return <LogoSpinner />;
		}
		const tokenDetails = this.props.tokenDetails.item;
		if (this.userIsAdmin(tokenDetails) === false) {
			auth.logoutAndRedirectToLogoutPage(undefined, window.location.href);
		}

		const user = this.props?.userinfo?.data?.sub || '1234567';
		return (
			<>
				<Switch>
					<Route path="/wbciOptIn/*" component={WbciOptInRedirect} />
					<Route path="/logout" component={LogoutView} />

					<Route path="/:webuserId(w\d+)/(.*)" component={UserRedirect} />

					<Route render={() => <AuthenticatedLayout webuserId={user} />} />
				</Switch>
			</>
		);
	}

	private userIsAdmin(tokenDetails: TokenDetails) {
		const claims = tokenDetails.claims;
		return claims?.organisations ? Object.values(claims.organisations)[0].admin : false;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoot);
