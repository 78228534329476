import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import classes from './ReplaceTransition.scss';

interface Props {
	children: React.ReactElement;
	innerKey: string;
}

export class ReplaceTransition extends React.Component<Props> {
	public render() {
		return (
			<SwitchTransition mode="out-in">
				<CSSTransition key={this.props.innerKey} timeout={150} classNames={classes}>
					{this.props.children}
				</CSSTransition>
			</SwitchTransition>
		);
	}
}
