/* eslint-disable react/no-unused-prop-types */
import React from 'react';

export type RadioChildren = string | React.ReactNode;

export type RadioProps = {
	value: string;
	/**
	 * Die `children` sind das Label des `Radio`.
	 *
	 * `string` (recommended) | `React.Node`
	 */
	children: RadioChildren;
	disabled?: boolean;
};

/**
 * This components exist so the usage of <RadioGroup/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Radio = (props: RadioProps) => null;
