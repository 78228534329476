import api from '../../../api';
import { CustomerBaseProduct, DefaultCustomerProduct } from './types';
import { createAction } from '../..';

export const fetchCustomerProducts = createAction(
	'CUSTOMER_PRODUCTS_FETCH',
	(onFetched?: (result: [...DefaultCustomerProduct[], CustomerBaseProduct]) => void) => ({
		promise: () =>
			api.getCustomerProducts().then(result => {
				if (onFetched) {
					onFetched(result.items);
				}
				return result;
			}),
	})
);
