import { handleActions } from '../..';
import { ConferenceRoomState } from './types';
import * as actions from './actions';

const initialState: ConferenceRoomState = {
	fetched: false,
	fetching: false,
	items: [],
	updatingForConferenceRoom: [],
};

export default handleActions<ConferenceRoomState, PossibleActions<typeof actions>>(
	{
		CONFERENCE_ROOMS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		CONFERENCE_ROOMS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload,
		}),
		CONFERENCE_ROOM_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				items: [...state.items, payload],
			};
		},
		CONFERENCE_ROOM_RENAME_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.id) {
					return item;
				}

				return {
					...item,
					alias: data.alias,
				};
			}),
		}),
		CONFERENCE_ROOM_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.id),
		}),
		CONFERENCE_ROOM_GREETING_CREATE_SUCCESS: (state, { data, payload }) => ({
			...state,
			items: state.items.map(conferenceRoom => {
				if (conferenceRoom.id !== data.conferenceRoomId) {
					return conferenceRoom;
				}

				return { ...conferenceRoom, greeting: payload };
			}),
		}),
		CONFERENCE_ROOM_GREETING_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(conferenceRoom => {
				if (conferenceRoom.id !== data.conferenceRoomId) {
					return conferenceRoom;
				}

				return { ...conferenceRoom, greeting: null };
			}),
			updatingForConferenceRoom: [...state.updatingForConferenceRoom, data.conferenceRoomId],
		}),
		CONFERENCE_ROOM_GREETING_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			updatingForConferenceRoom: state.updatingForConferenceRoom.filter(
				conferenceRoom => conferenceRoom !== data.conferenceRoomId
			),
		}),
		CONFERENCE_ROOM_ACTIVE_AUDIOFILE_GREETING_SET_PENDING: (state, { data }) => ({
			...state,
			updatingForConferenceRoom: [...state.updatingForConferenceRoom, data.conferenceRoomId],
			items: state.items.map(conferenceRoom => {
				if (conferenceRoom.id !== data.conferenceRoomId) {
					return conferenceRoom;
				}

				return {
					...conferenceRoom,
					greeting: data.activeAudioFileId
						? {
								...conferenceRoom.greeting,
								id: data.conferenceRoomId,
								activeAudioFileId: data.activeAudioFileId,
						  }
						: null,
				};
			}),
		}),
		CONFERENCE_ROOM_ACTIVE_AUDIOFILE_GREETING_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForConferenceRoom: state.updatingForConferenceRoom.filter(
				conferenceRoom => conferenceRoom !== data.conferenceRoomId
			),
		}),
	},
	initialState
);
