import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { ReduxState } from '../../redux/types';
import { closeInvalidAccountStateSnackbar } from '../../redux/modules/snackbar';
import Snackbar from './Snackbar';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.invalidAccountStateSnackbarOpen,
	translate: state.translations.translate,
});

const mapDispatchToProps = {
	closeInvalidAccountStateSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

const InvalidAccountStateSnackbar = (props: Props) => (
	<Snackbar
		key="invalid-account-state-snackbar"
		snackbarOpen={props.open}
		closeSnackbar={props.closeInvalidAccountStateSnackbar}
		severity="error"
	>
		{props.translate('INVALID_ACCOUNT_STATE_SNACKBAR_TEXT')}
	</Snackbar>
);

export default connect(mapStateToProps, mapDispatchToProps)(InvalidAccountStateSnackbar);
