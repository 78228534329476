import React from 'react';
import CoffeeIcon from '../../../../media/icons/icon-coffee-dark-line-default-64.svg';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import classes from './AfterVideoIdentDialog.scss';
import { Translate } from '../../../../redux/modules/translations';

type ExternalProps = {
	translate: Translate;
};

const AfterVideoIdentDialog = (props: ExternalProps) => {
	const getHeadline = () => props.translate('IDENTITY_VERIFICATION_AFTER_REDIRECT_DIALOG_HEADLINE');

	const getMainText = () => props.translate('IDENTITY_VERIFICATION_AFTER_REDIRECT_DIALOG_TEXT');

	return (
		<DeprecatedDialogContent
			containerClassName={classes.container}
			icon={<img src={CoffeeIcon} alt="" />}
			headline={getHeadline()}
		>
			<div className={classes.contentContainer}>{getMainText()}</div>
		</DeprecatedDialogContent>
	);
};

export default AfterVideoIdentDialog;
