import React from 'react';
import { Button } from '@panda/ui';

interface Props {
	delete?: boolean;
	continue?: boolean;
	children: string;
	disabled?: boolean;
	loading?: boolean;
	width?: 'max' | 'max-on-touch-device';
}

export class SubmitButton extends React.Component<Props> {
	public render() {
		return (
			<Button
				disabled={this.props.disabled}
				loading={this.props.loading}
				action={this.props.continue ? 'trigger' : 'confirm'}
				variant={this.props.continue ? 'loud' : 'normal'}
				type="submit"
				width={this.props.width || 'max-on-touch-device'}
				size="xlarge"
				icon={this.props.delete ? 'delete' : undefined}
				critical={this.props.delete}
			>
				{this.props.children}
			</Button>
		);
	}
}
