import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import { hasKey } from '../../utils/types';

function isPromise(value: unknown): value is Promise<unknown> {
	return hasKey(value, 'then') && typeof value.then === 'function';
}

export interface PromiseAction<Type extends string, Data, Result> {
	type: Type;
	payload: { promise: Promise<Result>; data?: Data };
}

export function isPromiseAction(
	action: unknown
): action is PromiseAction<string, unknown, unknown> {
	return (
		hasKey(action, 'payload') &&
		hasKey(action.payload, 'promise') &&
		isPromise(action.payload.promise)
	);
}

type PromisePendingAction<Action extends PromiseAction<string, unknown, unknown>> = {
	type: `${Action['type']}_PENDING`;
	data: Action['payload']['data'];
	payload: Action['payload']['data'];
	optimist: { type: string; id: number };
};

type PromiseSuccessAction<Action extends PromiseAction<string, unknown, unknown>> = {
	type: `${Action['type']}_SUCCESS`;
	data: Action['payload']['data'];
	payload: Action['payload']['promise'] extends Promise<infer T> ? T : never;
	optimist: { type: string; id: number };
};

type PromiseFailureAction<Action extends PromiseAction<string, unknown, unknown>> = {
	type: `${Action['type']}_FAILURE`;
	data: Action['payload']['data'];
	payload: unknown;
	optimist: { type: string; id: number };
};

export type PromiseMiddlewareNext<Action> = Action extends PromiseAction<string, unknown, unknown>
	? PromisePendingAction<Action>
	: Action;

export type PromiseMiddlewareResult<Action, NextResult> = Action extends PromiseAction<
	string,
	unknown,
	unknown
>
	? // Technically a lie as the new actions are dispatched and therefore the final dispatch result should be assigned as
	  // the promises result. To prevent overcomplicating this typisation even further I will skip this step here.
	  Omit<Action, 'payload'> & {
			payload: Omit<Action['payload'], 'promise'> & {
				promise: Promise<PromiseSuccessAction<Action> | PromiseFailureAction<Action>>;
			};
	  }
	: NextResult;

export type PromiseMiddlewareEmits<Action> = Action extends PromiseAction<string, unknown, unknown>
	? PromiseSuccessAction<Action> | PromiseFailureAction<Action>
	: never;

let nextTransactionID = 0;

export default ({
	dispatch,
}: {
	dispatch: <A extends PromiseMiddlewareEmits<PromiseAction<string, unknown, unknown>>>(
		action: A
	) => A;
}) => {
	return <Action, NextResult>(next: (action: PromiseMiddlewareNext<Action>) => NextResult) =>
		(action: Action): PromiseMiddlewareResult<Action, NextResult> => {
			if (!isPromiseAction(action)) {
				return next(action as PromiseMiddlewareNext<Action>) as PromiseMiddlewareResult<
					Action,
					NextResult
				>;
			}

			nextTransactionID += 1;
			const transactionID = nextTransactionID;

			next({
				type: `${action.type}_PENDING`,
				optimist: { type: BEGIN, id: transactionID },
				payload: action.payload.data,
				data: action.payload.data,
			} as PromiseMiddlewareNext<Action>);

			const newPromise = action.payload.promise.then(
				resolved =>
					dispatch({
						type: `${action.type}_SUCCESS`,

						data: action.payload.data,
						payload: resolved,

						optimist: { type: COMMIT, id: transactionID },
					} as PromiseSuccessAction<typeof action>),

				rejected =>
					dispatch({
						type: `${action.type}_FAILURE`,

						data: action.payload.data,
						payload: rejected,

						optimist: { type: REVERT, id: transactionID },
					} as PromiseFailureAction<typeof action>)
			);

			return {
				...action,
				payload: {
					...action.payload,
					promise: newPromise,
				},
			} as unknown as PromiseMiddlewareResult<Action, NextResult>;
		};
};
