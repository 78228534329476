import { createAction } from '../..';
import api from '../../../api';
import { ApiBase64Upload } from '../../../api/types/identityVerification';
import { ReduxState } from '../../types';

function shouldFetchAccount(state: ReduxState) {
	return !state.account.fetching && !state.account.fetched;
}

export const fetchAccount = createAction('ACCOUNT_FETCH', (force?: boolean) => ({
	promise: () => api.getAccount(),
	shouldFetch: (state: ReduxState) => force || shouldFetchAccount(state),
}));

export const uploadLogo = createAction('ACCOUNT_UPLOAD_LOGO', (base64Content: string) => ({
	promise: () => {
		const request = {
			name: 'logo.png',
			content: base64Content,
		} as ApiBase64Upload;
		return api.uploadLogo(request);
	},
	shouldFetch: (state: ReduxState) => !state.account.uploadingLogo,
}));

export const deleteLogo = createAction('ACCOUNT_DELETE_LOGO', () => ({
	promise: api.deleteLogo(),
}));

export const verifyAccount = createAction('ACCOUNT_VERIFY');

export const resetUploadError = createAction('ACCOUNT_RESET_UPLOAD_ERROR');

export const resetUploadSuccess = createAction('ACCOUNT_RESET_UPLOAD_SUCCESS');
