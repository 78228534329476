import * as actions from './actions';
import { DateBasedForwarding, DateBasedForwardingState } from './types';
import { handleActions } from '../..';
import { ApiDateBasedSet } from '../../../api/types/dateBasedSets';

const initialState: DateBasedForwardingState = {
	items: {},
	fetchingForExtension: [],
	fetchedForExtension: [],
};

export default handleActions<DateBasedForwardingState, PossibleActions<typeof actions>>(
	{
		DATE_BASED_FORWARDING_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForExtension: [...state.fetchingForExtension, data.extensionId],
		}),
		DATE_BASED_FORWARDING_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				fetchingForExtension: [
					...state.fetchingForExtension.filter(extensionId => extensionId !== data.extensionId),
				],
				fetchedForExtension: [
					...state.fetchedForExtension.filter(extensionId => extensionId !== data.extensionId),
					data.extensionId,
				],
				items: {
					...state.items,
					[data.extensionId]:
						payload !== null
							? [
									...payload.map(set => {
										switch (set.type) {
											case 'HOLIDAY':
												return {
													extensionId: set.extensionId,
													id: set.id,
													holidayPresetId: set.holidayPresetId,
													destination: set.destination,
													type: 'HOLIDAY' as const,
													selectedHolidayIds: set.selectedHolidayIds,
													name: set.name || '',
												};
											case 'CUSTOM':
												return {
													extensionId: set.extensionId,
													id: set.id,
													holidayPresetId: set.holidayPresetId,
													destination: set.destination,
													type: 'CUSTOM' as const,
													customDates: set.customDates.sort(),
													name: set.name || '', // TODO remove after backend is deployed with mandatory name (and fix type)
												};
										}
										throw new Error('Unknown date based forwarding type');
									}),
							  ].sort((a, b) => Number.parseInt(a.id, 10) - Number.parseInt(b.id, 10))
							: null,
				},
			};
		},
		DATE_BASED_FORWARDINGS_SAVE_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				items: {
					...state.items,
					[data.extensionId]: [
						...payload.items
							.sort(
								(apiSetA, apiSetB) =>
									Number.parseInt(apiSetA.id, 10) - Number.parseInt(apiSetB.id, 10)
							)
							.map((dateBasedSet: ApiDateBasedSet): DateBasedForwarding => {
								switch (dateBasedSet.type) {
									case 'HOLIDAY':
										return {
											extensionId: data.extensionId,
											id: dateBasedSet.id,
											type: 'HOLIDAY' as const,
											holidayPresetId: dateBasedSet.preset,
											selectedHolidayIds: dateBasedSet.selectedHolidays,
											name: dateBasedSet.name,
											destination:
												state.items[data.extensionId]?.find(i => i.id === dateBasedSet.id)
													?.destination ?? null,
										};
									case 'CUSTOM':
										return {
											extensionId: data.extensionId,
											id: dateBasedSet.id,
											type: 'CUSTOM' as const,
											customDates: dateBasedSet.customDates.sort(),
											name: dateBasedSet.name || '',
											destination:
												state.items[data.extensionId]?.find(i => i.id === dateBasedSet.id)
													?.destination ?? null,
										};
								}
								throw new Error(`Invalid date based set type: ${dateBasedSet}`);
							}),
					],
				},
			};
		},
		DATE_BASED_FORWARDING_DESTINATION_SAVE_SUCCESS: (state, { data }) => {
			let dateBasedForwardings = state.items[data.extensionId];
			if (dateBasedForwardings) {
				const dateBasedForwarding = dateBasedForwardings.find(
					dateBasedSet => dateBasedSet.id === data.dateBasedSetId
				);

				if (dateBasedForwarding) {
					dateBasedForwardings = dateBasedForwardings.map(forwarding => {
						if (forwarding.id === data.dateBasedSetId) {
							return {
								...forwarding,
								destination: data.destination,
							};
						}

						return forwarding;
					});
				}
			}
			return {
				...state,
				items: {
					...state.items,
					[data.extensionId]: dateBasedForwardings,
				},
			};
		},
	},
	initialState
);
