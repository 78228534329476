import * as actions from './actions';
import { AppSubscriptionTypesState } from './types';
import { handleActions } from '../..';
import { ApiAppSubscriptionType } from '../../../api/types/app';
import { ProductWithDescription } from '../../../components/dialogs/mobileNew/components/ProductChoiceRadioGroup';

const initialState: AppSubscriptionTypesState = {
	fetching: false,
	fetched: false,
	items: [],
};
const mapApiAppSubscriptionTypesToProductsWithDescription = (
	apiAppSubscriptionTypes: ApiAppSubscriptionType[]
): ProductWithDescription[] => {
	return apiAppSubscriptionTypes.map(apiSubscriptionType => ({
		id: apiSubscriptionType.name,
		name: apiSubscriptionType.name,
		recurring: true,
		recurringFee: apiSubscriptionType.price,
		nonRecurringFee: 0,
		legacy: false,
	}));
};

export const appSubscriptionReducer = handleActions<
	AppSubscriptionTypesState,
	PossibleActions<typeof actions>
>(
	{
		APP_SUBSCRIPTION_TYPES_FETCH_PENDING: state => ({
			...state,
			fetching: true,
			fetched: false,
			items: [],
		}),
		APP_SUBSCRIPTION_TYPES_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: mapApiAppSubscriptionTypesToProductsWithDescription(payload.items),
		}),
	},
	initialState
);
