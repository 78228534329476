import { handleActions } from '../..';
import * as actions from './actions';
import { AccountState } from './types';

const initialState: AccountState = {
	fetching: false,
	fetched: false,
	uploadingLogo: false,
	uploadedLogo: false,
	uploadLogoError: false,
	deletingLogo: false,
};

export default handleActions<AccountState, PossibleActions<typeof actions>>(
	{
		ACCOUNT_FETCH_PENDING: state => ({ ...state, fetching: true }),

		ACCOUNT_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			...payload,
		}),

		ACCOUNT_VERIFY: state => ({ ...state, verified: true }),

		ACCOUNT_UPLOAD_LOGO_PENDING: state => ({
			...state,
			uploadingLogo: true,
			uploadedLogo: false,
			uploadLogoError: false,
		}),

		ACCOUNT_UPLOAD_LOGO_SUCCESS: state => ({
			...state,
			uploadingLogo: false,
			uploadedLogo: true,
			uploadLogoError: false,
		}),

		ACCOUNT_UPLOAD_LOGO_FAILURE: state => ({
			...state,
			uploadingLogo: false,
			uploadedLogo: false,
			uploadLogoError: true,
		}),
		ACCOUNT_DELETE_LOGO_PENDING: state => ({ ...state, deletingLogo: true }),

		ACCOUNT_DELETE_LOGO_SUCCESS: state => ({
			...state,
			deletingLogo: false,
			logoUrl: '',
		}),
		ACCOUNT_DELETE_LOGO_FAILURE: state => ({ ...state, deletingLogo: false }),

		ACCOUNT_RESET_UPLOAD_ERROR: state => ({ ...state, uploadLogoError: false }),

		ACCOUNT_RESET_UPLOAD_SUCCESS: state => ({ ...state, uploadedLogo: false }),
	},
	initialState
);
