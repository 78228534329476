import { handleActions } from '../..';
import * as actions from './actions';
import { TrunkContingentContractsState } from './types';

const initialState: TrunkContingentContractsState = {
	items: [],
	fetching: false,
	fetched: false,
	trunkId: '',
};

export default handleActions<TrunkContingentContractsState, PossibleActions<typeof actions>>(
	{
		TRUNK_CONTINGENT_CONTRACTS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
			fetched: false,
		}),
		TRUNK_CONTINGENT_CONTRACTS_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload.items,
				trunkId: data.trunkId,
			};
		},
	},
	initialState
);
