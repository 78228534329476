import { createAction } from '../..';
import { DeviceContingentState } from './types';
import api from '../../../api';

const shouldFetchDeviceContingents = (state: DeviceContingentState, deviceId: string) =>
	!state.fetchingForDevice.includes(deviceId) && !state.fetchedForDevice.includes(deviceId);

export const fetchDeviceContingents = createAction(
	'DEVICE_CONTINGENTS_FETCH',
	(userId: string, deviceId: string) => ({
		promise: () => api.getContingents(userId, deviceId),
		shouldFetch: (state: { deviceContingents: DeviceContingentState }) =>
			shouldFetchDeviceContingents(state.deviceContingents, deviceId),
		data: { userId, deviceId },
	})
);

export const forceFetchDeviceContingents = createAction(
	'DEVICE_CONTINGENTS_FETCH',
	(userId: string, deviceId: string) => ({
		promise: api.getContingents(userId, deviceId),
		data: { userId, deviceId },
	})
);
