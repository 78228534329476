import { createAction } from '../..';
import { ReduxState } from '../../types';
import api from '../../../api';

function shouldFetchBalance(state: ReduxState) {
	return !state.balance.fetched && !state.balance.fetching;
}

export const fetchBalance = createAction('BALANCE_FETCH', (force?: boolean) => ({
	promise: () => api.getBalance(),
	shouldFetch: (state: ReduxState) => force || shouldFetchBalance(state),
}));
