import React from 'react';
import classnames from 'classnames';
import classes from './SideNavigationPill.scss';
import { useTranslate } from '../../../redux/modules/translations';

export type NavigationPillType = 'NEW' | 'BETA';

interface Props {
	type: NavigationPillType;
}

export const SideNavigationPill = (props: Props) => {
	const translate = useTranslate();

	return (
		<span
			className={classnames(classes.pill, {
				[classes.new]: props.type === 'NEW',
				[classes.beta]: props.type === 'BETA',
			})}
		>
			{props.type === 'NEW' ? translate('NAVIGATION_PILL_NEW') : translate('NAVIGATION_PILL_BETA')}
		</span>
	);
};
