import moment from 'moment-timezone';
import { saveLocale } from '@web-apps/phonenumbers-utils';
import { handleActions } from '../..';

import * as actions from './actions';
import { UserInfoState } from './types';

export const initialState: UserInfoState = {
	data: null,

	impersonated: false,
	fetched: false,
	fetching: false,
};

export default handleActions<UserInfoState, PossibleActions<typeof actions>>(
	{
		USERINFO_FETCH_PENDING: state => ({ ...state, fetching: true }),
		USERINFO_FETCH_SUCCESS: (state, { payload }) => {
			moment.locale(payload.locale);

			/*
			 * This is a hack, packages/app-web/src/utils/phonenumbers/index.ts uses
			 * locale as default from localstorage instead from the store.
			 * Big appreciated if you can fix it.
			 */
			saveLocale(payload.locale);

			return {
				fetching: false,
				fetched: true as const,

				impersonated: state.impersonated,
				data: payload,
			};
		},
		USERINFO_SET_IMPERSONATED: state => ({ ...state, impersonated: true }),
	},
	initialState as UserInfoState
);
