import React from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { BaseLink } from './BaseLink';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

type GeneralProps = {
	children: string;
	/**
	 * Setze diese Prop nur, wenn das automatische Resizing bei Touch Devices dein Interface zerstört.
	 * Kümmere dich dann darum, dass die Touchfläche groß genug ist.
	 */
	deprecated?: boolean;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs#disabled-context) */
	disabled?: boolean;
	/** Setzt rel='noreferrer noopener nofollow' */
	secure?: boolean;
	/** target='blank' sollte nur in Ausnahmen verwendet werden. Zudem solltest du secure=true setzen, wenn du außerhalb des Sipgate-Kosmos verlinkst. */
	target?: 'blank';
	to: string;
};

type AsButtonProps = {
	button: true;
	/** Die Größen "small" und "xlarge" können nur in der variant="loud" gesetzt werden. */
	size?: 'small' | 'medium' | 'large' | 'xlarge';
	width?: 'content' | 'max' | 'max-on-touch-device';
	variant?: 'normal' | 'loud';
};

type LinkProps = {
	button?: false;
	size?: never;
	width?: never;
	variant?: 'quiet' | 'normal';
};

const styles = {
	icon: (
		variant: 'quiet' | 'normal' | 'loud',
		size: 'small' | 'medium' | 'large' | 'xlarge',
		disabled: boolean,
		button: boolean
	) =>
		classnames(
			'self-center',
			disabled && 'bg-gray-400',
			button && size === 'small' && 'ml-6',
			button && size === 'medium' && 'ml-8',
			button && size === 'large' && 'ml-10',
			button && size === 'xlarge' && 'ml-12',
			!button && ['ml-quarter-text']
		),
	text: classnames('whitespace-normal'),
};

type Props = GeneralProps & (AsButtonProps | LinkProps);

const ExternalLink = ({
	button = false,
	deprecated = false,
	children,
	disabled,
	secure = false,
	to,
	size = 'medium',
	width = 'content',
	target,
	variant = 'normal',
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const getIconSize = () => {
		switch (size) {
			case 'small':
				return '12';
			case 'medium':
				return '16';
			case 'large':
			case 'xlarge':
				return '24';
		}
	};

	return (
		<BaseLink
			button={button}
			deprecated={deprecated}
			disabled={disabled}
			secure={secure}
			size={size}
			width={width}
			target={target}
			type="external"
			url={to}
			variant={variant}
		>
			<span className={styles.text}>{children}</span>
			{'\uFEFF'}
			{button ? (
				<SetIcon
					icon="external-link"
					size={getIconSize()}
					touchSize={!deprecated ? '24' : undefined}
					className={styles.icon(variant, size, isDisabled(disabled, disabledContextValue), button)}
				/>
			) : (
				<SetIcon
					icon="external-link"
					inline
					size="text"
					className={styles.icon(variant, size, isDisabled(disabled, disabledContextValue), button)}
				/>
			)}
		</BaseLink>
	);
};

export { ExternalLink };
