import { handleActions } from '../..';

import { DeviceContingentState } from './types';
import * as actions from './actions';

const initialState: DeviceContingentState = {
	items: {},
	fetchingForDevice: [],
	fetchedForDevice: [],
};

export default handleActions<DeviceContingentState, PossibleActions<typeof actions>>(
	{
		DEVICE_CONTINGENTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForDevice: [...state.fetchingForDevice, data.deviceId],
		}),
		DEVICE_CONTINGENTS_FETCH_SUCCESS: (state, { data, payload }) => ({
			...state,
			items: {
				...state.items,
				[data.deviceId]: payload.contingents,
			},
			fetchingForDevice: state.fetchingForDevice.filter(device => device !== data.deviceId),
			fetchedForDevice: [
				...state.fetchedForDevice.filter(device => device !== data.deviceId),
				data.deviceId,
			],
		}),
	},
	initialState
);
