import { LegacyProduct, Product } from '../products';
import {
	CallPackageContract,
	Contingent,
	ContingentContract,
	Contract,
	EMPTY_CALL_PACKAGE,
	EMPTY_PRODUCT,
} from './types';

const isDataSnackContract = (contract: ContingentContract) =>
	contract.productName.startsWith('dataflat_upgrade_') ||
	contract.productName.startsWith('CA_CH_US_ROAMINGDATA_') ||
	contract.productName.startsWith('ROW_ROAMINGDATA_');

// We don't know what this is for. If you know, please give it a proper name.
// Also, this shouldn't be done in our frontend (see also ADR 2019-03-18-product-specific-logic.md).
const isOminousUnknownSpecialThing = (contract: ContingentContract) =>
	contract.productName.startsWith('sipgate_');

export const isCallPackageContract = (contract: Contract): contract is CallPackageContract =>
	contract.contractType === 'CALLPACKAGE';

export const isContingentContract = (contract: Contract): contract is ContingentContract =>
	contract.contractType === 'CONTINGENT';

export const isActiveContract = (contract: Contract) =>
	contract.activationDate === null || contract.activationDate < new Date();

export const selectContractsForDevice = (items: Contract[], deviceId: string) => {
	return items.filter(item => item.deviceId === deviceId);
};

export const selectTotalContingentAmount = (contingents: Contingent[]) =>
	contingents.reduce((curr, next) => curr + next.amount, 0);

export const selectTotalContingentUsed = (contingents: Contingent[]) =>
	contingents.reduce((curr, next) => curr + next.used, 0);

export const getPrimaryDataContingents = (items: Contract[], deviceId: string) => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(isOminousUnknownSpecialThing)
		.sort(
			(a, b) =>
				selectTotalContingentAmount(b.contingents) - selectTotalContingentAmount(a.contingents)
		)
		.flatMap(deviceContingents =>
			deviceContingents.contingents.filter(contingent => contingent.unit === 'KILOBYTE')
		);
};

/** @deprecated please use isActiveContract */
export const getActiveDataContract = (
	items: Contract[],
	deviceId: string
): ContingentContract | undefined => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(contract => !isDataSnackContract(contract))
		.find(isActiveContract);
};

export const getActiveDataProduct = (
	items: Contract[],
	deviceId: string,
	products: Product[],
	legacyProducts?: LegacyProduct[]
): Product => {
	const activeContract = getActiveDataContract(items, deviceId);
	if (!activeContract) {
		return EMPTY_PRODUCT;
	}

	let activeProduct = products.find(product => product.id === activeContract.productId);

	if (!activeProduct && legacyProducts) {
		const activeLegacyProduct = legacyProducts.find(
			additionalProduct => additionalProduct.id === activeContract.productId
		);

		if (activeLegacyProduct) {
			activeProduct = {
				...activeLegacyProduct,
				name: activeContract.productName,
			};
		}
	}

	if (!activeProduct) {
		return {
			name: activeContract.productName,
			recurringFee: EMPTY_PRODUCT.recurringFee,
			id: activeContract.productId,
			nonRecurringFee: EMPTY_PRODUCT.nonRecurringFee,
			recurring: EMPTY_PRODUCT.recurring,
			legacy: EMPTY_PRODUCT.legacy,
		};
	}

	return activeProduct;
};

export const getDataSnackContingents = (items: Contract[], deviceId: string) => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(isDataSnackContract)
		.flatMap(deviceContingent => deviceContingent.contingents);
};

export const getAllDataContingents = (items: Contract[], deviceId: string) => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(contract => isDataSnackContract(contract) || isOminousUnknownSpecialThing(contract))
		.flatMap(deviceContingent => deviceContingent.contingents)
		.filter(contingent => contingent.unit === 'KILOBYTE');
};

/** @deprecated please use isActiveContract */
export const getActiveCallPackageContract = (
	items: Contract[],
	deviceId: string
): CallPackageContract | undefined => {
	return selectContractsForDevice(items, deviceId)
		.filter(isCallPackageContract)
		.find(isActiveContract);
};

export const getActiveCallPackageProduct = (
	items: Contract[],
	deviceId: string,
	products: Product[],
	legacyProducts?: LegacyProduct[]
): Product => {
	const activeContract = getActiveCallPackageContract(items, deviceId);
	if (!activeContract) {
		return EMPTY_CALL_PACKAGE;
	}

	let activeProduct = products.find(product => product.id === activeContract.productId);

	if (!activeProduct && legacyProducts) {
		const activeLegacyProduct = legacyProducts.find(
			additionalProduct => additionalProduct.id === activeContract.productId
		);

		if (activeLegacyProduct) {
			activeProduct = {
				...activeLegacyProduct,
				name: activeContract.productName,
			};
		}
	}

	if (!activeProduct) {
		return {
			name: activeContract.productName,
			recurringFee: EMPTY_CALL_PACKAGE.recurringFee,
			id: activeContract.productId,
			nonRecurringFee: EMPTY_CALL_PACKAGE.nonRecurringFee,
			recurring: EMPTY_CALL_PACKAGE.recurring,
			legacy: EMPTY_CALL_PACKAGE.legacy,
		};
	}

	return activeProduct;
};
