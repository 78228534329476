import { InternalLink } from '@panda/ui';
import classnames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import { AuthenticatedLayoutContext } from '../layouts/AuthenticatedLayout';
import SipgateLogo from '../media/logo/satellite_wort_bild_marke_weiß.svg';
import BreadcrumbsBar from '../navigation/accountSection/BreadcrumbsBar';
import { useAccount } from '../redux/modules/account';
import { useIsScrolled } from '../redux/modules/igel/hooks';
import { useUserRestrictions } from '../redux/modules/restrictions';
import { useTranslate } from '../redux/modules/translations';
import { useUserInfo } from '../redux/modules/userinfo';
import { AccountOverviewPath, EventlistPath, isAccountSection } from '../routes/paths';
import classes from './Header.scss';
import HelpButton from './HelpButton';
import MenuButton from './MenuButton';
import UserDropdown from './UserDropdown';

const Header = () => {
	const isIgelScrolled = useIsScrolled();
	const location = useLocation();
	const translate = useTranslate();
	const user = useUserInfo();
	const accountData = useAccount();
	const restrictions = useUserRestrictions(user?.sub, ['CAN_USE_PBX_FEATURES']);
	const [isScrolled, setIsScrolled] = React.useState(false);

	const { onToggleNavigation } = useContext(AuthenticatedLayoutContext);

	const isOnIgelSite = location.pathname.includes('/team/');
	const mainContent = document.getElementById('main-content');

	const canUsePbxFeatures = restrictions?.fn.canUsePbxFeatures();
	const hasCustomLogo = !!accountData?.logoUrl;

	useEffect(() => {
		if (mainContent) {
			const onScroll = () => {
				if (mainContent.scrollTop > 0 && window.location.pathname !== EventlistPath()) {
					setIsScrolled(true);
				} else {
					setIsScrolled(false);
				}
			};

			mainContent.addEventListener('scroll', onScroll);
			mainContent.removeEventListener('scroll', onScroll);
			mainContent.addEventListener('scroll', onScroll);
			return () => mainContent.removeEventListener('scroll', onScroll);
		}
	}, [mainContent]);

	const renderButton = () => {
		if (!canUsePbxFeatures) return null;

		if (isAccountSection(location.pathname)) {
			return (
				<div>
					<InternalLink to={EventlistPath()} variant="normal" size="large" button>
						{translate('USER_AREA')}
					</InternalLink>
				</div>
			);
			// TODO: replace with a restriction?
		}
		if (user?.isAdmin) {
			return (
				<div>
					<InternalLink to={AccountOverviewPath()} variant="normal" size="large" button>
						{translate('DEDICATED_ADMIN_AREA')}
					</InternalLink>
				</div>
			);
		}
	};

	return (
		<header
			className={classnames(classes.header, {
				[classes.scrolled]: isScrolled || (isIgelScrolled && isOnIgelSite),
				[classes.withCustomLogo]: hasCustomLogo,
			})}
		>
			<div className={classes.left}>
				<MenuButton onClick={onToggleNavigation} />
				<img src={SipgateLogo} alt={translate('LOGO_LINK_LABEL')} className={classes.logo} />
			</div>
			<BreadcrumbsBar />
			<div className={classes.right}>
				<HelpButton />
				{renderButton()}
				<UserDropdown />
			</div>
		</header>
	);
};

export default Header;
