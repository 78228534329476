import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { ExternalLink } from '@panda/ui';
import classes from './SideNavigationLink.scss';
import { SideNavigationMenuLink } from '../SideNavigation.types';
import { AreColorsInvertedContext, IsSideNavigationOpenContext } from '../SideNavigation.utils';
import { Translate, useTranslate } from '../../../redux/modules/translations';
import { SideNavigationPill } from '../SideNavigationPill/SideNavigationPill';

type Props = {
	item: SideNavigationMenuLink;
	inGroup?: boolean;
};

function getLinkItem(item: SideNavigationMenuLink, translate: Translate) {
	if (item.external) {
		return (
			<>
				{item.icon && (
					<span
						data-testid="icon"
						className={classes.icon}
						style={{ maskImage: `url(${item.icon})`, WebkitMaskImage: `url(${item.icon})` }}
					/>
				)}
				<ExternalLink to={item.path} variant="quiet" target="blank">
					{translate(item.title)}
				</ExternalLink>
			</>
		);
	}
	return (
		<>
			{item.icon && (
				<span
					data-testid="icon"
					className={classes.icon}
					style={{ maskImage: `url(${item.icon})`, WebkitMaskImage: `url(${item.icon})` }}
				/>
			)}
			<span className={classes.text}>{translate(item.title)}</span>
			{item.pill && <SideNavigationPill type={item.pill} />}
		</>
	);
}

export const SideNavigationLink = ({ item, inGroup }: Props) => {
	const translate = useTranslate();
	const isSideNavigationOpen = useContext(IsSideNavigationOpenContext);
	const areColorsInverted = useContext(AreColorsInvertedContext);
	const history = useHistory();
	const [isActive, setIsActive] = useState(
		item.isActive ? item.isActive(history.location.pathname) : false
	);

	useEffect(() => {
		if (item.isActive) {
			setIsActive(item.isActive(history.location.pathname));
		}
	}, [history.location.pathname, item]);

	return (
		<li>
			<NavLink
				to={item.external ? { pathname: item.path } : item.path}
				target={item.external ? '_blank' : undefined}
				className={classnames(classes.item, {
					[classes.invertColors]: areColorsInverted,
					[classes.slim]: !isSideNavigationOpen,
					[classes.active]: isActive,
					[classes.inGroup]: inGroup,
				})}
				activeClassName={classes.active}
			>
				{getLinkItem(item, translate)}
			</NavLink>
		</li>
	);
};
