import React from 'react';
import { Button } from '@panda/ui';
import IdentityVerificationIcon from '../../../../media/icons/icon-verify-dark-line-default-64.svg';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import { Translate } from '../../../../redux/modules/translations';
import VideoIdentDelayHint from './components/VideoIdentDelayHint';
import { VideoIdentContent } from './components/VideoIdentContent';
import classes from './StartVideoIdentDialog.scss';
import { UserInfo } from '../../../../redux/modules/userinfo';

interface ExternalProps {
	translate: Translate;
	videoIdentLink?: string;
	autoIdentLink?: string;
	showIdnowDelayWarning: boolean;
	verificationNotFound: boolean;
	userInfo: UserInfo;
}

type Props = ExternalProps;

export class StartVideoIdentDialog extends React.Component<Props> {
	private redirectToIdNow = (url: string) => {
		window.open(url, '_blank');
	};

	private renderButtons() {
		if (this.props.showIdnowDelayWarning) {
			return null;
		}

		return (
			<div className={classes.buttonContainer}>
				{this.props.videoIdentLink && (
					<Button
						onClick={() => this.redirectToIdNow(this.props.videoIdentLink!)}
						size="xlarge"
						width="max-on-touch-device"
					>
						{this.props.translate('IDENTITY_VERIFICATION_START_VIDEO_IDENT_DIALOG_BUTTON')}
					</Button>
				)}
				{this.props.autoIdentLink && (
					<Button
						onClick={() => this.redirectToIdNow(this.props.autoIdentLink!)}
						size="xlarge"
						width="max-on-touch-device"
					>
						{this.props.translate('IDENTITY_VERIFICATION_START_AUTO_IDENT_DIALOG_BUTTON')}
					</Button>
				)}
			</div>
		);
	}

	private shouldRenderButtons() {
		return this.props.userInfo.sub === 'w0' && !this.props.showIdnowDelayWarning;
	}

	public render() {
		return (
			<DeprecatedDialogContent
				icon={<img src={IdentityVerificationIcon} alt="" />}
				headline={this.props.translate('IDENTITY_VERIFICATION_START_VIDEO_IDENT_DIALOG_HEADLINE')}
				button={this.shouldRenderButtons() && this.renderButtons()}
				hintText={
					<VideoIdentDelayHint
						translate={this.props.translate}
						showIdnowDelayWarning={this.props.showIdnowDelayWarning}
					/>
				}
			>
				{!this.props.showIdnowDelayWarning && (
					<VideoIdentContent
						userInfo={this.props.userInfo}
						translate={this.props.translate}
						verificationNotFound={this.props.verificationNotFound}
						autoIdentPossible={!!this.props.autoIdentLink}
					/>
				)}
			</DeprecatedDialogContent>
		);
	}
}
