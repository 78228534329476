import { User } from './types';

export function searchUsers(users: User[], searchTerm: string) {
	return users.filter(user =>
		`${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
	);
}

export function getUserByEmail(users: User[], email: string) {
	return users.filter(user => user.email.toLowerCase() === email.toLowerCase());
}

export function sortUsersByName(a: User, b: User) {
	return `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`);
}

export default {
	getUser: (users: User[], userId: string) => users.find(user => user.id === userId),
};
