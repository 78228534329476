import { createAction } from '../../..';
import Constants from './constants';

export default {
	setPhoneNumber: createAction(
		Constants.GSUITE_PHONE_NUMBERS_SET,
		(phoneNumber: string, locale: string) => ({
			phoneNumber,
			locale,
		})
	),
	setTransferPhoneNumber: createAction(
		Constants.GSUITE_PHONE_NUMBERS_TRANSFER_SET,
		(transferPhoneNumber: string, locale: string) => ({
			transferPhoneNumber,
			locale,
		})
	),
};
