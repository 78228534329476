import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { connect, ReduxProps } from '../../redux';
import classes from './BreadcrumbsBar.scss';
import { Breadcrumb } from './Breadcrumb';
import { ReduxState } from '../../redux/types';

import { selectors as usersSelectors } from '../../redux/modules/users';
import { fetchLinks } from '../../redux/modules/links';
import { fetchUserInfo } from '../../redux/modules/userinfo';
import {
	AccountOverviewPath,
	ACDS_OVERVIEW,
	CONFERENCE_ROOM_OVERVIEW,
	EventlistPath,
	GROUPS_OVERVIEW,
	HARDWARE_ROUTE,
	iframePaths,
	isAccountSection,
	IVRS_OVERVIEW,
	LOCATIONS,
	MICROSOFT_TEAMS_ROUTE,
	MOBILE_TELEPHONY,
	SAML_SSO,
	TRUNKS_OVERVIEW,
	UNLINKED_DEVICES_OVERVIEW,
	USERS_OVERVIEW,
	PresencePath,
	ContactsPath,
	ROUTING,
	DevicePoolPath,
	WebUserNotificationsPath,
	BLOCKLIST,
	EVENTLIST,
	CUSTOMISATION,
	PERSONAL_SETTINGS,
} from '../../routes/paths';
import { getMobileDeviceById, selectDevice } from '../../redux/modules/devices';
import { selectGroup } from '../../redux/modules/groups';
import { selectTrunk } from '../../redux/modules/trunks';
import { selectConferenceRoom } from '../../redux/modules/conferenceRoom';
import { selectAcd } from '../../redux/modules/acds';

interface UrlMapping {
	getBreadcrumbs: (
		theWholeReduxState: ReduxState,
		path: string
	) => { name: string; link: string }[];
	keywords: RegExp[];
}

const cleanupLink = (link: string) => {
	return link.replace(new RegExp(/^\//), '');
};

const USER_DETAIL_REGEX = new RegExp(`${USERS_OVERVIEW}/(w\\d+)`);
const TRUNK_DETAIL_REGEX = new RegExp(`${TRUNKS_OVERVIEW}/(t\\d+)`);
const CONFERENCE_ROOM_DETAIL_REGEX = new RegExp(`${CONFERENCE_ROOM_OVERVIEW}/(c\\d+)`);
const UNLINKED_DEVICE_DETAIL_REGEX = new RegExp(`${UNLINKED_DEVICES_OVERVIEW}/(e\\d+)`);
const GROUP_DETAIL_REGEX = new RegExp(`${GROUPS_OVERVIEW}/(g\\d+)`);
const UNLINKED_DEVICE_POOL_DETAIL_REGEX = new RegExp(`${MOBILE_TELEPHONY}/(y\\d+)`);
const ACD_DETAIL_REGEX = new RegExp(`${ACDS_OVERVIEW}/([\\w-]+)`);

const accountSectionUrlMap: UrlMapping[] = [
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('USER_ADMINISTRATION'),
					link: cleanupLink(USERS_OVERVIEW),
				},
			];
			const matches = USER_DETAIL_REGEX.exec(path);
			if (matches) {
				const user = usersSelectors.getUser(theWholeReduxState.users.items, matches[1]);
				if (user) {
					breadcrumbs.push({
						name: `${user.firstname} ${user.lastname}`,
						link: cleanupLink(`${USERS_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [USER_DETAIL_REGEX],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('USER_ADMINISTRATION'),
				link: cleanupLink(USERS_OVERVIEW),
			},
		],
		keywords: [new RegExp(USERS_OVERVIEW)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('EVENTLISTVIEW_TITLE'),
				link: cleanupLink(EVENTLIST),
			},
		],
		keywords: [new RegExp(EVENTLIST)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('GROUP_ADMINISTRATION'),
					link: cleanupLink(GROUPS_OVERVIEW),
				},
			];
			const matches = GROUP_DETAIL_REGEX.exec(path);
			if (matches) {
				const group = selectGroup(theWholeReduxState.groups, matches[1]);
				if (group) {
					breadcrumbs.push({
						name: `${group.alias}`,
						link: cleanupLink(`${GROUPS_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [GROUP_DETAIL_REGEX],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('GROUP_ADMINISTRATION'),
				link: cleanupLink(GROUPS_OVERVIEW),
			},
		],
		keywords: [
			new RegExp(GROUPS_OVERVIEW),
			/\/team\/settings\/group/,
			/\/team\/settings\/forwarding\/department/,
		],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('ACD_ADMINISTRATION'),
					link: cleanupLink(ACDS_OVERVIEW),
				},
			];
			const matches = ACD_DETAIL_REGEX.exec(path);
			if (matches) {
				const acd = selectAcd(theWholeReduxState.acds.items, matches[1]);
				if (acd) {
					breadcrumbs.push({
						name: `${acd.name}`,
						link: cleanupLink(`${ACDS_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [ACD_DETAIL_REGEX],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACD_ADMINISTRATION'),
				link: cleanupLink(ACDS_OVERVIEW),
			},
		],
		keywords: [new RegExp(ACDS_OVERVIEW)],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_SAML_SSO'),
				link: cleanupLink(SAML_SSO),
			},
		],
		keywords: [new RegExp(SAML_SSO)],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_TRUNKS'),
					link: cleanupLink(TRUNKS_OVERVIEW),
				},
			];

			const matches = TRUNK_DETAIL_REGEX.exec(path);
			if (matches) {
				const trunk = selectTrunk(theWholeReduxState.trunks.items, matches[1]);

				if (trunk) {
					breadcrumbs.push({
						name: trunk.alias,
						link: cleanupLink(`${TRUNKS_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [TRUNK_DETAIL_REGEX],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_TRUNKS'),
				link: cleanupLink(TRUNKS_OVERVIEW),
			},
		],
		keywords: [new RegExp(TRUNKS_OVERVIEW), /\/team\/settings\/trunking/],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_LOCATIONS'),
				link: cleanupLink(LOCATIONS),
			},
		],
		keywords: [new RegExp(LOCATIONS)],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_NUMBERS'),
				link: cleanupLink(iframePaths.numberSettings),
			},
		],
		keywords: [
			/\/team\/settings\/phonenumbers/,
			/\/team\/settings\/booknumbers/,
			/\/team\/settings\/numbers/,
			/\/team\/settings\/portednumbers/,
			/\/team\/settings\/mobilenumberimport/,
		],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_MS_TEAMS'),
				link: cleanupLink(MICROSOFT_TEAMS_ROUTE),
			},
		],
		keywords: [new RegExp(MICROSOFT_TEAMS_ROUTE)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('PUBLIC_PHONES_ADMINISTRATION'),
					link: cleanupLink(UNLINKED_DEVICES_OVERVIEW),
				},
			];
			const matches = UNLINKED_DEVICE_DETAIL_REGEX.exec(path);
			if (matches) {
				const unlinkedDevice = selectDevice(theWholeReduxState.devices.items, matches[1]);

				if (unlinkedDevice) {
					breadcrumbs.push({
						name: unlinkedDevice.alias,
						link: cleanupLink(`${UNLINKED_DEVICES_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [new RegExp(UNLINKED_DEVICES_OVERVIEW)],
	},
	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const toplevel = {
				name: theWholeReduxState.translations.translate('UNLINKED_DEVICE_POOLS_ADMINISTRATION'),
				link: cleanupLink(MOBILE_TELEPHONY),
			};

			const matches = UNLINKED_DEVICE_POOL_DETAIL_REGEX.exec(path);
			if (!matches) {
				return [toplevel];
			}

			const mobileDevice = getMobileDeviceById(theWholeReduxState.devices.items, matches[1]);
			if (!mobileDevice) {
				return [toplevel];
			}

			if (!mobileDevice.owner) {
				return [
					toplevel,
					{
						name: mobileDevice.alias,
						link: cleanupLink(`${MOBILE_TELEPHONY}/${matches[1]}`),
					},
				];
			}

			const owner = usersSelectors.getUser(theWholeReduxState.users.items, mobileDevice.owner);
			if (!owner) {
				return [
					toplevel,
					{
						name: mobileDevice.alias,
						link: cleanupLink(`${MOBILE_TELEPHONY}/${matches[1]}`),
					},
				];
			}

			return [
				toplevel,
				{
					name: `${owner.firstname} ${owner.lastname}`,
					link: cleanupLink(`${MOBILE_TELEPHONY}/${matches[1]}`),
				},
			];
		},
		keywords: [new RegExp(MOBILE_TELEPHONY)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_PHONES'),
				link: cleanupLink(HARDWARE_ROUTE),
			},
		],
		keywords: [new RegExp('hardware')],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState, path: string) => {
			const breadcrumbs = [
				{
					name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_CONFERENCEROOM'),
					link: cleanupLink(CONFERENCE_ROOM_OVERVIEW),
				},
			];

			const matches = CONFERENCE_ROOM_DETAIL_REGEX.exec(path);
			if (matches) {
				const conferenceRoom = selectConferenceRoom(
					theWholeReduxState.conferenceRoom.items,
					matches[1]
				);
				if (conferenceRoom) {
					breadcrumbs.push({
						name: conferenceRoom.alias,
						link: cleanupLink(`${CONFERENCE_ROOM_OVERVIEW}/${matches[1]}`),
					});
				}
			}
			return breadcrumbs;
		},
		keywords: [new RegExp(CONFERENCE_ROOM_OVERVIEW), CONFERENCE_ROOM_DETAIL_REGEX],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('IVR_ADMINISTRATION'),
				link: cleanupLink(IVRS_OVERVIEW),
			},
		],
		keywords: [new RegExp(IVRS_OVERVIEW), new RegExp(iframePaths.ivrSettings)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_ACCOUNT'),
				link: cleanupLink(iframePaths.accountSettings),
			},
		],
		keywords: [new RegExp(iframePaths.accountSettings), /\/team\/settings\/invoices/],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_CONTRACTS'),
				link: cleanupLink(iframePaths.contractsSettings),
			},
		],
		keywords: [new RegExp(iframePaths.contractsSettings)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_CUSTOMISATION'),
				link: cleanupLink(CUSTOMISATION),
			},
		],
		keywords: [new RegExp(CUSTOMISATION)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('ACCOUNT_NAVIGATION_AFFILIATE'),
				link: cleanupLink(iframePaths.affiliateSettings),
			},
		],
		keywords: [new RegExp(iframePaths.affiliateSettings)],
	},

	{
		getBreadcrumbs: (theWholeReduxState: ReduxState) => [
			{
				name: theWholeReduxState.translations.translate('PERSONAL_SETTINGS'),
				link: cleanupLink(PERSONAL_SETTINGS),
			},
		],
		keywords: [new RegExp(PERSONAL_SETTINGS)],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('PRESENCE'),
				link: cleanupLink(PresencePath()),
			},
		],
		keywords: [new RegExp(PresencePath())],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('CONTACTS'),
				link: cleanupLink(ContactsPath()),
			},
		],
		keywords: [new RegExp(ContactsPath())],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('ROUTING_VIEW'),
				link: cleanupLink(ROUTING),
			},
		],
		keywords: [new RegExp(ROUTING)],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('DEVICE_POOL'),
				link: cleanupLink(DevicePoolPath()),
			},
		],
		keywords: [new RegExp(DevicePoolPath())],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('NOTIFICATIONS'),
				link: cleanupLink(WebUserNotificationsPath()),
			},
		],
		keywords: [new RegExp(WebUserNotificationsPath())],
	},
	{
		getBreadcrumbs: theWholeReduxState => [
			{
				name: theWholeReduxState.translations.translate('BLACKLIST'),
				link: cleanupLink(BLOCKLIST),
			},
		],
		keywords: [new RegExp(BLOCKLIST)],
	},
];

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
	translate: state.translations.translate,
	reduxState: state,
	userinfoData: state.userinfo.data,
});

const mapDispatchToProps = {
	fetchLinks,
	fetchUserInfo,
};

type Props = RouteComponentProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class BreadcrumbsBar extends PureComponent<Props> {
	public componentDidMount() {
		this.props.fetchLinks();
		this.props.fetchUserInfo();
	}

	private getUrlMapping = () =>
		accountSectionUrlMap.find(i =>
			i.keywords.some(reg => {
				return reg.test(this.props.location.pathname);
			})
		);

	public render() {
		if (!this.props.links.fetched) {
			return (
				<nav
					className={classes.bar}
					aria-label={this.props.translate('BREADCRUMB_NAVIGATION_LABEL')}
				/>
			);
		}

		if (!this.props.links.fetched) {
			return null;
		}

		const breadcrumbItems: React.ReactNode[] = [];

		const urlMapping = this.getUrlMapping();
		if (urlMapping !== undefined) {
			urlMapping
				.getBreadcrumbs(this.props.reduxState, this.props.location.pathname)
				.forEach((crumb, key, arr) => {
					breadcrumbItems.push(
						<Breadcrumb
							key={`${crumb.name}-${urlMapping}`}
							link={`/${crumb.link}`}
							current={Object.is(arr.length - 1, key)}
						>
							{crumb.name}
						</Breadcrumb>
					);
				});
		}

		const link = isAccountSection(this.props.location.pathname)
			? AccountOverviewPath()
			: EventlistPath();

		if (isAccountSection(this.props.location.pathname)) {
			breadcrumbItems.unshift(
				<Breadcrumb key="account" link={link} current={breadcrumbItems.length === 0}>
					{this.props.translate('ACCOUNT_NAVIGATION_HEADING_HOME')}
				</Breadcrumb>
			);
		}

		let currentPageTitle;
		if (urlMapping !== undefined) {
			currentPageTitle = urlMapping
				.getBreadcrumbs(this.props.reduxState, this.props.location.pathname)
				.pop()!.name;
		} else {
			currentPageTitle = this.props.translate('ACCOUNT_NAVIGATION_HEADING_HOME');
		}

		return (
			<nav className={classes.bar} aria-label={this.props.translate('BREADCRUMB_NAVIGATION_LABEL')}>
				<ol>{breadcrumbItems}</ol>
				<Helmet>
					<title>{`${currentPageTitle} - satellite | App`}</title>
				</Helmet>
			</nav>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BreadcrumbsBar));
