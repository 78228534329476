import classnames from 'classnames';
import React from 'react';
import { useTableBodyContext } from './contexts/tableBodyContext';
import { useTableContext } from './contexts/tableContext';
import { useTableRowContext } from './contexts/tableRowContext';

type Props = {
	children: React.ReactNode;
};

const styles = {
	td: (isSmallView: boolean, isFirstColumn: boolean, isActiveRow: boolean) =>
		classnames(
			'font-brand',
			'font-normal',
			'text-base/20',
			isSmallView ? ['pl-16', 'pr-8', 'py-10'] : ['px-8', 'py-12', 'last:pr-0', 'table-cell'],
			isSmallView && isFirstColumn && ['w-auto', '-order-2', 'flex-1'],
			isSmallView && !isFirstColumn && ['w-full', 'flex-auto'],
			isSmallView && isActiveRow && 'block',
			isSmallView && !isActiveRow && 'hidden'
		),
	mobileColumnHeader: (isSmallView: boolean) =>
		classnames(
			'font-normal',
			'text-xs/14',
			'mb-2',
			'text-gray-600',
			isSmallView ? 'block' : 'hidden'
		),
};

const TableCell = ({ children }: Props): JSX.Element => {
	const { isFirstColumn, headerTitle } = useTableRowContext();
	const { isActiveRow } = useTableBodyContext();
	const { isSmallView } = useTableContext();

	return (
		<td className={styles.td(isSmallView, isFirstColumn, isActiveRow)}>
			<strong className={styles.mobileColumnHeader(isSmallView)}>{headerTitle}</strong>
			{children}
		</td>
	);
};

export { TableCell };
