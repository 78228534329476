import { handleActions } from '../..';
import { AccountEvent, EventState } from './types';
import * as actions from './actions';

export const initialState: EventState = {
	items: [],
	fetching: false,
	fetched: false,
};

const dialogEvents = {
	ACCOUNT_NEEDS_ACTIVATION: true,
	BUSINESSVERIFICATIONFAILED: true,
	BUSINESSVERIFICATIONSUCCEEDED: true,
};

export default handleActions<EventState, PossibleActions<typeof actions>>(
	{
		EVENTS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		EVENTS_FETCH_SUCCESS: (state, { payload }) => ({
			fetching: false,
			fetched: true,
			items: [
				...payload.items.map(event => ({
					...event,
					dialogEvent: Object.prototype.hasOwnProperty.call(
						dialogEvents,
						String.prototype.toUpperCase.call(event.eventName)
					),
				})),
			],
		}),
		EVENT_DELETE_PENDING: (state, { data }) => {
			const indexOfDeletingEvent = state.items.findIndex(
				(event: AccountEvent) => event.eventId === data.eventId
			);
			if (data.eventType.toUpperCase() === 'STICKY') {
				return { ...state };
			}
			return {
				...state,
				items: [
					...state.items.slice(0, indexOfDeletingEvent),
					...state.items.slice(indexOfDeletingEvent + 1),
				],
			};
		},
		EVENT_CREATE_LOCAL_SUCCESS: (state, { payload }) => {
			return {
				...state,
				items: [...state.items, payload],
			};
		},
	},
	initialState
);
