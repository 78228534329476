import { handleActions } from '../..';
import { Contract, ContractsState } from './types';

import * as actions from './actions';

const initialState: ContractsState = {
	items: [],
	fetchingForDevice: [],
	fetchedForDevice: [],
};

const removeItemFromArray = (arr: readonly string[], deviceId: string) =>
	arr.filter(item => item !== deviceId);

export default handleActions<ContractsState, PossibleActions<typeof actions>>(
	{
		CONTRACTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForDevice: [
				...removeItemFromArray(state.fetchingForDevice, data.deviceId),
				data.deviceId,
			],
		}),

		CONTRACTS_FETCH_SUCCESS: (state, { payload, data }) => {
			const items = payload.items.map((item: Contract) => ({
				...item,
				deviceId: data.deviceId,
				cancellationDate: item.cancellationDate ? new Date(item.cancellationDate) : null,
				activationDate: item.activationDate ? new Date(item.activationDate) : null,
			}));

			const oldClearedItems = state.items.filter(item => item.deviceId !== data.deviceId);

			return {
				...state,
				fetchingForDevice: removeItemFromArray(state.fetchingForDevice, data.deviceId),
				fetchedForDevice: [
					...removeItemFromArray(state.fetchedForDevice, data.deviceId),
					data.deviceId,
				],
				items: [...oldClearedItems, ...items],
			};
		},

		CONTRACTS_CANCEL_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				items: state.items.map(item => {
					if (item.contractId !== data.contractId) {
						return item;
					}

					return {
						...item,
						cancellationDate: payload.cancellationDate ? new Date(payload.cancellationDate) : null,
					};
				}),
			};
		},

		CONTRACTS_REVOKE_DOWNGRADE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items
					.filter(item => item.contractId !== data.futureContractId)
					.map(item => {
						if (item.contractId === data.activeContractId) {
							return { ...item, cancellationDate: null };
						}
						return item;
					}),
			};
		},
	},
	initialState
);
