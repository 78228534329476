import React, { useRef, useState } from 'react';
import classes from './HelpButton.scss';
import HelpIcon from './img/question_mark.svg';
import DropOut from './DropOut';
import useKeyboardEvents from './hooks/useKeyboardEvents';
import useOutsideClick from './hooks/useOutsideClick';
import { useTranslate } from '../redux/modules/translations';

const HelpButton = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const translate = useTranslate();
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	return (
		<div id="helpbutton" className={classes.help} ref={ref}>
			<button
				type="button"
				onClick={() => setOpen(!open)}
				aria-controls="helpbutton-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="helpbutton-button"
			>
				<img src={HelpIcon} alt="Help Button" />
			</button>
			<DropOut id="helpbutton-menu" open={open} ariaLabelledBy="helpbutton-button">
				<ul>
					<li>
						<a href="https://help.satellite.me/" target="_blank" rel="noreferrer">
							{translate('HELPDESK_LINK')}
						</a>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};
export default HelpButton;
