import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import { connect, ReduxProps } from '../redux';

import ErrorSnackbar from '../components/messages/ErrorSnackbar';
import WarningSnackbar from '../components/messages/WarningSnackbar';
import { fetchUserInfo } from '../redux/modules/userinfo';
import ImplicitAuthenticator from '../login/ImplicitAuthenticator';
import AuthenticatedRoot from './AuthenticatedRoot';
import AutoCloseInfoSnackbar from '../components/messages/AutoCloseInfoSnackbar';
import AutoCloseSuccessSnackbar from '../components/messages/AutoCloseSuccessSnackbar';
import { ReduxState } from '../redux/types';
import { lazyLoadWithoutSpinner } from '../helpers/lazy-loading';
import { LanguageAttributeUpdater } from '../helpers/LanguageAttributeUpdater';
import PoliteLiveRegion from '../components/messages/PoliteLiveRegion';
import { fetchRestrictions } from '../redux/modules/restrictions';

const LoadableDeprecatedDialogWrapper = lazyLoadWithoutSpinner(
	() => import('../layouts/DeprecatedDialogWrapper')
);
const LoadableDialogWrapper = lazyLoadWithoutSpinner(() => import('../layouts/DialogWrapper'));

const mapStateToProps = (state: ReduxState) => ({
	userinfo: state.userinfo,
	translationsFetched: state.translations.fetched,
});

const mapDispatchToProps = {
	fetchUserInfo,
	fetchRestrictions,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & RouteComponentProps;

class Root extends React.Component<Props> {
	public componentDidMount() {
		this.disableLoadingAnimation();
		this.props.fetchUserInfo();
		this.props.fetchRestrictions();
	}

	private disableLoadingAnimation() {
		const animation = document.getElementById('loading-animation');

		if (animation) {
			setTimeout(() => {
				animation.remove();
			}, 600);

			animation.classList.add('exit');
		}
	}

	private renderDialogWrapperWithTranslations() {
		if (this.props.translationsFetched) {
			return (
				<>
					<LoadableDeprecatedDialogWrapper />
					<LoadableDialogWrapper />
				</>
			);
		}

		return '';
	}

	public render() {
		return (
			<div style={{ height: '100%' }}>
				<Helmet>
					<title>satellite | App</title>
				</Helmet>
				<LanguageAttributeUpdater userinfo={this.props.userinfo.data || undefined} />

				<Switch>
					{/* Used during normal login */}
					<Route path="/implicit-auth-redirect" component={ImplicitAuthenticator} />

					<Route component={AuthenticatedRoot} />
				</Switch>
				<AutoCloseInfoSnackbar />
				<AutoCloseSuccessSnackbar />
				<WarningSnackbar />
				<ErrorSnackbar />
				<PoliteLiveRegion />
				{this.renderDialogWrapperWithTranslations()}
			</div>
		);
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
