import pathToRegexp from 'path-to-regexp';
import { generatePath } from 'react-router';
import { RegisterDevice } from '../../redux/modules/devices';

export * from './deprecated-dialogs';

export const ACCOUNT_OVERVIEW = 'controlpanel';
export const AFTER_VIDEO_IDENT_PATH = 'postverification';
export const EVENTLIST = 'history';
export const DEVICE_POOL = 'device-pool';
export const HARDWARE_ROUTE = 'settings/hardware';
export const MICROSOFT_TEAMS_ROUTE = 'ms-teams';
export const FORCE = 'force';
export const BLOCKLIST = 'blocklist';
export const PERSONAL_SETTINGS = 'personal-settings';
export const CUSTOMISATION = 'customisation';
export const GROUPS_OVERVIEW = 'groups';
export const ACDS_OVERVIEW = 'acds';
export const UNLINKED_DEVICES_OVERVIEW = 'unlinkeddevices';
export const MOBILE_TELEPHONY = 'mobile-telephony';
export const USERS_OVERVIEW = 'users';
export const ROUTING = 'routing';
export const CONFERENCE_ROOM_OVERVIEW = 'conferenceroom';
export const IVRS_OVERVIEW = 'ivrs';
export const PERSONAL_ACCESS_TOKEN = 'personal-access-token';
export const HARDWARE_DETAIL = ':phoneId(\\d+)';
export const TRUNKS_OVERVIEW = 'trunks';
export const SAML_SSO = 'saml-sso';
export const LOCATIONS = 'locations';

// ========================================================================
// Paths
// ========================================================================

export const AccountOverviewPath = () => `/${ACCOUNT_OVERVIEW}` as const;

export const AcdDetailPath = (acdId: string, created?: boolean) => {
	if (created) {
		return generatePath(`/${ACDS_OVERVIEW}/:acdId/created`, {
			acdId,
		});
	}

	return generatePath(`/${ACDS_OVERVIEW}/:acdId`, {
		acdId,
	});
};
export const AcdOverviewPath = () => `/${ACDS_OVERVIEW}` as const;
export const BlocklistPath = () => `/${BLOCKLIST}` as const;
export const PersonalSettingsPath = () => `/${PERSONAL_SETTINGS}` as const;

export const EventlistPath = () => `/${EVENTLIST}` as const;

export const PhonesPath = () => `/${HARDWARE_ROUTE}` as const;

export const SoftphonePath = () => `/phone` as const;

export const SendFaxPath = (view: 'new' | 'stationary' | 'templates', draftId?: string) => {
	if (view === 'new' && draftId) {
		return `/fax/new/${draftId}` as const;
	}

	return `/fax/${view}` as const;
};

export const ProvisionedPhonePath = (id: string) =>
	generatePath(`/${HARDWARE_ROUTE}/${HARDWARE_DETAIL}`, {
		phoneId: id,
	});

export const GroupsOverviewPath = () => `/${GROUPS_OVERVIEW}` as const;

export const GroupDetailPath = (groupId: string) =>
	generatePath(`/${GROUPS_OVERVIEW}/:groupId`, {
		groupId,
	});

export const GroupDetailRoutingPath = (groupId: string) =>
	generatePath(`/${GROUPS_OVERVIEW}/:groupId/routing`, {
		groupId,
	});

export const GroupStatisticsDetailPath = (groupId: string) =>
	generatePath(`/${GROUPS_OVERVIEW}/:groupId/statistics`, {
		groupId,
	});

export const UnlinkedPhonesOverviewPath = () => `/${UNLINKED_DEVICES_OVERVIEW}` as const;

export const UnlinkedPhoneDetailPath = (deviceId: string) =>
	generatePath(`/${UNLINKED_DEVICES_OVERVIEW}/:deviceId(e\\d+)`, {
		deviceId,
	});

export const UsersOverviewPath = () => `/${USERS_OVERVIEW}` as const;

export const UserDetailPath = (targetUserId: string) =>
	`${UsersOverviewPath()}/${targetUserId}` as const;

export const UserRoutingPath = (targetUserId: string) =>
	`${UsersOverviewPath()}/${targetUserId}/routing` as const;

export const TrunksOverviewPath = () => `/${TRUNKS_OVERVIEW}` as const;

export const TrunksChangeContractOverviewPath = (trunkId: string) =>
	generatePath(`/${TRUNKS_OVERVIEW}/:trunkId/settings/dialog/change-trunk/:trunkId`, {
		trunkId,
	});

export const TrunksBookMinutePlansOverviewPath = (trunkId: string, productId: string) =>
	generatePath(
		`/${TRUNKS_OVERVIEW}/:trunkId/settings/dialog/book-trunk-contingent/:trunkId/:productId`,
		{ trunkId, productId }
	);

export const TrunksDetailviewPath = (trunkId: string) =>
	generatePath(`/${TRUNKS_OVERVIEW}/:trunkId`, { trunkId });

export const RoutingPath = () => `/${ROUTING}` as const;

export const PersonalAccessTokenPath = () => `/${PERSONAL_ACCESS_TOKEN}` as const;

export const ConferenceOverviewPath = () => `/${CONFERENCE_ROOM_OVERVIEW}` as const;

export const IvrsOverviewPath = () => `/${IVRS_OVERVIEW}` as const;

export const CustomisationPath = () => `/${CUSTOMISATION}` as const;

export const LocationsPath = () => `/${LOCATIONS}` as const;

export const DevicePoolPath = () => `/device-pool` as const;

export const UnlinkedEmergencyLocationPath = (device: RegisterDevice) =>
	generatePath(`/${UNLINKED_DEVICES_OVERVIEW}/:deviceId(e\\d+)/emergency-location`, {
		deviceId: device.id,
	});

export const PresencePath = () => `/presence` as const;

// ========================================================================
// Team iFrame Paths
// ========================================================================

export const SettingsPortedNumbersPath = () => `/team/settings/portednumbers` as const;
export const SettingsMobileNumberImport = () => `/team/settings/mobilenumberimport` as const;
export const SettingsProductsPath = () => `/team/settings/products` as const;
export const SettingsBaseproductsPath = () => `/team/settings/products/change` as const;
export const SettingsInvoicesPath = () => `/team/settings/invoices` as const;
export const SettingsAccountPath = () => `/team/settings/account` as const;
export const SettingsAccountConfigurePath = () => `${SettingsAccountPath()}/configure` as const;
export const SettingsAccountCreditPath = () => `${SettingsAccountPath()}/creditaccount` as const;
export const SettingsAccountCreditAutoPath = () =>
	`${SettingsAccountCreditPath()}/type/auto` as const;
export const SettingsAccountItemizedBillPath = () =>
	`${SettingsAccountPath()}/itemizedbill` as const;
export const SettingsUserSetupMiscPath = () =>
	`/team/settings/setup/miscellaneous/webuser` as const;
export const NewUserPath = () => `/team/settings/new/webuser` as const;
export const NewTrunkPath = () => `/team/settings/trunking/new` as const;
export const BookTrunkingPath = () =>
	`${SettingsProductsPath()}/additionalcontracts/show/trunkingonly` as const;
export const WebUserNotificationsPath = () => `/team/configure/filter` as const;
export const UnlinkedDeviceEditPath = (deviceId: string) => `/unlinkeddevices/${deviceId}` as const;

export const ConferenceRoomDetailPath = (extensionId: string) =>
	`/conferenceroom/${extensionId}` as const;

export const IvrDetailPath = (masterSipId: string, extensionId: string) =>
	`/team/settings/ivr/phone/ivr/${masterSipId}${extensionId}` as const;

export const ConferenceContractPath = () =>
	`/team/settings/products/additionalcontracts/show/conferenceroom` as const;

export const AddIvrPath = () => `/team/settings/ivr/new/` as const;

export const ContactsPath = () => '/team/contacts/version2' as const;

// ========================================================================
// Webphone Paths
// ========================================================================

export const webphonePathFragments = {
	PHONE: 'phone',
	HISTORY: 'history',
	SETTINGS: 'settings',
};

export const iframePaths = {
	numberSettings: '/team/settings/phonenumbers',
	bookNumbersSettings: '/team/settings/numbers/new',
	contractsSettings: '/team/settings/products',
	accountSettings: '/team/settings/account',
	conferenceroomSettings: '/team/settings/conferenceroom',
	ivrSettings: '/team/settings/ivr',
	designSettings: '/team/settings/design',
	affiliateSettings: '/team/settings/affiliate',
	miscellaneousSettings: 'team/settings/setup/miscellaneous',
	orderSim: '/team/settings/products/ordersim',
};

const accountSectionUrls = [
	pathToRegexp(`/groups`, { end: false }),
	pathToRegexp(`/users`, { end: false }),
	pathToRegexp(`/trunks`, { end: false }),
	pathToRegexp(`/locations`, { end: false }),
	pathToRegexp(`/settings/hardware`, { end: false }),
	pathToRegexp(`/${ACCOUNT_OVERVIEW}`, { end: false }),
	pathToRegexp(`/${CONFERENCE_ROOM_OVERVIEW}`, { end: false }),
	pathToRegexp(`/${IVRS_OVERVIEW}`, { end: false }),
	pathToRegexp(`/${ACDS_OVERVIEW}`, { end: false }),
	pathToRegexp(`/${CUSTOMISATION}`, { end: false }),

	pathToRegexp(`/team/settings/phonenumbers`, { end: false }),
	pathToRegexp(`/team/settings/booknumbers`, { end: false }),
	pathToRegexp(`/team/settings/portednumbers`, { end: false }),
	pathToRegexp(`/team/settings/mobilenumberimport`),
	pathToRegexp(`/team/settings/numbers`, { end: false }),
	pathToRegexp(`/team/settings/products`, { end: false }),
	pathToRegexp(`/team/settings/design`),
	pathToRegexp(`/team/settings/account`, { end: false }),
	pathToRegexp(`/team/settings/invoices`),
	pathToRegexp(`/team/settings/setup/miscellaneous`),
	pathToRegexp(`/team/settings/hardware`, { end: false }),
	pathToRegexp(`/team/settings/trunking`, { end: false }),
	pathToRegexp(`/team/settings/conferenceroom`, { end: false }),
	pathToRegexp(`/team/settings/setup`),
	pathToRegexp(`/team/settings/new/webuser`),
	pathToRegexp(`/team/settings/group`, { end: false }),
	pathToRegexp(`/team/settings/ivr`, { end: false }),
	pathToRegexp(`/team/settings/design`),
	pathToRegexp(`/team/settings/forwarding/department/`, { end: false }),
	pathToRegexp(`/team/settings/affiliate`, { end: false }),
	pathToRegexp(`/ms-teams`, { end: false }),
	pathToRegexp(`/${UNLINKED_DEVICES_OVERVIEW}`, { end: false }),
	pathToRegexp(`/${MOBILE_TELEPHONY}`, { end: false }),
	pathToRegexp(`/${SAML_SSO}`, { end: false }),
];

export const isAccountSection = (path: string) => {
	return accountSectionUrls.some(url => url.test(path));
};
