import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	fetchAccount,
	uploadLogo,
	resetUploadError as resetUploadErrorAction,
	resetUploadSuccess as resetUploadSuccessAction,
} from './actions';
import { deleteLogo as deleteLogoAction } from '.';

export const useAccount = () => {
	const dispatch = useDispatch();
	const account = useSelector(state => state.account);

	useEffect(() => {
		dispatch(fetchAccount());
	}, [dispatch]);

	return account.fetched ? account : null;
};

export const useLogo = () => {
	const dispatch = useDispatch();

	const deleteLogo = () => {
		return dispatch(deleteLogoAction()).payload.promise;
	};

	return {
		deleteLogo,
		uploadLogo: useCallback(
			(base64Content: string) => {
				return dispatch(uploadLogo(base64Content));
			},
			[dispatch]
		),
		uploading: useSelector(state => state.account.uploadingLogo),
		uploaded: useSelector(state => state.account.uploadedLogo),
		uploadError: useSelector(state => state.account.uploadLogoError),
		resetUploadError: useCallback(() => dispatch(resetUploadErrorAction()), [dispatch]),
		resetUploadSuccess: useCallback(() => dispatch(resetUploadSuccessAction()), [dispatch]),
	};
};
