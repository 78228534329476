import classnames from 'classnames';
import React, { createContext } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import Header from '../accountNavigation/Header';
import IdentityVerificationCageDialog from '../components/dialogs/identityverification/IdentityVerificationCageDialog';
import LoadingBar from '../components/LoadingBar';
import InvalidAccountStateSnackbar from '../components/messages/InvalidAccountStateSnackbar';
import { ReplaceTransition } from '../components/transitions/ReplaceTransition';
import { lazyLoad } from '../helpers/lazy-loading';
import { SideNavigation } from '../navigation/SideNavigation/SideNavigation';
import { connect, ReduxProps } from '../redux';
import { fetchAppProperties } from '../redux/modules/appProperties';
import { fetchIdentityVerification } from '../redux/modules/identityVerification';
import { setImpersonated } from '../redux/modules/userinfo';
import { ReduxState } from '../redux/types';
import {
	ACCOUNT_OVERVIEW,
	PERSONAL_SETTINGS,
	GROUPS_OVERVIEW,
	isAccountSection,
	USERS_OVERVIEW,
} from '../routes/paths';
import { DialogRedirect, WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { ariaId } from '../utils/a11y/aria-id';
import auth from '../utils/authenticate/auth';
import MediaQueryDecorator, { MediaQueryProps } from '../utils/MediaQueryDecorator';
import { getAccountMenuLinks } from '../utils/sideNavigation/accountMenu';
import classes from './AuthenticatedLayout.scss';
import { PandaOneColumnLayout } from './PandaOneColumnLayout';
import { fetchUsers } from '../redux/modules/users';

const LoadablePersonalSettings = lazyLoad(
	() => import('../views/users/user/settings/UserSettingsView')
);

const LoadableUserView = lazyLoad(() => import('../views/users/user/UserView'));
const LoadableUsers = lazyLoad(() => import('../views/users/UsersOverview'));

const LoadableGroups = lazyLoad(() => import('../views/groups/GroupsOverview'));
const LoadableGroupView = lazyLoad(() => import('../views/groups/GroupView'));
const LoadableAccountManagement = lazyLoad(() => import('../views/AccountManagementOverview'));
const EmptyComponent = () => null;

export interface AuthenticatedLayoutContextType {
	onToggleNavigation: () => void;
	onNavigation: () => void;
	mainNavigationId: string;
	isOpen: boolean;
	isMobile: boolean;
	hasBeenNavigated: boolean;
}

export const AuthenticatedLayoutContext = createContext<AuthenticatedLayoutContextType>({
	onToggleNavigation: () => {},
	onNavigation: () => {},
	mainNavigationId: 'mainNav',
	isOpen: false,
	isMobile: false,
	hasBeenNavigated: false,
});

interface State {
	navigationOpen: boolean;
	hasBeenNavigated: boolean;
}

const mapStateToProps = (state: ReduxState) => ({
	appProperties: state.appProperties,
	userinfo: state.userinfo,
	users: state.users,
	translate: state.translations.translate,
});

const mapDispatchToProps = {
	fetchAppProperties,
	fetchUsers,
	fetchIdentityVerification,
	setImpersonated,
};

interface ExternalProps {
	webuserId: string;
}

type Props = ExternalProps &
	WithDialogProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	MediaQueryProps &
	RouteComponentProps;
class AuthenticatedLayout extends React.Component<Props, State> {
	public state = {
		navigationOpen: this.props.mediaQueries.isXLargeDevice(),
		hasBeenNavigated: false,
	};

	private mainNavigationId = ariaId();

	private unbindPusher = () => {};

	public componentDidMount() {
		const impersonated = auth.getImpersonated();
		if (impersonated && impersonated.state === true) {
			this.props.setImpersonated();
		}

		this.props.fetchAppProperties();

		if (this.props.appProperties.fetched) {
			this.props.fetchIdentityVerification(this.props.appProperties);
		}
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.appProperties.fetched && !prevProps.appProperties.fetched) {
			this.props.fetchIdentityVerification(this.props.appProperties);
		}

		if (
			!this.props.mediaQueries.isXLargeDevice() &&
			prevProps.mediaQueries.isXLargeDevice() &&
			this.state.navigationOpen
		) {
			this.setState({ navigationOpen: false });
		}

		if (
			this.naviClosed(this.props.location.pathname) &&
			isAccountSection(prevProps.location.pathname) &&
			this.state.navigationOpen &&
			prevState.navigationOpen
		) {
			this.setState({ navigationOpen: false });
		}
	}

	public componentWillUnmount() {
		this.unbindPusher();
		this.unbindPusher = () => {};
	}

	private naviClosed(pathname: string) {
		return pathname.match(`/routing`) !== null;
	}

	private onNavigation = () => {
		this.setState({ hasBeenNavigated: true });
		if (!this.props.mediaQueries.isXLargeDevice()) {
			this.setState({ navigationOpen: false });
		}
	};

	private onOpenNavigation = () => {
		this.setState({ navigationOpen: true });
	};

	private onCloseNavigation = () => {
		this.setState({ navigationOpen: false });
	};

	private renderRoutes = () => {
		return (
			<>
				<Switch location={this.props.location}>
					<Route path="/blank/(.+)" component={EmptyComponent} />
					{/* eslint-disable-next-line no-restricted-syntax */}
					<Redirect from="/" to={`/${ACCOUNT_OVERVIEW}`} strict exact />
					<Route
						path={`/${PERSONAL_SETTINGS}`}
						render={() => (
							<PandaOneColumnLayout>
								<LoadablePersonalSettings
									webuserId={this.props.webuserId}
									headerTranslationKey="PERSONAL_SETTINGS"
								/>
							</PandaOneColumnLayout>
						)}
					/>
					<Route
						path={`/${USERS_OVERVIEW}/:userId([0-9]*)`}
						render={({ match }) => <LoadableUserView userId={match.params.userId} />}
					/>
					<Route path={`/${USERS_OVERVIEW}`} component={LoadableUsers} />
					<Route
						path={`/${GROUPS_OVERVIEW}/:groupId(ch_[a-zA-Z0-9-]*)`}
						render={({ match }) => <LoadableGroupView groupId={match.params.groupId} />}
					/>
					<Route path={`/${GROUPS_OVERVIEW}`} component={LoadableGroups} />
					<Route path={`/${ACCOUNT_OVERVIEW}`} component={LoadableAccountManagement} />
					<DialogRedirect location={this.props.location} />
				</Switch>

				<IdentityVerificationCageDialog />
			</>
		);
	};

	private renderNavigation = () => {
		if (this.props.location.pathname === '/' || !this.props.appProperties.fetched) {
			return null;
		}

		const userMenu = getAccountMenuLinks(
			this.props.users.items.find(user => user.id === this.props.userinfo.data?.sub)?.email
		);

		return <SideNavigation menu={userMenu} />;
	};

	public render() {
		const pathTokens = this.props.location.pathname.split('/');
		const transitionKey = pathTokens[1] || '';

		return (
			<AuthenticatedLayoutContext.Provider
				value={{
					onToggleNavigation: this.state.navigationOpen
						? this.onCloseNavigation
						: this.onOpenNavigation,
					onNavigation: this.onNavigation,
					mainNavigationId: this.mainNavigationId,
					isOpen: this.state.navigationOpen,
					isMobile: !this.props.mediaQueries.isXLargeDevice(),
					hasBeenNavigated: this.state.hasBeenNavigated,
				}}
			>
				<div className={classes.rootContainer} data-test-selector="app-web-authenticated">
					<a href="#main-content" className={classnames(classes.skipToContentLink, 'sr-only')}>
						{this.props.translate('MAIN_NAV_SKIP_LINK')}
					</a>
					{!this.state.navigationOpen && (
						<button
							type="button"
							onClick={this.state.navigationOpen ? this.onCloseNavigation : this.onOpenNavigation}
							className={classnames(classes.skipToContentLink, 'sr-only')}
							aria-expanded={this.state.navigationOpen}
							aria-controls="aria-0"
						>
							{this.props.translate('ACCOUNT_NAVIGATION_TOGGLE_ARIA_BUTTON')}
						</button>
					)}

					<LoadingBar />

					<div className={classes.container}>
						{this.renderNavigation()}

						<div className={classes.stickyContainer}>
							<Header />
							<main id="main-content" className={classes.childContainer}>
								<ReplaceTransition innerKey={transitionKey}>
									<div className={classes.content}>{this.renderRoutes()}</div>
								</ReplaceTransition>
							</main>
						</div>
					</div>
					<InvalidAccountStateSnackbar />
				</div>
			</AuthenticatedLayoutContext.Provider>
		);
	}
}

export default withDialogs(
	connect(mapStateToProps, mapDispatchToProps)(MediaQueryDecorator(withRouter(AuthenticatedLayout)))
);
