import { handleActions } from '../..';
import { Product, ProductsState } from './types';
import * as actions from './actions';
import { ApiError } from '../../../api';

const initialState: ProductsState = {
	items: [],
	fetchingForProductType: [],
	fetchedForProductType: [],

	itemsBookingIsAllowed: [],
	fetchingIsProductBookingAllowed: [],
	fetchedIsProductBookingAllowed: [],

	legacyItems: [],
	fetchingLegacyItems: [],
};

export default handleActions<ProductsState, PossibleActions<typeof actions>>(
	{
		PRODUCTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForProductType: [
				...state.fetchingForProductType.filter(type => type !== data.type),
				data.type,
			],
		}),
		PRODUCTS_FETCH_SUCCESS: (state, { payload, data }) => {
			const orderedItems = [...payload.items].sort(
				(a: Product, b: Product) => a.recurringFee - b.recurringFee
			);

			const items = state.items.find(item => item.productType === data.type) || {
				products: [],
				productType: data.type,
			};

			return {
				...state,
				fetchedForProductType: [
					...state.fetchedForProductType.filter(type => type !== data.type),
					data.type,
				],
				fetchingForProductType: [
					...state.fetchingForProductType.filter(type => type !== data.type),
				],
				items: [
					...state.items.filter(item => item.productType !== data.type),
					{
						...items,
						products: [...items.products, ...orderedItems].filter(
							(item, index, original) => original.findIndex(i => i.id === item.id) === index
						),
					},
				],
			};
		},
		SINGLE_PRODUCT_FETCH_PENDING: (state, { data: { productId } }) => ({
			...state,
			fetchingLegacyItems: [
				...state.fetchingLegacyItems.filter(type => type !== productId),
				productId,
			],
		}),
		SINGLE_PRODUCT_FETCH_SUCCESS: (state, { payload, data: { productId } }) => {
			return {
				...state,
				fetchingLegacyItems: [...state.fetchingLegacyItems.filter(id => id !== productId)],
				legacyItems: [...state.legacyItems.filter(item => item.id !== productId), payload],
			};
		},
		IS_PRODUCT_BOOKING_ALLOWED_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingIsProductBookingAllowed: [
				...state.fetchingIsProductBookingAllowed.filter(productId => productId !== data.productId),
				data.productId,
			],
		}),
		IS_PRODUCT_BOOKING_ALLOWED_FETCH_SUCCESS: (state, { data }) => ({
			...state,
			fetchedIsProductBookingAllowed: [
				...state.fetchedIsProductBookingAllowed.filter(productId => productId !== data.productId),
				data.productId,
			],
			fetchingIsProductBookingAllowed: [
				...state.fetchingIsProductBookingAllowed.filter(productId => productId !== data.productId),
			],
			itemsBookingIsAllowed: [
				...state.itemsBookingIsAllowed.filter(item => item.productId !== data.productId),
				{
					productId: data.productId,
					allowed: true,
				},
			],
		}),
		IS_PRODUCT_BOOKING_ALLOWED_FETCH_FAILURE: (state, { data, payload }) => {
			if (payload instanceof ApiError && payload.status !== 402) {
				return {
					...state,
					fetchingIsProductBookingAllowed: [
						...state.fetchingIsProductBookingAllowed.filter(
							productId => productId !== data.productId
						),
					],
				};
			}

			return {
				...state,
				fetchedIsProductBookingAllowed: [
					...state.fetchedIsProductBookingAllowed.filter(productId => productId !== data.productId),
					data.productId,
				],
				fetchingIsProductBookingAllowed: [
					...state.fetchingIsProductBookingAllowed.filter(
						productId => productId !== data.productId
					),
				],
				itemsBookingIsAllowed: [
					...state.itemsBookingIsAllowed.filter(item => item.productId !== data.productId),
					{
						productId: data.productId,
						allowed: false,
					},
				],
			};
		},
	},
	initialState
);
