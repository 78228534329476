import {
	SideNavigationItem,
	SideNavigationMenu,
} from '../../navigation/SideNavigation/SideNavigation.types';
import { AccountOverviewPath, GroupsOverviewPath, UsersOverviewPath } from '../../routes/paths';
import accountOverviewIcon from '../../media/icons/icon-myicons-home.svg';
import usersIcon from '../../media/icons/icon-myicons-userprofile.svg';
import groupsIcon from '../../media/icons/icon-myicons-users-profile-group.svg';
import paymentIcon from '../../media/icons/icon-myicons-credit-card.svg';

export const getAccountMenuLinks = (email?: string): SideNavigationMenu => {
	/**
	 * zum Überprüfen von Restrictions am besten getRestriction(restrictions, 'CAN_USE_PUSH_API', webuserId) verwenden
	 */

	const items: SideNavigationItem[] = [];

	items.push({
		kind: 'LINK',
		path: AccountOverviewPath(),
		title: 'ADMIN_DASHBOARD',
		icon: accountOverviewIcon,
	});

	items.push({
		kind: 'LINK',
		path: UsersOverviewPath(),
		title: 'SATELLITE_USERS',
		icon: usersIcon,
	});
	items.push({
		kind: 'LINK',
		path: GroupsOverviewPath(),
		title: 'SATELLITE_GROUPS',
		icon: groupsIcon,
	});
	items.push({
		kind: 'LINK',
		path: `https://account.satellite.me${
			email ? `/?email=${encodeURIComponent(email || '')}` : ''
		}`,
		title: 'SATELLITE_PAYMENT',
		icon: paymentIcon,
		external: true,
	});

	return { items };
};
