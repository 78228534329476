import { handleActions, Immutable } from '../..';

import { createPhoneline } from '../phonelines';
import {
	RestrictionFunctions,
	RestrictionValue,
	RestrictionState,
	RestrictionValueWithTarget,
} from './types';
import { ApiRestriction } from '../../../api/types/restrictions';
import * as actions from './actions';

function isAllowed(targets: Immutable<RestrictionValueWithTarget<null>[]>): RestrictionValue;
function isAllowed(
	targets: Immutable<RestrictionValueWithTarget<string>[]>,
	target: string
): RestrictionValue;
function isAllowed(
	targets: Immutable<RestrictionValueWithTarget<string | null>[]>,
	target?: string
): RestrictionValue; // TODO: Rip this overload out after typing restrictions correctly
function isAllowed(
	targets: Immutable<RestrictionValueWithTarget<string | null>[]>,
	target: string | null = null
) {
	const restriction = targets.find(v => v.target === target);

	if (restriction?.value) {
		return { value: true, reason: null };
	}

	if (restriction?.reason) {
		return { value: false, reason: restriction.reason };
	}

	return { value: false, reason: null };
}

export function buildRestrictionFunctions(restrictions: {
	[restriction in ApiRestriction]:
		| RestrictionValueWithTarget<string | null>[]
		| Immutable<RestrictionValueWithTarget<string | null>[]>;
}): RestrictionFunctions {
	return {
		canEditCallRecording: userId =>
			isAllowed(restrictions.CAN_ACTIVATE_CALL_RECORDING, userId).value,
		canCreateConferenceRoom: () => isAllowed(restrictions.CAN_CREATE_CONFERENCE_ROOM).value,
		canUseConferenceRooms: () => isAllowed(restrictions.CAN_USE_CONFERENCE_ROOMS).value,
		canCreateIvr: () => isAllowed(restrictions.CAN_CREATE_IVR).value,
		canUseSAMLSso: () => isAllowed(restrictions.CAN_USE_SAML_SSO).value,
		canUseIvr: () => isAllowed(restrictions.CAN_USE_IVR).value,
		canUseSipgateApp: userId => isAllowed(restrictions.CAN_USE_SIPGATE_APP, userId).value,
		canCreateAddress: () => isAllowed(restrictions.CAN_CREATE_ADDRESS),
		canEditAddress: addressId => isAllowed(restrictions.CAN_EDIT_ADDRESS, addressId.toString()),
		canMoveAddress: addressId => isAllowed(restrictions.CAN_MOVE_ADDRESS, addressId.toString()),
		canDeleteAddress: addressId => isAllowed(restrictions.CAN_DELETE_ADDRESS, addressId.toString()),
		canUsePhonebookEntry: () => isAllowed(restrictions.CAN_USE_PHONEBOOK_ENTRY).value,
		canEditPhonebookEntry: addressId =>
			isAllowed(restrictions.CAN_EDIT_PHONEBOOK_ENTRY, addressId.toString()),
		canUseAcd: userId => isAllowed(restrictions.CAN_USE_ACD, userId).value,
		canCreateWebuser: userId => isAllowed(restrictions.CAN_CREATE_WEBUSER, userId).value,
		canUsePbxFeatures: () => isAllowed(restrictions.CAN_USE_PBX_FEATURES).value,
	};
}

const initialState: RestrictionState = {
	CAN_ACCOUNT_SEND_WEB_SMS: [],
	CAN_ACTIVATE_CALL_RECORDING: [],
	CAN_ACTIVATE_SIM: [],
	CAN_ADD_PHONE_NUMBER: [],
	CAN_BOOK_TRUNKING: [],
	CAN_CHANGE_DEVICE_EMERGENCY_LOCATION: [],
	CAN_CHANGE_EXTERNAL_DEVICE: [],
	CAN_CHANGE_FORWARDING_TIMES: [],
	CAN_CHANGE_PHONE_PROVISIONING_CONFIGURATION: [],
	CAN_CHANGE_QUICKDIAL_NUMBER: [],
	CAN_CHANGE_VOICEMAIL_SETTINGS: [],
	CAN_CREATE_CALL_QUEUE: [],
	CAN_CREATE_CONFERENCE_ROOM: [],
	CAN_CREATE_EXTERNAL_DEVICE: [],
	CAN_CREATE_EXTRA_VOIP_PHONE_WITHOUT_CHARGE: [],
	CAN_CREATE_FAX_EXTENSION: [],
	CAN_CREATE_GROUP: [],
	CAN_CREATE_IVR: [],
	CAN_CREATE_MOBILE_DEVICE: [],
	CAN_CREATE_PHONELINE_EXTENSION: [],
	CAN_CREATE_QUICKDIAL_NUMBER: [],
	CAN_CREATE_REGISTER_DEVICE: [],
	CAN_CREATE_SHARED_CONTACTS: [],
	CAN_CREATE_SHARED_VOIP_PHONE_WITHOUT_CHARGE: [],
	CAN_CREATE_TRUNK_DEVICE: [],
	CAN_CREATE_UNLINKED_DEVICE: [],
	CAN_CREATE_UNLINKED_MOBILE_DEVICE: [],
	CAN_CREATE_WEBUSER: [],
	CAN_DELETE_EXTERNAL_DEVICE: [],
	CAN_DELETE_FAX_EXTENSION: [],
	CAN_DELETE_MOBILE_DEVICE: [],
	CAN_DELETE_PHONELINE_EXTENSION: [],
	CAN_USE_SAML_SSO: [],
	CAN_DELETE_QUICKDIAL_NUMBER: [],
	CAN_DELETE_REGISTER_DEVICE: [],
	CAN_DELETE_TRUNK_DEVICE: [],
	CAN_EDIT_GROUP_SETTINGS: [],
	CAN_GET_ACCOUNT_BALANCE: [],
	CAN_GET_PHONE_PROVISIONING_CONFIGURATION: [],
	CAN_INDIVIDUALIZE_ACCOUNT: [],
	CAN_SEE_PREVIEW_MESSAGES: [],
	CAN_SEE_TRUNKING_CONTINGENT_ADVERTISEMENT: [],
	CAN_USE_ACD: [],
	CAN_EDIT_ACD_SETTINGS: [],
	CAN_USE_AFFILIATE: [],
	CAN_USE_AUTOPROVISION: [],
	CAN_USE_CALL_QUEUE: [],
	CAN_USE_CONFERENCE_ROOMS: [],
	CAN_USE_GROUPS: [],
	CAN_USE_IVR: [],
	CAN_USE_PBX_FEATURES: [],
	CAN_USE_PUSH_API: [],
	CAN_USE_SOFTPHONE: [],
	CAN_USE_TRUNKS: [],
	CAN_USE_UNLINKED_DEVICES: [],
	CAN_USE_WEB_BLF: [],
	GSUITE_EXTENSION: [],
	CAN_USE_SIPGATE_APP: [],
	CAN_CREATE_ADDRESS: [],
	CAN_DELETE_ADDRESS: [],
	CAN_EDIT_ADDRESS: [],
	CAN_MOVE_ADDRESS: [],
	CAN_USE_PHONEBOOK_ENTRY: [],
	CAN_EDIT_PHONEBOOK_ENTRY: [],
	CAN_SEND_FAX: [],
	fn: {
		canEditCallRecording: () => false,
		canCreateConferenceRoom: () => false,
		canUseConferenceRooms: () => false,
		canUseIvr: () => false,
		canCreateIvr: () => false,
		canUseSAMLSso: () => false,
		canUseSipgateApp: () => false,
		canCreateAddress: () => ({ value: false, reason: null }),
		canEditAddress: () => ({ value: false, reason: null }),
		canMoveAddress: () => ({ value: false, reason: null }),
		canDeleteAddress: () => ({ value: false, reason: null }),
		canUsePhonebookEntry: () => false,
		canEditPhonebookEntry: () => ({ value: false, reason: null }),
		canUseAcd: () => false,
		canCreateWebuser: () => false,
		canUsePbxFeatures: () => false,
	},
};

export default handleActions<
	RestrictionState,
	PossibleActions<typeof actions | typeof createPhoneline>
>(
	{
		PHONELINE_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			CAN_DELETE_PHONELINE_EXTENSION: [
				...state.CAN_DELETE_PHONELINE_EXTENSION,
				{
					target: payload.id,
					value: true,
					reason: null,
				},
			],
		}),

		RESTRICTIONS_FETCH_SUCCESS: (state, { payload }) => {
			const newState = { ...state };

			for (const item of payload.items) {
				if (item.restriction in newState) {
					newState[item.restriction] = [
						...newState[item.restriction].filter(res => res.target !== item.target),
						{
							target: item.target,
							value: item.value,
							reason: item.reason,
						},
					];
				}
			}

			return {
				...newState,
				fn: buildRestrictionFunctions(newState),
			};
		},
	},
	initialState
);

export const initialRestrictionState = { ...initialState };
