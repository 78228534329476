export const breakPoints = {
	SM: 640,
	MD: 768,
	LG: 1024,
	FullRoutingGraph: 74 * 16,
	XL: 1280,
};

export class MediaQueries {
	private readonly screenWidth: number;

	public constructor(screenWidth: number) {
		this.screenWidth = screenWidth;
	}

	public getCurrentDeviceWidth = () => this.screenWidth;

	public isMinSmallDevice = () => this.screenWidth >= breakPoints.SM;

	public isMinMediumDevice = () => this.screenWidth >= breakPoints.MD;

	public isMinLargeDevice = () => this.screenWidth >= breakPoints.LG;

	public shouldShowFullRoutingGraph = () => this.screenWidth >= breakPoints.FullRoutingGraph;

	public isMaxSmallDevice = () => this.screenWidth < breakPoints.SM;

	public showFullView = () => this.isMinLargeDevice();

	public isXLargeDevice = () => this.screenWidth >= breakPoints.XL;
}
