import { createAction } from '../../..';
import api from '../../../../api';
import { ReduxState } from '../../../types';

const shouldFetchCallRestrictions = (state: ReduxState) =>
	!state.trunkCallRestrictions ||
	(state.trunkCallRestrictions.items.length === 0 &&
		!state.trunkCallRestrictions.fetched &&
		!state.trunkCallRestrictions.fetching);

export const fetchTrunkCallRestrictions = createAction('CALLRESTRICTIONS_TRUNK_FETCH', () => ({
	promise: () => api.getTrunkCallRestrictions(),
	shouldFetch: shouldFetchCallRestrictions,
}));

export const forceFetchTrunkCallRestrictions = createAction('CALLRESTRICTIONS_TRUNK_FETCH', () => ({
	promise: () => api.getTrunkCallRestrictions(),
}));

export const setTrunkCallRestrictions = createAction(
	'CALLRESTRICTIONS_TRUNK_SET',
	(id: string, restriction: string, enabled: boolean) => ({
		promise: () => api.setTrunkCallRestrictions(id, restriction, enabled),
		data: { id, restriction, enabled },
	})
);
