import React from 'react';
import classnames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { ManagedForm as BaseManagedForm, FieldValues, MappedValues } from '@web-apps/forms';
import classes from './Form.scss';

export class ManagedForm<
	Source extends FieldValues,
	Target extends MappedValues<Source>
> extends React.Component<
	BaseManagedForm<Source, Target> extends React.Component<infer P> ? P : never
> {
	public render() {
		return (
			<BaseManagedForm
				state={this.props.state}
				onSubmit={this.props.onSubmit}
				validators={this.props.validators}
				formValidator={this.props.formValidator}
				initialValues={this.props.initialValues}
				className={classnames(classes.form, this.props.className)}
			>
				{this.props.children}
			</BaseManagedForm>
		);
	}
}
