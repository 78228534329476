import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';

import {
	createUser as createUserAction,
	fetchUserEmailChangeRequest,
	fetchUserLanguage,
	fetchUsers,
} from './actions';
import { useUserInfo } from '../userinfo';

export const useUsers = () => {
	const dispatch = useDispatch();
	const usersState = useSelector(state => state.users);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	return useMemo(
		() => ({
			areUsersFetched: usersState.fetched,
			areUsersFetching: usersState.fetching,
			allUsers: usersState.items,
			getUsersByIds: (ids: string[]) => usersState.items.filter(user => ids.includes(user.id)),
			getUserById: (id: string) => usersState.items.find(user => user.id === id),
			createUser: (
				firstName: string,
				lastName: string,
				email: string,
				isAdmin: boolean,
				locationId: number
			) => dispatch(createUserAction(firstName, lastName, email, isAdmin, locationId)),
		}),
		[usersState, dispatch]
	);
};

export const useCurrentUser = () => {
	const users = useUsers();
	const userInfo = useUserInfo();

	if (!userInfo) {
		return undefined;
	}

	return users.getUserById(userInfo.sub);
};

export const useUserById = (webuserId: string) => useUsers().getUserById(webuserId);

export const useUserLanguage = (webuserId: string) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchUserLanguage(webuserId));
	}, [webuserId, dispatch]);

	return useSelector(state =>
		state.users.fetchedLanguageForUser.includes(webuserId)
			? state.users.itemsLanguage[webuserId]
			: null
	);
};

export const useUserEmailChangeRequest = (webuserId: string) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchUserEmailChangeRequest(webuserId));
	}, [webuserId, dispatch]);

	return useSelector(state =>
		state.users.fetchedEmailChangeRequestForUser.includes(webuserId)
			? state.users.itemsEmailChangeRequests[webuserId]
			: null
	);
};
