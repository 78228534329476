import { Product } from '../products';
import {
	ApiCallPackageContract,
	ApiContingentContract,
	ApiContract,
	ApiContingent,
	ApiContingentType,
	ApiContingentUnit,
} from '../../../api/types/contracts';

export type Contract = ApiContract;
export type Contingent = ApiContingent;
export type ContingentType = ApiContingentType;
export type ContingentUnit = ApiContingentUnit;

export type CallPackageContract = ApiCallPackageContract;
export type ContingentContract = ApiContingentContract;

export interface ContractsState {
	items: Contract[];
	fetchingForDevice: string[];
	fetchedForDevice: string[];
}

export const EMPTY_PRODUCT: Product = {
	name: 'DEVICE_CONTINGENTS_EMPTY_CONTINGENT',
	recurringFee: -1,
	id: '-1',
	nonRecurringFee: -1,
	recurring: false,
	legacy: false,
};

export const EMPTY_CALL_PACKAGE: Product = {
	...EMPTY_PRODUCT,
	name: 'EMPTY_CALL_PACKAGE',
};

export const EMPTY_TRUNKING_CONTINGENT: Product = {
	...EMPTY_PRODUCT,
	name: 'EMPTY_TRUNKING_CONTINGENT',
};

export const EMPTY_TRUNKING_PRODUCT: Product = {
	...EMPTY_PRODUCT,
	name: 'EMPTY_TRUNKING_PRODUCT',
};
