import { DateTime } from 'luxon';
import { AcdAudioFileState } from './types';
import * as actions from './actions';
import { handleActions } from '../..';

const initialState: AcdAudioFileState = {
	items: [],
	fetchedForAcd: [],
	fetchingForAcd: [],
	creatingAudioFileForAcd: [],
};

export default handleActions<AcdAudioFileState, PossibleActions<typeof actions>>(
	{
		ACD_AUDIO_FILES_FETCH_PENDING: (state, action) => ({
			...state,
			fetchingForAcd: [...state.fetchingForAcd, action.data.acdId],
		}),

		ACD_AUDIO_FILES_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,

				items: [
					...state.items.filter(item => item.acdId !== data.acdId),
					...payload.items.map(item => ({
						id: item.id,
						alias: item.alias,
						url: state.items.find(stateItem => stateItem.id === item.id)?.url ?? {
							state: 'UNFETCHED' as const,
						},
						acdId: data.acdId,
						initial: item.initial,
						type: item.type,
						default: item.default,
					})),
				],

				fetchedForAcd: [
					...state.fetchedForAcd.filter(fetched => fetched.acdId !== data.acdId),
					{ acdId: data.acdId, timestamp: DateTime.now() },
				],
				fetchingForAcd: state.fetchingForAcd.filter(id => id !== data.acdId),
			};
		},

		ACD_AUDIO_FILE_CREATE_PENDING: (state, { data }) => ({
			...state,
			creatingAudioFileForAcd: [...state.creatingAudioFileForAcd, data.acdId],
		}),

		ACD_AUDIO_FILE_CREATE_SUCCESS: (state, { payload, data }) => ({
			...state,

			creatingAudioFileForAcd: state.creatingAudioFileForAcd.filter(acdId => acdId !== data.acdId),

			items: [
				...state.items.filter(item => item.id !== payload.id),
				{
					id: payload.id,
					alias: payload.alias,
					acdId: data.acdId,
					active: false,
					url: { state: 'UNFETCHED' as const },
					initial: false,
					type: payload.type,
					default: false,
				},
			],
		}),

		ACD_AUDIO_FILE_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(audioFile => audioFile.id !== data.audioFileId),
			};
		},

		ACD_AUDIO_FILE_SET_ALIAS_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						alias: data.alias,
					};
				}),
			};
		},

		ACD_AUDIO_FILE_FETCH_CONTENT_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHING' as const },
					};
				}),
			};
		},

		ACD_AUDIO_FILE_FETCH_CONTENT_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHED' as const, url: URL.createObjectURL(payload) },
					};
				}),
			};
		},

		ACD_AUDIO_FILE_FETCH_CONTENT_FAILURE: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'ERROR' as const, timestamp: DateTime.now() },
					};
				}),
			};
		},
	},
	initialState
);
