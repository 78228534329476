import classNames from 'classnames';
import React from 'react';

import classes from './VisualInput.module.css';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & {
	condition: 'default' | 'error' | 'disabled';
	passwordInput?: boolean;
};

const styles = {
	input: (condition: 'default' | 'error' | 'disabled', passwordInput: boolean) =>
		classNames(
			classes.hideArrows,
			'appearance-none',
			'opacity-100' /* required on iOS */,
			'w-full',
			'h-40',
			'p-8',
			'm-0',
			'font-brand',
			'font-normal',
			'text-base/24',
			'ring-1',
			'ring-inset',
			'box-border',
			'rounded-sm',
			'duration-150',
			'ease-in-out',
			'transition',
			'focus:outline-none',
			'placeholder-italic',
			'caret-blue-500',
			'truncate',
			'placeholder:opacity-100', // Fixes firefox because we dont load preflight: https://github.com/tailwindlabs/tailwindcss/issues/3300
			condition === 'default' && [
				'text-gray-800',
				'bg-gray-25',
				'ring-gray-100',
				'hover:ring-gray-300',
				'active:ring-blue-500',
				'focus:ring-blue-500',
				'placeholder-gray-600',
				'hover:placeholder-gray-800',
				'focus-visible:placeholder-gray-800',
			],
			condition === 'error' && [
				'text-gray-800',
				'bg-gray-25',
				'ring-gray-100',
				'hover:ring-gray-300',
				'active:ring-color-error',
				'focus:ring-color-error',
				'placeholder-gray-600',
				'hover:placeholder-gray-800',
				'focus-visible:placeholder-gray-800',
				'caret-color-error',
			],
			!passwordInput && condition === 'error' && 'pr-40',
			passwordInput && 'font-mono placeholder:font-brand',
			passwordInput && condition === 'error' && 'pr-64',
			passwordInput && condition !== 'error' && 'pr-40',
			condition === 'disabled' && [
				'ring-gray-50',
				'bg-gray-50',
				'text-gray-400',
				'placeholder-gray-400',
				'disabled:cursor-not-allowed',
			]
		),
};

const VisualInput = React.forwardRef<HTMLInputElement, Props>(
	({ condition, passwordInput = false, ...inputProps }, ref) => {
		return (
			<input
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...inputProps}
				className={styles.input(condition, passwordInput)}
				ref={ref}
			/>
		);
	}
);

export { VisualInput };
