import { handleActions } from '../..';
import * as actions from './actions';
import { OrganisationPaymentState } from './types';
import { OrganisationPayment } from '../../../api/types/organisationPayment';

const initialState: OrganisationPaymentState = {
	item: {} as OrganisationPayment,
	fetched: false,
	fetching: false,
};

export default handleActions<OrganisationPaymentState, PossibleActions<typeof actions>>(
	{
		ORGANISATION_PAYMENT_STATE_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				item: payload,
			};
		},
	},
	initialState
);
